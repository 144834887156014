import React, { useEffect, useState } from "react";
import Datatable from "../../components/datatable";
import { useDispatch } from "../../store";
import { useLocation, useNavigate } from "react-router-dom";
import MainLayout from "../layout/MainLayout";
import { employeeThunks } from "../../store/reducers/employee/employee.thunk";
import Select from "react-select";
import { reportThunk } from "../../store/reducers/report/report.thunk";

const EmployeeWiseReport2 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [reportData, setReportData] = useState([]);
  const [option, setOption] = useState({});
  const [employeeList,setEmployeeList] = useState([])
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const location = useLocation();
  const pathnameSegments = location.pathname.split("/");
  const moduleName = pathnameSegments[1];

  const allowedModules = JSON.parse(localStorage.getItem("allowedModules"));
  const selectedModule = allowedModules.find(
    (module) => module.slug === moduleName
  );

  const moduleId = selectedModule.uid;

 
  const columns = [
    {
      name: "date",
      label: "date",
    },
    {
      name: "empCode",
      label: "Emp Code",
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "projectName",
      label: "project name",
    },
   
    {
        name: "normalHours",
        label: "normal hours",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "normalOT",
        label: "normal ot",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "extraOT",
        label: "extra ot",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "sickLeaves",
        label: "sick leave",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "annualLeaves",
        label: "annual leave",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "lossOfPay",
        label: "loss of pay",
        options: {
          filter: false,
          sort: true,
        },
      },
  ];

  useEffect(() => {
    employeeListApi()
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };
  const employeeListApi = async () => {
    try {
      const response = await dispatch(
        employeeThunks.employeeList({moduleId })
      );
      if (employeeThunks.gratuityEmployeeList.fulfilled.match(response)) {
        if (response.payload) {
          setEmployeeList(response.payload);
          return response.payload;
        }
      }
    } catch (error) {
      console.error("Error fetching company data:", error);
    }
  };

  const handleEmployeeChange = (selectedOptions) => {
    const selectedEmployeeUids = selectedOptions.map((employee) => employee.value);
    setSelectedEmployee(selectedEmployeeUids);
  };
  
  const generateEmployeeReport = async (page=1,rowsPerPage=10,searchText='',name='',order='') => {
    try {
      const data = {
        employees : selectedEmployee,
        startDate : startDate,
        endDate : endDate
      }
   
        const response = await dispatch(reportThunk.employeeWiseReportList2({data, moduleId, page, rowsPerPage, searchText, name, order }));
        if (reportThunk.employeeWiseReportList2.fulfilled.match(response)) {
          if (response.payload) {
            const options = {
              count: response.payload.totalRecords,
              page: response.payload.currentPage - 1, // Zero-based index
              rowsPerPage: response.payload.recordPerPage,
            }
            setOption(options)
            setReportData(response.payload.data);
            return response.payload;
          }
        }
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };


  const breadcrumb = {
    mainHeader: "Employee Wise Report",
    subHeader: "Home",
    subHeaderLink: "/dashboard",
    subHeader1: "Report",
    subHeader1Link: null,
    subHeader2: null,
    subHeader2Link: null,
  };


  return (
    <MainLayout breadcrumb={breadcrumb}>
      <div className="card">
      <div className="card-body">
      <div className="form-group row d-flex justify-content-center mt-5">
          <div className="col-md-2">
                    <input 
                     type="date"
                    placeholder="Select From Date"
                    className="form-control"
                    name="startDate"
                    id="startDate"
                    onChange={handleStartDateChange}
                     />
                </div>
                <div className="col-md-2">
                    <input 
                    type="date" 
                    placeholder="Select End Date"
                    className="form-control"
                    name="endDate"
                    id="endDate"
                    onChange={handleEndDateChange}
                    />
                </div>
              <div className="col-sm-4  col-md-4">
              <Select
                      placeholder="Select Employee"
                      isSearchable
                      isMulti
                      id="employeeId"
                      name="employeeId"
                      onChange={handleEmployeeChange}
                      options={employeeList.map((data) => ({
                        value: data.uid,
                        label:  data.employee_code + " - " + data.name,
                      }))}
                    />
              </div>
              <div className="col-md-2">
                    <button className="btn btn-outline-primary" onClick={()=>{generateEmployeeReport()}}>Show Report</button>
                </div>
            </div>
        </div>
        </div>  
      <div className="card">
        <div className="card-body">
        
          <Datatable
            columns={columns}
            data={reportData}
            options={option}
            api={generateEmployeeReport}
          />
        </div>
      </div>
    </MainLayout>
  );
};

export default EmployeeWiseReport2;
