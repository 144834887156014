import React, { useEffect, useState } from 'react';
import { ThemeColor } from '../../../constants/themeColorConstant';
import Datatable from '../../../components/datatable';
import { useDispatch } from '../../../store';
import { useParams } from 'react-router-dom';
import { projectCostingThunk } from '../../../store/reducers/projectCosting/projectCosting.thunk';

const ProjectExpense = ({ moduleId }) => {

  const [projectExpenseData, setProjectExpenseData] = useState()
  const [options, setOption] = useState()
  const dispatch = useDispatch()
  const { id } = useParams();


  const columns1 = [
    {
      name: "projectName",
      label: "Project Name",
    },
    {
      name: "totalProjectedExpense",
      label: "total project expense",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "totalSupplierExpense",
      label: "total supplier expense",
    },
    {
      name: "totalSalaryExpense",
      label: "total Salary Expense",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "totalActualExpense",
      label: "total Actual Expense",
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  useEffect(() => {
    projectExpenseListApi()
  }, [])

  const projectExpenseListApi = async (page = 1, rowsPerPage = 10, searchText = '', name = '', order = '') => {
    try {
      const response = await dispatch(projectCostingThunk.projectWiseTotalExpenseList({ projectId: id, moduleId, page, rowsPerPage, searchText, name, order }));
      if (projectCostingThunk.projectWiseTotalExpenseList.fulfilled.match(response)) {
        if (response.payload) {
          const options = {
            count: response.payload.totalRecords,
            page: response.payload.currentPage - 1, // Zero-based index
            rowsPerPage: response.payload.recordPerPage,
          }
          setOption(options)
          setProjectExpenseData(response.payload.data);
          return response.payload
        }
      }
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };



  const options1 = {
    searchOpen: true,
    search: true,
    print: true,
    pagination: true,
    filter: true,
    download: true,
    viewColumns: true
  }

  return (
    <div>
      <div className="card-body">
        <div className="card-header ">
          <p
            className="card-title"
            style={{
              fontSize: "20px",
              fontWeight: "500",
              color: ThemeColor.EASTERNBLUE,
            }}
          >
            Project Expense
          </p>
        </div>
        <Datatable
          columns={columns1}
          data={projectExpenseData}
          options={options1}
          api={projectExpenseListApi}
        />
      </div>
    </div>
  );
}

export default ProjectExpense;
