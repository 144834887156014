import React, { useEffect, useState } from "react";
import { ThemeColor } from "../../constants/themeColorConstant";
import MainLayout from "../layout/MainLayout";
import { useDispatch } from "../../store";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { roleThunks } from "../../store/reducers/role/role.thunk";
import { userThunks } from "../../store/reducers/users/users.thunk";
import Select from "react-select";

const validationSchema = Yup.object().shape({
  userName: Yup.string()
    .required("Please enter name")
    .matches(
      /^[A-Za-z\s]+$/,
      "Company name must contain only letters and spaces"
    )
    .trim(),
  roleId :Yup.string().required("Please select role") ,  
  permission :Yup.string().required("Please select permission") ,  
  userId: Yup.string().required("Please enter userId"),
  password: Yup.string().required("Please enter password"),
  mobileNumber: Yup.string()
  .required("Please enter mobile number")
  .matches(/^\d+$/, "Mobile number should only contain numbers"),
});

const AddUser = () => {

  const navigate = useNavigate()

  /* eslint-disable no-unused-vars */
  const [activeTab, setActiveTab] = useState("AddUser");
  /* eslint-disable no-unused-vars */
  
  const [selectedImage, setSelectedImage] = useState(null);
   const [roleData,setRoleData] = useState([]) 
   const [permissionList,setPermissionList] = useState([])
    

  const dispatch = useDispatch();
  const location = useLocation();
  const pathnameSegments = location.pathname.split("/");
  const moduleName = pathnameSegments[1];

  const allowedModules = JSON.parse(localStorage.getItem("allowedModules"));
  const selectedModule = allowedModules.find(
    (module) => module.slug === moduleName
  );

  const moduleId = selectedModule.uid;

  const {
    errors,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
   setFieldValue
  } = useFormik({
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
    await addUserApi(values);
    },
    initialValues: {
    },
  });

  useEffect(() => {
    getPermissionApi()
    getRoleListApi()
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRoleListApi = async () => {
    try {
      const response = await dispatch(roleThunks.roleData({moduleId}));
      if (roleThunks.roleData.fulfilled.match(response)) {
        if (response.payload) {
          setRoleData(response.payload.data);
          return response.payload
        }
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };


  
  const addUserApi = async (data) => {
    try {
      const response = await dispatch(
        userThunks.addUser({ data, moduleId })
      );
      if (userThunks.addUser.fulfilled.match(response)) {
        navigate("/hrms-user")
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const getPermissionApi = async () => {
    try {
      const response = await dispatch(userThunks.userPermission({moduleId}));
      if (userThunks.userPermission.fulfilled.match(response)) {
        if (response.payload) {  
          setPermissionList(response.payload);
          return response.payload
        }
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };



  const breadcrumb = {
    mainHeader: "Manage User",
    subHeader : "Home",
    subHeaderLink : "/dashboard",
    subHeader1 : "Users",
    subHeader1Link : "/hrms-user",
    subHeader2 : "Add",
    subHeader2Link : null,
  };

  return (
    <MainLayout breadcrumb={breadcrumb}>
      <div className="card card-info">
        <div className="card-body">
          <ul
            className="nav nav-tabs"
            style={{
              borderBottom: `2px solid ${ThemeColor.GREY}`,
              backgroundColor: "transparent",
            }}
          >
            <li className="nav-item">
              <button
                className={`nav-link ${
                  activeTab === "AddUser" ? "active" : ""
                }`}
                style={{
                  borderBottom: `3px solid ${
                    activeTab === "AddUser" ? ThemeColor.BLUE : "transparent"
                  }`,
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  backgroundColor: "transparent",
                }}
              >
                Add User
              </button>
            </li>
          </ul>
          <form className="form-horizontal" onSubmit={handleSubmit}>
            <div className="card-body">
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">Photo</label>
                <div className="col-sm-5  col-md-5">
                  {selectedImage && (
                    <img
                      src={URL.createObjectURL(selectedImage)}
                      alt="Employee profile"
                        //width="165px"
                        height="165px"
                    />
                  )}
                  <input
                    type="file"
                    name="profilePic"
                    id="profilePic"
                    onChange={(event) => {
                        const file = event.target.files[0];
                        setSelectedImage(file);
                        const fileExtension = file.name.split('.').pop();
                        const reader = new FileReader();
                        reader.onload = (e) => {
                          const base64Data = e.target.result;
                          const profilePicObject = {};
                          profilePicObject[`emp.${fileExtension}`] = base64Data;
                      
                        setFieldValue("profilePic", profilePicObject);
                        };
                        reader.readAsDataURL(file);
                      }}
                  />
                </div>

              </div>
              <div className="form-group row">
                  <label className="col-sm-4 col-md-3">
                    Role <span style={{ color: ThemeColor.RED }}>*</span>
                  </label>
                  <div className="col-sm-5  col-md-5">
                    <Select
                      placeholder="Select Role"
                      isSearchable
                      id="roleId"
                      name="roleId"
                      onChange={(selectedOption) => {
                        setFieldValue(
                          "roleId",
                          selectedOption ? selectedOption.value : ""
                        );
                      }}
                      options={roleData.map((data) => ({
                        value: data.uid,
                        label: data.name,
                      }))}
                    />
                    {errors.role && touched.role && (
                      <div className="error" style={{ color: ThemeColor.RED }}>
                        {errors.role}
                      </div>
                    )}
                  </div>
              </div>
              <div className="form-group row">
                  <label className="col-sm-4 col-md-3">
                  Permision <span style={{ color: ThemeColor.RED }}>*</span>
                  </label>
                  <div className="col-sm-5  col-md-5">
                    <Select
                      placeholder="Select Permission"
                      isSearchable
                      id="permission"
                      name="permission"
                      onChange={(selectedOption) => {
                        setFieldValue(
                          "permission",
                          selectedOption ? selectedOption.value : ""
                        );
                      }}
                      options={roleData.map((data) => ({
                        value: data.slug,
                        label: data.name,
                      }))}
                    />
                    {errors.permission && touched.permission && (
                      <div className="error" style={{ color: ThemeColor.RED }}>
                        {errors.permission}
                      </div>
                    )}
                  </div>
              </div>
              <div className="form-group row">
                  <label className="col-sm-4 col-md-3">
                   User Name <span style={{ color: ThemeColor.RED }}>*</span>
                  </label>
                  <div className="col-sm-5  col-md-5">
                    <input
                      type="text"
                      className="form-control"
                      name="userName"
                      id="userName"
                      placeholder="Name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.userName && touched.userName && (
                      <div className="error" style={{ color: ThemeColor.RED }}>
                        {errors.userName}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">
                   User ID <span style={{ color: ThemeColor.RED }}>*</span>
                  </label>
                  <div className="col-sm-5  col-md-5">
                    <input
                      type="text"
                      className="form-control"
                      name="userId"
                      id="userId"
                      placeholder="User Id"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.userId && touched.userId && (
                      <div className="error" style={{ color: ThemeColor.RED }}>
                        {errors.userId}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">
                   Passowrd <span style={{ color: ThemeColor.RED }}>*</span>
                  </label>
                  <div className="col-sm-5  col-md-5">
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      id="password"
                      placeholder="Password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.password && touched.password && (
                      <div className="error" style={{ color: ThemeColor.RED }}>
                        {errors.password}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">
                   Mobile <span style={{ color: ThemeColor.RED }}>*</span>
                  </label>
                  <div className="col-sm-5  col-md-5">
                    <input
                      type="text"
                      className="form-control"
                      name="mobileNumber"
                      id="mobileNumber"
                      placeholder="Mobile Number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.mobileNumber && touched.mobileNumber && (
                      <div className="error" style={{ color: ThemeColor.RED }}>
                        {errors.mobileNumber}
                      </div>
                    )}
                  </div>
                </div>
            </div>

            <div className="d-flex align-items-center justify-content-center">
              <button
                type="submit"
                className="btn btn-primary"
                style={{ padding: "8px 20px" }}
              >
                <i className="fa fa-edit" style={{ marginRight: "8px" }} />
                Add
              </button>
              <button
                type="button"
                className="btn btn-default"
                style={{ padding: "8px 20px", marginLeft: "10px" }}
                onClick={()=>{
                  navigate("/hrms-user")
                }}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </MainLayout>
  );
};

export default AddUser;
