import React, { useState, useEffect } from "react";

import Datatable from "../../components/datatable";
import SalaryChart from "./SalaryChart";
import ExpenseChart from "./ExpenseChart";
import OverTimeChart from "./OverTimeChart";

import { useDispatch } from "../../store";
import { companyThunks } from "../../store/reducers/company/company.thunk";
import YearSelector from "./YearSelector";

const laborCardCols = [
  {
    label: "Emp Code",
    name: "employeeCode",
  },
  {
    label: "Name",
    name: "name",
  },
  {
    label: "Labor Expiry",
    name: "date",
  },
];
const visaCardCols = [
  {
    label: "Emp Code",
    name: "employeeCode",
  },
  {
    label: "Name",
    name: "name",
  },
  {
    label: "Visa Expiry",
    name: "date",
  },
];
const passportCardCols = [
  {
    label: "Emp Code",
    name: "employeeCode",
  },
  {
    label: "Name",
    name: "name",
  },
  {
    label: "Passport Expiry",
    name: "date",
  },
];

const tableOptions = {
  pagination: false,
  search: false,
  print: false,
  download: false,
  viewColumns: true,
  filter: false,
};

const Chart = () => {
  const [chartData, setChartData] = useState({});
  const [selectedYear, setSelectedYear] = useState({
    salaryYear: new Date().getFullYear(),
    expenseNDeductionsYear: new Date().getFullYear(),
    extraNNormalOtYear: new Date().getFullYear(),
  });
  const dispatch = useDispatch();

  const getDashboardDetails = async () => {
    const data = {
      salaryYears: [2024, 2023, 2022],
      expenseNDeductionsYears: [2024, 2023, 2022],
      extraNNormalOtYears: [2024, 2023, 2022],
    };
    try {
      const response = await dispatch(companyThunks.getDashboardDetails(data));
      if (response.payload) {
        localStorage.setItem("dashboard", JSON.stringify(response.payload));
        if (
          localStorage.getItem("dashboard") &&
          JSON.parse(localStorage.getItem("dashboard"))
        ) {
          const dashboardData = JSON.parse(localStorage.getItem("dashboard"));
          setChartData({
            ...dashboardData,
            salary: dashboardData.salary[selectedYear.salaryYear],
            expenseNDeductions:
              dashboardData.expenseNDeductions[
                selectedYear.expenseNDeductionsYear
              ],
            extraNNormalOt: {
              extraOt:
                dashboardData.extraNNormalOt.extraOt[
                  selectedYear.extraNNormalOtYear
                ],
              normalOt:
                dashboardData.extraNNormalOt.normalOt[
                  selectedYear.extraNNormalOtYear
                ],
            },
          });
        }
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const handleYearSelection = (e, key) => {
    console.log("E ###", key);
    setSelectedYear((prev) => {
      return {
        ...prev,
        [key]: e.target.value,
      };
    });
  };

  useEffect((_) => {
    (async (_) => {
      await getDashboardDetails();
    })();
  }, []);

  useEffect(
    (_) => {
      if (localStorage.getItem("dashboard")) {
        const dashboardData = JSON.parse(localStorage.getItem("dashboard"));

        console.log(
          "Salary Data on change ####",
          dashboardData.salary[selectedYear.salaryYear]
        );
        console.log(
          "Expense Deduction Data on change ####",
          dashboardData.expenseNDeductions[selectedYear.expenseNDeductionsYear]
        );
        console.log(
          "OT Data on change ####",
          dashboardData.extraNNormalOt.extraOt[selectedYear.extraNNormalOtYear],
          dashboardData.extraNNormalOt.normalOt[selectedYear.extraNNormalOtYear]
        );
        setChartData({
          ...dashboardData,
          salary: dashboardData.salary[selectedYear.salaryYear],
          expenseNDeductions:
            dashboardData.expenseNDeductions[
              selectedYear.expenseNDeductionsYear
            ],
          extraNNormalOt: {
            extraOt:
              dashboardData.extraNNormalOt.extraOt[
                selectedYear.extraNNormalOtYear
              ],
            normalOt:
              dashboardData.extraNNormalOt.normalOt[
                selectedYear.extraNNormalOtYear
              ],
          },
        });
      }
    },
    [selectedYear]
  );

  return (
    <section className="content">
      <div className="container-fluid">
        <div className="row mb-3">
          <div className="col-sm-4 dashboard-table-wrapper">
            <h5>Labor Expiry</h5>
            <Datatable
              responsive
              data={chartData.laborCard}
              columns={laborCardCols}
              options={tableOptions}
            />
          </div>
          <div className="col-sm-4 dashboard-table-wrapper">
            <h5>Visa Expiry</h5>
            <Datatable
              responsive
              data={chartData.visa}
              columns={visaCardCols}
              options={tableOptions}
            />
          </div>
          <div className="col-sm-4 dashboard-table-wrapper">
            <h5>Passport Expiry</h5>
            <Datatable
              responsive
              data={chartData.passport}
              columns={passportCardCols}
              options={tableOptions}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <div className="card card-primary">
              <div className="card-body">
                <div className="chart-title-container">
                  <h5>Salary chart</h5>
                  <YearSelector
                    value={selectedYear.salaryYear}
                    handleChange={handleYearSelection}
                    yearKey="salaryYear"
                  />
                </div>
                <div className="chart-container">
                  <SalaryChart
                    selectedYear={selectedYear.salaryYear}
                    data={chartData.salary}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="card card-info">
              <div className="card-body">
                <div className="chart-title-container">
                  <h5>Expense chart</h5>
                  <YearSelector
                    value={selectedYear.expenseNDeductionsYear}
                    handleChange={handleYearSelection}
                    yearKey="expenseNDeductionsYear"
                  />
                </div>
                <div className="chart-container">
                  <ExpenseChart
                    selectedYear={selectedYear.expenseNDeductionsYear}
                    data={chartData.expenseNDeductions}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <div className="card card-primary">
              <div className="card-body">
                <div className="chart-title-container">
                  <h5>OverTime chart</h5>
                  <YearSelector
                    value={selectedYear.extraNNormalOtYear}
                    handleChange={handleYearSelection}
                    yearKey="extraNNormalOtYear"
                  />
                </div>
                <div className="chart-container">
                  <OverTimeChart
                    selectedYear={selectedYear.extraNNormalOtYear}
                    data={chartData.extraNNormalOt}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6"></div>
        </div>
      </div>
    </section>
  );
};

export default Chart;
