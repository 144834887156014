import React from 'react'
import { useFormik } from "formik";
import { useDispatch } from '../../store';
import { useParams } from 'react-router';
import { bankThunks } from '../../store/reducers/bank/bank.thunk';


const AccountDetails = ({activeTab,moduleId,employeeData})=> {

  const dispatch = useDispatch()
  const {id} = useParams()
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
  } = useFormik({
    enableReinitialize: true,
    onSubmit: async(values) => {
     await updateWPSDetailsApi(values)
    },
    initialValues: {
      wpsIbanNumber : employeeData.bankDetail ? employeeData.bankDetail.wpsIbanNumber : null,
      wpsBankName : employeeData.bankDetail ? employeeData.bankDetail.wpsBankName : null,
      wpsPersonalNumber :  employeeData.bankDetail ? employeeData.bankDetail.wpsPersonalNumber : null,
      wpsRouteCode :  employeeData.bankDetail ? employeeData.bankDetail.wpsRouteCode : null,
    },
  });

 const updateWPSDetailsApi = async (data) => {
    try {
      const response = await dispatch(bankThunks.updateBankDetails({id,data,moduleId,activeTab}));
      if (bankThunks.updateBankDetails.fulfilled.match(response)) {
        setValues(data)
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  return (
    <form className="form-horizontal" id="education_info" onSubmit={handleSubmit}>
    <div className="card-body">
      <div className="form-group row">
        <label className="col-sm-4 col-md-3">IBAN Number</label>
        <div className="col-sm-5  col-md-5">
          <input
            type="text"
            className="form-control"
            name="wpsIbanNumber"
            id="wpsIbanNumber"
            placeholder="IBAN Number"
           value={values.wpsIbanNumber}
           onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-sm-4 col-md-3">Personal Number</label>
        <div className="col-sm-5  col-md-5">
          <input
            type="text"
            className="form-control"
            name="wpsPersonalNumber"
            id="wpsPersonalNumber"
            placeholder="Personal Number"
           value={values.wpsPersonalNumber}
           onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-sm-4 col-md-3">Bank Name</label>
        <div className="col-sm-5  col-md-5">
          <input
            type="text"
            className="form-control"
            name="wpsBankName"
            id="wpsBankName"
            placeholder="Bank Name"
           value={values.wpsBankName}
           onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-sm-4 col-md-3">Route Code</label>
        <div className="col-sm-5  col-md-5">
          <input
            type="text"
            className="form-control"
            name="wpsRouteCode"
            id="wpsRouteCode"
            placeholder="Route Code"
           value={values.wpsRouteCode}
           onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      </div>
    </div>
    <div className="d-flex align-items-center justify-content-center">
      <button
        type="submit"
        className="btn btn-primary"
        style={{ padding: "8px 20px" }}
      >
        <i className="fa fa-floppy-o" style={{ marginRight: "8px" }} />
        Update
      </button>
    </div>
  </form>
  )
}

export default AccountDetails