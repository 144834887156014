import React, { useEffect, useState } from "react";
import Datatable from "../../components/datatable";
import { useDispatch } from "../../store";
import { ThemeColor } from "../../constants/themeColorConstant";
import { useLocation, useNavigate } from "react-router-dom";
import MainLayout from "../layout/MainLayout";
import { payrollThunks } from "../../store/reducers/payroll/payroll.thunk";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const PayrollSummary = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [payrollSummaryData, setPayrollSummaryData] = useState([]);
  const [selectedMonthYear, setSelectedMonthYear] = useState("");
  const [option, setOption] = useState({});


  const location = useLocation();
  const pathnameSegments = location.pathname.split("/");
  const moduleName = pathnameSegments[1];

  const allowedModules = JSON.parse(localStorage.getItem("allowedModules"));
  const selectedModule = allowedModules.find(
    (module) => module.slug === moduleName
  );

  const moduleId = selectedModule.uid;



  const columns = [
    {
      name: "modeOfPayment",
      label: "MODE OF PAYMENT",
    },
    {
      name: "amount",
      label: "AMOUNT",
      options: {
        filter: false,
        sort: true,
      },
    },

  ];

  const handleMonthYearChange = async (event) => {
    const selectedDate = event.target.value;
    setSelectedMonthYear(selectedDate);
  };

  const getPayrollSummaryApi = async (page = 1, rowsPerPage = 10, searchText = '', name = '', order = '') => {
    try {
      // alert(payrollSummaryData)
      const formattedMonthYear = selectedMonthYear.split('-').reverse().join('/');
      const response = await dispatch(payrollThunks.payrollSummary({ selectedMonthYear: formattedMonthYear, moduleId, page, rowsPerPage, searchText, name, order }));
      if (payrollThunks.payrollSummary.fulfilled.match(response)) {
        if (response.payload) {
          const options = {
            count: response.payload.totalRecords,
            page: response.payload.currentPage - 1, // Zero-based index
            rowsPerPage: response.payload.recordPerPage,
          }
          setOption(options)
          setPayrollSummaryData(response.payload[0]);
          return response.payload;
        }
      }
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };

  const breadcrumb = {
    mainHeader: "Payroll Sumary",
    subHeader: "Home",
    subHeaderLink: "/dashboard",
    subHeader1: "report",
    subHeader1Link: null,
    subHeader2: null,
    subHeader2Link: null,
  };
  return (
    <MainLayout breadcrumb={breadcrumb}>
      <div className="card">
        <div className="card-body">
          <div className="card-header ">
            <p
              className="card-title"
              style={{
                fontSize: "20px",
                fontWeight: "500",
                color: ThemeColor.EASTERNBLUE,
              }}
            >
              Payroll Summary
            </p>
          </div>
          <div className="form-group row d-flex justify-content-center mt-5">
            <div className="col-sm-4  col-md-4">
              <input type="month"
                name="month"
                id="month"

                className="form-control"
                value={selectedMonthYear}
                onChange={handleMonthYearChange}
              />
            </div>

            <div className="col-sm-4  col-md-4">

              <button type="submit" className="btn btn-primary" onClick={() => getPayrollSummaryApi()}>
                Show Report
              </button>

            </div>
          </div>

          <TableContainer  component={Paper}>
       
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead sx={{backgroundColor: `${ThemeColor.BLUE} !important`}}>
                <TableRow>
                  <TableCell align="center">MODE OF PAYMENT</TableCell>
                  <TableCell align="center">AMOUNT</TableCell>
                </TableRow>
              </TableHead>
              {payrollSummaryData != ''? 
              <TableBody>
                <TableRow
                >
                  <TableCell component="th" scope="row" align="right">
                    Total Round off
                  </TableCell>
                  <TableCell align="left">{payrollSummaryData.totalRoundOff}</TableCell>
                </TableRow>
                <TableRow
                >
                  <TableCell component="th" scope="row" align="right">
                    WPS
                  </TableCell>
                  <TableCell align="left">{payrollSummaryData.wps}</TableCell>
                </TableRow>
                <TableRow
                >
                  <TableCell component="th" scope="row" align="right">
                    Cash
                  </TableCell>
                  <TableCell align="left">{payrollSummaryData.cash}</TableCell>
                </TableRow>
                <TableRow
                >
                  <TableCell align="right" component="th" scope="row">
                    Total Amount
                  </TableCell>
                  <TableCell align="left">{payrollSummaryData.totalAmount}</TableCell>
                </TableRow>
              </TableBody>
              :
                <TableRow align="center">No data</TableRow>
              }
            </Table>
          </TableContainer>
          {/* <Datatable columns={columns} data={payrollSummaryData} options={option} /> */}
        </div>
      </div>
    </MainLayout>
  );
};

export default PayrollSummary;
