import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import { processMonth } from "../../utils/processMonth";

function CustomLegend({ selectedYear }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginRight: "2em",
        }}
      >
        <div
          style={{
            width: "40px",
            height: "15px",
            backgroundColor: "#8884d8",
            marginRight: "1em",
          }}
        ></div>
        <p style={{ margin: 0 }}>Extra OT</p>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: "40px",
            height: "15px",
            backgroundColor: "#82ca9d",
            marginRight: "1em",
          }}
        ></div>
        <p style={{ margin: 0 }}>Normal OT</p>
      </div>
    </div>
  );
}

export default function OverTimeChart({ data, selectedYear }) {
  const processedData = [];
  if (data) {
    Object.keys(data).forEach((key) => {
      Object.keys(data[key]).forEach((newKey) => {
        processedData.push({
          name: processMonth(newKey),
          extraOT: data["extraOt"][newKey],
          normalOT: data["normalOt"][newKey],
        });
      });
    });

    console.log("Processed Data #", processedData);
  }

  return (
    <ResponsiveContainer>
      <AreaChart
        data={processedData}
        margin={{ top: 5, right: -10, left: -40, bottom: 0 }}
      >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis dataKey="name" />
        <YAxis />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Area
          type="monotone"
          dataKey="extraOT"
          stroke="#8884d8"
          fillOpacity={1}
          fill="url(#colorUv)"
        />
        <Area
          type="monotone"
          dataKey="normalOT"
          stroke="#82ca9d"
          fillOpacity={1}
          fill="url(#colorPv)"
        />
        <Legend content={<CustomLegend selectedYear={selectedYear} />} />
      </AreaChart>
    </ResponsiveContainer>
  );
}
