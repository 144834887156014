import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  allowedModules: [],
  user : null
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAllowedModules: (state, action) => {
      state.allowedModules = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    }
  },
});

export const { setAllowedModules,setUser} = authSlice.actions;

export default authSlice.reducer;