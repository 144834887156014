import React, { useEffect, useState } from "react";
import Datatable from "../../components/datatable";

import { useDispatch} from "../../store";
import { ThemeColor } from "../../constants/themeColorConstant";
import { useLocation, useNavigate} from "react-router-dom";
import MainLayout from "../layout/MainLayout";
import { insuranceThunks } from "../../store/reducers/insurance/insurance.thunk";


const Insurance = () => {
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [insuranceDetails, setInsuranceDetails] = useState([]);
  const [option,setOption] = useState({})

  const location = useLocation();
  const pathnameSegments = location.pathname.split('/');
  const moduleName = pathnameSegments[1];
  
  const allowedModules = JSON.parse(localStorage.getItem("allowedModules"));
  const selectedModule = allowedModules.find(
    (module) => module.slug === moduleName
  );

  const moduleId = selectedModule.uid

  const renderActions = (rowData) => {

    const employeeId = rowData.uid;
    const editIcon = (
      <i
        className="fa fa-edit"
        style={{cursor:"pointer"}}
       onClick={() => navigate(`/insurance/edit/${employeeId}`, { state: { moduleId } })}
      />
    );
    return (
      <div>
        {editIcon}
      </div>
    );
  };

  const columns = [
    {
      name: "action",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const rowData = insuranceDetails[dataIndex];
          return renderActions(rowData);
        },
      },
    },
    {
      name: "employeeCode",
      label: "Employee Code",
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "designationName",
      label: "Designation",
    },
    {
      name: "healthInsurProvider",
      label: "Health Insurance provider",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "healthPolicyNo",
      label: "Health Policy number",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "healthInsuexpiry",
      label: "Health Insurance Expiry",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "worksmanInsuProvider",
      label: "Workman Insurance provider",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "worksmanPolicyNo",
      label: "Workman policy number",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "worksmanInsuExpiry",
      label: "Workman policy expiry",
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  useEffect(() => {
    geInsuranceDetailsApi()
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  

  const geInsuranceDetailsApi = async (page=1,rowsPerPage=10,searchText='',name='',order='') => {
    try {
      const response = await dispatch(insuranceThunks.insuranceData({moduleId,page,rowsPerPage,searchText,name,order}));
      if (insuranceThunks.insuranceData.fulfilled.match(response)) {
        if (response.payload) {
          const options = {
            count: response.payload.totalRecords,
            page: response.payload.currentPage-1, // Zero-based index
            rowsPerPage:response.payload.recordsPerPage,
          }
          setOption(options)
          setInsuranceDetails(response.payload.data);
          return response.payload
        }
      }
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };
  
  const breadcrumb = {
    mainHeader: "Insurance Master",
    subHeader: "Home",
    subHeaderLink: "/dashboard",
    subHeader1: "Insurance Master",
    subHeader1Link:null,
    subHeader2: null,
    subHeader2Link: null,
  };

  return (
    <MainLayout breadcrumb={breadcrumb}>
      <div className="card">
        <div className="card-body">
          <div className="card-header ">
            <p
              className="card-title"
              style={{
                fontSize: "20px",
                fontWeight: "500",
                color: ThemeColor.EASTERNBLUE,
              }}
            >
              Insurance Master
            </p>
          </div>

          <Datatable columns={columns} data={insuranceDetails} options={option} customBodyRender={renderActions} api={geInsuranceDetailsApi}  />
        </div>
      </div>
    </MainLayout>
  );
};

export default Insurance;
