import axios from "axios";
import { handleLogout } from "../constants/methodsConstant";
import {
  ToastType,
  setToastContent,
} from "../store/reducers/toast/toast.slice";
import { dispatch } from "../store";

export const ApiCall = async (
  method = "",
  url = "",
  data = {},
  moduleId = ""
) => {
  let baseURL = process.env.REACT_APP_BASE_URL;
  let response = null;
  let token = localStorage.getItem("accessToken")
    ? localStorage.getItem("accessToken")
    : "";

  const apiUrl = `${baseURL}/${url}`;

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    "module-id": moduleId,
  };

  data = JSON.stringify(data);
  try {
    const response = await axios({ method, url: apiUrl, headers, data });
    return response.data;
  } catch (e) {
    console.error(e);
    if (e?.response) {
      if (e.response.status == 401) {
        // handleLogout();
      }
      dispatch(
        setToastContent({
          type: ToastType.error,
          message: e?.response?.data?.message || "Something went wrong!",
        })
      );
    } else {
      dispatch(
        setToastContent({
          type: ToastType.error,
          message: e?.response?.data?.message || "Something went wrong!",
        })
      );
    }
  }
  return response;
};
