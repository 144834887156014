import React, { useEffect, useState } from "react";
import Datatable from "../../components/datatable";
import MainLayout from "../layout/MainLayout";
import { useDispatch } from "../../store";
import { useLocation, useNavigate } from "react-router-dom";
import { clientThunks } from "../../store/reducers/client/client.thunk";
import { ThemeColor } from "../../constants/themeColorConstant";
import ConfirmationBox from "../../components/ConfirmationBox";

const Client = () => {

  const dispatch = useDispatch();
  const [clientData, setClientData] = useState([]);
  const [option,setOption] = useState({})

  const navigate = useNavigate()
  const location = useLocation();
  const pathnameSegments = location.pathname.split('/');
  const moduleName = pathnameSegments[1];

  
  const allowedModules = JSON.parse(localStorage.getItem("allowedModules"));
  const selectedModule = allowedModules.find((module) => module.slug === moduleName);
  const moduleId = selectedModule.uid

  const renderActions = (rowData) => {
    if (rowData && rowData.uid) {
    const clientId = rowData.uid;

    const contactIcon = (
      <i
        className="fa fa-phone"
        style={{cursor:"pointer"}}
        onClick={() => navigate(`/clients/contact-manage/${clientId}`, { state: { moduleId } })}
      />
    );

    const editIcon = (
      <i
        className="fa fa-edit"
        style={{cursor:"pointer",marginLeft:"10px"}}
        onClick={() => navigate(`/clients/edit/${clientId}`, { state: { moduleId } })}
      />
    );
  

    return (
      <div>
        {contactIcon}
        {editIcon}
      </div>
    );}
    else{
      return null
    }
  };

  const columns = [
    {
        name: "action",
        label: "Action",
        options: {
          customBodyRenderLite: (dataIndex) => {
            const rowData = clientData[dataIndex];
            return renderActions(rowData);
          },
        },
      },
    {
      name: "shortName",
      label: "SHORT",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
        name: "clientName",
        label: "CLIENT NAME",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "businessType",
        label: "BUSINESS TYPE",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "descipline",
        label: "DISCIPLINE",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "poBoxNumber",
        label: "PO Box",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "tel1Number",
        label: "TEL-1",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "tel2Number",
        label: "TEL-2",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "faxNumber",
        label: "FAX",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "email",
        label: "EMAIL",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "address",
        label: "ADDRESS",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "currentStatus",
        label: "CURRENT STATUS",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "rating",
        label: "RATING",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "future",
        label: "FUTURE",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "marketingStatus",
        label: "MARKETING STATUS",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "remark",
        label: "REMARK",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "regNo",
        label: "Registration Number",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "website",
        label: "WEBSITE",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "estYear",
        label: "EST YEAR",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "legalStatus",
        label: "LEGAL STATUS",
        options: {
          filter: false,
          sort: true,
        },
      },
  ];

  useEffect(()=>{
    getClientListApi(1,10)
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const getClientListApi = async (page,rowsPerPage,searchText='',name='',order='') => {
    try {
      const response = await dispatch(clientThunks.clientData({moduleId,page,rowsPerPage,searchText,name,order}));
      if (clientThunks.clientData.fulfilled.match(response)) {
        if (response.payload) {
          const options = {
            count: response.payload.totalRecords,
            page: response.payload.currentPage-1, // Zero-based index
            rowsPerPage:response.payload.recordsPerPage,
          }
          setOption(options)
          setClientData(response.payload.data);
          return response.payload
        }
      }
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };


  const breadcrumb = {
    mainHeader: "Clients Manage",
    subHeader: "Home",
    subHeaderLink: "/dashboard",
    subHeader1: "Clients Manage",
    subHeader1Link: null,
    subHeader2: null,
    subHeader2Link: null,
  };

  return (
    <MainLayout breadcrumb={breadcrumb}>
    <div className="card">
      <div className="card-body">
        <div className="card-header ">
          <p
            className="card-title"
            style={{
              fontSize: "20px",
              fontWeight: "500",
              color: ThemeColor.EASTERNBLUE,
            }}
          >
           Clients Manage
          </p>
          <div className="d-flex justify-content-end">
            <button
              onClick={() =>
                navigate("/clients/add", { state: { moduleId } })
              }
              className="btn btn-primary"
            >
              Add
            </button>
            
          </div>
        </div>

        <Datatable columns={columns} data={clientData} options={option} customBodyRender={renderActions} api={getClientListApi} />
      </div>
    </div>
  </MainLayout>
  );
};

export default Client;
