import React from 'react'
import { useFormik } from "formik";
import { useDispatch } from '../../store';
import { useParams } from 'react-router';
import { authorityThunks } from '../../store/reducers/authority/authority.thunk';
import { parseCustomDate } from '../employee/Personal';
import Datepicker from '../../components/datepicker';


const LaborDepartment = ({activeTab,moduleId,employeeData})=> {
  const dispatch = useDispatch()
  const {id} = useParams()
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
  } = useFormik({
    enableReinitialize: true,
    onSubmit: async(values) => {

      const formattedValues = {
        ...values,
        laborDepartmentExpiryDate: values.laborDepartmentExpiryDate && parseCustomDate(values.laborDepartmentExpiryDate),
      };

      await updateLaborDetailsApi(formattedValues)
    },
    initialValues: {

      laborDepartmentExpiryDate : employeeData.authority ? employeeData.authority.laborDepartmentExpiryDate : null,
      laborContractNumber : employeeData.authority ? employeeData.authority.laborContractNumber : null,
      workPermitNumber : employeeData.authority ? employeeData.authority.workPermitNumber : null,
      personalNumber : employeeData.authority ? employeeData.authority.personalNumber : null,
    },
  });


  const updateLaborDetailsApi = async (data) => {
    try {
      const response = await dispatch(authorityThunks.updateAuthoritiesDetails({id,data,moduleId,activeTab}));
      if (authorityThunks.updateAuthoritiesDetails.fulfilled.match(response)) {
        return response
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  return (
    <form className="form-horizontal" id="education_info" onSubmit={handleSubmit}>
    <div className="card-body">
      <div className="form-group row">
        <label className="col-sm-4 col-md-3">Labor Contract Number</label>
        <div className="col-sm-5  col-md-5">
          <input
            type="text"
            className="form-control"
            name="laborContractNumber"
            id="laborContractNumber"
            placeholder="Labor Contract Number"
           value={values.laborContractNumber}
           onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-sm-4 col-md-3">Work Permit Number</label>
        <div className="col-sm-5  col-md-5">
          <input
            type="text"
            className="form-control"
            name="workPermitNumber"
            id="workPermitNumber"
            placeholder="Work Permit Number"
           value={values.workPermitNumber}
           onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      </div>  
     <div className="form-group row">
        <label className="col-sm-4 col-md-3">Personal Number</label>
        <div className="col-sm-5  col-md-5">
          <input
            type="text"
            className="form-control"
            name="personalNumber"
            id="personalNumber"
            placeholder="Personal Number"
           value={values.personalNumber}
           onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-sm-4 col-md-3">Expiry Date</label>
        <div className="col-sm-5  col-md-5">
              <Datepicker
              name="laborDepartmentExpiryDate"
              id="laborDepartmentExpiryDate"
              value={values.laborDepartmentExpiryDate}
              dateFormat="yyyy-mm-dd"
              onChange={(date) =>
                setValues({
                  ...values,
                  laborDepartmentExpiryDate: parseCustomDate(date.target.value),
                })
              }
            />
        </div>
      </div>
    </div>
    <div className="d-flex align-items-center justify-content-center">
      <button
        type="submit"
        className="btn btn-primary"
        style={{ padding: "8px 20px" }}
      >
        <i className="fa fa-floppy-o" style={{ marginRight: "8px" }} />
        Update
      </button>
    </div>
  </form>
  )
}

export default LaborDepartment