import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ThemeColor } from "../../constants/themeColorConstant";
import { parseCustomDate } from "./Personal";
import { useDispatch } from "../../store";
import { employeeThunks } from "../../store/reducers/employee/employee.thunk";
import { useParams } from "react-router";
import Datepicker from "../../components/datepicker";


const validationSchema = Yup.object().shape({
  qualification: Yup.string().required("Please enter qualification"),
});

const Education = ({ activeTab, moduleId, employeeData }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const {
    values,
    errors,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    setValues,
  } = useFormik({
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const formattedValues = {
        ...values,
        yearOfPassingCollege1: values.yearOfPassingCollege1 &&  parseCustomDate(values.yearOfPassingCollege1),
        yearOfPassingCollege2: values.yearOfPassingCollege2 && parseCustomDate(values.yearOfPassingCollege2),
        yearOfPassingSchool: values.yearOfPassingSchool && parseCustomDate(values.yearOfPassingSchool),
      };
      await updateEducationDataApi(formattedValues);
    },
    initialValues: {
      qualification: employeeData ? employeeData.qualification : "",
      yearOfPassingCollege1: employeeData ? employeeData.yearOfPassingCollege1 : null,
      yearOfPassingCollege2: employeeData ? employeeData.yearOfPassingCollege2 : null,
      yearOfPassingSchool: employeeData ? employeeData.yearOfPassingSchool : null,
      collegeName1: employeeData ? employeeData.collegeName1 : null,
      collegeName2: employeeData ? employeeData.collegeName2 : null,
      schoolName: employeeData ? employeeData.schoolName : null,
      otherQualifications: employeeData ? employeeData.otherQualifications : null,
    },
  });

  const updateEducationDataApi = async (data) => {
    try {
      const response = await dispatch(
        employeeThunks.updateEmployee({ id, data, moduleId, activeTab })
      );
      if (employeeThunks.updateEmployee.fulfilled.match(response)) {
        return response
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  return (
    <form
      className="form-horizontal"
      id="education_info"
      onSubmit={handleSubmit}
    >
      <div className="card-body">
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">
            Qualification <span style={{ color: ThemeColor.RED }}>*</span>
          </label>
          <div className="col-sm-5  col-md-5">
            <input
              type="text"
              className="form-control"
              name="qualification"
              id="qualification"
              placeholder="Qualification"
              value={values.qualification}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.qualification && touched.qualification && (
              <div className="error" style={{ color: ThemeColor.RED }}>
                {errors.qualification}
              </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">College Name 1</label>
          <div className="col-sm-5  col-md-5">
            <input
              type="text"
              className="form-control"
              name="collegeName1"
              id="collegeName1"
              placeholder="College Name 1"
              value={values.collegeName1}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Year Of Passing</label>
          <div className="col-sm-5  col-md-5">
            <Datepicker
              name="yearOfPassingCollege1"
              id="yearOfPassingCollege1"
              value={values.yearOfPassingCollege1}
              dateFormat="yyyy-mm-dd"
              onChange={(date) =>
                setValues({
                  ...values,
                  yearOfPassingCollege1: parseCustomDate(date.target.value),
                })
              }
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">College Name 2</label>
          <div className="col-sm-5  col-md-5">
            <input
              type="text"
              className="form-control"
              name="collegeName2"
              id="collegeName2"
              placeholder="College Name 2"
              value={values.collegeName2}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Year Of Passing</label>
          <div className="col-sm-5  col-md-5">
            <Datepicker
              name="yearOfPassingCollege2"
              id="yearOfPassingCollege2"
              value={values.yearOfPassingCollege2}
              dateFormat="dd-mm-yyyy"
              onChange={(date) =>
                setValues({
                  ...values,
                  yearOfPassingCollege2: parseCustomDate(date.target.value),
                })
              }
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">School Name</label>
          <div className="col-sm-5  col-md-5">
            <input
              type="text"
              className="form-control"
              name="schoolName"
              id="schoolName"
              placeholder="School Name "
              value={values.schoolName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Year Of Passing</label>
          <div className="col-sm-5  col-md-5">
            <Datepicker
              name="yearOfPassingSchool"
              id="yearOfPassingSchool"
              value={values.yearOfPassingSchool}
              dateFormat="yyyy-mm-dd"
              onChange={(date) =>
                setValues({
                  ...values,
                  yearOfPassingSchool: parseCustomDate(date.target.value),
                })
              }
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Other Qualification</label>
          <div className="col-sm-5  col-md-5">
            <input
              type="text"
              className="form-control"
              name="otherQualifications"
              id="otherQualifications"
              placeholder="Other Qualification"
              value={values.otherQualifications}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <button
          type="submit"
          className="btn btn-primary"
          style={{ padding: "8px 20px" }}
        >
          <i className="fa fa-floppy-o" style={{ marginRight: "8px" }} />
          Update
        </button>
      </div>
    </form>
  );
};

export default Education;
