const startingYear = "2020";
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const generateOptions = () => {
  const options = [];
  let currentYear = new Date().getFullYear();
  let currentMonth = new Date().getMonth();
  for (let year = startingYear; year <= currentYear; year++) {
    for (let monthIndex = 0; monthIndex < months.length; monthIndex++) {
      if (
        (year == currentYear && monthIndex > currentMonth) ||
        (year == startingYear && monthIndex < 7)
      ) {
      } else {
        const monthNumber = monthIndex + 1;
        const label = `${months[monthIndex]} - ${year}`;
        const value = `${monthNumber}-${year}`;
        options.push({ label, value });
      }
    }
  }
  return options;
};
