import React from 'react'
import { useFormik } from "formik";
import { useDispatch } from '../../store';
import { useParams } from 'react-router';
import { bankThunks } from '../../store/reducers/bank/bank.thunk';

const AccountDetails = ({activeTab,moduleId,employeeData})=> {

  const dispatch = useDispatch()
  const {id} = useParams()
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    enableReinitialize: true,
    onSubmit: async(values) => {
      await updateAccountDetailsApi(values)
    },
    initialValues: {
      accountName : employeeData.bankDetail ? employeeData.bankDetail.accountName : null,
      number : employeeData.bankDetail ? employeeData.bankDetail.number : null,
      ibanNumber :  employeeData.bankDetail ? employeeData.bankDetail.ibanNumber : null,
      bankName :  employeeData.bankDetail ? employeeData.bankDetail.bankName : null,
      branchAndCountry : employeeData.bankDetail ? employeeData.bankDetail.branchAndCountry : null,
      swiftCode : employeeData.bankDetail ? employeeData.bankDetail.swiftCode : null,
    },
  });

  const updateAccountDetailsApi = async (data) => {
    try {
      const response = await dispatch(bankThunks.updateBankDetails({id,data,moduleId,activeTab}));
      if (bankThunks.updateBankDetails.fulfilled.match(response)) {
       return response
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  return (
    <form className="form-horizontal" id="education_info" onSubmit={handleSubmit}>
    <div className="card-body">
      <div className="form-group row">
        <label className="col-sm-4 col-md-3">Account Name</label>
        <div className="col-sm-5  col-md-5">
          <input
            type="text"
            className="form-control"
            name="accountName"
            id="accountName"
            placeholder="Account Name"
           value={values.accountName}
           onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-sm-4 col-md-3">Number</label>
        <div className="col-sm-5  col-md-5">
          <input
            type="text"
            className="form-control"
            name="number"
            id="number"
            placeholder="Number"
           value={values.number}
           onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-sm-4 col-md-3">IBAN/IFSC Number</label>
        <div className="col-sm-5  col-md-5">
          <input
            type="text"
            className="form-control"
            name="ibanNumber"
            id="ibanNumber"
            placeholder="IBAN/IFSC Number"
           value={values.ibanNumber}
           onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-sm-4 col-md-3">Bank Name</label>
        <div className="col-sm-5  col-md-5">
          <input
            type="text"
            className="form-control"
            name="bankName"
            id="bankName"
            placeholder="Bank Name"
           value={values.bankName}
           onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-sm-4 col-md-3">Branch & Country</label>
        <div className="col-sm-5  col-md-5">
          <input
            type="text"
            className="form-control"
            name="branchAndCountry"
            id="branchAndCountry"
            placeholder="Branch & Country"
           value={values.branchAndCountry}
           onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-sm-4 col-md-3">Swift Code</label>
        <div className="col-sm-5  col-md-5">
          <input
            type="text"
            className="form-control"
            name="swiftCode"
            id="swiftCode"
            placeholder="Swift Code"
           value={values.swiftCode}
           onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      </div>
    </div>
    <div className="d-flex align-items-center justify-content-center">
      <button
        type="submit"
        className="btn btn-primary"
        style={{ padding: "8px 20px" }}
      >
        <i className="fa fa-floppy-o" style={{ marginRight: "8px" }} />
        Update
      </button>
    </div>
  </form>
  )
}

export default AccountDetails