import React, { useEffect, useState } from "react";
import YupPassword from "yup-password";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ThemeColor } from "../../constants/themeColorConstant";
import { useDispatch } from "../../store";
import { employeeThunks } from "../../store/reducers/employee/employee.thunk";
import { useNavigate, useParams } from "react-router-dom";
import Datepicker from "../../components/datepicker";
import { projectThunks } from "../../store/reducers/projects/project.thunk";
import Select from "react-select";
import { images } from "../../assets/asset";

YupPassword(Yup);
const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Please enter employee name")
    .matches(/^[A-Za-z\s]+$/, "Employee name must contain only letters")
    .trim(),
});

export const parseCustomDate = (customDate) => {
  const [day, month, year] = customDate.split("-");
  return `${year}-${month}-${day}`;
};

const Personal = ({
  setStatus,
  employeeDesignation,
  employeeDepartment,
  employeeData,
  moduleId,
  activeTab,
}) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [projectList, setProjectList] = useState([]);
  console.log(employeeData.dob);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const {
    values,
    errors,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    setValues,
    setFieldValue,
  } = useFormik({
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      delete values.departmentIdObj;
      const formattedValues = {
        ...values,
        dob: values.dob && parseCustomDate(values.dob),
        gratuityDate:
          values.gratuityDate && parseCustomDate(values.gratuityDate),
        startExperienceDate:
          values.startExperienceDate &&
          parseCustomDate(values.startExperienceDate),
        dateOfJoining:
          values.dateOfJoining && parseCustomDate(values.dateOfJoining),
        leftDate: values.leftDate && parseCustomDate(values.leftDate),
      };
      await updatePersonalDataApi(formattedValues);
    },
    initialValues: {
      name: employeeData ? employeeData.name : "",
      dob: employeeData && employeeData.dob ? parseCustomDate(employeeData.dob) : null,
      gender: employeeData ? employeeData.gender : null,
      designationId: employeeData.designation
        ? employeeData.designation.uid
        : null,
      departmentId: employeeData.department
        ? employeeData.department.uid
        : null,
      departmentIdObj: employeeData.department ? employeeData.department : null,
      dateOfJoining:
        employeeData && employeeData.dateOfJoining
          ? parseCustomDate(employeeData.dateOfJoining)
          : null,
      startExperienceDate:
        employeeData && employeeData.startExperienceDate
          ? parseCustomDate(employeeData.startExperienceDate)
          : null,
      gratuityDate:
        employeeData && employeeData.gratuityDate
          ? parseCustomDate(employeeData.gratuityDate)
          : null,
      leftDate:
        employeeData && employeeData.leftDate ? parseCustomDate(employeeData.leftDate) : null,
      sendPayslip: employeeData ? employeeData.sendPayslip : null,
      status: employeeData ? employeeData.status : null,
      currentProjectId: employeeData.currentProject
        ? employeeData.currentProject.uid
        : null,
    },
  });


  useEffect(() => {
    projectListApi();
  }, []);

  const updatePersonalDataApi = async (data) => {
    try {
      const response = await dispatch(
        employeeThunks.updateEmployee({ id, data, moduleId, activeTab })
      );
      if (employeeThunks.updateEmployee.fulfilled.match(response)) {
        // navigate("/employee");
        return response;
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const updateEmployeeTypeApi = async (type) => {
    try {
      const data = {
        type: type, // "permanent" or "temporary"
      };
      const response = await dispatch(
        employeeThunks.updateEmployeeType({ id, data, moduleId })
      );
      if (employeeThunks.updateEmployeeType.fulfilled.match(response)) {
        navigate("/employee");
        return response;
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const projectListApi = async () => {
    try {
      const response = await dispatch(projectThunks.projectList(moduleId));
      if (projectThunks.projectList.fulfilled.match(response)) {
        if (response.payload) {
          setProjectList(response.payload);
        }
      }
    } catch (error) {
      console.error("Error fetching project list:", error);
    }
  };

  return (
    <form
      className="form-horizontal"
      id="personal_info"
      onSubmit={handleSubmit}
    >
      <div className="card-body">
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">
            Employee Code <span style={{ color: ThemeColor.RED }}>*</span>
          </label>
          <div className="col-sm-5  col-md-5">
            <input
              type="text"
              className="form-control"
              name="employeeCode"
              id="employeeCode"
              placeholder="Employee Code"
              value={employeeData.employeeCode}
              readOnly
            />
          </div>
        </div>
          <div className="form-group row">
          {employeeData.type !== "permanent" && (
    <label className="col-sm-4 col-md-3">Employee Type</label>
  )}
            <div className="col-sm-5  col-md-5 d-flex" style={{ gap: "5px" }}>
            {employeeData.type == "contract" && (
              <>
               <button
                type="button"
                name="type"
                id="type"
                className="btn btn-outline-primary"
                onClick={() => updateEmployeeTypeApi("permanent")}
              >
                Convert To Permanent Employee
              </button>
              <button
                type="button"
                name="type"
                id="type"
                className="btn btn-outline-primary"
                onClick={() => updateEmployeeTypeApi("temporary")}
              >
                Convert To Temporary Employee
              </button>
              </>
              )}
               {employeeData.type == "temporary" && (
                <button
                type="button"
                name="type"
                id="type"
                className="btn btn-outline-primary"
                onClick={() => updateEmployeeTypeApi("permanent")}
                >
                Convert To Permanent Employee
                </button>
               )}
            </div>
          </div>
        
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">
            Name <span style={{ color: ThemeColor.RED }}>*</span>
          </label>
          <div className="col-sm-5  col-md-5">
            <input
              type="text"
              className="form-control"
              name="name"
              id="name"
              placeholder="Employee Name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.name && touched.name && (
              <div className="error" style={{ color: ThemeColor.RED }}>
                {errors.name}
              </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Gender</label>
          <div className="col-sm-5  col-md-5">
            <div className="d-flex" style={{ gap: "5px" }}>
              <input
                style={{ width: "20px" }}
                name="gender"
                id="gender"
                type="radio"
                value="male"
                onChange={handleChange}
                onBlur={handleBlur}
                checked={values.gender === "male"}
              />
              Male
              <input
                style={{ width: "20px", marginLeft: "10px" }}
                type="radio"
                name="gender"
                id="gender"
                value="female"
                onChange={handleChange}
                onBlur={handleBlur}
                checked={values.gender === "female"}
              />
              Female
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Date Of Birth</label>
          <div className="col-sm-5  col-md-5">
            <Datepicker
              name="dob"
              id="dob"
              value={values.dob}
              dateFormat="dd-mm-yyyy"
              onChange={(date) =>
                setValues({
                  ...values,
                  dob: parseCustomDate(date.target.value),
                })
              }
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Designation</label>
          <div className="col-sm-5  col-md-5">
            <select
              className="form-control"
              name="designationId"
              id="designationId"
              onChange={handleChange}
            >
              <option>Select</option>
              {employeeDesignation.map((data) => {
                return (
                  <option
                    key={data.uid}
                    selected={data.uid === values.designationId}
                    value={data.uid}
                  >
                    {data.name}
                  </option>
                );
              })}
            </select>
            <div className="error" id="err_name" />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Department</label>
          <div className="col-sm-5  col-md-5">
            <Select
              placeholder="Select Department"
              isSearchable
              id="departmentId"
              name="departmentId"
              onChange={(selectedOption) => {
                setValues({
                  ...values,
                  departmentIdObj: selectedOption,
                  departmentId: selectedOption.value,
                });
              }}
              value={values.departmentIdObj}
              options={employeeDepartment.map((employee) => ({
                value: employee.uid,
                label:  employee.name,
              }))}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Date Of Joining</label>
          <div className="col-sm-5  col-md-5">
            <Datepicker
              name="dateOfJoining"
              id="dateOfJoining"
              value={values.dateOfJoining}
              dateFormat="dd-mm-yyyy"
              onChange={(date) =>
                setValues({
                  ...values,
                  dateOfJoining: parseCustomDate(date.target.value),
                })
              }
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Gratuity Date</label>
          <div className="col-sm-5  col-md-5">
            <Datepicker
              name="gratuityDate"
              id="gratuityDate"
              value={values.gratuityDate}
              dateFormat="dd-mm-yyyy"
              onChange={(date) =>
                setValues({
                  ...values,
                  gratuityDate: parseCustomDate(date.target.value),
                })
              }
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Start Experience Date</label>
          <div className="col-sm-5  col-md-5">
            <Datepicker
              name="startExperienceDate"
              id="startExperienceDate"
              value={values.startExperienceDate}
              dateFormat="dd-mm-yyyy"
              onChange={(date) =>
                setValues({
                  ...values,
                  startExperienceDate: parseCustomDate(date.target.value),
                })
              }
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Current Project</label>
          <div className="col-sm-5  col-md-5">
            <select
              className="form-control"
              name="currentProjectId"
              id="currentProjectId"
              onChange={handleChange}
            >
              <option>Select</option>
              {projectList.map((data) => {
                return (
                  <option
                    selected={data.uid === values.currentProjectId}
                    value={data.uid}
                  >
                    {data.project_number + " - " + data.project_name }
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Status</label>
          <div className="col-sm-5  col-md-5">
            <select
              className="form-control"
              name="status"
              id="status"
              onChange={handleChange}
            >
              <option>Select</option>
              {setStatus.map((data) => {
                return (
                  <option
                    selected={data.slug === values.status}
                    value={data.slug}
                  >
                    {data.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Left Date</label>
          <div className="col-sm-5  col-md-5">
            <Datepicker
              name="leftDate"
              id="leftDate"
              value={values.leftDate}
              dateFormat="dd-mm-yyyy"
              onChange={(date) =>
                setValues({
                  ...values,
                  leftDate: parseCustomDate(date.target.value),
                })
              }
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Send Payslip</label>
          <div className="col-sm-5  col-md-5">
            <div className="d-flex" style={{ gap: "5px" }}>
              <input
                style={{ width: "20px" }}
                type="radio"
                value={true}
                name="sendPayslip"
                id="sendPayslip"
                onChange={handleChange}
                onBlur={handleBlur}
                checked={values.sendPayslip === "true" || true}
              />{" "}
              Yes
              <input
                style={{ width: "20px", marginLeft: "10px" }}
                type="radio"
                value={false}
                name="sendPayslip"
                id="sendPayslip"
                onChange={handleChange}
                onBlur={handleBlur}
                checked={values.sendPayslip === "false" || false}
              />{" "}
              No
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Photo</label>
          <div className="col-sm-5  col-md-5">
            {selectedImage ? (
              <img
                src={URL.createObjectURL(selectedImage)}
                alt="Employee profile"
                //width="165px"
                height="165px"
              ></img>
            ) : (
              <img
                src={
                  employeeData && employeeData.photo != null
                    ? employeeData.photo
                    : images.profileImage
                }
                alt="Employee profile"
                //width="165px"
                height="165px"
              ></img>
            )}
            <br />
            <br />
            <input
              type="file"
              id="photo"
              name="photo"
              onChange={(event) => {
                const file = event.target.files[0];
                if (file) {
                  setSelectedImage(file);
                  const fileExtension = file.name.split(".").pop();
                  const reader = new FileReader();
                  reader.onload = (e) => {
                    const base64Data = e.target.result;
                    const photoObject = {};
                    photoObject[`emp.${fileExtension}`] = base64Data;
                    setFieldValue("photo", photoObject);
                  };
                  reader.readAsDataURL(file);
                } else {
                }
              }}
            ></input>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <button
          type="submit"
          className="btn btn-primary"
          style={{ padding: "8px 20px" }}
        >
          <i className="fa fa-floppy-o" style={{ marginRight: "8px" }} />
          Update
        </button>
      </div>
    </form>
  );
};

export default Personal;
