import React, { useEffect, useState } from "react";
import YupPassword from "yup-password";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ThemeColor } from "../../constants/themeColorConstant";
import { useDispatch } from "../../store";
import { employeeThunks } from "../../store/reducers/employee/employee.thunk";
import { useNavigate, useParams } from "react-router-dom";
import Datepicker from "../../components/datepicker";
import { projectThunks } from "../../store/reducers/projects/project.thunk";
import Select from "react-select";
import { images } from "../../assets/asset";

const CurrentProject = ({
  setStatus,
  employeeData,
  moduleId,
  activeTab,
}) => {
  const [projectList, setProjectList] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  // const {
  //   values,
  //   errors,
  //   handleBlur,
  //   handleChange,
  //   touched,
  //   handleSubmit,
  //   setValues,
  //   setFieldValue,
  // } = useFormik({
  //   validationSchema: {},
  //   enableReinitialize: true,
  //   onSubmit: async (values) => {
  //     // const formattedValues = {
  //     //   ...values,
  //     //   dob: values.dob && parseCustomDate(values.dob),
  //     //   gratuityDate:
  //     //     values.gratuityDate && parseCustomDate(values.gratuityDate),
  //     //   startExperienceDate:
  //     //     values.startExperienceDate &&
  //     //     parseCustomDate(values.startExperienceDate),
  //     //   dateOfJoining:
  //     //     values.dateOfJoining && parseCustomDate(values.dateOfJoining),
  //     //   leftDate: values.leftDate && parseCustomDate(values.leftDate),
  //     // };
  //     await updateProjectDataApi(values);
  //   },
  //   initialValues: {
  //     currentProjectId: employeeData.currentProject
  //       ? employeeData.currentProject.uid
  //       : null,
  //   },
  // });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    enableReinitialize: true,
    onSubmit: async (values) => {
      await updateProjectDataApi(values)
    },
    initialValues: {
      currentProjectId: employeeData.currentProject
        ? employeeData.currentProject.uid
        : null,
    },
  });


  useEffect(() => {
    projectListApi();
  }, []);

  const updateProjectDataApi = async (data) => {
    try {
      const response = await dispatch(
        employeeThunks.updateEmployee({ id, data, moduleId, activeTab })
      );
      if (employeeThunks.updateEmployee.fulfilled.match(response)) {
        // navigate("/employee");
        return response;
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const projectListApi = async () => {
    try {
      let data = {
        employeeId: '5d91b188-1b6e-4ef3-b6e1-1c3dfbfd1a65'
      }
      const response = await dispatch(projectThunks.employeeWiseProjectList(data));

      if (projectThunks.employeeWiseProjectList.fulfilled.match(response)) {

        if (response.payload) {
          setProjectList(response.payload);
        }
      }
    } catch (error) {
      console.error("Error fetching project list:", error);
    }
  };

  return (
    <form
      className="form-horizontal"
      id="personal_info"
      onSubmit={handleSubmit}
    >
      <div className="card-body">

        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Current Project</label>
          <div className="col-sm-5  col-md-5">
            <select
              className="form-control"
              name="currentProjectId"
              id="currentProjectId"
              onChange={handleChange}
            >
              <option>Select</option>
              {projectList.map((data) => {
                return (
                  <option
                    selected={data.uid === values.currentProjectId}
                    value={data.uid}
                  >
                    {data.projectName} {" - "} {data.projectNumber}
                  </option>
                );
              })}
            </select>
          </div>
        </div>

      </div>
      <div className="d-flex align-items-center justify-content-center">
        <button
          type="submit"
          className="btn btn-primary"
          style={{ padding: "8px 20px" }}
        >
          <i className="fa fa-floppy-o" style={{ marginRight: "8px" }} />
          Update
        </button>
      </div>
    </form>
  );
};

export default CurrentProject;
