import React, { useEffect, useState } from "react";
import Datatable from "../../components/datatable";

import { useDispatch } from "../../store";
import { ThemeColor } from "../../constants/themeColorConstant";
import { useLocation, useNavigate } from "react-router-dom";
import MainLayout from "../layout/MainLayout";
import { userThunks } from "../../store/reducers/users/users.thunk";
import ConfirmationBox from "../../components/ConfirmationBox";

const Users = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userData, setUserData] = useState([]);
  const [option, setOption] = useState({});
  const [userIdToUpdate, setUserIdToUpdate] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [statusToUpdate, setStatusToUpdate] = useState(false); // Add statusToUpdate state

  const location = useLocation();
  const pathnameSegments = location.pathname.split("/");
  const moduleName = pathnameSegments[1];

  const allowedModules = JSON.parse(localStorage.getItem("allowedModules"));
  const selectedModule = allowedModules.find(
    (module) => module.slug === moduleName
  );

  const moduleId = selectedModule.uid;

  const renderActions = (rowData) => {
    if (rowData && rowData.uid) {
      const userId = rowData.uid;
      const editIcon = (
        <i
          className="fa fa-edit"
          style={{ cursor: "pointer" }}
          onClick={() =>
            navigate(`/hrms-user/edit/${userId}`, { state: { moduleId } })
          }
        />
      );

      return <div>{editIcon}</div>;
    } else {
      return null;
    }
  };

  const columns = [
    {
      name: "action",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const rowData = userData[dataIndex];
          return renderActions(rowData);
        },
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "email",
      label: "Email",
    },
    {
      name: "roleName",
      label: "Role",
    },
    {
      name: "phone",
      label: "Phone",
    },
    {
      name: "access",
      label: "Access",
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const rowData = userData[tableMeta.rowIndex];
          if (rowData && rowData.uid) {
            const userId = rowData.uid;
            const statusIcon = value ? (
              <i
                className="fa fa-unlock"
                style={{ cursor: "pointer" }}
                onClick={() => handleConfirmUpdateStatus(userId, value)}
              />
            ) : (
              <i
                className="fa fa-lock"
                style={{ cursor: "pointer" }}
                onClick={() => handleConfirmUpdateStatus(userId, value)}
              />
            );
            return statusIcon;
          } else {
            return null;
          }
        },
      },
    },
  ];

  useEffect(() => {
    getUserListApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserListApi = async (
    page = 1,
    rowsPerPage = 10,
    searchText = "",
    name = "",
    order = ""
  ) => {
    try {
      const response = await dispatch(
        userThunks.userList({
          moduleId,
          page,
          rowsPerPage,
          searchText,
          name,
          order,
        })
      );
      if (userThunks.userList.fulfilled.match(response)) {
        if (response.payload) {
          const options = {
            count: response.payload.totalRecords,
            page: response.payload.currentPage - 1, // Zero-based index
            rowsPerPage: response.payload.recordsPerPage,
          };
          setOption(options);
          setUserData(response.payload.data);
          return response.payload;
        }
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const handleConfirmUpdateStatus = async (userId, currentStatus) => {
    setUserIdToUpdate(userId);
    setStatusToUpdate(!currentStatus); // Toggle the statusToUpdate state
    setShowConfirmation(true);
  };

  const handleStatusUpdate = async () => {
    try {
      const response = await dispatch(
        userThunks.updateUserStatus({
          userId: userIdToUpdate,
          moduleId,
          data: { status: statusToUpdate }, // Use the statusToUpdate state here
        })
      );
      if (userThunks.updateUserStatus.fulfilled.match(response)) {
        const updatedUserData = userData.map((data) =>
          data.uid === userIdToUpdate
            ? { ...data, status: statusToUpdate }
            : data
        );
        setUserData(updatedUserData);
        setUserIdToUpdate(null);
        setShowConfirmation(false);
        return response;
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const breadcrumb = {
    mainHeader: "Manage User",
    subHeader: "Home",
    subHeaderLink: "/dashboard",
    subHeader1: "Users",
    subHeader1Link: null,
    subHeader2: null,
    subHeader2Link: null,
  };

  return (
    <MainLayout breadcrumb={breadcrumb}>
      <div className="card">
        <div className="card-body">
          <div className="card-header ">
            <p
              className="card-title"
              style={{
                fontSize: "20px",
                fontWeight: "500",
                color: ThemeColor.EASTERNBLUE,
              }}
            >
              Manage User
            </p>
            <div className="d-flex justify-content-end">
              <button
                onClick={() =>
                  navigate("/hrms-user/add", { state: { moduleId } })
                }
                className="btn btn-primary"
              >
                Add
              </button>
            </div>
          </div>

          <Datatable
            columns={columns}
            data={userData}
            options={option}
            customBodyRender={renderActions}
            api={getUserListApi}
          />
        </div>
      </div>
      <ConfirmationBox
        show={showConfirmation}
        onHide={() => setShowConfirmation(false)}
        onConfirm={handleStatusUpdate}
        message="Are you sure you want to update status?"
      />
    </MainLayout>
  );
};

export default Users;
