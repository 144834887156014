import React, { useEffect, useState } from "react";
import Datatable from "../../components/datatable";
import { useDispatch } from "../../store";
import { ThemeColor } from "../../constants/themeColorConstant";
import { useLocation, useNavigate } from "react-router-dom";
import MainLayout from "../layout/MainLayout";
import { gratuityThunks } from "../../store/reducers/gratuity/gratuity.thunk";
import { employeeThunks } from "../../store/reducers/employee/employee.thunk";
import ConfirmationBox from "../../components/ConfirmationBox";
import Select from "react-select";

const Gratuity = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [gratuityData, setgratuityData] = useState([]);
  const [option, setOption] = useState({});
  const [employeeList, setEmployeeList] = useState([])
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [employeeIdToDelete, setEmployeeIdToDelete] = useState(null);

  const location = useLocation();
  const pathnameSegments = location.pathname.split("/");
  const moduleName = pathnameSegments[1];

  const allowedModules = JSON.parse(localStorage.getItem("allowedModules"));
  const selectedModule = allowedModules.find(
    (module) => module.slug === moduleName
  );

  const moduleId = selectedModule.uid;

  const renderActions = (rowData) => {
    if (rowData && rowData.uid) {
      const gratuityId = rowData.gratuityUid;
      const editIcon = (
        <i
          className="fa fa-edit"
          style={{ cursor: "pointer" }}
          onClick={() => navigate(`/gratuity/edit/${gratuityId}`, { state: { moduleId } })}
        />
      );
      const deleteIcon = (
        <i style={{ marginLeft: "10px", cursor: "pointer" }}
          className="fa fa-trash-o"
          onClick={() => {
            setEmployeeIdToDelete(gratuityId);
            setShowConfirmation(true);
          }}
        />
      );

      return (
        <div>
          {editIcon}
          {deleteIcon}
        </div>
      );
    }
    else {
      return null
    }
  };

  const columns = [
    {
      name: "action",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const rowData = gratuityData[dataIndex];
          return renderActions(rowData);
        },
      },
    },
    {
      name: "employeeCode",
      label: "Emp Code",
    },
    {
      name: "employeeName",
      label: "Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "designationName",
      label: "Designation",
    },

    {
      name: "fromDate",
      label: "from Date",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "toDate",
      label: "To Date",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "gratuityAmount",
      label: "Gratuity Amount",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "posted",
      label: "Posted",
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  useEffect(() => {
    employeeListApi()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getGratuityDetailsApi();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEmployee]);

  const employeeListApi = async () => {
    try {
      const response = await dispatch(
        employeeThunks.gratuityEmployeeList({ gratuity: true, moduleId })
      );
      if (employeeThunks.gratuityEmployeeList.fulfilled.match(response)) {
        if (response.payload) {
          setEmployeeList(response.payload);
          return response.payload;
        }
      }
    } catch (error) {
      console.error("Error fetching company data:", error);
    }
  };

  const handleEmployeeChange = async(event) => {
    const selectedValue = event.value;
     await setSelectedEmployee(selectedValue);
   
    // getGratuityDetailsApi(selectedValue);
  };


  const getGratuityDetailsApi = async (page = 1, rowsPerPage = 10, searchText = '', name = '', order = '') => {
    try {
      // selectedValue = selectedEmployee != '' ? selectedEmployee : selectedValue;
      if (selectedEmployee) {
        const response = await dispatch(gratuityThunks.gratuityList({ selectedValue : selectedEmployee, moduleId, page, rowsPerPage, searchText, name, order }));
        if (gratuityThunks.gratuityList.fulfilled.match(response)) {
          if (response.payload) {
            const options = {
              count: response.payload.totalRecords,
              page: response.payload.currentPage - 1, // Zero-based index
              rowsPerPage: response.payload.recordPerPage,
            }
            setOption(options)
            setgratuityData(response.payload.data);
            return response.payload;
          }
        }
      } else {
        setOption({});
        setgratuityData([]);
      }
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await dispatch(
        gratuityThunks.deleteGratuity({ id: employeeIdToDelete, moduleId })
      );
      if (gratuityThunks.deleteGratuity.fulfilled.match(response)) {
        const deletedGratuityId = response.payload.id;
        const updatedGratuityData = gratuityData.filter(
          (gratuity) => gratuity && gratuity.uid !== deletedGratuityId
        );
        setgratuityData(updatedGratuityData);
      }
    } catch (error) {
      console.error("Error deleting gratuity:", error);
    }

    setEmployeeIdToDelete(null);
    setShowConfirmation(false);
  };



  const breadcrumb = {
    mainHeader: "Manage Gratuity",
    subHeader: "Home",
    subHeaderLink: "/dashboard",
    subHeader1: "listing",
    subHeader1Link: null,
    subHeader2: null,
    subHeader2Link: null,
  };


  return (
    <MainLayout breadcrumb={breadcrumb}>
      <div className="card">
        <div className="card-body">
          <div className="card-header ">
            <p
              className="card-title"
              style={{
                fontSize: "20px",
                fontWeight: "500",
                color: ThemeColor.EASTERNBLUE,
              }}
            >
              Manage gratuity
            </p>
            <div className="d-flex justify-content-end">
              <button
                onClick={() =>
                  navigate("/gratuity/add", { state: { moduleId } })
                }
                className="btn btn-primary"
              >
                Add
              </button>
            </div>
          </div>
          <div className="form-group row d-flex justify-content-center mt-5">
            <div className="col-sm-4  col-md-4">
              <Select
                placeholder="Select Employee"
                isSearchable
                id="employee"
                name="employee"
                onChange={handleEmployeeChange}
                options={employeeList.map((data) => (
                  {
                    value: data.uid,
                    label: data.employee_code + " - " + data.name,
                  }
                ))}
              />
            </div>
          </div>
          <Datatable
            columns={columns}
            data={gratuityData}
            options={option}
            api={getGratuityDetailsApi}
            customBodyRender={renderActions}
          />
        </div>
      </div>
      <ConfirmationBox
        show={showConfirmation}
        onHide={() => setShowConfirmation(false)}
        onConfirm={handleConfirmDelete}
        message="Are you sure you want to delete gratuity?"
      />
    </MainLayout>
  );
};

export default Gratuity;
