import React, { useEffect, useState } from "react";
import Datatable from "../../components/datatable";
import { useDispatch } from "../../store";
import { ThemeColor } from "../../constants/themeColorConstant";
import { useLocation, useNavigate } from "react-router-dom";
import MainLayout from "../layout/MainLayout";
import { formTemplateThunks } from "../../store/reducers/formTemplate/formTemplate.thunk";
import ConfirmationBox from "../../components/ConfirmationBox";

const Form = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState([]);
  const [option, setOption] = useState({});
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [formIdToDelete, setFormIdToDelete] = useState(null);

  const location = useLocation();
  const pathnameSegments = location.pathname.split("/");
  const moduleName = pathnameSegments[1];

  const allowedModules = JSON.parse(localStorage.getItem("allowedModules"));
  const selectedModule = allowedModules.find(
    (module) => module.slug === moduleName
  );

  const moduleId = selectedModule.uid;

  const renderActions = (rowData) => {
    if (rowData && rowData.uid) {
    const employeeId = rowData.uid;
    const editIcon = (
      <i
        className="fa fa-edit"
        style={{ cursor: "pointer" }}
        onClick={() => navigate(`/form-template/edit/${employeeId}`, { state: { moduleId } })}
      />
    );
    const deleteIcon = (
      <i style={{ marginLeft: "10px", cursor: "pointer" }}
        className="fa fa-trash-o"
       onClick={() => {
        setFormIdToDelete(employeeId);
        setShowConfirmation(true);
      }}
      />
    );
    return (
      <div>
        {editIcon}
        {deleteIcon}
      </div>
    );
    }else{
      return null
    }
  };

  const columns = [
    {
      name: "action",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const rowData = formData[dataIndex];
          return renderActions(rowData);
        },
      },
    },
    {
      name: "formNumber",
      label: "Form number",
    },
    {
      name: "formDetails",
      label: "form details",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "revisedOn",
      label: "Revised On",
    },
    {
      name: "remark",
      label: "Remark",
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  useEffect(() => {
     getFormListApi()
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

    const getFormListApi = async (page=1,rowsPerPage=10,searchText='',name='',order='') => {
      try {
        const response = await dispatch(formTemplateThunks.formTemplateList({moduleId,page,rowsPerPage,searchText,name,order}));
        if (formTemplateThunks.formTemplateList.fulfilled.match(response)) {
          if (response.payload) {
            const options = {
              count: response.payload.totalRecords,
              page: response.payload.currentPage-1, // Zero-based index
              rowsPerPage:response.payload.recordsPerPage,
            }
            setOption(options)
            setFormData(response.payload.data);
            return response.payload
          }
        }
      } catch (error) {
        console.error("Error fetching client data:", error);
      }
    };

    const handleConfirmDelete = async () => {
      try {
        const response = await dispatch(formTemplateThunks.deleteFormTemplate({id:formIdToDelete,moduleId}));
        if (formTemplateThunks.deleteFormTemplate.fulfilled.match(response)) {
          const updatedFormData = formData.filter((data) => data && data.uid !== formIdToDelete); // Check if 'role' exists
          setFormData(updatedFormData);
        }
       
      } catch (error) {
        console.error("Error fetching asset data:", error);
      }
      setFormIdToDelete(null);
      setShowConfirmation(false);
    };

  const breadcrumb = {
    mainHeader: "Manage Form",
    subHeader: "Home",
    subHeaderLink: "/dashboard",
    subHeader1: "listing",
    subHeader1Link: null,
    subHeader2: null,
    subHeader2Link: null,
  };

  return (
    <MainLayout breadcrumb={breadcrumb}>
      <div className="card">
        <div className="card-body">
          <div className="card-header ">
            <p
              className="card-title"
              style={{
                fontSize: "20px",
                fontWeight: "500",
                color: ThemeColor.EASTERNBLUE,
              }}
            >
             Manage Form
            </p>
            <div className="d-flex justify-content-end">
              <button
                onClick={() =>
                  navigate("/form-template/add", { state: { moduleId } })
                }
                className="btn btn-primary"
              >
                Add
              </button>

            </div>
          </div>
          <Datatable
            columns={columns}
            data={formData}
            options={option}
            customBodyRender={renderActions}
          />
        </div>
      </div>
      <ConfirmationBox
        show={showConfirmation}
        onHide={() => setShowConfirmation(false)}
        onConfirm={handleConfirmDelete}
        message="Are you sure you want to delete form?"
      />
    </MainLayout>
  );
};

export default Form;
