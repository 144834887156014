import React, { useEffect, useState } from 'react';
import Datatable from '../../components/datatable';
import { useDispatch } from '../../store';
import { ThemeColor } from '../../constants/themeColorConstant';
import { useLocation, useNavigate } from 'react-router-dom';
import MainLayout from '../layout/MainLayout';
import { authorityThunks } from '../../store/reducers/authority/authority.thunk';

const AuthorityDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [authoritiesDetails, setAuthoritiesDetails] = useState([]);
  const [option, setOption] = useState({});

  const location = useLocation();
  const pathnameSegments = location.pathname.split('/');
  const moduleName = pathnameSegments[1];

  const allowedModules = JSON.parse(localStorage.getItem('allowedModules'));
  const selectedModule = allowedModules.find((module) => module.slug === moduleName);

  const moduleId = selectedModule.uid;

  const renderActions = (rowData) => {
    const employeeId = rowData.uid;
    const editIcon = (
      <i
        className='fa fa-edit'
        style={{ cursor: 'pointer' }}
        onClick={() => navigate(`/authority/edit/${employeeId}`, { state: { moduleId } })}
      />
    );

    return <div>{editIcon}</div>;
  };

  const columns = [
    {
      name: 'action',
      label: 'Action',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const rowData = authoritiesDetails[dataIndex];
          return renderActions(rowData);
        },
      },
    },
    {
      name: 'employeeCode',
      label: 'Employee Code',
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'designationName',
      label: 'Profession',
    },
    {
      name: 'passportNumber',
      label: 'Passport Number',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'passportExpiry',
      label: 'Passport Expiry',
    },
    {
      name: 'visaUIdNumber',
      label: 'Visa uid number',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'visaExpiry',
      label: 'visa expiry',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'laborContract',
      label: 'Labor contract',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'laborExpiry',
      label: 'labor expiry',
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  useEffect(() => {
    geAuthorityDetailsApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const geAuthorityDetailsApi = async (
    page = 1,
    rowsPerPage = 10,
    searchText = '',
    name = '',
    order = '',
  ) => {
    try {
      const response = await dispatch(
        authorityThunks.authorityData({ moduleId, page, rowsPerPage, searchText, name, order }),
      );
      if (authorityThunks.authorityData.fulfilled.match(response)) {
        if (response.payload) {
          const options = {
            count: response.payload.totalRecords,
            page: response.payload.currentPage - 1, // Zero-based index
            rowsPerPage: response.payload.recordsPerPage,
          };
          setOption(options);
          setAuthoritiesDetails(response.payload.data);
          return response.payload;
        }
      }
    } catch (error) {
      console.error('Error fetching client data:', error);
    }
  };

  const breadcrumb = {
    mainHeader: 'Authority Master',
    subHeader: 'Home',
    subHeaderLink: '/dashboard',
    subHeader1: 'Authority',
    subHeader1Link: null,
    subHeader2: null,
    subHeader2Link: null,
  };

  return (
    <MainLayout breadcrumb={breadcrumb}>
      <div className='card'>
        <div className='card-body'>
          <div className='card-header '>
            <p
              className='card-title'
              style={{
                fontSize: '20px',
                fontWeight: '500',
                color: ThemeColor.EASTERNBLUE,
              }}
            >
              Authority Master
            </p>
          </div>

          <Datatable
            columns={columns}
            data={authoritiesDetails}
            options={option}
            customBodyRender={renderActions}
            api={geAuthorityDetailsApi}
          />
        </div>
      </div>
    </MainLayout>
  );
};

export default AuthorityDetails;
