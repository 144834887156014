import React from "react";
import MainLayout from "../../pages/layout/MainLayout";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "../../store";
import ToastMessage from "../../components/Toast";
import Chart from "../chart/Chart";

const Dashboard = () => {
  const location = useLocation();
 
  const pathname = location.pathname.substring(1);
  const toastData = useSelector((state) => state.toast);

  return (
    <MainLayout>
      {/* <div className="content-wrapper"> */}
        {/* <div className="content-header"> */}
          <div className="container-fluid">
            <div className="row mb-2" style={{backgroundColor:"none"}}>
              <div className="col-sm-6">
                <h1 className="m-0">Dashboard</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li
                    className="breadcrumb-item active"
                    style={{ textTransform: "capitalize" }}
                  >
                    {pathname}
                  </li>
                </ol>
              </div>
           
            </div>
            <div className="card">
        <div className="card-body">
          <Chart/>
        </div> 
      </div>
          </div>
        {/* </div> */}
       
      {/* </div> */}
     
      <ToastMessage toastData={toastData} />
    </MainLayout>
  );
};

export default Dashboard;
