import React, { useEffect, useState } from "react";
import Datatable from "../../components/datatable";

import { useDispatch } from "../../store";
import { ThemeColor } from "../../constants/themeColorConstant";
import {useLocation, useNavigate} from "react-router-dom";
import MainLayout from "../layout/MainLayout";
import { leaveThunks } from "../../store/reducers/leave/leave.thunk";


const Leave = () => {
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [leaveDetails, setLeaveDetails] = useState([]);
  const [option,setOption] = useState({})


  const location = useLocation();
  const pathnameSegments = location.pathname.split('/');
  const moduleName = pathnameSegments[1];
  
  const allowedModules = JSON.parse(localStorage.getItem("allowedModules"));
  const selectedModule = allowedModules.find(
    (module) => module.slug === moduleName
  );

  const moduleId = selectedModule.uid

  const renderActions = (rowData) => {
    const employeeId = rowData.employeeUId;
    const editIcon = (
      <i
      class="fa fa-clock-o fa-lg" 
        style={{cursor:"pointer",marginLeft:"15px"}}
       onClick={() => navigate(`/leaves/edit/${employeeId}`, { state: { moduleId } })}
      />
    );
   
    return (
      <div>
        {editIcon}
      </div>
    );
  };

  const columns = [
    {
      name: "action",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const rowData = leaveDetails[dataIndex];
          return renderActions(rowData);
        },
      },
    },
    {
      name: "employeeCode",
      label: "Employee Code",
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "designation",
      label: "Designation",
      options: {
        customBodyRender: (value) => {
          return value
        },
      }  
    },
    {
      name: "SLTotal",
      label: "SL total",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "SLTaken",
      label: "SL Taken",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "SLBal",
      label: "SL bal",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "ALTotal",
      label: "AL Total",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
        name: "ALTaken",
        label: "AL Taken",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "ALBal",
        label: "AL bal",
        options: {
          filter: false,
          sort: true,
        },
      },
    {
      name: "LOP",
      label: "LOP",
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  useEffect(() => {
    geLeaveDetailsApi()
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const geLeaveDetailsApi = async (page=1,rowsPerPage=10,searchText='',name='',order='') => {
    try {
      const response = await dispatch(leaveThunks.leaveData({moduleId,page,rowsPerPage,searchText,name,order}));
      if (leaveThunks.leaveData.fulfilled.match(response)) {
        if (response.payload) {
          const options = {
            count: response.payload.totalRecords,
            page: response.payload.currentPage-1, // Zero-based index
            rowsPerPage:response.payload.recordsPerPage,
          }
          setOption(options)
          setLeaveDetails(response.payload.data);
          return response.payload
        }
      }
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };


  const breadcrumb = {
    mainHeader: "Manage Leave",
    subHeader: "Home",
    subHeaderLink: "/dashboard",
    subHeader1: "Manage Leave",
    subHeader1Link:null,
    subHeader2: null,
    subHeader2Link: null,
  };

  return (
    <MainLayout breadcrumb={breadcrumb}>
      <div className="card">
        <div className="card-body">
          <div className="card-header ">
            <p
              className="card-title"
              style={{
                fontSize: "20px",
                fontWeight: "500",
                color: ThemeColor.EASTERNBLUE,
              }}
            >
              Manage Leave
            </p>
          </div>

          <Datatable columns={columns} data={leaveDetails} options={option} customBodyRender={renderActions} api={geLeaveDetailsApi}  />
        </div>
      </div>
    </MainLayout>
  );
};

export default Leave;
