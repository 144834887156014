import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "../../store";
import { ThemeColor } from "../../constants/themeColorConstant";
import MainLayout from "../layout/MainLayout";
import { useLocation, useNavigate } from "react-router-dom";
import { projectThunks } from "../../store/reducers/projects/project.thunk";
import { clientThunks } from "../../store/reducers/client/client.thunk";
import Select from "react-select";

const validationSchema = Yup.object().shape({
  clientId: Yup.string().required("Please select client"),
  type: Yup.string().required("Please select type"),
});

const AddProject = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("projectAdd");
  const [statusList, setStatusList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [scopeList, setScopeList] = useState([]);

  const location = useLocation();
  const { moduleId } = location.state || {};

  const dispatch = useDispatch();

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const {
    errors,
    handleBlur,
    handleChange,
    touched,
    setFieldValue,
    handleSubmit,
  } = useFormik({
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      await addProjectApi(values);
    },
    initialValues: {
      clientId :"",
      type:"",
      reservedPercentage : 35
    },
  });

  useEffect(() => {
    getScopeApi();
    getStatusApi();
    getTypesApi();
    getClientListApi();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loginClickHandle = (e) => {
    e.preventDefault();
    e.persist();
    handleSubmit(e);
  };

  const addProjectApi = async (data) => {
    try {
      console.log({data})
      const response = await dispatch(
        projectThunks.addProject({ data, moduleId })
      );
      if (projectThunks.addProject.fulfilled.match(response)) {
        navigate("/projects");
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const getScopeApi = async () => {
    try {
      const response = await dispatch(
        projectThunks.projectScopesList(moduleId)
      );
      if (projectThunks.projectScopesList.fulfilled.match(response)) {
        if (response.payload) {
          setScopeList(response.payload);
        }
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };
  const getStatusApi = async () => {
    try {
      const response = await dispatch(
        projectThunks.projectStatusList(moduleId)
      );
      if (projectThunks.projectStatusList.fulfilled.match(response)) {
        if (response.payload) {
          setStatusList(response.payload);
        }
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const getTypesApi = async () => {
    try {
      const response = await dispatch(projectThunks.projectTypesList(moduleId));
      if (projectThunks.projectTypesList.fulfilled.match(response)) {
        if (response.payload) {
          setTypeList(response.payload);
        }
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const getClientListApi = async () => {
    try {
      const response = await dispatch(clientThunks.clientList({ moduleId }));
      if (clientThunks.clientList.fulfilled.match(response)) {
        if (response.payload) {
          setClientList(response.payload);
          return response.payload;
        }
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const breadcrumb = {
    mainHeader: "Project",
    subHeader: "Home",
    subHeaderLink: "/dashboard",
    subHeader1: "Project",
    subHeader1Link: "/projects",
    subHeader2: "Add",
    subHeader2Link: null,
  };

  return (
    <>
      <MainLayout breadcrumb={breadcrumb}>
        <div className="card card-info">
          <div className="card-body">
            <ul
              className="nav nav-tabs"
              style={{
                borderBottom: `2px solid ${ThemeColor.GREY}`,
                backgroundColor: "transparent",
              }}
            >
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    activeTab === "projectAdd" ? "active" : ""
                  }`}
                  onClick={() => handleTabChange("Project Add")}
                  style={{
                    borderBottom: `3px solid ${
                      activeTab === "projectAdd"
                        ? ThemeColor.BLUE
                        : "transparent"
                    }`,
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    backgroundColor: "transparent",
                  }}
                >
                  Project Add
                </button>
              </li>
            </ul>
            <form
              className="form-horizontal"
              id="personal_info"
              onSubmit={handleSubmit}
            >
              <div className="card-body">
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Project Number</label>
                  <div className="col-sm-5  col-md-5">
                    <input
                      type="text"
                      className="form-control"
                      name="projectNumber"
                      id="projectNumber"
                      placeholder="Project Number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Project Name</label>
                  <div className="col-sm-5  col-md-5">
                    <input
                      type="text"
                      className="form-control"
                      name="projectName"
                      id="projectName"
                      placeholder="Project Name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Scope</label>
                  <div className="col-sm-5  col-md-5">
                     <Select
                      placeholder="Select Scope"
                      isSearchable
                      id="scope"
                      name="scope"
                      onChange={(selectedOption) => {
                        setFieldValue(
                          "scope",
                          selectedOption ? selectedOption.value : ""
                        );
                      }}
                      options={scopeList.map((data) => ({
                        value: data.slug,
                        label: data.name,
                      }))}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Location</label>
                  <div className="col-sm-5  col-md-5">
                    <textarea
                      id="location"
                      name="location"
                      cols={2}
                      rows={2}
                      className="form-control"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    ></textarea>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">
                    Client <span style={{ color: ThemeColor.RED }}>*</span>
                  </label>
                  <div className="col-sm-5  col-md-5">
                     <Select
                      placeholder="Select Client"
                      isSearchable
                      id="clientId"
                      name="clientId"
                      onChange={(selectedOption) => {
                        setFieldValue(
                          "clientId",
                          selectedOption ? selectedOption.value : ""
                        );
                      }}
                      options={clientList.map((data) => ({
                        value: data.uid,
                        label: data.name,
                      }))}
                    />
                    {errors.clientId && touched.clientId && (
                      <div className="error" style={{ color: ThemeColor.RED }}>
                        {errors.clientId}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">
                    Type <span style={{ color: ThemeColor.RED }}>*</span>
                  </label>
                  <div className="col-sm-5  col-md-5">
                     <Select
                      placeholder="Select Type"
                      isSearchable
                      id="type"
                      name="type"
                      onChange={(selectedOption) => {
                        setFieldValue(
                          "type",
                          selectedOption ? selectedOption.value : ""
                        );
                      }}
                      options={typeList.map((data) => ({
                        value: data.slug,
                        label: data.name,
                      }))}
                    />
                    {errors.type && touched.type && (
                      <div className="error" style={{ color: ThemeColor.RED }}>
                        {errors.type}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Quote Ref</label>
                  <div className="col-sm-5  col-md-5">
                    <input
                      type="text"
                      className="form-control"
                      name="quoteRef"
                      id="quoteRef"
                      placeholder="Quote Ref"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">LPO Number</label>
                  <div className="col-sm-5  col-md-5">
                    <input
                      type="text"
                      className="form-control"
                      name="lpoNumber"
                      id="lpoNumber"
                      placeholder="LPO Number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">LPO Value</label>
                  <div className="col-sm-5  col-md-5">
                    <input
                      type="text"
                      className="form-control"
                      name="lpoValue"
                      id="lpoValue"
                      placeholder="LPO Value"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">LPO Date</label>
                  <div className="col-sm-5  col-md-5">
                    <input
                      type="date"
                      id="lpoDate"
                      name="lpoDate"
                      className="form-control"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Start Date</label>
                  <div className="col-sm-5  col-md-5">
                    <input
                      type="date"
                      id="startDate"
                      name="startDate"
                      className="form-control"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">
                    Expected Finish Date
                  </label>
                  <div className="col-sm-5  col-md-5">
                    <input
                      type="date"
                      id="expectedFinishDate"
                      name="expectedFinishDate"
                      className="form-control"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">
                    Actual Finish Date
                  </label>
                  <div className="col-sm-5  col-md-5">
                    <input
                      type="date"
                      id="actualFinishDate"
                      name="actualFinishDate"
                      className="form-control"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Status</label>
                  <div className="col-sm-5  col-md-5">
                    <Select
                      placeholder="Select Status"
                      isSearchable
                      id="status"
                      name="status"
                      onChange={(selectedOption) => {
                        setFieldValue(
                          "status",
                          selectedOption ? selectedOption.value : ""
                        );
                      }}
                      options={statusList.map((data) => ({
                        value: data.slug,
                        label: data.name,
                      }))}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Project Remark</label>
                  <div className="col-sm-5  col-md-5">
                    <textarea
                      id="projectRemark"
                      name="projectRemark"
                      cols={2}
                      rows={2}
                      className="form-control"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    ></textarea>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">
                    Emergency Reserved Percentage (%)
                  </label>
                  <div className="col-sm-5  col-md-5">
                    <select
                      className="form-control"
                      name="reservedPercentage"
                      id="reservedPercentage"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option>Select</option>
                      {[...Array(100).keys()].map((number) => (
                        <option key={number + 1} selected={35 === number+1} value={number + 1}>
                          {number + 1}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ gap: "10px", marginTop: "30px" }}
              >
                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{
                    padding: "8px 20px",
                    width: "120px",
                  }}
                  onClick={(e) => loginClickHandle(e)}
                >
                  <i
                    className="fa fa-floppy-o"
                    style={{ marginRight: "8px" }}
                  />
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-default"
                  style={{
                    padding: "8px 20px",
                    width: "120px",
                  }}
                  onClick={()=>{navigate("/projects")}}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default AddProject;
