import React from "react";
import { useFormik } from "formik";
import { useDispatch } from "../../store";
import { useParams } from "react-router";
import { authorityThunks } from "../../store/reducers/authority/authority.thunk";
import {parseCustomDate } from "../employee/Personal";
import Datepicker from "../../components/datepicker";

const ImmigrationDetails = ({ activeTab, moduleId, employeeData }) => {

  const dispatch = useDispatch();
  const { id } = useParams();
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
  } = useFormik({
    enableReinitialize: true,
    onSubmit: async (values) => {
      const formattedValues = {
        ...values,
        issueDate: values.issueDate && parseCustomDate(values.issueDate),
        expiryDate: values.expiryDate && parseCustomDate(values.expiryDate),
        cardExpiryDate: values.cardExpiryDate && parseCustomDate(values.cardExpiryDate),
        emiratesExpiryDate : values.emiratesExpiryDate && parseCustomDate(values.emiratesExpiryDate)
      };
      await updateImmigrationDetailsApi(formattedValues);
    },
    initialValues: {
      visaUidNumber: employeeData.authority
        ? employeeData.authority.visaUidNumber
        : null,
      visaFileNumber: employeeData.authority
        ? employeeData.authority.visaFileNumber
        : null,
      profession: employeeData.authority
        ? employeeData.authority.profession
        : null,
      emiratesIdNumber: employeeData.authority
        ? employeeData.authority.emiratesIdNumber
        : null,
      cardNumber: employeeData.authority
        ? employeeData.authority.cardNumber
        : null,
      cardExpiryDate:  employeeData.authority
        ? employeeData.authority.cardExpiryDate
        : null,
      issueDate: employeeData.authority
        ? employeeData.authority.issueDate
        : null,
      expiryDate: employeeData.authority
        ? employeeData.authority.expiryDate
        : null,
        emiratesExpiryDate :   employeeData.authority
        ? employeeData.authority.emiratesExpiryDate
        : null,
    },
  });

  const updateImmigrationDetailsApi = async (data) => {
    try {
      const response = await dispatch(
        authorityThunks.updateAuthoritiesDetails({
          id,
          data,
          moduleId,
          activeTab,
        })
      );
      if (authorityThunks.updateAuthoritiesDetails.fulfilled.match(response)) {
        return response
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  return (
    <form
      className="form-horizontal"
      id="immigration_info"
      onSubmit={handleSubmit}
    >
      <div className="card-body">
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Visa UID Number</label>
          <div className="col-sm-5  col-md-5">
            <input
              type="text"
              className="form-control"
              name="visaUidNumber"
              id="visaUidNumber"
              placeholder="Visa UID Number"
              value={values.visaUidNumber}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Visa File Number</label>
          <div className="col-sm-5  col-md-5">
            <input
              type="text"
              className="form-control"
              name="visaFileNumber"
              id="visaFileNumber"
              placeholder="Visa File Number"
              value={values.visaFileNumber}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Profession</label>
          <div className="col-sm-5  col-md-5">
            <input
              type="text"
              className="form-control"
              name="profession"
              id="profession"
              placeholder="Profession"
              value={values.profession}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Issue Date</label>
          <div className="col-sm-5  col-md-5">
            <Datepicker
              name="issueDate"
              id="issueDate"
              value={values.issueDate}
              dateFormat="yyyy-mm-dd"
              onChange={(date) =>
                setValues({
                  ...values,
                  issueDate: parseCustomDate(date.target.value),
                })
              }
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Expiry Date</label>
          <div className="col-sm-5  col-md-5">
            <Datepicker
              name="expiryDate"
              id="expiryDate"
              value={values.expiryDate}
              dateFormat="yyyy-mm-dd"
              onChange={(date) =>
                setValues({
                  ...values,
                  expiryDate: parseCustomDate(date.target.value),
                })
              }
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Emirates Expiry Date</label>
          <div className="col-sm-5  col-md-5">
            <Datepicker
              name="emiratesExpiryDate"
              id="emiratesExpiryDate"
              value={values.emiratesExpiryDate}
              dateFormat="yyyy-mm-dd"
              onChange={(date) =>
                setValues({
                  ...values,
                  emiratesExpiryDate: parseCustomDate(date.target.value),
                })
              }
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Card Expiry Date</label>
          <div className="col-sm-5  col-md-5">
            <Datepicker
              name="cardExpiryDate"
              id="cardExpiryDate"
              value={values.cardExpiryDate}
              dateFormat="yyyy-mm-dd"
              onChange={(date) =>
                setValues({
                  ...values,
                  cardExpiryDate: parseCustomDate(date.target.value),
                })
              }
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Emirates ID Number</label>
          <div className="col-sm-5  col-md-5">
            <input
              type="text"
              className="form-control"
              name="emiratesIdNumber"
              id="emiratesIdNumber"
              placeholder="Emirates ID Number"
              value={values.emiratesIdNumber}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Card Number</label>
          <div className="col-sm-5  col-md-5">
            <input
              type="text"
              className="form-control"
              name="cardNumber"
              id="cardNumber"
              placeholder="Card Number"
              value={values.cardNumber}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <button
          type="submit"
          className="btn btn-primary"
          style={{ padding: "8px 20px" }}
        >
          <i className="fa fa-floppy-o" style={{ marginRight: "8px" }} />
          Update
        </button>
      </div>
    </form>
  );
};

export default ImmigrationDetails;
