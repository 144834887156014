import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "../../store";
import { ThemeColor } from "../../constants/themeColorConstant";
import MainLayout from "../layout/MainLayout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Datepicker from "../../components/datepicker";
import { parseCustomDate } from "../employee/Personal";
import { formTemplateThunks } from "../../store/reducers/formTemplate/formTemplate.thunk";

const validationSchema = Yup.object().shape({
  formNumber: Yup.string().required("Please enter form number"),

});

const EditForm = () => {
  const [activeTab, setActiveTab] = useState("editForm");
  const [formData,setFormData] = useState([])
  const [selectedFile,setSelectedFile] = useState(null)
  const navigate = useNavigate()

  const location = useLocation();
  const { moduleId } = location.state || {};
  const { id } = useParams();

  const dispatch = useDispatch();

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    setValues,
    setFieldValue
  } = useFormik({
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const formattedValues = {
        ...values,
        revisedOn: values.revisedOn && parseCustomDate(values.revisedOn),
      };

      await updateFormTemplateApi(formattedValues);
    },
    initialValues: {
       formNumber : formData ? formData.formNumber : "",
        formDetails : formData ? formData.formDetails : null,
        revisedOn : formData && formData.revisedOn ? formData.revisedOn : null,
        remark : formData ? formData.remark : null,
    },
  });

  useEffect(() => {
    getFormTemplateDataApi()
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loginClickHandle = (e) => {
    e.preventDefault();
    e.persist();
    handleSubmit(e);
  };


  const getFormTemplateDataApi = async () => {
    try {
      const response = await dispatch(formTemplateThunks.formTemplateDetails({id,moduleId}));
      if (formTemplateThunks.formTemplateDetails.fulfilled.match(response)) {
        if (response.payload) {
          setFormData(response.payload);
        }
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

    const updateFormTemplateApi = async (data) => {
      try {
        const response = await dispatch(
          formTemplateThunks.updateFormTemplate({id, data, moduleId })
        );
        if (formTemplateThunks.updateFormTemplate.fulfilled.match(response)) {
            navigate("/form-template")
        }
      } catch (error) {
        console.error("Error fetching employee data:", error);
      }
    };

  const breadcrumb = {
    mainHeader: "Manage Form",
    subHeader: "Home",
    subHeaderLink: "/dashboard",
    subHeader1: "Manage Form",
    subHeader1Link: "/form-template",
    subHeader2: "edit",
    subHeader2Link: null,
  };

  return (
    <>
      <MainLayout breadcrumb={breadcrumb}>
        <div className="card card-info">
          <div className="card-body">
            <ul
              className="nav nav-tabs"
              style={{
                borderBottom: `2px solid ${ThemeColor.GREY}`,
                backgroundColor: "transparent",
              }}
            >
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    activeTab === "editForm" ? "active" : ""
                  }`}
                  onClick={() => handleTabChange("editForm")}
                  style={{
                    borderBottom: `3px solid ${
                      activeTab === "editForm" ? ThemeColor.BLUE : "transparent"
                    }`,
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    backgroundColor: "transparent",
                  }}
                >
                  Edit Form
                </button>
              </li>
            </ul>
            <form
              className="form-horizontal"
              id="personal_info"
              onSubmit={handleSubmit}
            >
              <div className="card-body">
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">
                    Form Number <span style={{ color: ThemeColor.RED }}>*</span>
                  </label>
                  <div className="col-sm-5  col-md-5">
                    <input
                      type="text"
                      className="form-control"
                      name="formNumber"
                      id="formNumber"
                      placeholder="Form Number"
                      value={values.formNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.formNumber && touched.formNumber && (
                      <div className="error" style={{ color: ThemeColor.RED }}>
                        {errors.formNumber}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Form Details</label>
                  <div className="col-sm-5  col-md-5">
                    <input
                      type="text"
                      className="form-control"
                      name="formDetails"
                      id="formDetails"
                      placeholder="Form Details"
                      value={values.formDetails}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Revised On</label>
                  <div className="col-sm-5  col-md-5">
                    <Datepicker
                      name="revisedOn"
                      id="revisedOn"
                      value={values.revisedOn}
                      dateFormat="yyyy-mm-dd"
                      onChange={(date) =>
                        setValues({
                          ...values,
                          revisedOn: parseCustomDate(date.target.value),
                        })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Remark</label>
                  <div className="col-sm-5  col-md-5">
                    <textarea
                      id="remark"
                      name="remark"
                      cols={2}
                      rows={2}
                      value={values.remark}
                      className="form-control"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    ></textarea>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">
                    form <span style={{ color: ThemeColor.RED }}>*</span>
                  </label>
                  <div className="col-sm-3  col-md-3">
                    <input
                      type="text"
                      className="form-control"
                      name="uploadFile"
                      id="uploadFile"
                      placeholder="Form Details"
                      value={selectedFile ? selectedFile.name : formData.form}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      readOnly
                    />
                  </div>
                  <div className="col-sm-2 col-md-2">
                    <label className="btn btn-primary" style={{fontWeight:"normal"}}>
                      Upload Document
                      <input
                        type="file"
                        name="form"
                        id="form"
                        style={{ display: "none" }}
                        onChange={(event) => {
                          const file = event.target.files[0];
                          if (file) {
                            setSelectedFile(file);
                            const fileExtension = file.name.split('.').pop();
                            const reader = new FileReader();
                            reader.onload = (e) => {
                              const base64Data = e.target.result;
                              const fileObject = {};
                              fileObject[`form.${fileExtension}`] = base64Data;
                              setFieldValue("form", fileObject);
                            };
                            reader.readAsDataURL(file);
                          } else {
                           
                          }
                        }}
                      />
                    </label>
                  </div>
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-center">
              <button
                type="submit"
                className="btn btn-primary"
                style={{ padding: "8px 20px" }}
                onClick={(e) => loginClickHandle(e)}
              >
                <i className="fa fa-edit" style={{ marginRight: "8px" }} />
                Update
              </button>
              <button
                type="button"
                className="btn btn-default"
                style={{ padding: "8px 20px", marginLeft: "10px" }}
                onClick={()=>{
                  navigate("/form-template")
                }}
              >
                Cancel
              </button>
            </div>
            </form>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default EditForm;
