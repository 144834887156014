export const ROUTES = {
  // AUTH
  LOGIN_ROUTE: "companies/auth/login",
  EMPLOYEE_LOGIN_ROUTE: "companies/auth/emp-login",

  //COMMON

  GET_COMMON_API: "companies/common",
  // EMPLOYEE
  GET_EMPLOYEE: "companies/employees",
  EMPLOYEE_STATUS: "companies/common/employee-status",
  EMPLOYEE_TYPES: "companies/common/employee-types",
  GET_EMPLOYEELIST: "companies/attendances/employees-list",
  EMPLOYEE_VEHICLE: "companies/vehicles/employees",
  GRATUITY_EMPLOYEE: "companies/gratuity/employee",

  //COMMON
  GET_DESIGNATION: "companies/common/designations",
  GET_GENDER: "companies/common/genders",
  GET_BLOODGROUP: "companies/common/blood-groups",
  GET_DEPARTMENT: "companies/common/departments",
  GET_MODULES: "companies/common/modules",

  //COMPANY
  GET_COMPANY: "companies",
  UPDATE_COMPANY: "companies",
  DASHBOARD_DETAILS: "companies/dashboard",

  //ROLE
  GET_ROLE: "companies/roles",
  UPDATE_STATUS: "companies/roles/status",

  //BANK
  GET_BANKDETAILS: "companies/bank-details",

  //CLIENT
  GET_CLIENT: "companies/clients",
  CREATE_CLIENT: "companies/clients/",
  UPDATE_CLIENT: "companies/clients",
  GET_DESCIPLINES: "companies/common/desciplines",
  GET_BUSINESSTYPES: "companies/common/business-types",
  GET_LEGALSTATUS: "companies/common/legat-status",
  GET_MARKETINGSTATUS: "companies/common/marketing-status",
  GET_RATING: "companies/common/ratings",
  GET_CURRENTSTATUS: "companies/common/current-status",
  CLIENT_CONTACT_LIST: "companies/client-contacts/list",
  CLIENT_DESIGNATION: "companies/common/client-contact-designations",
  CLIENT_CONTACT: "companies/client-contacts",

  //INSURANCE
  GET_INSURANCE: "companies/insurances",
  HEALTH_TYPE: "companies/common//health-types",
  WORKSMEN_TYPE: "companies/common/worksmen-types",

  //AUTHORITY
  GET_AUTHORITY: "companies/authorities",
  PASSPORT_STATUS: "companies/common/passport-status",

  //LEAVE
  GET_LEAVES: "companies/leaves",
  GET_LEAVESDETAILS: "companies/leaves",

  //ATTENDANCE VIEW
  GET_ATTENDANCEVIEW: "companies/attendance-view",

  //ATTENDANCE
  GET_ATTENDANCE: "companies/attendances",
  DELETE_ATTENDANCE: "companies/attendances/remove",

  //EXPNSE DEDUCTION
  GET_EXPENSE: "companies/expense",
  EXPENSE_TYPE: "companies/common/expense-types",

  //VEHICLE MATRIX
  GET_VEHICLE: "companies/vehiclematrix",
  GET_VEHICLELIST: "companies/vehicles/list",
  GET_VEHICLE_HISTORY: "companies/vehiclematrix/history",

  //PACKAGE
  GET_PACKAGE: "companies/packages",
  MODE_OF_TRANSFER: "companies/common/modes-of-transfer",
  GET_PACKAGEHISTORY: "companies/packages/history",

  //ASSET
  GET_ASSET: "companies/asset",
  GET_TYPE: "companies/common/asset-types",
  GET_STATUS: "companies/common/asset-status",

  //GRATUITY
  GET_GRATUITY: "companies/gratuity",

  //FORM TEMPLATE

  GET_FORM: "companies/formtemplate",

  //USERS
  GET_USER: "companies/users",
  USER_PERMISSION: "companies/common/user-permissons-types",

  //PROJECTS
  GET_PROJECT: "companies/projects",
  PROJECT_LIST: "companies/projects/list",
  GET_PROJECT_STATUS: "companies/common/project-status",
  GET_PROJECT_SCOPE: "companies/common/project-scopes",
  GET_PROJECT_TYPES: "companies/common/project-types",
  GET_EMPLOYEE_PROJECT: "companies/employees",

  //PAYROLL
  GET_PAYROLL: "companies/payroll",

  //SUBCONTRACTOR
  GET_SUBCONTRACTOR: "companies/subcontractor",

  //PROJECT COSTING
  GET_PROJECTCOSTING: "companies/project-expenses",
  GET_PROJECTEXPENSE: "companies/projectexpense",

  //REPORT
  GET_REPORT: "companies/reports",

  //EMPLOYEE SELF ACCESS
  GET_EMPLOYEELOGIN: "companies/employeelogin",

  //PLANNING

  GET_PALNNING: "companies/plannings",

  // PRIORITY
  GET_PRIORITY: "companies/priority/get-data",
  GET_PRIORITY_COLUMNS_LIST: "companies/priority/get-column-list",
};
