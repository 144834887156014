import React, { useEffect, useState } from "react";
import { ThemeColor } from "../../constants/themeColorConstant";
import MainLayout from "../layout/MainLayout";
import { useLocation, useParams } from "react-router";
import {useNavigate} from "react-router-dom";
import { useDispatch } from "../../store";
import {roleThunks } from "../../store/reducers/role/role.thunk";
import { useFormik } from "formik";

const AddRole = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const location = useLocation();
  const { moduleId } = location.state || {};
  const navigate = useNavigate()
  const [allowedModulesList, setAllowedModulesList] = useState([]);

  
  const { values, handleBlur, handleChange, handleSubmit,setFieldValue } = useFormik({
    enableReinitialize: true,
    onSubmit: async (values) => {
      await addRoleDataApi(values);
    },
    initialValues: {
            status : "true",
            allowedModules : []
    },
  });

  useEffect(() => {
    geAllowedModulesApi();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  
  const handleRoleChange = (module) => {
    setFieldValue("allowedModules", [...values.allowedModules, module.uid]);
  };

  const geAllowedModulesApi = async () => {
    try {
      const response = await dispatch(
        roleThunks.allowedModulesData({ moduleId })
      );
      if (roleThunks.allowedModulesData.fulfilled.match(response)) {
        if (response.payload) {
          setAllowedModulesList(response.payload);
        }
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const addRoleDataApi = async (data) => {
    try {
      const response = await dispatch(
        roleThunks.addRole({ id, data, moduleId })
      );
      if (roleThunks.addRole.fulfilled.match(response)) {
        navigate("/role")
        return response.payload;
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const breadcrumb = {
    mainHeader: "Manage Role",
    subHeader: "Home",
    subHeaderLink: "/dashboard",
    subHeader1: "Manage Role",
    subHeader1Link: "/role",
    subHeader2: "edit",
    subHeader2Link: null,
  };
  
  return (
    <MainLayout breadcrumb={breadcrumb}>
      <div className="card">
        <div className="card-body">
          <div className="card-header">
            <p
              className="card-title"
              style={{
                fontSize: "20px",
                fontWeight: "500",
                color: ThemeColor.EASTERNBLUE,
              }}
            >
              Edit Role
            </p>
          </div>
          <form onSubmit={handleSubmit}>
            <div style={{ padding: "50px 20px" }}>
              <div className="form-group row">
                <label className="col-sm-2 col-md-2">
                  Role Name <span style={{ color: ThemeColor.RED }}>*</span>
                </label>
                <div className="col-sm-4  col-md-4">
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    id="name"
                    placeholder="Role"
                    value={values.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <div className="error" id="err_name" />
                </div>
              </div>
              <div className="form-group row" style={{ margin: "2rem 0" }}>
                <label className="col-sm-2 col-md-2">Role Access</label>
                <div className="col-sm-10">
                  <div className="row">
                    {allowedModulesList.map((module) => {
                      return (
                        <div key={module.id} className="col-sm-4 col-md-4">
                          <label style={{ fontWeight: "normal" }}>
                            <input
                              type="checkbox"
                              id={module.uid}
                              checked={values.allowedModules.includes(module.uid)}
                                onChange={() => handleRoleChange(module)}
                             
                              style={{
                                marginRight: "8px",
                                width: "18px",
                                height: "18px",
                              }}
                            />
                            {module.name}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ gap: "10px", marginTop: "30px" }}
              >
                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{
                    padding: "8px 20px",
                    width: "120px",
                  }}
                >
                  <i
                    className="fa fa-floppy-o"
                    style={{ marginRight: "8px" }}
                  />
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-default"
                  style={{
                    padding: "8px 20px",
                    width: "120px",
                  }}
                  onClick={()=>{navigate("/role")}}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </MainLayout>
  );
};

export default AddRole;
