import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Checkbox, FormControlLabel } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import MainLayout from "../layout/MainLayout";
import Datatable from "../../components/datatable";

import { useDispatch } from "../../store";
import { ThemeColor } from "../../constants/themeColorConstant";
import { priorityThunks } from "../../store/reducers/priority/priority.thunk";

const initialColumns = [
  {
    name: "emp_emp_code",
    slug: "emp_emp_code",
    label: "Emp Code",
    options: {
      sort: true,
      filter: false,
    },
  },
  {
    name: "emp_name",
    slug: "emp_name",
    label: "Name",
    options: {
      sort: true,
      filter: false,
    },
  },
  {
    name: "emp_email",
    slug: "emp_email",
    label: "Email",
    options: {
      sort: true,
      filter: false,
    },
  },
  {
    name: "emp_mobile_number",
    slug: "emp_mobile_number",
    label: "Mobile No",
    options: {
      sort: true,
      filter: false,
    },
  },
  {
    name: "emp_pr_exp",
    slug: "emp_pr_exp",
    label: "Pr Exp",
    options: {
      sort: true,
      filter: false,
    },
  },
  {
    name: "emp_date_of_joining",
    slug: "emp_date_of_joining",
    label: "Date of Joining",
    options: {
      sort: true,
      filter: false,
    },
  },

  {
    name: "emp_emp_status",
    slug: "emp_emp_status",
    label: "Emp Status",
    options: {
      sort: true,
      filter: false,
    },
  },
];

const columnLabels = {
  bank: "Bank",
  bankWps: "Bank WPS",
  employee: "Employee",
  healthInsurance: "Health Insurance",
  immigration: "Immigration",
  labor: "Labor",
  passport: "Passport",
  pkg: "Package",
  projects: "Projects",
  worksmanInsurance: "Insurance",
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const Priority = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [value, setValue] = useState(0);
  const [priorityData, setPriorityData] = useState([]);
  const [columns, setColumns] = useState({});
  const [selectedColumns, setSelectedColumns] = useState([...initialColumns]);

  const pathnameSegments = location.pathname.split("/");
  const moduleName = pathnameSegments[1];

  const allowedModules = JSON.parse(localStorage.getItem("allowedModules"));

  const selectedModule = allowedModules.find(
    (module) => module.slug === moduleName
  );
  const moduleId = selectedModule.uid;

  useEffect(() => {
    getPriorityData();
    getPriorityColumns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    (_) => {
      getPriorityData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedColumns]
  );

  const getPriorityData = async (
    page = 1,
    rowsPerPage = 10,
    searchText = "",
    name = "",
    order = ""
  ) => {
    try {
      const priorityResponse = await dispatch(
        priorityThunks.getPriorityData({
          columnsToSelect: selectedColumns.map((c) => c.name),
          moduleId,
          page,
          rowsPerPage,
          searchText,
          name,
          order,
        })
      );
      setPriorityData([...priorityResponse.payload]);
    } catch (error) {
      console.log("Error @", error);
    }
  };

  const getPriorityColumns = async () => {
    try {
      const priorityColumnsResponse = await dispatch(
        priorityThunks.getPriorityColumns({
          moduleId,
        })
      );
      setColumns(priorityColumnsResponse.payload);
    } catch (error) {
      console.log("Error @", error);
    }
  };

  const handleCheckColumns = ({ l, checked }) => {
    setSelectedColumns((prevData) => {
      return checked
        ? [
            ...prevData,
            {
              slug: l.slug,
              name: l.slug,
              label: l.name,
              options: {
                sort: true,
                filter: false,
              },
            },
          ]
        : [...prevData.filter((p) => p.slug !== l.slug)];
    });
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const breadcrumb = {
    mainHeader: "Priority",
    subHeader: "Home",
    subHeaderLink: "/dashboard",
    subHeader1: "Listing",
    subHeader1Link: null,
    subHeader2: null,
    subHeader2Link: null,
  };

  return (
    <MainLayout breadcrumb={breadcrumb}>
      <div className="card">
        <div className="card-body">
          <div className="card-header ">
            <p
              className="card-title"
              style={{
                fontSize: "20px",
                fontWeight: "500",
                color: ThemeColor.EASTERNBLUE,
              }}
            >
              Priority
            </p>
          </div>
          <Datatable
            columns={selectedColumns}
            data={priorityData}
            options={{}}
            api={getPriorityData}
            components={{
              TableViewCol: (_) => (
                <div className="column-selector">
                  <Box sx={{ width: "100%" }}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <Tabs
                        value={value}
                        onChange={handleTabChange}
                        variant="scrollable"
                        aria-label="Priority Columns"
                        scrollButtons
                        allowScrollButtonsMobile
                      >
                        {Object.keys(columns).map((c) => (
                          <Tab label={columnLabels[c]} />
                        ))}
                      </Tabs>
                    </Box>
                    {Object.values(columns).map((c, i) => {
                      return (
                        <CustomTabPanel value={value} index={i}>
                          <div className="tab-container">
                            {c.map((l) => {
                              return (
                                <div class="column-container">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        onChange={(e) => {
                                          handleCheckColumns({
                                            l,
                                            checked: e.target.checked,
                                          });
                                        }}
                                        checked={selectedColumns.some(
                                          (s) => l.slug === s.name
                                        )}
                                      />
                                    }
                                    label={l.name}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </CustomTabPanel>
                      );
                    })}
                  </Box>
                </div>
              ),
            }}
          />
        </div>
      </div>
    </MainLayout>
  );
};

export default Priority;
