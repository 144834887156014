import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiCall } from "../../../utils/api";
import { ROUTES } from "../../../constants/routesConstant";
import { HTTP_METHODS } from "../../../constants/methodsConstant";
import { ToastType, setToastContent } from "../toast/toast.slice";


export const formTemplateList = createAsyncThunk(
    "formTemplate/list",
    async (inputData, { dispatch }) => {
      try {
        const response = await ApiCall(
          HTTP_METHODS.GET,
          `${ROUTES.GET_FORM}?page=${inputData.page}&limit=${inputData.rowsPerPage}&search=${inputData.searchText}&sort=${inputData.name}&order=${inputData.order}`,
          {},
          inputData.moduleId
        );
        if (response?.isSuccess) {
          return response.payload;
        } else {
          dispatch(
            setToastContent({
              type: ToastType.error,
              message: response.message,
            })
          );
        }
      } catch (error) {
        throw new Error("An error occurred");
      }
    }
  );

  export const formTemplateDetails = createAsyncThunk(
    "formTemplate/details",
    async (inputData, { dispatch }) => {
      try {
        const response = await ApiCall(
          HTTP_METHODS.GET,
          `${ROUTES.GET_FORM}/${inputData.id}`,
          {},
          inputData.moduleId
        );
        if (response?.isSuccess) {
          return response.payload;
        } else {
          dispatch(
            setToastContent({
              type: ToastType.error,
              message: response.message,
            })
          );
        }
      } catch (error) {
        throw new Error("An error occurred");
      }
    }
  );

  export const addFormTemplate = createAsyncThunk(
    "formTemplate/add",
    async (inputData, { dispatch }) => {
      try {
        const response = await ApiCall(
          HTTP_METHODS.POST,
          ROUTES.GET_FORM,
          inputData.data,
          inputData.moduleId
        );
        if (response?.isSuccess) {
          dispatch(
            setToastContent({
              type: ToastType.success,
              message: response.message,
            })
          );
          return response.payload;
        } else {
          dispatch(
            setToastContent({
              type: ToastType.error,
              message: response.message,
            })
          );
        }
      } catch (error) {
        throw new Error("An error occurred");
      }
    }
  );

  export const updateFormTemplate = createAsyncThunk(
    "formTemplate/update",
    async (inputData, { dispatch }) => {
      try {
        const response = await ApiCall(
          HTTP_METHODS.PATCH,
          `${ROUTES.GET_FORM}/${inputData.id}`,
          inputData.data,
          inputData.moduleId
        );
        if (response?.isSuccess) {
          dispatch(
            setToastContent({
              type: ToastType.success,
              message: response.message,
            })
          );
          return response.payload;
        } else {
          dispatch(
            setToastContent({
              type: ToastType.error,
              message: response.message,
            })
          );
        }
      } catch (error) {
        throw new Error("An error occurred");
      }
    }
  );

 

  export const deleteFormTemplate = createAsyncThunk(
    "formTemplate/delete",
    async (inputData, { dispatch }) => {  
      try {
        const response = await ApiCall(
          HTTP_METHODS.DELETE,
          ROUTES.GET_FORM+`/${inputData.id}`,
          {},
          inputData.moduleId
        );
        if (response?.isSuccess) {
          dispatch(
            setToastContent({
              type: ToastType.success,
              message: response.message,
            })
          );
          return response.payload;
        } else {
          dispatch(
            setToastContent({
              type: ToastType.error,
              message: response.message,
            })
          );
        }
      } catch (error) {
        throw new Error("An error occurred");
      }
    }
  );


  export const formTemplateThunks = {
    formTemplateList,
    formTemplateDetails,
    addFormTemplate,
    updateFormTemplate,
    deleteFormTemplate,
  };  