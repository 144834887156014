import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { authThunks } from "../store/reducers/authentication/auth.thunk";
import * as Yup from "yup";
import { useFormik } from "formik";
import YupPassword from "yup-password";
import {
  ToastType,
  setToastContent,
} from "../store/reducers/toast/toast.slice";
import { useSelector } from "../store";
import ToastMessage from "../components/Toast";



export const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

YupPassword(Yup);
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required("Please enter email")
    .matches(emailRegex, "Please enter valid email"),
  password: Yup.string().required("Please enter password"),
});

const Signin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const toastData = useSelector((state) => state.toast);

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
  } = useFormik({
    validationSchema: validationSchema,
    onSubmit: (values) => {
      loginApiCall(values);
    },
    initialValues: {
      email: "",
      password: "",
    },
  });

  const loginClickHandle = (e) => {
    e.preventDefault();
    e.persist();
    handleSubmit(e);
  };

  const loginApiCall = async (data) => {
    try {
      const response = await dispatch(authThunks.login(data));
      if (authThunks.login.fulfilled.match(response)) {
        if (response.payload) {
          if (response.payload?.accessToken) {
            navigate("/dashboard");
          }
        }
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            duration: 3000,
            message: "Invalid credentials!",
          })
        );
      }
    } catch (error) {
      setError("Incorrect email or password!");
    }
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    navigate("/forgotPassword");
  };

  return (
    <div className="hold-transition login-page">
      <div className="login-box">
        <div className="card">
          <div className="card-body login-card-body">
          <div className="login-logo">
        <img
                src="/dist/img/logo.png"
                className="img-circle elevation-2"
                alt="User"
              />
        </div>
           
            <form>
              <div className="input-group mb-3">
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="form-control"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Email"
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fa fa-envelope" />
                  </div>
                </div>
              </div>
              {touched.email && errors.email && (
                <div style={{ color: "red", textAlign: "center" }}>
                  {errors.email}
                </div>
              )}
              <div className="input-group mb-3">
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Password"
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fa fa-lock" />
                  </div>
                </div>
              </div>
              {touched.password && errors.password && (
                <div style={{ color: "red", textAlign: "center" }}>
                  {errors.password}
                </div>
              )}
              {error && (
                <div style={{ color: "red", textAlign: "center" }}>{error}</div>
              )}
             
              <div className="input-group mb-3">
                  <button
                    type="submit"
                    className="btn btn-primary btn-block"
                    onClick={(e) => {
                      loginClickHandle(e);
                    }}
                  >
                    Sign In
                  </button>
                </div>
             
            </form>
            <p className="mt-3" style={{ textAlign: "center" }}>
              <Link onClick={handleForgotPassword}>
                Forgot password?
              </Link>
            </p>
          </div>
        </div>
      </div>
      <ToastMessage toastData={toastData} />
    </div>
  );
};

export default Signin;
