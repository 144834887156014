import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "../../store";
import { ThemeColor } from "../../constants/themeColorConstant";
import MainLayout from "../layout/MainLayout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { clientThunks } from "../../store/reducers/client/client.thunk";
import {parseCustomDate } from "../employee/Personal";
import Datepicker from "../../components/datepicker";
import { projectCostingThunk } from "../../store/reducers/projectCosting/projectCosting.thunk";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Please enter name")
    .matches(/^[A-Za-z\s]+$/, "name must contain only letters")
    .trim(),
});

const EditSubContractor = () => {
  const [activeTab, setActiveTab] = useState("Edit");
  const [desciplines, setDesciplines] = useState([]);
  const [businessTypes, setBusinessTypes] = useState([]);
  const [legalStatus, setLegalStatus] = useState([]);
  const [currentStatus, setCurrentStatus] = useState([]);
  const [marketingStatus, setMarketingStatus] = useState([]);
  const [clientRating, setClientRating] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [subContractorData, setSubContractorData] = useState([]);
  const [selectedCardImages, setSelectedCardImages] = useState({
    "card-1": null,
    "card-2": null,
    "card-3": null,
    "card-4": null,
  });

  const location = useLocation();
  const { moduleId } = location.state || {};
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    resetForm,
    setValues,
    setFieldValue
  } = useFormik({
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const formattedValues = {
        ...values,
        dateOfEstablishment: parseCustomDate(values.dateOfEstablishment),
      };
  
      await updateSubContratorDataApi(formattedValues);
      resetForm();
    },
    initialValues: {
      name: subContractorData ? subContractorData.name : "",
      shortName: subContractorData ? subContractorData.shortName : null,
      registrationNumber: subContractorData ? subContractorData.registrationNumber : null,
      discipline: subContractorData ? subContractorData.discipline : null,
      businessType: subContractorData ? subContractorData.businessType : null,
      address: subContractorData ? subContractorData.address : null,
      poBoxNumber: subContractorData ? subContractorData.poBoxNumber : null,
      telNumber1: subContractorData ? subContractorData.telNumber1 : null,
      telNumber2: subContractorData ? subContractorData.telNumber2 : null,
      faxNumber: subContractorData ? subContractorData.faxNumber : null,
      email: subContractorData ? subContractorData.email : null,
      website: subContractorData ? subContractorData.website : null,
      future: subContractorData ? subContractorData.future : null,
      legalStatus: subContractorData ? subContractorData.legalStatus : null,
      dateOfEstablishment:
        subContractorData && subContractorData.dateOfEstablishment
          ? parseCustomDate(subContractorData.dateOfEstablishment)
          : null,
      currentStatus: subContractorData ? subContractorData.currentStatus : null,
      rating: subContractorData ? subContractorData.rating : null,
      marketingStatus: subContractorData ? subContractorData.marketingStatus : null,
      remark: subContractorData ? subContractorData.remark : null,
      cards: subContractorData ? subContractorData.cards : null,
    },
  });

  useEffect(() => {
    getSubContractorDataApi();
    getDesciplineListApi();
    businessTypesListApi();
    legalStatusListApi();
    marketingStatusListApi();
    currentStatusListApi();
    ratingListApi();
  }, []);

  const loginClickHandle = (e) => {
    e.preventDefault();
    e.persist();
    handleSubmit(e);
  };

  const handleCardChange = (event, cardName) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const base64Data = e.target.result;

        const fileExtension = file.name.split(".").pop();
        setSelectedCardImages((prevImages) => ({
          ...prevImages,
          [cardName]: {
            base64: base64Data,
            extension: fileExtension,
          },
        }));

        setFieldValue("cards", {
          ...values.cards,
          [cardName]: {
            [`${cardName}.${fileExtension}`]: base64Data,
          },
        });
      };

      reader.readAsDataURL(file);
    }
  };


  const updateSubContratorDataApi = async (data) => {
    try {
      const response = await dispatch(
        projectCostingThunk.editSubContractor({ id, data, moduleId })
      );
      if (projectCostingThunk.editSubContractor.fulfilled.match(response)) {
        navigate("/project-costing/sub-contract");
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const getSubContractorDataApi = async () => {
    const data = {
      id,
      moduleId,
    };
    try {
      const response = await dispatch(projectCostingThunk.subContractorById(data));
      if (projectCostingThunk.subContractorById.fulfilled.match(response)) {
        if (response.payload) {
          setSubContractorData(response.payload);
        }
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const getDesciplineListApi = async () => {
    try {
      const response = await dispatch(clientThunks.getDesciplineList(moduleId));
      if (clientThunks.getDesciplineList.fulfilled.match(response)) {
        if (response.payload) {
          setDesciplines(response.payload);
        }
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const businessTypesListApi = async () => {
    try {
      const response = await dispatch(clientThunks.getBusinessType(moduleId));
      if (clientThunks.getBusinessType.fulfilled.match(response)) {
        if (response.payload) {
          setBusinessTypes(response.payload);
        }
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const legalStatusListApi = async () => {
    try {
      const response = await dispatch(clientThunks.getLegalStatus(moduleId));
      if (clientThunks.getLegalStatus.fulfilled.match(response)) {
        if (response.payload) {
          setLegalStatus(response.payload);
        }
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };
  const marketingStatusListApi = async () => {
    try {
      const response = await dispatch(
        clientThunks.getMarketingStatus(moduleId)
      );
      if (clientThunks.getMarketingStatus.fulfilled.match(response)) {
        if (response.payload) {
          setMarketingStatus(response.payload);
        }
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };
  const currentStatusListApi = async () => {
    try {
      const response = await dispatch(clientThunks.getCurrentStatus(moduleId));
      if (clientThunks.getCurrentStatus.fulfilled.match(response)) {
        if (response.payload) {
          setCurrentStatus(response.payload);
        }
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const ratingListApi = async () => {
    try {
      const response = await dispatch(clientThunks.getRating(moduleId));
      if (clientThunks.getRating.fulfilled.match(response)) {
        if (response.payload) {
          setClientRating(response.payload);
        }
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const breadcrumb = {
    mainHeader: "Sub-Contractor Edit",
    subHeader: "Home",
    subHeaderLink: "/dashboard",
    subHeader1: "Sub-Contractor",
    subHeader1Link: "/project-costing/sub-contract",
    subHeader2: "edit",
    subHeader2Link: null,
  };

  return (
    <MainLayout breadcrumb={breadcrumb}>
      <div className="card card-info">
        <div className="card-body">
          <ul
            className="nav nav-tabs"
            style={{
              borderBottom: `2px solid ${ThemeColor.GREY}`,
              backgroundColor: "transparent",
            }}
          >
            <li className="nav-item">
              <button
                className={`nav-link ${
                  activeTab === "Edit" ? "active" : ""
                }`}
                onClick={() => handleTabChange("Edit")}
                style={{
                  borderBottom: `3px solid ${
                    activeTab === "Edit"
                      ? ThemeColor.BLUE
                      : "transparent"
                  }`,
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  backgroundColor: "transparent",
                }}
              >
                 Edit
              </button>
            </li>
          </ul>
          <form
            className="form-horizontal"
            id="client_info"
            onSubmit={handleSubmit}
          >
            <div className="card-body">
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">
                  Name <span style={{ color: ThemeColor.RED }}>*</span>
                </label>
                <div className="col-sm-5  col-md-5">
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    id="name"
                    placeholder="Sub Contractor Name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.name && touched.name && (
                    <div className="error" style={{ color: ThemeColor.RED }}>
                      {errors.name}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">Short Name</label>
                <div className="col-sm-5  col-md-5">
                  <input
                    type="text"
                    className="form-control"
                    name="shortName"
                    id="shortName"
                    placeholder="Short Name"
                    value={values.shortName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.shortName && touched.shortName && (
                    <div className="error" style={{ color: ThemeColor.RED }}>
                      {errors.shortName}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">Registration Number</label>
                <div className="col-sm-5  col-md-5">
                  <input
                    type="text"
                    className="form-control"
                    name="registrationNumber"
                    id="registrationNumber"
                    placeholder="Registration Number"
                    value={values.registrationNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.registrationNumber && touched.registrationNumber && (
                    <div className="error" style={{ color: ThemeColor.RED }}>
                      {errors.registrationNumber}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">Discipline</label>
                <div className="col-sm-5  col-md-5">
                  <select
                    className="form-control"
                    name="discipline"
                    id="discipline"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option>Select</option>
                    {desciplines.map((data, index) => {
                      return (
                        <option
                          key={index}
                          selected={data.slug === values.discipline}
                          value={data.slug}
                        >
                          {data.name}
                        </option>
                      );
                    })}
                  </select>
                  {errors.discipline && touched.discipline && (
                    <div className="error" style={{ color: ThemeColor.RED }}>
                      {errors.discipline}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">Business Type</label>
                <div className="col-sm-5  col-md-5">
                  <select
                    className="form-control"
                    name="businessType"
                    id="businessType"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option>Select</option>
                    {businessTypes.map((data, index) => {
                      return (
                        <option
                          key={index}
                          selected={data.slug === values.businessTypes}
                          value={data.slug}
                        >
                          {data.name}
                        </option>
                      );
                    })}
                  </select>
                  {errors.businessType && touched.businessType && (
                    <div className="error" style={{ color: ThemeColor.RED }}>
                      {errors.businessType}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">Address</label>
                <div className="col-sm-5  col-md-5">
                  <textarea
                    id="address"
                    name="address"
                    cols={2}
                    rows={2}
                    className="form-control"
                    value={values.address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  ></textarea>
                  {errors.address && touched.address && (
                    <div className="error" style={{ color: ThemeColor.RED }}>
                      {errors.address}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">PO Box Number</label>
                <div className="col-sm-5  col-md-5">
                  <input
                    type="text"
                    className="form-control"
                    name="poBoxNumber"
                    id="poBoxNumber"
                    placeholder="PO Box Number"
                    value={values.poBoxNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.poBoxNumber && touched.poBoxNumber && (
                    <div className="error" style={{ color: ThemeColor.RED }}>
                      {errors.poBoxNumber}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">Tel-1 Number</label>
                <div className="col-sm-5  col-md-5">
                  <input
                    type="text"
                    className="form-control"
                    name="telNumber1"
                    id="telNumber1"
                    placeholder="Tel-1 Number"
                    value={values.telNumber1}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.telNumber1 && touched.telNumber1 && (
                    <div className="error" style={{ color: ThemeColor.RED }}>
                      {errors.telNumber1}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">Tel-2 Number</label>
                <div className="col-sm-5  col-md-5">
                  <input
                    type="text"
                    className="form-control"
                    name="telNumber2"
                    id="telNumber2"
                    placeholder="Tel-2 Number"
                    value={values.telNumber2}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.telNumber2 && touched.telNumber2 && (
                    <div className="error" style={{ color: ThemeColor.RED }}>
                      {errors.telNumber2}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">Fax Number</label>
                <div className="col-sm-5  col-md-5">
                  <input
                    type="text"
                    className="form-control"
                    name="faxNumber"
                    id="faxNumber"
                    placeholder="Fax Number"
                    value={values.faxNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.faxNumber && touched.faxNumber && (
                    <div className="error" style={{ color: ThemeColor.RED }}>
                      {errors.faxNumber}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">
                  Email <span style={{ color: ThemeColor.RED }}>*</span>
                </label>
                <div className="col-sm-5  col-md-5">
                  <input
                    type="text"
                    className="form-control"
                    name="email"
                    id="email"
                    placeholder="Email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.email && touched.email && (
                    <div className="error" style={{ color: ThemeColor.RED }}>
                      {errors.email}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">
                  Website <span style={{ color: ThemeColor.RED }}>*</span>
                </label>
                <div className="col-sm-5  col-md-5">
                  <input
                    type="text"
                    className="form-control"
                    name="website"
                    id="website"
                    placeholder="Website"
                    value={values.website}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.website && touched.website && (
                    <div className="error" style={{ color: ThemeColor.RED }}>
                      {errors.website}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">
                  Future <span style={{ color: ThemeColor.RED }}>*</span>
                </label>
                <div className="col-sm-5  col-md-5">
                  <input
                    type="text"
                    className="form-control"
                    name="future"
                    id="future"
                    placeholder="Future"
                    value={values.future}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.future && touched.future && (
                    <div className="error" style={{ color: ThemeColor.RED }}>
                      {errors.future}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">Legal Status</label>
                <div className="col-sm-5  col-md-5">
                  <select
                    className="form-control"
                    name="legalStatus"
                    id="legalStatus"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option>Select</option>
                    {legalStatus.map((data, index) => {
                      return (
                        <option
                          key={index}
                          selected={data.slug === values.legalStatus}
                          value={data.slug}
                        >
                          {data.name}
                        </option>
                      );
                    })}
                  </select>
                  {errors.legalStatus && touched.legalStatus && (
                    <div className="error" style={{ color: ThemeColor.RED }}>
                      {errors.legalStatus}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">Date Of Establishment</label>
                <div className="col-sm-5  col-md-5">
                  <Datepicker
                    name="dateOfEstablishment"
                    id="dateOfEstablishment"
                    value={values.dateOfEstablishment}
                    dateFormat="yyyy-mm-dd"
                    onChange={(date) => setValues({ ...values, dateOfEstablishment: parseCustomDate(date.target.value) })}
                  />
                  {errors.dateOfEstablishment && touched.dateOfEstablishment && (
                    <div className="error" style={{ color: ThemeColor.RED }}>
                      {errors.dateOfEstablishment}
                    </div>
                  )}
                </div>
              </div>
              {/* <div className="form-group row">
                <label className="col-sm-4 col-md-3">Date Of Establishment</label>
                <div className="col-sm-5  col-md-5">
                  <Datepicker
                    name="establishmentDate"
                    id="establishmentDate"
                    value={values.establishmentDate}
                    dateFormat="yyyy-mm-dd"
                    onChange={(date) => setValues({ ...values, establishmentDate: parseCustomDate(date.target.value) })}
                  />
                  {errors.establishmentDate && touched.establishmentDate && (
                    <div className="error" style={{ color: ThemeColor.RED }}>
                      {errors.establishmentDate}
                    </div>
                  )}
                </div>
              </div> */}
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">Current Status</label>
                <div className="col-sm-5  col-md-5">
                  <select
                    className="form-control"
                    name="currentStatus"
                    id="currentStatus"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option>Select</option>
                    {currentStatus.map((data, index) => {
                      return (
                        <option
                          key={index}
                          selected={data.slug === values.currentStatus}
                          value={data.slug}
                        >
                          {data.name}
                        </option>
                      );
                    })}
                  </select>
                  {errors.currentStatus && touched.currentStatus && (
                    <div className="error" style={{ color: ThemeColor.RED }}>
                      {errors.currentStatus}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">Rating</label>
                <div className="col-sm-5  col-md-5">
                  <select
                    className="form-control"
                    name="rating"
                    id="rating"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option>Select</option>
                    {clientRating.map((data, index) => {
                      return (
                        <option
                          key={index}
                          selected={data.slug === values.rating}
                          value={data.slug}
                        >
                          {data.name}
                        </option>
                      );
                    })}
                  </select>
                  {errors.rating && touched.rating && (
                    <div className="error" style={{ color: ThemeColor.RED }}>
                      {errors.rating}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">Marketing Status</label>
                <div className="col-sm-5  col-md-5">
                  <select
                    className="form-control"
                    name="marketingStatus"
                    id="marketingStatus"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option>Select</option>
                    {marketingStatus.map((data, index) => {
                      return (
                        <option
                          key={index}
                          selected={data.slug === values.marketingStatus}
                          value={data.slug}
                        >
                          {data.name}
                        </option>
                      );
                    })}
                  </select>
                  {errors.marketingStatus && touched.marketingStatus && (
                    <div className="error" style={{ color: ThemeColor.RED }}>
                      {errors.marketingStatus}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">Remark</label>
                <div className="col-sm-5  col-md-5">
                  <textarea
                    id="remark"
                    name="remark"
                    cols={2}
                    rows={2}
                    className="form-control"
                    value={values.remark}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  ></textarea>
                  {errors.remark && touched.remark && (
                    <div className="error" style={{ color: ThemeColor.RED }}>
                      {errors.remark}
                    </div>
                  )}
                </div>
              </div>
              {[1, 2, 3, 4].map((cardNumber) => (
                <div className="form-group row" key={`card${cardNumber}`}>
                  <label className="col-sm-4 col-md-3">{`Card${cardNumber}`}</label>
                  <div className="col-sm-5 col-md-5">
                    <img
                      src={
                        selectedCardImages[`card-${cardNumber}`]
                          ? selectedCardImages[`card-${cardNumber}`].base64
                          : values.cards && values.cards[`card${cardNumber}`]
                      }
                      alt={`Card ${cardNumber}`}
                      style={{
                        width: "125px",
                        height: "125px",
                        marginLeft: "10px",
                        marginRight: "10px",
                      }}
                    />
                    <input
                      type="file"
                      name={`card-${cardNumber}`}
                      id={`card-${cardNumber}`}
                      className="mx-2"
                      onChange={(event) =>
                        handleCardChange(event, `card-${cardNumber}`)
                      }
                    />
                    <div className="error" id={`err_card-${cardNumber}`} />
                  </div>
                </div>
              ))}
            </div>
            <div
                className="d-flex align-items-center justify-content-center"
                style={{ gap: "10px", marginTop: "30px" }}
              >
                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{
                    padding: "8px 20px",
                    width: "120px",
                  }}
                  onClick={(e) => loginClickHandle(e)}
                >
                  <i
                    className="fa fa-floppy-o"
                    style={{ marginRight: "8px" }}
                  />
                  Update
                </button>
                <button
                  type="button"
                  className="btn btn-default"
                  style={{
                    padding: "8px 20px",
                    width: "120px",
                  }}
                  onClick={()=>{navigate("/project-costing/sub-contract")}}
                >
                  Cancel
                </button>
              </div>
          </form>
        </div>
      </div>
    </MainLayout>
  );
};

export default EditSubContractor;
