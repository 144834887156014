import React, { useEffect, useState } from "react";
import Datatable from "../../components/datatable";
import { useDispatch } from "../../store";
import { ThemeColor } from "../../constants/themeColorConstant";
import { useLocation, useNavigate } from "react-router-dom";
import MainLayout from "../layout/MainLayout";
import { payrollThunks } from "../../store/reducers/payroll/payroll.thunk";
import { projectCostingThunk } from "../../store/reducers/projectCosting/projectCosting.thunk";

const SubContractor = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [subContractorData, setSubContractorData] = useState([]);
  const [option, setOption] = useState({});

  const location = useLocation();
  const pathnameSegments = location.pathname.split("/");
  const moduleName = pathnameSegments[1];

  const allowedModules = JSON.parse(localStorage.getItem("allowedModules"));
  const selectedModule = allowedModules.find(
    (module) => module.slug === moduleName
  );

  const moduleId = selectedModule.uid;


  const renderActions = (rowData) => {
    const subContractorId = rowData.uid;
    const editIcon = (
      <i
        className="fa fa-edit"
        style={{ cursor: "pointer" }}
        onClick={() => navigate(`/project-costing/sub-contract/edit/${subContractorId}`, { state: { moduleId } })}
      />
    );
    return (
      <div>
        {editIcon}
      </div>
    );
  };

  const handleAmountClick = (rowIndex) => {

    const rowData = subContractorData[rowIndex];
    if (rowData) {
      const subContractorId = rowData.uid;
      navigate(`/project-costing/sub-contract/projectDetails/${subContractorId}`, { state: { moduleId } });
    }
  };

  const columns = [
    {
      name: "action",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const rowData = subContractorData[dataIndex];
          return renderActions(rowData);
        },
      },
    },
    {
      name: "shortName",
      label: "Short",
    },
    {
      name: "name",
      label: "Sub-Contractor",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "totalAmount",
      label: "Total Amount",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta) => (
          
          <span
            style={{ cursor: "pointer", color: "blue" }}
            onClick={() => handleAmountClick(tableMeta.rowIndex)}
          >
            
            {value}
          </span>
        ),
      },
    },
    {
      name: "paidAmount",
      label: "Paid Amount",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta) => (
          <span
            style={{ cursor: "pointer", color: "blue" }}
            onClick={() => handleAmountClick(tableMeta.rowIndex)}
          >
            {value}
          </span>
        ),
      },
    },
    {
      name: "businessType",
      label: "Business Type",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "discipline",
      label: "Discipline",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "poBoxNumber",
      label: "PO Box",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "telNumber1",
      label: "TEL-1",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "telNumber2",
      label: "TEL-2",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "faxNumber",
      label: "FAX",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "email",
      label: "EMAIL",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "address",
      label: "ADDRESS",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "currentStatus",
      label: "CURRENT STATUS",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "rating",
      label: "RATING",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "future",
      label: "FUTURE",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "marketingStatus",
      label: "MARKETING STATUS",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "remark",
      label: "REMARK",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "registrationNumber",
      label: "Registration Number",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "website",
      label: "WEBSITE",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "dateOfEstablishment",
      label: "EST YEAR",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "legalStatus",
      label: "LEGAL STATUS",
      options: {
        filter: false,
        sort: true,
      },
    },

  ];

  useEffect(() => {
    getSubcontractorListApi()
  }, [])

  const getSubcontractorListApi = async (page = 1, rowsPerPage = 10, searchText = '', name = '', order = '') => {
    try {
      const response = await dispatch(projectCostingThunk.subContractorList({ moduleId, page, rowsPerPage, searchText, name, order }));
      if (projectCostingThunk.subContractorList.fulfilled.match(response)) {
        if (response.payload) {
          const options = {
            count: response.payload.totalRecords,
            page: response.payload.currentPage - 1, // Zero-based index
            rowsPerPage: response.payload.recordPerPage,
          }
          setOption(options)
          setSubContractorData(response.payload.data);
          return response.payload
        }
      }
    } catch (error) {
      console.error("Error fetching subcontractor data:", error);
    }
  };

  const breadcrumb = {
    mainHeader: "Sub-Contractor Manage",
    subHeader: "Home",
    subHeaderLink: "/dashboard",
    subHeader1: "Sub-Contractor",
    subHeader1Link: "/project-costing/sub-contract",
    subHeader2: null,
    subHeader2Link: null,
  };

  return (
    <MainLayout breadcrumb={breadcrumb}>
      <div className="card">
        <div className="card-body">
          <div className="card-header ">
            <p
              className="card-title"
              style={{
                fontSize: "20px",
                fontWeight: "500",
                color: ThemeColor.EASTERNBLUE,
              }}
            >
              Sub-Contractor Manage
            </p>
            <div className="d-flex justify-content-end">
              <button
                onClick={() =>
                  navigate("/project-costing/sub-contract/add", { state: { moduleId } })
                }
                className="btn btn-primary"
              >
                Add
              </button>

            </div>
          </div>
          <Datatable
            columns={columns}
            data={subContractorData}
            options={option}
            customBodyRender={renderActions}
            api={getSubcontractorListApi}
          />
        </div>
      </div>
    </MainLayout>
  );
};

export default SubContractor;
