import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiCall } from "../../../utils/api";
import { HTTP_METHODS } from "../../../constants/methodsConstant";
import { ROUTES } from "../../../constants/routesConstant";
import { ToastType, setToastContent } from "../toast/toast.slice";

export const attendanceViewData = createAsyncThunk(
  "attendanceView/list",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.GET,
        `${ROUTES.GET_ATTENDANCEVIEW}?page=${inputData.page}&limit=${inputData.rowsPerPage}&search=${inputData.searchText}&sort=${inputData.name}&order=${inputData.order}`,
        {},
        inputData.moduleId
      );
      if (response?.isSuccess) {
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);

export const attendanceDetails = createAsyncThunk(
  "attendance/list",
  async (inputData, { dispatch }) => {
    try {
      let url = `${ROUTES.GET_ATTENDANCE}/${inputData.employeeId}?month=${inputData.selectedMonthYear}`;
      if (inputData.projectId) {
        url = `${url}&projectId=${inputData.projectId}`;
      }
      const response = await ApiCall(
        HTTP_METHODS.GET,
        url,
        {},
        inputData.moduleId
      );
      if (response?.isSuccess) {
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);

export const updateattendanceDetails = createAsyncThunk(
  "attendance/updateAttendance",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.PATCH,
        `${ROUTES.GET_ATTENDANCE}/${inputData.id}`,
        {},
        inputData.moduleId
      );
      if (response?.isSuccess) {
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);

export const callUpdateAttendanceDataApi = createAsyncThunk(
  "employee/updateAttendanceData",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.PATCH,
        `${ROUTES.GET_ATTENDANCE}/${inputData.employeeId}`,
        inputData.data,
        inputData.moduleId
      );
      if (response?.isSuccess) {
        dispatch(
          setToastContent({
            type: ToastType.success,
            message: response.message,
          })
        );
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);

export const remarkData = createAsyncThunk(
  "attendance/list",
  async (inputData, { dispatch }) => {
    try {
   
      const response = await ApiCall(
        HTTP_METHODS.GET,
        `${ROUTES.GET_ATTENDANCE}/meta/${inputData.employeeId}?month=${inputData.formattedMonthYear}`,
        {},
        inputData.moduleId
      );
      if (response?.isSuccess) {
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);

export const remarkDataUpdate = createAsyncThunk(
  "attendance/list",
  async (inputData, { dispatch }) => {
    try {
   
      const response = await ApiCall(
        HTTP_METHODS.POST,
        `${ROUTES.GET_ATTENDANCE}/meta`,
        inputData.data,
        inputData.moduleId
      );
      if (response?.isSuccess) {
        dispatch(
          setToastContent({
            type: ToastType.success,
            message: response.message,
          })
        );
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);

export const employeeList = createAsyncThunk(
  "attedance/employeeList",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.GET,
        ROUTES.GET_EMPLOYEELIST,
        {},
        inputData.moduleId
      );
      if (response?.isSuccess) {
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);


export const attendanceThunks = {
  attendanceViewData,
  attendanceDetails,
  updateattendanceDetails,
  callUpdateAttendanceDataApi,
  remarkData,
  remarkDataUpdate
};
