import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import { useDispatch } from '../../store';
import { employeeThunks } from '../../store/reducers/employee/employee.thunk';


const Others = ({moduleId,activeTab,employeeData}) => {

  const { id } = useParams();
  const [bloodGroups,setBloodGroups] = useState([])
  const dispatch = useDispatch()
  
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    enableReinitialize: true,
    onSubmit: async(values) => {
      await updateOthersDataApi(values)
    },
    initialValues: {
      hobbies: employeeData ? employeeData.hobbies : null,
      bloodGroup: employeeData ? employeeData.bloodGroup :null,
      extraCurriclularActivity: employeeData ? employeeData.extraCurriclularActivity :null,
    },
  });

  const updateOthersDataApi = async (data) => {
    try {
      const response = await dispatch(employeeThunks.updateEmployee({id,data,moduleId,activeTab}));
      if (employeeThunks.updateEmployee.fulfilled.match(response)) {
            return response.payload
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  useEffect(()=>{
    getBloodGroupsApi()
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const getBloodGroupsApi = async () => {
    try {
      const response = await dispatch(
        employeeThunks.getBloodGroups(moduleId)
      );
      if (employeeThunks.getBloodGroups.fulfilled.match(response)) {
        if (response.payload) {
          setBloodGroups(response.payload);
        }
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  return (
    <form className="form-horizontal" id="others_info" onSubmit={handleSubmit}>
    <div className="card-body">
      <div className="form-group row">
        <label className="col-sm-4 col-md-3">
          Hobbies 
        </label>
        <div className="col-sm-5  col-md-5">
          <input
            type="text"
            className="form-control"
            name="hobbies"
            id="hobbies"
            placeholder="Hobbies"
            value={values.hobbies}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-sm-4 col-md-3">Blood Group</label>
        <div className="col-sm-5  col-md-5">
          <select
            className="form-control"
            name="bloodGroup"
            id="bloodGroup"
            onChange={handleChange}
            onBlur={handleBlur}
          >
            <option>Select Blood Group</option>
            {bloodGroups.map((data)=>{
              return(
                <option selected={data.name === values.bloodGroup} value={data.uid}>{data.name}</option>
              )
            })}
            
          </select>
        </div>
      </div>
      <div className="form-group row">
        <label class="col-sm-4 col-md-3">
          Extra Curricular Activity
        </label>
        <div className="col-sm-5  col-md-5">
          <input
            type="text"
            className="form-control"
            name="extraCurriclularActivity"
            id="extraCurriclularActivity"
            placeholder="Extra Curricular Activity"
            value={values.extraCurriclularActivity}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      </div>
    </div>
    <div className="d-flex align-items-center justify-content-center">
      <button
        type="submit"
        className="btn btn-primary"
        style={{ padding: "8px 20px" }}
      >
        <i className="fa fa-floppy-o" style={{ marginRight: "8px" }} />
        Update
      </button>
    </div>
  </form>
  )
}

export default Others