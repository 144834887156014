import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik,resetForm } from "formik";
import { useDispatch } from "../../store";
import { ThemeColor } from "../../constants/themeColorConstant";
import MainLayout from "../layout/MainLayout";
import { useLocation, useNavigate } from "react-router-dom";
import { employeeThunks } from "../../store/reducers/employee/employee.thunk";
import {  expenseDeductionThunks } from "../../store/reducers/expenseDeduction/expenseDeduction.thunk";
import Select from "react-select";

const validationSchema = Yup.object().shape({
  employeeId: Yup.string().required("Please select employee"),
  type: Yup.string().required("Please select type"),
  amount : Yup.string().required("Please enter amount"),
  date : Yup.string().required("Please select date")
});

const AddExpenseDeduction = () => {

  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState("addNew");
  const [employeeList,setEmployeeList] = useState([])
  const [expenseTypeList,setExpenseTypeList] = useState([])

  const location = useLocation();
  const { moduleId } = location.state || {};
  const intialValues = {
    employeeId :"",
    type:"",
    amount:"",
    date:""
  }
  const [formValues, setformValues] = useState(intialValues);
  const dispatch = useDispatch();

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values,{resetForm}) => {
      await addExpenseDeductionApi(values,resetForm);

    },
   

    initialValues: intialValues,
  });

  useEffect(() => {
    employeeListApi()
    expenseTypeListApi()
   // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const loginClickHandle = (e) => {
    e.preventDefault();
    e.persist();
    handleSubmit(e);
  };

    const addExpenseDeductionApi = async (data,resetForm) => {
      try {
        const response = await dispatch(
          expenseDeductionThunks.addExpense({ data, moduleId })
        );
        if (expenseDeductionThunks.addExpense.fulfilled.match(response)) {
          if (response.payload) {
            resetForm({data:{}})
            setformValues(values);
            console.log(formValues)
            // navigate("/expense-deduction")
            return response.payload;
          }
        }
      } catch (error) {
        console.error("Error fetching employee data:", error);
      }
    };

    const employeeListApi = async () => {
        try {
          const response = await dispatch(
            employeeThunks.employeeList({moduleId })
          );
          if (employeeThunks.employeeList.fulfilled.match(response)) {
            if (response.payload) {
              setEmployeeList(response.payload);
              return response.payload;
            }
          }
        } catch (error) {
          console.error("Error fetching company data:", error);
        }
      };

      const expenseTypeListApi = async () => {
        try {
          const response = await dispatch(
            expenseDeductionThunks.expenseTypeList({moduleId })
          );
          if (expenseDeductionThunks.expenseTypeList.fulfilled.match(response)) {
            if (response.payload) {
              setExpenseTypeList(response.payload);
              return response.payload;
            }
          }
        } catch (error) {
          console.error("Error fetching company data:", error);
        }
      };

  const breadcrumb = {
    mainHeader: "Expense Add",
    subHeader: "Home",
    subHeaderLink: "/dashboard",
    subHeader1: "Expense Add",
    subHeader1Link: "/expense-deduction",
    subHeader2: "add",
    subHeader2Link: null,
  };

  return (
    <>
      <MainLayout breadcrumb={breadcrumb}>
        <div className="card card-info">
          <div className="card-body">
            <ul
              className="nav nav-tabs"
              style={{
                borderBottom: `2px solid ${ThemeColor.GREY}`,
                backgroundColor: "transparent",
              }}
            >
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    activeTab === "addNew" ? "active" : ""
                  }`}
                  onClick={() => handleTabChange("addNew")}
                  style={{
                    borderBottom: `3px solid ${
                      activeTab === "addNew" ? ThemeColor.BLUE : "transparent"
                    }`,
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    backgroundColor: "transparent",
                  }}
                >
                  Add New
                </button>
              </li>
            </ul>
            <form
              className="form-horizontal"
              id="personal_info"
              onSubmit={handleSubmit}
            >
              <div className="card-body">
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">
                    Employee <span style={{ color: ThemeColor.RED }}>*</span>
                  </label>
                  <div className="col-sm-5  col-md-5">
                     <Select
                      placeholder="Select Employee"
                      isSearchable
                      id="employeeId"
                      name="employeeId"
                      onChange={(selectedOption) => {
                        setFieldValue(
                          "employeeId",
                          selectedOption ? selectedOption.value : ""
                        );
                      }}
                      options={employeeList.map((data) => ({
                        value: data.uid,
                        label:  data.employee_code + " - " + data.name,
                      }))}
                    />
                    {errors.employeeId && touched.employeeId && (
                      <div className="error" style={{ color: ThemeColor.RED }}>
                        {errors.employeeId}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">
                    Type <span style={{ color: ThemeColor.RED }}>*</span>
                  </label>
                  <div className="col-sm-5  col-md-5">
                     <Select
                      placeholder="Select Type"
                      isSearchable
                      id="type"
                      name="type"
                      onChange={(selectedOption) => {
                        setFieldValue(
                          "type",
                          selectedOption ? selectedOption.value : ""
                        );
                      }}
                      options={expenseTypeList.map((data) => ({
                        value: data.id,
                        label: data.name,
                      }))}
                    />
                    {errors.type && touched.type && (
                      <div className="error" style={{ color: ThemeColor.RED }}>
                        {errors.type}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Amount  <span style={{ color: ThemeColor.RED }}>*</span></label>
                  <div className="col-sm-5  col-md-5">
                    <input
                      type="text"
                      className="form-control"
                      name="amount"
                      id="amount"
                      placeholder="Amount"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                     {errors.amount && touched.amount && (
                      <div className="error" style={{ color: ThemeColor.RED }}>
                        {errors.amount}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Date <span style={{ color: ThemeColor.RED }}>*</span></label>
                  <div className="col-sm-5  col-md-5">
                    <input type="date"
                      id="date"
                      name="date"
                      className="form-control"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                     {errors.date && touched.date && (
                      <div className="error" style={{ color: ThemeColor.RED }}>
                        {errors.date}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Remark</label>
                  <div className="col-sm-5  col-md-5">
                    <textarea
                      id="remark"
                      name="remark"
                      cols={2}
                      rows={2}
                      value={values.remark}
                      className="form-control"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    ></textarea>
                  </div>
                </div>
              </div>

              <div
                className="d-flex align-items-center justify-content-center"
                style={{ gap: "10px", marginTop: "30px" }}
              >
                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{
                    padding: "8px 20px",
                    width: "120px",
                  }}
                  onClick={(e) => loginClickHandle(e)}
                >
                  <i
                    className="fa fa-floppy-o"
                    style={{ marginRight: "8px" }}
                  />
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-default"
                  style={{
                    padding: "8px 20px",
                    width: "120px",
                  }}
                  onClick={()=>{navigate("/expense-deduction")}}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default AddExpenseDeduction;
