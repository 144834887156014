import "./assets/css/App.css";
import { Provider } from "react-redux";
import { store } from "./store";
import MainRoutes from "./routes/routes";

function App() {
  return (
    <div className="wrapper">
      <Provider store={store}>
        <MainRoutes />
      </Provider>
    </div>
  );
}

export default App;
