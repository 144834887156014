import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "../../store";
import { ThemeColor } from "../../constants/themeColorConstant";
import MainLayout from "../layout/MainLayout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { clientThunks } from "../../store/reducers/client/client.thunk";

const validationSchema = Yup.object().shape({
    contactName: Yup.string().required("Please enter contact name"),
    mobile :  Yup.string().required("Please enter mobile number"),
    clientId : Yup.string().required("Please select client"),
});

const AddContact = () => {
  const [activeTab, setActiveTab] = useState("addForm");
  const [clientDesignation,setClientDesignation] = useState([])
  const [clientList,setClientList] = useState([])
  const navigate = useNavigate()

  const location = useLocation();
  const { moduleId } = location.state || {};
  const { id } = useParams();

  const dispatch = useDispatch();

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    resetForm,
  } = useFormik({
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {


      await addClientContactApi(values);
      resetForm();
    },
    initialValues: {
      contactName:"",
      clientId :"",
      mobile : ""
    },
  });


  useEffect(() => {
    designationListApi()
    clientListApi()
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loginClickHandle = (e) => {
    e.preventDefault();
    e.persist();
    handleSubmit(e);
  };

  const designationListApi = async () => {
    try {
      const response = await dispatch(clientThunks.clientDesignationList(moduleId));
      if (clientThunks.clientDesignationList.fulfilled.match(response)) {
        if (response.payload) {
          setClientDesignation(response.payload);
        }
      }
    } catch (error) {
      console.error("Error fetching project list:", error);
    }
  };

  const clientListApi = async () => {
    try {
      const response = await dispatch(clientThunks.clientList(moduleId));
      if (clientThunks.clientList.fulfilled.match(response)) {
        if (response.payload) {
            setClientList(response.payload);
        }
      }
    } catch (error) {
      console.error("Error fetching project list:", error);
    }
  };



    const addClientContactApi = async (data) => {
      try {
        const response = await dispatch(
          clientThunks.addClientContact({id, data, moduleId })
        );
        if (clientThunks.addClientContact.fulfilled.match(response)) {
            navigate(`/clients/contact-manage/${id}`)
        }
      } catch (error) {
        console.error("Error fetching client  contact data:", error);
      }
    };

  const breadcrumb = {
    mainHeader: "Contact Add" ,
    subHeader: "Home",
    subHeaderLink: "/dashboard",
    subHeader1: "Clients",
    subHeader1Link: "/clients",
    subHeader2: "add",
    subHeader2Link: null,
  };

  return (
    <>
      <MainLayout breadcrumb={breadcrumb}>
        <div className="card card-info">
          <div className="card-body">
            <ul
              className="nav nav-tabs"
              style={{
                borderBottom: `2px solid ${ThemeColor.GREY}`,
                backgroundColor: "transparent",
              }}
            >
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    activeTab === "addForm" ? "active" : ""
                  }`}
                  onClick={() => handleTabChange("addForm")}
                  style={{
                    borderBottom: `3px solid ${
                      activeTab === "addForm" ? ThemeColor.BLUE : "transparent"
                    }`,
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    backgroundColor: "transparent",
                  }}
                >
                  Contact Add
                </button>
              </li>
            </ul>
            <form
              className="form-horizontal"
              id="personal_info"
              onSubmit={handleSubmit}
            >
              <div className="card-body">
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">
                    Contact Name <span style={{ color: ThemeColor.RED }}>*</span>
                  </label>
                  <div className="col-sm-5  col-md-5">
                    <input
                      type="text"
                      className="form-control"
                      name="contactName"
                      id="contactName"
                      placeholder="Contact Name"
                      value={values.contactName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.contactName && touched.contactName && (
                      <div className="error" style={{ color: ThemeColor.RED }}>
                        {errors.contactName}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Mobile <span style={{ color: ThemeColor.RED }}>*</span>
                  </label>
                  <div className="col-sm-5  col-md-5">
                    <input
                      type="text"
                      className="form-control"
                      name="mobile"
                      id="mobile"
                      placeholder="Mobile"
                      value={values.mobile}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                     {errors.mobile && touched.mobile && (
                      <div className="error" style={{ color: ThemeColor.RED }}>
                        {errors.mobile}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Tel Number</label>
                  <div className="col-sm-5  col-md-5">
                    <input
                      type="text"
                      className="form-control"
                      name="telNumber"
                      id="telNumber"
                      placeholder="Tel Number"
                      value={values.telNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Email</label>
                  <div className="col-sm-5  col-md-5">
                    <input
                      type="text"
                      className="form-control"
                      name="email"
                      id="email"
                      placeholder="Email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Designation</label>
                  <div className="col-sm-5  col-md-5">
                    <select
                      className="form-control"
                      name="designationId"
                      id="designationId"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option>Select Department</option>
                      {clientDesignation.map((data, index) => {
                        return (
                          <option key={index} value={data.slug} selected={data.slug === values.designation}>
                            {data.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Client <span style={{ color: ThemeColor.RED }}>*</span></label>
                  <div className="col-sm-5  col-md-5">
                    <select
                      className="form-control"
                      name="clientId"
                      id="clientId"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option>Select Client</option>
                      {clientList.map((data, index) => {
                        return (
                          <option key={index} value={data.uid} selected={data.uid === values.clientId}>
                            {data.name}
                          </option>
                        );
                      })}
                    </select>
                    {errors.clientId && touched.clientId && (
                      <div className="error" style={{ color: ThemeColor.RED }}>
                        {errors.clientId}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-center">
                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{ padding: "8px 20px" }}
                  onClick={(e) => loginClickHandle(e)}
                >
                  <i
                    className="fa fa-floppy-o"
                    style={{ marginRight: "8px" }}
                  />
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-default"
                  style={{
                    padding: "8px 20px",
                    width: "120px",
                    marginLeft:"10px"
                  }}
                  onClick={()=>{navigate("/clients")}}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default AddContact;
