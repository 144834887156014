import React from "react";
import {
  Tooltip,
  Cell,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
} from "recharts";

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        {payload[0].payload.label +
          ": " +
          payload[0].name +
          ": " +
          payload[0].value}
      </div>
    );
  }

  return null;
};

function CustomLegend() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginRight: "2em",
        }}
      >
        <div
          style={{
            width: "40px",
            height: "15px",
            backgroundColor: "#8884d8",
            marginRight: "1em",
          }}
        ></div>
        <p style={{ margin: 0 }}>Expenses</p>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: "40px",
            height: "15px",
            backgroundColor: "#82ca9d",
            marginRight: "1em",
          }}
        ></div>
        <p style={{ margin: 0 }}>Deduction</p>
      </div>
    </div>
  );
}

export default function ExpenseChart({ data, selectedYear }) {
  const processedData = [];
  if (data) {
    Object.keys(data).forEach((v) => {
      processedData.push({
        name: selectedYear.toString(),
        label: v,
        value: data[v],
      });
    });
  }
  const COLORS = ["#8884d8", "#82ca9d", "#FFBB28", "#FF8042"];

  return (
    <ResponsiveContainer>
      <PieChart width={400} height={400}>
        <Pie
          dataKey="value"
          data={processedData || []}
          cx="50%"
          cy="50%"
          innerRadius={100}
          outerRadius={130}
          fill="#82ca9d"
        >
          {processedData ? (
            processedData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))
          ) : (
            <></>
          )}
        </Pie>
        <Tooltip content={<CustomTooltip />} />
        <Legend content={<CustomLegend />} />
      </PieChart>
    </ResponsiveContainer>
  );
}
