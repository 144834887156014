import React, { useState } from "react";
import Datatable from "../../components/datatable";
import { useDispatch } from "../../store";
import { useLocation, useNavigate } from "react-router-dom";
import MainLayout from "../layout/MainLayout";
import { reportThunk } from "../../store/reducers/report/report.thunk";


const LeaveReport = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [reportData, setReportData] = useState([]);
  const [startDate, setStartDate] = useState(""); 
  const [endDate, setEndDate] = useState(""); 
  const [option, setOption] = useState({});


  const location = useLocation();
  const pathnameSegments = location.pathname.split("/");
  const moduleName = pathnameSegments[1];

  const allowedModules = JSON.parse(localStorage.getItem("allowedModules"));
  const selectedModule = allowedModules.find(
    (module) => module.slug === moduleName
  );

  const moduleId = selectedModule.uid;

 
  const columns = [
    {
      name: "empCode",
      label: "Emp Code",
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
        sort: true,
      },
    },
      {
        name: "sickLeaves",
        label: "sick leave",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "annualLeaves",
        label: "annual leave",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "lossOfPay",
        label: "loss of pay",
        options: {
          filter: false,
          sort: true,
        },
      },
  ];


    const getLeaveReport = async (page=1,rowsPerPage=10,searchText='',name='',order='') => {
      try {
        
          const response = await dispatch(reportThunk.leaveReportList({startDate,endDate,moduleId, page, rowsPerPage, searchText, name, order }));
          if (reportThunk.leaveReportList.fulfilled.match(response)) {
            if (response.payload) {
              const options = {
                count: response.payload.totalRecords,
                page: response.payload.currentPage - 1, // Zero-based index
                rowsPerPage: response.payload.recordPerPage,
              }
              setOption(options)
              setReportData(response.payload.data);
              return response.payload;
            }
          }
      } catch (error) {
        console.error("Error fetching client data:", error);
      }
    };

  

  const breadcrumb = {
    mainHeader: "Leave Report",
    subHeader: "Home",
    subHeaderLink: "/dashboard",
    subHeader1: "Report",
    subHeader1Link: null,
    subHeader2: null,
    subHeader2Link: null,
  };


  return (
    <MainLayout breadcrumb={breadcrumb}>
      <div className="card">
        <div className="card-body">
        <div className="form-group row d-flex justify-content-center mt-5">
          <div className="col-md-2">
              <input 
              type="date" 
              id="startDate"
              name="startDate"
              placeholder="Select From Date"
              className="form-control"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}/>
          </div>
            <div className="col-md-2">
                <input 
                type="date"
                id="endDate"
                name="endDate"
                placeholder="Select End Date"
                className="form-control"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)} />
            </div>
            <div className="col-md-2">
                <button className="btn btn-outline-primary" onClick={()=>{getLeaveReport()}}>Show Report</button>
            </div>
        </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">       
          <Datatable
            columns={columns}
            data={reportData}
            options={option}
            api={getLeaveReport}
          />
        </div>
      </div>
    </MainLayout>
  );
};

export default LeaveReport;
