import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiCall } from "../../../utils/api";
import { HTTP_METHODS } from "../../../constants/methodsConstant";
import { ROUTES } from "../../../constants/routesConstant";
import { ToastType, setToastContent } from "../toast/toast.slice";

export const packageList = createAsyncThunk(
    "package/list",
    async (inputData, { dispatch }) => {
      try {
        const response = await ApiCall(
          HTTP_METHODS.GET,
          `${ROUTES.GET_PACKAGE}?page=${inputData.page}&limit=${inputData.rowsPerPage}&search=${inputData.searchText}&sort=${inputData.name}&order=${inputData.order}`,
          {},
          inputData.moduleId
        );
        if (response?.isSuccess) {
          return response.payload;
        } else {
          dispatch(
            setToastContent({
              type: ToastType.error,
              message: response.message,
            })
          );
        }
      } catch (error) {
        throw new Error("An error occurred");
      }
    }
  );

  export const packageDetails = createAsyncThunk(
    "package/details",
    async (inputData, { dispatch }) => { 
      try {
        const response = await ApiCall(
          HTTP_METHODS.GET,
          `${ROUTES.GET_PACKAGE}/${inputData.id}/${inputData.activeTab}?page=${inputData.page}&limit=${inputData.rowsPerPage}&search=${inputData.searchText}&sort=${inputData.name}&order=${inputData.order}`,
          {},
          inputData.moduleId
        );
        if (response?.isSuccess) {
          return response.payload;
        } else {
          dispatch(
            setToastContent({
              type: ToastType.error,
              message: response.message,
            })
          );
        }
      } catch (error) {
        throw new Error("An error occurred");
      }
    }
  );

  export const updatePackageDetails = createAsyncThunk(
    "employee/updatePackageDetails",
    async (inputData, { dispatch }) => {
      try {
        const response = await ApiCall(
          HTTP_METHODS.POST,
          ROUTES.GET_PACKAGE+`/${inputData.id}`,
          inputData.data,
          inputData.moduleId
        );
        if (response?.isSuccess) {
          dispatch(
            setToastContent({
              type: ToastType.success,
              message: response.message,
            })
          );
          return response.payload;
        } else {
          dispatch(
            setToastContent({
              type: ToastType.error,
              message: response.message,
            })
          );
        }
      } catch (error) {
        throw new Error("An error occurred");
      }
    }
  );

  export const updatePackageHistoryDetails = createAsyncThunk(
    "employee/updatePackageHistoryDetails",
    async (inputData, { dispatch }) => {
      try {
        const response = await ApiCall(
          HTTP_METHODS.POST,
          ROUTES.GET_PACKAGEHISTORY+`/${inputData.id}`,
          inputData.data,
          inputData.moduleId
        );
        if (response?.isSuccess) {
          dispatch(
            setToastContent({
              type: ToastType.success,
              message: response.message,
            })
          );
          return response.payload;
        } else {
          dispatch(
            setToastContent({
              type: ToastType.error,
              message: response.message,
            })
          );
        }
      } catch (error) {
        throw new Error("An error occurred");
      }
    }
  );

  export const modeOfTransferList = createAsyncThunk(
    "package/modeOfTransferList",
    async (inputData, { dispatch }) => {  
      try {
        const response = await ApiCall(
          HTTP_METHODS.GET,
          ROUTES.MODE_OF_TRANSFER,
          {},
          inputData.moduleId
        );
        if (response?.isSuccess) {
          return response.payload;
        } else {
          dispatch(
            setToastContent({
              type: ToastType.error,
              message: response.message,
            })
          );
        }
      } catch (error) {
        throw new Error("An error occurred");
      }
    }
  );


  export const packageThunks = {
    packageList,
    packageDetails,
    updatePackageDetails,
    updatePackageHistoryDetails,
    modeOfTransferList
  };  