
export const ThemeColor = {
    BLUE :"#59c1fd",
    DARKBLUE : "#2e2eb8",
    BLACK : '#000000',
    WHITE : '#FFFFFF',
    EASTERNBLUE : "#17A2B8",
    RED : "#D50000",
    GREY : "#d6d7f5",
    SECONDARYGREY : "#e6ecf2",
    bgF : "bg-f",
    lightEASTERNBLUE : "#d1e5e8"
}
