import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeColor } from "../../constants/themeColorConstant";
import { handleLogout } from "../../constants/methodsConstant";
import { images } from "../../assets/asset";

const Header = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [userData, setUserData] = useState(false);
  const navigate = useNavigate()

  useEffect(() => {
    let userData = JSON.parse(localStorage.getItem("userData"));
    setUserData(userData);
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    if (typeof window !== "undefined") {
      window.addEventListener("resize", handleResize);
    }
    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("resize", handleResize);
      }
    };
    
  }, []);
 
  return (
    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
      <ul className="navbar-nav align-items-center d-flex">
        <li className="nav-item">
          <a className="nav-link" data-widget="pushmenu" href="#" role="button">
            <i className="fa fa-bars" />
          </a>
        </li>
        <li className="nav-item ">
          <p
            style={{
              fontSize: isMobile ? "16px" : "20px",
              fontWeight: "500",
              color: ThemeColor.EASTERNBLUE,
              margin: "0",
            }}
          >
            {isMobile ? "HR & PAYROLL" : "HR & PAYROLL MANAGEMENT SYSTEM"}
          </p>
        </li>
      </ul>

      <ul className="navbar-nav ml-auto">
        <li
          className="nav-item"
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
                 src={ userData && userData.profile!=null ? userData.profile : images.profileImage}
              className="img-circle "
              alt="User"
              style={{ width: "28px", height: "28px", marginRight: "5px" }}
            />
            <h6 style={{ margin: "0" }} onClick={()=>{navigate("/profile")}}>{userData.name}</h6>
          </div>
        </li>
        <li
          className="nav-item"
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            marginLeft: "15px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <i className="fa fa-sign-out" style={{ marginRight: "5px" }} />
            <h6 style={{ margin: "0" }} onClick={()=>handleLogout()}>Logout</h6>
          </div>
        </li>
      </ul>
    </nav>
  );
};

export default Header;
