import React, { useEffect, useState } from 'react';
import { ThemeColor } from '../../../constants/themeColorConstant';
import Datatable from '../../../components/datatable';
import { projectCostingThunk } from '../../../store/reducers/projectCosting/projectCosting.thunk';
import { useDispatch } from '../../../store';
import { useNavigate, useParams } from 'react-router-dom';
import ConfirmationBox from '../../../components/ConfirmationBox';

const ActualExpense = ({moduleId}) => {

    const navigate = useNavigate()
    const [actualExpenseData,setActualExpenseData] = useState()
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [expenseIdToDelete, setExpenseIdToDelete] = useState(null);

    const [options,setOption] = useState()
    const dispatch = useDispatch()
    const { id } = useParams();


    const renderActions = (rowData) => {
   
      if(rowData && rowData.projectExpenseId ){
      const expenseId = rowData.projectExpenseId;
      const projectId = id;

      const editIcon = (
        <i
          className="fa fa-edit"
          style={{ cursor: "pointer" }}
          onClick={() =>
            navigate(`/project-costing/project-expense/edit/${expenseId}`, { state: { moduleId,projectId } })
          }
        />
      );
      const deleteIcon = (
        <i
          style={{ marginLeft: "10px", cursor: "pointer" }}
          className="fa fa-trash-o"
          onClick={() => {
            setExpenseIdToDelete(expenseId);
            setShowConfirmation(true);
          }}
        />
      );
  
      return (
        <div>
          {editIcon}
          {deleteIcon}
        </div>
      );
    }
      else{
        return null
      }
    };


    const columns = [
      {
        name: "action",
        label: "Action",
        options: {
          customBodyRenderLite: (dataIndex) => {
            const rowData = actualExpenseData[dataIndex];
            return renderActions(rowData);
          },
        },
      },
          {
            name: "short",
            label: "short",
            options: {
              filter: false,
              sort: true,
            },
          },
          {
            name: "subContractor",
            label: "Sub Contractor",
          },
          {
            name: "invoiceNo",
            label: "invoice no",
            options: {
              filter: false,
              sort: true,
            },
          },
          {
              name: "supplierExpense",
              label: "Actual expense",
              options: {
                filter: false,
                sort: true,
              },
            },
          {
              name: "date",
              label: "date",
              options: {
                filter: false,
                sort: true,
              },
            },
            {
              name: "type",
              label: "type",
              options: {
                filter: false,
                sort: true,
              },
            },
            {
              name: "remark",
              label: "remark",
              options: {
                filter: false,
                sort: true,
              },
            },
      ];
    
      useEffect(()=>{
        projectActualExpenseListApi()
      },[])
    
      const projectActualExpenseListApi = async (page=1,rowsPerPage=10,searchText='',name='',order='') => {
        try {
          const response = await dispatch(projectCostingThunk.projectWiseActualExpenseList({projectId:id,moduleId,page,rowsPerPage,searchText,name,order}));
          if (projectCostingThunk.projectWiseActualExpenseList.fulfilled.match(response)) {
            if (response.payload) {
              const options = {
                count: response.payload.totalRecords,
                page: response.payload.currentPage-1, // Zero-based index
                rowsPerPage:response.payload.recordPerPage,
              }
              setOption(options)
              setActualExpenseData(response.payload.data);
              return response.payload
            }
          }
        } catch (error) {
          console.error("Error fetching client data:", error);
        }
      };

      const handleConfirmDelete = async () => {
        try {
          const response = await dispatch(
            projectCostingThunk.deleteProjectExpense({ id: expenseIdToDelete, moduleId })
          );
          if (projectCostingThunk.deleteProjectExpense.fulfilled.match(response)) {
            const updatedExpenseData = actualExpenseData.filter(
              (expense) => expense && expense.projectExpenseId !== expenseIdToDelete
            );
            setActualExpenseData(updatedExpenseData);
          }
        } catch (error) {
          console.error("Error deleting employee:", error);
        }
        setExpenseIdToDelete(null);
        setShowConfirmation(false);
      };
  

    const options2 = {
      print:true,
      filter:true,
      download:true,
      viewColumns : true
    }
    
  return (
    <div className="card-body">
          <div className="card-header ">
            <p
              className="card-title"
              style={{
                fontSize: "20px",
                fontWeight: "500",
                color: ThemeColor.EASTERNBLUE,
              }}
            >
            Actual Expense
            </p>
          </div>
          <Datatable
            columns={columns}
            data={actualExpenseData}
            options={options2}
            api={projectActualExpenseListApi}
            customBodyRender={renderActions}
          />
           <ConfirmationBox
        show={showConfirmation}
        onHide={() => setShowConfirmation(false)}
        onConfirm={handleConfirmDelete}
        message="Are you sure you want to delete expense?"
      />
        </div>
  );
}

export default ActualExpense;
