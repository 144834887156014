import React from 'react'

const Footer = () => {
  return (
   <div>
  <footer className="main-footer">
    <strong>Copyright © 2023 <a href="https://adminlte.io">STEADFAST</a>.</strong>
    All rights reserved.
    <div className="float-right d-none d-sm-inline-block">
      <b>Version</b> 3.2.0
    </div>
  </footer>
</div>

  )
}


export default Footer