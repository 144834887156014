import React, { useEffect, useState } from "react";
import Datatable from "../../components/datatable";

import { useDispatch } from "../../store";
import { ThemeColor } from "../../constants/themeColorConstant";
import { useLocation, useNavigate } from "react-router-dom";
import MainLayout from "../layout/MainLayout";
import { packageThunks } from "../../store/reducers/package/package.thunk";

const PackageDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [packageData, setPackageData] = useState([]);
  const [option, setOption] = useState({});

  const location = useLocation();
  const pathnameSegments = location.pathname.split("/");
  const moduleName = pathnameSegments[1];

  const allowedModules = JSON.parse(localStorage.getItem("allowedModules"));
  const selectedModule = allowedModules.find(
    (module) => module.slug === moduleName
  );

  const moduleId = selectedModule.uid;

  const renderActions = (rowData) => {
    const employeeId = rowData.uid;
    const editIcon = (
      <i
        className="fa fa-edit"
        style={{ cursor: "pointer" }}
         onClick={() => navigate(`/package/edit/${employeeId}`, { state: { moduleId } })}
      />
    );
    const deleteIcon = (
      <i
        style={{ marginLeft: "10px", cursor: "pointer" }}
        className="fa fa-trash-o"
        //onClick={() =>  deleteEmployeeApi(employeeId)}
      />
    );

    return (
      <div>
        {editIcon}
        {deleteIcon}
      </div>
    );
  };

  const columns = [
    {
      name: "action",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const rowData = packageData[dataIndex];
          return renderActions(rowData);
        },
      },
    },
    {
      name: "employeeCode",
      label: "Employee Code",
    },
    {
      name: "employeeName",
      label: "Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "designationName",
      label: "Designation",
    },
    {
      name: "status",
      label: "EMP Status",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "molValue",
      label: "MOL Value",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "totalPackages",
      label: "Total package",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "basic",
      label: "Basic",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "hra",
      label: "HRA",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "otherAllowance",
      label: "Other Allowance",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "transport",
      label: "Transport",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "telephone",
      label: "Telephone",
      options: {
        filter: false,
        sort: true,
      },
    },
    // {
    //   name: "furniture",
    //   label: "Furniture",
    //   options: {
    //     filter: false,
    //     sort: true,
    //   },
    // },
    {
      name: "vehicleAllowance",
      label: "Vehicle",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "petrol",
      label: "Petrol",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
        name: "incentives",
        label: "Incentives",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "airfare",
        label: "Air Fare",
        options: {
          filter: false,
          sort: true,
        },
      },
  ];

 
  useEffect(() => {
     getPackageDetailsApi()
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

    const getPackageDetailsApi = async (page=1,rowsPerPage=10,searchText='',name='',order='') => {
      try {
        const response = await dispatch(packageThunks.packageList({moduleId,page,rowsPerPage,searchText,name,order}));
        if (packageThunks.packageList.fulfilled.match(response)) {
          if (response.payload) {
            const options = {
              count: response.payload.totalRecords,
              page: response.payload.currentPage-1, // Zero-based index
              rowsPerPage:response.payload.recordsPerPage,
            }
            setOption(options)
            setPackageData(response.payload.data);
            return response.payload
          }
        }
      } catch (error) {
        console.error("Error fetching client data:", error);
      }
    };
    

  const breadcrumb = {
    mainHeader: "Package",
    subHeader: "Home",
    subHeaderLink: "/dashboard",
    subHeader1: "Package",
    subHeader1Link: null,
    subHeader2: null,
    subHeader2Link: null,
  };

  return (
    <MainLayout breadcrumb={breadcrumb}>
      <div className="card">
        <div className="card-body">
          <div className="card-header ">
            <p
              className="card-title"
              style={{
                fontSize: "20px",
                fontWeight: "500",
                color: ThemeColor.EASTERNBLUE,
              }}
            >
              Package
            </p>
          </div>

          <Datatable
            columns={columns}
            data={packageData}
            options={option}
            api={getPackageDetailsApi}
            customBodyRender={renderActions}
          />
        </div>
      </div>
    </MainLayout>
  );
};

export default PackageDetails;
