import React, { useEffect, useState } from "react";
import Datatable from "../../components/datatable";


import { useDispatch } from "../../store";
import { ThemeColor } from "../../constants/themeColorConstant";
import { useLocation, useNavigate } from "react-router-dom";
import MainLayout from "../layout/MainLayout";
import { projectThunks } from "../../store/reducers/projects/project.thunk";


const Project = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [projectData, setProjectData] = useState([]);

  const [option, setOption] = useState({});

  const location = useLocation();
  const pathnameSegments = location.pathname.split("/");
  const moduleName = pathnameSegments[1];

  const allowedModules = JSON.parse(localStorage.getItem("allowedModules"));
  const selectedModule = allowedModules.find(
    (module) => module.slug === moduleName
  );

  const moduleId = selectedModule.uid;

  const renderActions = (rowData) => {
    const employeeId = rowData.uid;
    const editIcon = (
      <i
        className="fa fa-edit"
        style={{ cursor: "pointer" }}
         onClick={() => navigate(`/projects/edit/${employeeId}`, { state: { moduleId } })}
      />
    );

    return (
      <div>
        {editIcon}
      </div>
    );
  };

  const columns = [
    {
      name: "action",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const rowData = projectData[dataIndex];
          return renderActions(rowData);
        },
      },
    },
    {
      name: "clientName",
      label: "Client",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "projectName",
      label: "Project Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "projectNumber",
      label: "Project Number",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "scope",
      label: "Scope",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "location",
      label: "Location",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "type",
      label: "Type",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "quoteRef",
      label: "Quote ref",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "lpoNo",
      label: "LPO Number",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "lpoValue",
      label: "LPO Value",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "startDate",
      label: "Start Date",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "expectedFinishDate",
      label: "Expected Finish Date",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "actualFinishDate",
      label: "Actual Finish Date",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "currentStatus",
      label: "Current Status",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "percentage",
      label: "Percentage",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "percentValue",
      label: "PercentageValue",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "reservedAmount",
      label: "Reserved Amount",
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  useEffect(() => {
    getProjectDetailsApi()
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

    const getProjectDetailsApi = async (page=1,rowsPerPage=10,searchText='',name='',order='') => {
      try {
        const response = await dispatch(projectThunks.projectListData({moduleId,page,rowsPerPage,searchText,name,order}));
        if (projectThunks.projectListData.fulfilled.match(response)) {
          if (response.payload) {
            const options = {
              count: response.payload.totalRecords,
              page: response.payload.currentPage-1, // Zero-based index
              rowsPerPage:response.payload.recordsPerPage,
            }
            setOption(options)
            setProjectData(response.payload.data);
            return response.payload
          }
        }
      } catch (error) {
        console.error("Error fetching client data:", error);
      }
    };
    

  const breadcrumb = {
    mainHeader: "Project Manage",
    subHeader: "Home",
    subHeaderLink: "/dashboard",
    subHeader1: "Project Manage",
    subHeader1Link: null,
    subHeader2: null,
    subHeader2Link: null,
  };

  return (
    <MainLayout breadcrumb={breadcrumb}>
      <div className="card">
        <div className="card-body">
          <div className="card-header ">
            <p
              className="card-title"
              style={{
                fontSize: "20px",
                fontWeight: "500",
                color: ThemeColor.EASTERNBLUE,
              }}
            >
              Project Manage
            </p>
            <div className="d-flex justify-content-end">
              <button
                onClick={() =>
                  navigate("/projects/add", { state: { moduleId } })
                }
                className="btn btn-primary"
              >
                Add
              </button>

            </div>
          </div>
          <Datatable
            columns={columns}
            data={projectData}
            options={option}
            customBodyRender={renderActions}
            api={getProjectDetailsApi}
          />
        </div>
      </div>
    </MainLayout>
  );
};

export default Project;
