import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiCall } from "../../../utils/api";
import { HTTP_METHODS } from "../../../constants/methodsConstant";
import { ROUTES } from "../../../constants/routesConstant";
import { ToastType, setToastContent } from "../toast/toast.slice";

export const assetList = createAsyncThunk(
    "asset/list",
    async (inputData, { dispatch }) => {
      try {
        const response = await ApiCall(
          HTTP_METHODS.GET,
          `${ROUTES.GET_ASSET}?page=${inputData.page}&limit=${inputData.rowsPerPage}&search=${inputData.searchText}&sort=${inputData.name}&order=${inputData.order}`,
          {},
          inputData.moduleId
        );
        if (response?.isSuccess) {
          return response.payload;
        } else {
          dispatch(
            setToastContent({
              type: ToastType.error,
              message: response.message,
            })
          );
        }
      } catch (error) {
        throw new Error("An error occurred");
      }
    }
  );

  export const assetDetails = createAsyncThunk(
    "asset/details",
    async (inputData, { dispatch }) => {
      try {
        const response = await ApiCall(
          HTTP_METHODS.GET,
          `${ROUTES.GET_ASSET}/${inputData.id}`,
          {},
          inputData.moduleId
        );
        if (response?.isSuccess) {
          return response.payload;
        } else {
          dispatch(
            setToastContent({
              type: ToastType.error,
              message: response.message,
            })
          );
        }
      } catch (error) {
        throw new Error("An error occurred");
      }
    }
  );

  export const addAsset = createAsyncThunk(
    "asset/add",
    async (inputData, { dispatch }) => {
      try {
        const response = await ApiCall(
          HTTP_METHODS.POST,
          ROUTES.GET_ASSET,
          inputData.data,
          inputData.moduleId
        );
        if (response?.isSuccess) {
          dispatch(
            setToastContent({
              type: ToastType.success,
              message: response.message,
            })
          );
          return response.payload;
        } else {
          dispatch(
            setToastContent({
              type: ToastType.error,
              message: response.message,
            })
          );
        }
      } catch (error) {
        throw new Error("An error occurred");
      }
    }
  );

  export const updateAsset = createAsyncThunk(
    "asset/update",
    async (inputData, { dispatch }) => {
      try {
        const response = await ApiCall(
          HTTP_METHODS.PATCH,
          `${ROUTES.GET_ASSET}/${inputData.id}`,
          inputData.data,
          inputData.moduleId
        );
        if (response?.isSuccess) {
          dispatch(
            setToastContent({
              type: ToastType.success,
              message: response.message,
            })
          );
          return response.payload;
        } else {
          dispatch(
            setToastContent({
              type: ToastType.error,
              message: response.message,
            })
          );
        }
      } catch (error) {
        throw new Error("An error occurred");
      }
    }
  );

  export const statusList = createAsyncThunk("asset/status",async(inputData,{dispatch})=>{
    try{
        const response = await ApiCall(
            HTTP_METHODS.GET,
            ROUTES.GET_STATUS,
            {},
            inputData
          );
          if (response?.isSuccess) {
            return response.payload;
          } else {
            dispatch(
              setToastContent({
                type: ToastType.error,
                message:response.message,
              })
            );
          }
    }
    catch(error){
    throw new Error("An error occurred");
    }

})

export const typeList = createAsyncThunk("asset/type",async(inputData,{dispatch})=>{
  try{
      const response = await ApiCall(
          HTTP_METHODS.GET,
          ROUTES.GET_TYPE,
          {},
          inputData
        );
        if (response?.isSuccess) {
          return response.payload;
        } else {
          dispatch(
            setToastContent({
              type: ToastType.error,
              message:response.message,
            })
          );
        }
  }
  catch(error){
  throw new Error("An error occurred");
  }

})


  export const deleteAsset = createAsyncThunk(
    "asset/delete",
    async (inputData, { dispatch }) => {  
      try {
        const response = await ApiCall(
          HTTP_METHODS.DELETE,
          ROUTES.GET_ASSET+`/${inputData.id}`,
          {},
          inputData.moduleId
        );
        if (response?.isSuccess) {
          dispatch(
            setToastContent({
              type: ToastType.success,
              message: response.message,
            })
          );
          return response.payload;
        } else {
          dispatch(
            setToastContent({
              type: ToastType.error,
              message: response.message,
            })
          );
        }
      } catch (error) {
        throw new Error("An error occurred");
      }
    }
  );


  export const assetThunks = {
    assetList,
    addAsset,
    updateAsset,
    deleteAsset,
    assetDetails,
    typeList,
    statusList
  };  