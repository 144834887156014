import React, { useEffect, useState } from 'react';
import { ThemeColor } from '../../../constants/themeColorConstant';
import Datatable from '../../../components/datatable';
import { useDispatch } from '../../../store';
import { useNavigate, useParams } from 'react-router-dom';
import { projectCostingThunk } from '../../../store/reducers/projectCosting/projectCosting.thunk';
import ConfirmationBox from '../../../components/ConfirmationBox';

const ExpenseDetails = ({ moduleId }) => {

  const navigate = useNavigate()
  const [projectExpenseData, setProjectExpenseData] = useState()
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [expenseIdToDelete, setExpenseIdToDelete] = useState(null);
  const [options, setOption] = useState()
  const dispatch = useDispatch()
  const { id } = useParams();



  const renderActions = (rowData) => {
    if (rowData && rowData.projectExpenseId) {
      const expenseId = rowData.projectExpenseId;
      const projectId = id;
      const editIcon = (
        <i
          className="fa fa-edit"
          style={{ cursor: "pointer" }}
          onClick={() =>
            navigate(`/project-costing/project-expense/edit/${expenseId}`, { state: { moduleId, projectId } })
          }
        />
      );
      const deleteIcon = (
        <i
          style={{ marginLeft: "10px", cursor: "pointer" }}
          className="fa fa-trash-o"
          onClick={() => {
            setExpenseIdToDelete(expenseId);
            setShowConfirmation(true);
          }}
        />
      );

      return (
        <div>
          {editIcon}
          {deleteIcon}
        </div>
      );
    }
    else {
      return null
    }
  };


  const columns = [
    {
      name: "action",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const rowData = projectExpenseData[dataIndex];
          return renderActions(rowData);
        },
      },
    },
    {
      name: "short",
      label: "short",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "subContractor",
      label: "Sub Contractor",
    },
    {
      name: "projectedExpense",
      label: "projected Expense",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "date",
      label: "date",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "type",
      label: "type",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "remark",
      label: "remark",
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  useEffect(() => {
    projectExpenseListApi()
  }, [])

  const projectExpenseListApi = async (page = 1, rowsPerPage = 10, searchText = '', name = '', order = '') => {
    try {
      const response = await dispatch(projectCostingThunk.projectWiseExpenseList({ projectId: id, moduleId, page, rowsPerPage, searchText, name, order }));
      if (projectCostingThunk.projectWiseExpenseList.fulfilled.match(response)) {
        if (response.payload) {
          const options = {
            count: response.payload.totalRecords,
            page: response.payload.currentPage - 1, // Zero-based index
            rowsPerPage: response.payload.recordPerPage,
          }
          setOption(options)
          setProjectExpenseData(response.payload.data);
          return response.payload
        }
      }
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await dispatch(
        projectCostingThunk.deleteProjectExpense({ id: expenseIdToDelete, moduleId })
      );
      if (projectCostingThunk.deleteProjectExpense.fulfilled.match(response)) {
        const updatedExpenseData = projectExpenseData.filter(
          (expense) => expense && expense.projectExpenseId !== expenseIdToDelete
        );
        setProjectExpenseData(updatedExpenseData);
      }
    } catch (error) {
      console.error("Error deleting employee:", error);
    }
    setExpenseIdToDelete(null);
    setShowConfirmation(false);
  };

  const options1 = {
    print: true,
    filter: true,
    download: true,
    viewColumns: true
  }


  return (

    <div className="card-body">
      <div className="card-header ">
        <p
          className="card-title"
          style={{
            fontSize: "20px",
            fontWeight: "500",
            color: ThemeColor.EASTERNBLUE,
          }}
        >
          Expense Details
        </p>
        <div className="d-flex justify-content-end">
          <button
            onClick={() =>
              navigate(`/project-costing/project-expense/add/${id}`, { state: { moduleId } })
            }
            className="btn btn-primary"
          >
            Add
          </button>

        </div>
      </div>
      <p
        className="card-title"
        style={{
          marginTop: "20px",
          marginLeft: "20px",
          marginBottom: "20px",
          fontSize: "20px",
          fontWeight: "500",
          color: ThemeColor.EASTERNBLUE,
        }}
      >
        Projected Expense
      </p>
      <Datatable columns={columns} data={projectExpenseData} customBodyRender={renderActions} options={options1}
        api={projectExpenseListApi}
      />
      <ConfirmationBox
        show={showConfirmation}
        onHide={() => setShowConfirmation(false)}
        onConfirm={handleConfirmDelete}
        message="Are you sure you want to delete expense?"
      />
    </div>

  );
}

export default ExpenseDetails;
