import React, { useEffect, useState } from "react";
import Datatable from "../../components/datatable";
import { useDispatch } from "../../store";
import { ThemeColor } from "../../constants/themeColorConstant";
import { useLocation, useNavigate } from "react-router-dom";
import MainLayout from "../layout/MainLayout";
import { bankThunks } from "../../store/reducers/bank/bank.thunk";

const BankDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [bankDetails, setBankDetails] = useState([]);
  const [option, setOption] = useState({});

  const location = useLocation();
  const pathnameSegments = location.pathname.split("/");
  const moduleName = pathnameSegments[1];

  const allowedModules = JSON.parse(localStorage.getItem("allowedModules"));
  const selectedModule = allowedModules.find(
    (module) => module.slug === moduleName
  );

  const moduleId = selectedModule.uid;

  const renderActions = (rowData) => {
    const employeeId = rowData.uid;
    const editIcon = (
      <i
        className="fa fa-edit"
        style={{ cursor: "pointer" }}
        onClick={() =>
          navigate(`/bank/edit/${employeeId}`, { state: { moduleId } })
        }
      />
    );

    return <div>{editIcon}</div>;
  };

  const columns = [
    {
      name: "action",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const rowData = bankDetails[dataIndex];
          return renderActions(rowData);
        },
      },
    },
    {
      name: "employeeCode",
      label: "Employee Code",
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "designationName",
      label: "Designation",
    },
    {
      name: "accountName",
      label: "Bank Account",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "bankName",
      label: "Bank Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "wpsIban",
      label: "WPS IBAN",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "wpsBank",
      label: "WPS Bank",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "wpsPersonalNo",
      label: "WPS Personal Number",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "wpsRouteCode",
      label: "WPS Route Code",
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  useEffect(() => {
    getBankDetailsApi();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const getBankDetailsApi = async (
    page=1,
    rowsPerPage=10,
    searchText = "",
    name = "",
    order = ""
  ) => {
    try {
      const response = await dispatch(
        bankThunks.bankData({
          moduleId,
          page,
          rowsPerPage,
          searchText,
          name,
          order,
        })
      );
      if (bankThunks.bankData.fulfilled.match(response)) {
        if (response.payload) {
          const options = {
            count: response.payload.totalRecords,
            page: response.payload.currentPage - 1, // Zero-based index
            rowsPerPage: response.payload.recordsPerPage,
          };
          setOption(options);
          setBankDetails(response.payload.data);
          return response.payload;
        }
      }
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };
  const breadcrumb = {
    mainHeader: "Bank Master",
    subHeader: "Home",
    subHeaderLink: "/dashboard",
    subHeader1: "Bank Master",
    subHeader1Link: null,
    subHeader2: null,
    subHeader2Link: null,
  };


  return (
    <MainLayout breadcrumb={breadcrumb}>
      <div className="card">
        <div className="card-body">
          <div className="card-header ">
            <p
              className="card-title"
              style={{
                fontSize: "20px",
                fontWeight: "500",
                color: ThemeColor.EASTERNBLUE,
              }}
            >
              Bank Master
            </p>
          </div>
          <Datatable
            columns={columns}
            data={bankDetails}
            options={option}
            customBodyRender={renderActions}
            api={getBankDetailsApi}
          />
        </div>
      </div>
    </MainLayout>
  );
};

export default BankDetails;
