import React, { useEffect, useState } from "react";
import Datatable from "../../components/datatable";
import { useDispatch } from "../../store";
import { ThemeColor } from "../../constants/themeColorConstant";
import {useLocation, useNavigate } from "react-router-dom";
import { employeeThunks } from "../../store/reducers/employee/employee.thunk";
import MainLayout from "../layout/MainLayout";
import ConfirmationBox from "../../components/ConfirmationBox";

const EmployeeDetails = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [employeeData, setEmployeeData] = useState([]);
  const [option, setOption] = useState({})
  
  const location = useLocation();
  const pathnameSegments = location.pathname.split('/');
  const moduleName = pathnameSegments[1];
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [employeeIdToDelete, setEmployeeIdToDelete] = useState(null);

  const allowedModules = JSON.parse(localStorage.getItem("allowedModules"));
  const selectedModule = allowedModules.find(
    (module) => module.slug === moduleName
  );

  const moduleId = selectedModule.uid

  const renderActions = (rowData) => {
    if (rowData && rowData.uid) {
    const employeeId = rowData.uid;
    const editIcon = (
      <i
        className="fa fa-edit fa-lg"
        style={{ cursor: "pointer" }}
        onClick={() => navigate(`/employee/edit/${employeeId}`, { state: { moduleId } })}
      />
    );
    const deleteIcon = (
      <i style={{ marginLeft: "10px", cursor: "pointer" }}
        className="fa fa-trash-o fa-lg"
       onClick={() => {
        setEmployeeIdToDelete(employeeId);
        setShowConfirmation(true);
      }}
      />
    );

    return (
      <div>
        {editIcon}
        {deleteIcon}
      </div>
    );
    }
    else{
      return null
    }
  };

  const columns = [
    {
      name: "action",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const rowData = employeeData[dataIndex];
          return renderActions(rowData);
        },
      },
    },
    {
      name: "employeeCode",
      label: "Emp ID",
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
        sort: true,
      },
    },
   
    {
      name: "designationName",
      label: "Designation",
    },
    {
      name: "dateOfJoining",
      label: "Date Of Joining",
    },
    {
      name: "startExperienceDate",
      label: "Pr Ex",
    },
    {
      name: "cuEx",
      label: "Cu Ex",
    },
    {
      name: "mobileNumber",
      label: "Mobile No",
    },
    {
      name: "officeEmail",
      label: "Office Email",
    },
    {
      name: "personalEmail",
      label: "Personal Email",
    },
    {
      name: "laborExpiry",
      label: "Labor Expiry",
    },
    {
      name: "visaExpiry",
      label: "Visa Expiry",
    },
    {
      name: "ppExpiry",
      label: "Passport Expiry",
    },
    {
      name: "status",
      label: "Status",
    },
    {
      name: "sendPayslip",
      label: "Send Payslip",
      options: {
        customBodyRender: (value) => (value ? "Yes" : "No"),
      },
    },
    {
      name: "leftDate",
      label: "Left Date",
    },
    {
      name: "remarks",
      label: "Remark",
    },
  ];

  useEffect(() => {
    getEmployeeListApi();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const getEmployeeListApi = async (page=1, rowsPerPage=10, searchText = '', name = '', order = '') => {
    try {
      const response = await dispatch(employeeThunks.employeeListData({ moduleId, page, rowsPerPage, searchText, name, order }));
      if (employeeThunks.employeeListData.fulfilled.match(response)) {
        if (response.payload) {
          const options = {
            count: response.payload.totalRecords,
            page: response.payload.currentPage - 1, // Zero-based index
            rowsPerPage: response.payload.recordsPerPage,
          }
          console.log({option})
          setOption(options)
          setEmployeeData(response.payload.data);
          return response.payload
        }
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await dispatch(
        employeeThunks.deleteEmployee({ employeeId: employeeIdToDelete, moduleId })
      );
      if (employeeThunks.deleteEmployee.fulfilled.match(response)) {
        const updatedEmployeeData = employeeData.filter(
          (employee) => employee && employee.uid !== employeeIdToDelete
        );
        setEmployeeData(updatedEmployeeData);
      }
    } catch (error) {
      console.error("Error deleting employee:", error);
    }
    setEmployeeIdToDelete(null);
    setShowConfirmation(false);
  };

 
  
  const breadcrumb = {
    mainHeader: "Employees",
    subHeader : "Home",
    subHeaderLink : "/dashboard",
    subHeader1 : "Employees",
    subHeader1Link : null,
    subHeader2 : null,
    subHeader2Link : null,

  };


  return (
    <MainLayout breadcrumb={breadcrumb}>
      <div className="card">
        <div className="card-body">
          <div className="card-header ">
            <p
              className="card-title"
              style={{
                fontSize: "20px",
                fontWeight: "500",
                color: ThemeColor.EASTERNBLUE,
              }}
            >
              Employee Manage
            </p>
            <div className="d-flex justify-content-end">
              <button
                onClick={() =>
                  navigate("/employee/add", { state: { moduleId } })
                }
                className="btn btn-primary"
              >
                Add
              </button>

            </div>
          </div>

          <Datatable columns={columns} data={employeeData} options={option} customBodyRender={renderActions} api={getEmployeeListApi} />
        </div>
      </div>
      <ConfirmationBox
        show={showConfirmation}
        onHide={() => setShowConfirmation(false)}
        onConfirm={handleConfirmDelete}
        message="Are you sure you want to delete employee?"
      />
    </MainLayout>
  );
};

export default EmployeeDetails;
