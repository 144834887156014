import React, { useEffect, useState } from "react";
import Datatable from "../../components/datatable";
import { useDispatch } from "../../store";
import { useLocation, useNavigate } from "react-router-dom";
import MainLayout from "../layout/MainLayout";
import { reportThunk } from "../../store/reducers/report/report.thunk";
import { employeeThunks } from "../../store/reducers/employee/employee.thunk";
import Select from "react-select";


const VehicleReport = () => {
  const dispatch = useDispatch();
  const [reportData, setReportData] = useState([]);
  const [option, setOption] = useState({});
  const [employeeList, setEmployeeList] = useState([])
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [projectList, setProjectList] = useState([]);


  const location = useLocation();
  const pathnameSegments = location.pathname.split("/");
  const moduleName = pathnameSegments[1];

  const allowedModules = JSON.parse(localStorage.getItem("allowedModules"));
  const selectedModule = allowedModules.find(
    (module) => module.slug === moduleName
  );

  const moduleId = selectedModule.uid;


  const columns = [
    {
      name: "date",
      label: "date",
    },
    {
      name: "employeeCode",
      label: "Emp Code",
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "expiryDate",
      label: "expiry date",
    },
    {
      name: "project",
      label: "project",
    },
    {
      name: "projectLocation",
      label: "project location",
    },

    {
      name: "fromDate",
      label: "from date",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "toDate",
      label: "to date",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "vehicle",
      label: "vehicle",
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  useEffect(() => {
    employeeListApi()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const employeeListApi = async () => {
    try {
      const response = await dispatch(
        employeeThunks.employeeList({ moduleId })
      );
      if (employeeThunks.gratuityEmployeeList.fulfilled.match(response)) {
        if (response.payload) {
          setEmployeeList(response.payload);
          return response.payload;
        }
      }
    } catch (error) {
      console.error("Error fetching company data:", error);
    }
  };

  const handleEmployeeChange = (selectedOptions) => {
    const selectedEmployeeUids = selectedOptions.map((employee) => employee.value);
    setSelectedEmployee(selectedEmployeeUids);
  };


  const vehicleDetailsApi = async (selectedValue = selectedEmployee, page = 1, rowsPerPage = 10, searchText = '', name = '', order = '') => {
    try {
      if (selectedValue) {
        const response = await dispatch(reportThunk.vehicleReportList({ selectedValue, moduleId, page, rowsPerPage, searchText, name, order }));
        if (reportThunk.vehicleReportList.fulfilled.match(response)) {
          if (response.payload) {
            const options = {
              count: response.payload.totalRecords,
              page: response.payload.currentPage - 1, // Zero-based index
              rowsPerPage: response.payload.recordPerPage,
            
            }
            setOption(options)
            setReportData(response.payload.data);
            return response.payload;
          }
        }
      } else {
        setOption({});
        setReportData([]);
      }
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };



  const breadcrumb = {
    mainHeader: "Vehicle Report",
    subHeader: "Home",
    subHeaderLink: "/dashboard",
    subHeader1: "Report",
    subHeader1Link: null,
    subHeader2: null,
    subHeader2Link: null,
  };


  return (
    <MainLayout breadcrumb={breadcrumb}>
      <div className="card">
        <div className="card-body">
          <div className="form-group row d-flex justify-content-center mt-5">
            <div className="col-sm-4  col-md-4">
              <Select
                name="employeeSelect"
                id="employeeSelect"
                placeholder="Select Employee"
                isSearchable
                isMulti
                options={employeeList.map((data) => ({
                  value: data.uid,
                  label: data.employee_code + " - " + data.name,
                }))}

                onChange={handleEmployeeChange}
              />
            </div>
            <div className="col-md-2 ">
              <button className="btn btn-outline-primary" onClick={() => { vehicleDetailsApi() }}>Show Report</button>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">

          <Datatable
            columns={columns}
            data={reportData}
            options={option}
            api={vehicleDetailsApi}
          />
        </div>
      </div>
    </MainLayout>
  );
};

export default VehicleReport;
