import React, { useEffect, useState } from "react";
import Datatable from "../../components/datatable";
import Select from "react-select";
import { useDispatch } from "../../store";
import { useFormik } from "formik";
import { employeeThunks } from "../../store/reducers/employee/employee.thunk";
import { useParams } from "react-router-dom";
import { projectThunks } from "../../store/reducers/projects/project.thunk";

const Attendance = ({ moduleId }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [projectList, setProjectList] = useState([]);
  const [attendanceData, setAttendanceData] = useState([]);

  const renderActions = (rowData) => {
    if (rowData && rowData.projectNo) {
 
    const removeIcon = (
      <i
        className="fa fa-remove"
        style={{ cursor: "pointer" }}
        onClick={() => addEmployeeProjectApi({projectIds:[rowData.project_uid],isDelete:true}) }
      />
    );

    return (
      <div>
        {removeIcon}
      </div>
    );
    }
    else{
      return null
    }
  };

  const EmployeeColumns = [
    {
      name: "action",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const rowData = attendanceData[dataIndex];
          return renderActions(rowData);
        },
      },
    },
    {
      name: "client",
      label: "Client",
    },
    {
      name: "projectNo",
      label: "Project No.",
    },
    {
      name: "project",
      label: "Project",
    },
  ];

  const { values, handleSubmit, setFieldValue } = useFormik({
    enableReinitialize: true,
    onSubmit: async (values) => {},
    initialValues: {
      projectIds: [],
    },
  });

  useEffect(() => {
    projectListApi();
    attendanceFilterDataApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const projectListApi = async () => {
    try {
      const response = await dispatch(projectThunks.projectList(moduleId));
      if (projectThunks.projectList.fulfilled.match(response)) {
        if (response.payload) {
          setProjectList(response.payload);
        }
      }
    } catch (error) {
      console.error("Error fetching project list:", error);
    }
  };

  const addEmployeeProjectApi = async (data) => {
    try {
      const response = await dispatch(
        employeeThunks.addEmployeeProject({ id, data, moduleId })
      );
      if (employeeThunks.addEmployeeProject.fulfilled.match(response)) {
        attendanceFilterDataApi();
        return response;
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const attendanceFilterDataApi = async () => {
    try {
      const response = await dispatch(
        employeeThunks.attendanceFilterData({ id, moduleId })
      );
      if (employeeThunks.attendanceFilterData.fulfilled.match(response)) {
        if (response.payload) {
          setAttendanceData(response.payload.attendanceProjectFilterData);
        }
      }
    } catch (error) {
      console.error("Error fetching attendance filter list:", error);
    }
  };


  return (
    <form
      className="form-horizontal"
      id="personal_info"
      onSubmit={handleSubmit}
    >
      <div className="card-body">
        <div className="card " style={{ padding: "20px" }}>
          <div className="form-group row d-flex justify-content-between">
            <div className="col-sm-3 col-md-5">
              <Select
                placeholder="Select Project"
                isSearchable
                isMulti
                name="projectIds"
                id="projectIds"
                options={projectList.map((project) => ({
                  value: project.uid,
                  label: project.project_number + " - " + project.project_name,
                }))}
                onChange={(selectedOptions) => {
                  const selectedProjectIds = selectedOptions.map(
                    (option) => option.value
                  );
                  setFieldValue("projectIds", selectedProjectIds);
                }}
              />
            </div>
            <div className="col-sm-2 col-md-2">
              <button
                onClick={()=>{addEmployeeProjectApi({projectIds : values.projectIds,isDelete:false})}}
                className="btn btn-primary"
              >
                Add
              </button>
            </div>
          </div>
        </div>
        <div className="card" style={{ marginTop: "50px" }}>
          <Datatable
            columns={EmployeeColumns}
            data={attendanceData}
            customBodyRender={renderActions}
          />
        </div>
      </div>
    </form>
  );
};

export default Attendance;
