const options = [
  { value: 2022, label: "2022" },
  { value: 2023, label: "2023" },
  { value: 2024, label: "2024" },
];

function YearSelector({ value, handleChange, yearKey }) {
  return (
    <select
      style={{ padding: "5px 10px" }}
      placeholder="Select Year"
      value={value}
      onChange={(e) => {
        handleChange(e, yearKey);
      }}
    >
      {options.map((o) => (
        <option value={o.value}>{o.label}</option>
      ))}
    </select>
  );
}

export default YearSelector;
