import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch } from "../../store";
import { useParams } from "react-router";
import { authorityThunks } from "../../store/reducers/authority/authority.thunk";
import {parseCustomDate } from "../employee/Personal";
import Datepicker from "../../components/datepicker";

const PassportDetails = ({ activeTab, moduleId, employeeData }) => {

  const [passportStatusList,setPassportStatusList] = useState([])
  const dispatch = useDispatch();
  const { id } = useParams();
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
  } = useFormik({
    enableReinitialize: true,
    onSubmit: async (values) => {
      const formattedValues = {
        ...values,
        dateOfIssue: values.dateOfIssue && parseCustomDate(values.dateOfIssue),
        dateOfExpiry: values.dateOfExpiry && parseCustomDate(values.dateOfExpiry),
      };
      await updateAuthoritiesDetailsApi(formattedValues);
    },
    initialValues: {
      number: employeeData.authority ? employeeData.authority.number : null,
      type: employeeData.authority ? employeeData.authority.type : null,
      countryCode: employeeData.authority
        ? employeeData.authority.countryCode
        : null,
      surname: employeeData.authority ? employeeData.authority.surname : null,
      givenName: employeeData.authority ? employeeData.authority.givenName : null,
      nationality: employeeData.authority
        ? employeeData.authority.nationality
        : null,
      placeOfBirth: employeeData.authority
        ? employeeData.authority.placeOfBirth
        : null,
      placeOfIssue: employeeData.authority
        ? employeeData.authority.placeOfIssue
        : null,
      fatherName: employeeData.authority
        ? employeeData.authority.fatherName
        : null,
      motherName: employeeData.authority
        ? employeeData.authority.motherName
        : null,
      passportStatus: employeeData.authority
        ? employeeData.authority.passportStatus
        : null,
      dateOfIssue: employeeData.authority
        ? employeeData.authority.dateOfIssue
        : null,
      dateOfExpiry: employeeData.authority
          ? employeeData.authority.dateOfExpiry
          : null,
    },
  });

  useEffect(()=>{
    passportStatusApi()
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const updateAuthoritiesDetailsApi = async (data) => {
    try {
      const response = await dispatch(
        authorityThunks.updateAuthoritiesDetails({
          id,
          data,
          moduleId,
          activeTab,
        })
      );
      if (authorityThunks.updateAuthoritiesDetails.fulfilled.match(response)) {
        return response
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const passportStatusApi = async () => {
    try {
      const response = await dispatch(
        authorityThunks.passportStatus({moduleId })
      );
      if (authorityThunks.passportStatus.fulfilled.match(response)) {
        if (response.payload) {
          setPassportStatusList(response.payload);
          return response.payload;
        }
      }
    } catch (error) {
      console.error("Error fetching company data:", error);
    }
  };

  return (
    <form
      className="form-horizontal"
      id="passport_info"
      onSubmit={handleSubmit}
    >
      <div className="card-body">
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Number</label>
          <div className="col-sm-5  col-md-5">
            <input
              type="text"
              className="form-control"
              name="number"
              id="number"
              placeholder="Number"
              value={values.number}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Type</label>
          <div className="col-sm-5  col-md-5">
            <input
              type="text"
              className="form-control"
              name="type"
              id="type"
              placeholder="Type"
              value={values.type}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Country Code</label>
          <div className="col-sm-5  col-md-5">
            <input
              type="text"
              className="form-control"
              name="countryCode"
              id="countryCode"
              placeholder="Country Code"
              value={values.countryCode}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Surname</label>
          <div className="col-sm-5  col-md-5">
            <input
              type="text"
              className="form-control"
              name="surname"
              id="surname"
              placeholder="Surname"
              value={values.surname}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Given Name</label>
          <div className="col-sm-5  col-md-5">
            <input
              type="text"
              className="form-control"
              name="givenName"
              id="givenName"
              placeholder="Given Name"
              value={values.givenName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Nationality</label>
          <div className="col-sm-5  col-md-5">
            <input
              type="text"
              className="form-control"
              name="nationality"
              id="nationality"
              placeholder="Nationality"
              value={values.nationality}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Place Of Birth</label>
          <div className="col-sm-5  col-md-5">
            <input
              type="text"
              className="form-control"
              name="placeOfBirth"
              id="placeOfBirth"
              placeholder="Place Of Birth"
              value={values.placeOfBirth}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Place Of Issue</label>
          <div className="col-sm-5  col-md-5">
            <input
              type="text"
              className="form-control"
              name="placeOfIssue"
              id="placeOfIssue"
              placeholder="Place Of Issue"
              value={values.placeOfIssue}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Date Of Issue</label>
          <div className="col-sm-5  col-md-5">
            <Datepicker
              name="dateOfIssue"
              id="dateOfIssue"
              value={values.dateOfIssue}
              dateFormat="yyyy-mm-dd"
              onChange={(date) =>
                setValues({
                  ...values,
                  dateOfIssue: parseCustomDate(date.target.value),
                })
              }
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Date Of Expiry</label>
          <div className="col-sm-5  col-md-5">
            <Datepicker
              name="dateOfExpiry"
              id="dateOfExpiry"
              value={values.dateOfExpiry}
              dateFormat="yyyy-mm-dd"
              onChange={(date) =>
                setValues({
                  ...values,
                  dateOfExpiry: parseCustomDate(date.target.value),
                })
              }
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Father Name</label>
          <div className="col-sm-5  col-md-5">
            <input
              type="text"
              className="form-control"
              name="fatherName"
              id="fatherName"
              placeholder="Father Name"
              value={values.fatherName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Mother Name</label>
          <div className="col-sm-5  col-md-5">
            <input
              type="text"
              className="form-control"
              name="motherName"
              id="motherName"
              placeholder="Mother Name"
              value={values.motherName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Passport Status</label>
          <div className="col-sm-5  col-md-5">
            <select
              className="form-control"
              name="passportStatus"
              id="passportStatus"
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option>Select</option>
              {passportStatusList.map((data,index) => {
                return (
                  <option
                    key={index}
                    selected={data.slug === values.passportStatus}
                    value={data.slug}
                  >
                    {data.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <button
          type="submit"
          className="btn btn-primary"
          style={{ padding: "8px 20px" }}
        >
          <i className="fa fa-floppy-o" style={{ marginRight: "8px" }} />
          Update
        </button>
      </div>
    </form>
  );
};

export default PassportDetails;
