import React, { useEffect, useState } from "react";
import Datatable from "../../components/datatable";
import { useDispatch } from "../../store";
import { ThemeColor } from "../../constants/themeColorConstant";
import { useLocation, useNavigate } from "react-router-dom";
import MainLayout from "../layout/MainLayout";
import { gratuityThunks } from "../../store/reducers/gratuity/gratuity.thunk";
import { employeeThunks } from "../../store/reducers/employee/employee.thunk";
import Select from "react-select";
import { payrollThunks } from "../../store/reducers/payroll/payroll.thunk";
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import clsx from 'clsx';
import { makeStyles } from "tss-react/mui";

const Payroll = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [payrollData, setPayrollData] = useState([]);
  const [option, setOption] = useState({});
  const [selectedMonthYear, setSelectedMonthYear] = useState("");
  const [employeeList, setEmployeeList] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState("");

  const location = useLocation();
  const pathnameSegments = location.pathname.split("/");
  const moduleName = pathnameSegments[1];

  const allowedModules = JSON.parse(localStorage.getItem("allowedModules"));
  const selectedModule = allowedModules.find(
    (module) => module.slug === moduleName
  );

  const moduleId = selectedModule.uid;

  const useStyles = makeStyles()(theme => ({
    footerCell: {
      backgroundColor: '#007bff !important',
      borderBottom: 'none',
      color: 'black'
    },
    stickyFooterCell: {
      position: 'sticky',
      bottom: 0,
      zIndex: 100,
    },
  }));

  const [stickyFooter, setStickyFooter] = useState(true);
  const { classes } = useStyles();
  const footerClasses = clsx({
    [classes.footerCell]: true,
    [classes.stickyFooterCell]: stickyFooter,
  });


  
  const columns = [
    {
      name: "empCode",
      label: "Emp Code",
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "designation",
      label: "Designation",
    },

    // {
    //   name: "totalPackage",
    //   label: "Total Package",
    //   options: {
    //     filter: false,
    //     sort: true,
    //   },
    // },
    {
      name: "basicPayable",
      label: "Basic Payable",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "hraPayable",
      label: "HRA Payable",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "otherAllowancesPayable",
      label: "Other Allowance Payable",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "vehicleAllowance",
      label: "Vehicle Allowance",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "presentDays",
      label: "Present Days",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "arrearsLeaveSalaryPayable",
      label: "Arrears Leave Salary Payable",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "airCharges",
      label: "Air Charges",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "incentivesOtherPayable",
      label: "Incentives Other Payable",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "overTime",
      label: "Over Time (HRA)",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "otPayable",
      label: "OT Payable",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "fuelExpenses",
      label: "Fuel Expense",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "expensesPayable",
      label: "Expense Payble",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "totalGrossPayable",
      label: "Total Gross Payable",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "rent",
      label: "Rent",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "advance",
      label: "Advance",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "otherDeductions",
      label: "Other Deduction",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "grantSalaryHold",
      label: "Grant-Salary Hold",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "totalDeductions",
      label: "Total Deduction",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "netPayable",
      label: "Net Payable",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "netRoundOff",
      label: "Net Round Off",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "molValue",
      label: "MOL Value",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "difference",
      label: "Difference %",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value + "%";
        },
      },
    },
    {
      name: "netRoundOffDiff",
      label: "Net Round Off Diff",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "month",
      label: "Month",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "transMode",
      label: "Transfer Mode",
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  const handleMonthYearChange = async (event) => {
    const selectedDate = event.target.value;
    await setSelectedMonthYear(selectedDate);
    // viewPayrollListApi()
  };

  useEffect(() => {
    if (selectedMonthYear != "")
      viewPayrollListApi();
  }, [selectedMonthYear])

  const viewPayrollListApi = async (page = 1, rowsPerPage = 10, searchText = '', name = '', order = '') => {
    try {
      console.log({ selectedMonthYear });
      const formattedMonthYear = selectedMonthYear.split('-').reverse().join('/');
      const response = await dispatch(payrollThunks.viewPayrollList({ selectedMonthYear: formattedMonthYear, moduleId, page, rowsPerPage, searchText, name, order }));
      if (payrollThunks.viewPayrollList.fulfilled.match(response)) {
        if (response.payload) {
         
          const options = {
            count: response.payload.totalRecords,
            page: response.payload.currentPage - 1, // Zero-based index
            rowsPerPage: response.payload.recordPerPage,
            customTableBodyFooterRender: function (opts) {
              let dataWithSum = response.payload.dataWithSum;
              return (
                <TableFooter className={footerClasses}>
                 <TableRow>
                    {opts.selectableRows !== 'none' ? <TableCell className={footerClasses} /> : null}
                    {opts.columns.map((col, index) => {

                      if (col.display === 'true') {
                        if (col.name === 'totalPackage') {
                          let totalPackage =
                            opts.data.reduce((accu, item) => {
                              return accu + Number(item.data[index]);
                            }, 0);

                            totalPackage = Math.round(totalPackage);
                          return (
                            <TableCell key={index} className={footerClasses}>
                               {dataWithSum.totalPackage}
                            </TableCell>
                          );
                        }
                       else if (col.name === 'basicPayable') {
                          let basicPayable =
                            opts.data.reduce((accu, item) => {
                              return accu + Number(item.data[index]);
                            }, 0);

                          basicPayable = Math.round(basicPayable);
                          return (
                            <TableCell key={index} className={footerClasses}>
                               {dataWithSum.basicPayable}
                            </TableCell>
                          );
                        }
                        else if (col.name === 'hraPayable') {
                          let hra =
                            opts.data.reduce((accu, item) => {
                              return accu + Number(item.data[index]);
                            }, 0);

                          hra = Math.round(hra);
                          return (
                            <TableCell key={index} className={footerClasses}>
                              {dataWithSum.hraPayable}
                            </TableCell>
                          );
                        }
                        else if (col.name === 'otherAllowancesPayable') {
                          let otherAllowancesPayable =
                            opts.data.reduce((accu, item) => {
                              return accu + Number(item.data[index]);
                            }, 0);

                          otherAllowancesPayable = Math.round(otherAllowancesPayable);
                          return (
                            <TableCell key={index} className={footerClasses}>
                              {dataWithSum.otherAllowancesPayable}
                            </TableCell>
                          );
                        }
                        else if (col.name === 'vehicleAllowance') {
                          let VehicleAllowancePayable =
                            opts.data.reduce((accu, item) => {
                              return accu + Number(item.data[index]);
                            }, 0);

                          VehicleAllowancePayable = Math.round(VehicleAllowancePayable);
                          return (
                            <TableCell key={index} className={footerClasses}>
                              {dataWithSum.vehicleAllowance}
                            </TableCell>
                          );
                        }
                        else if (col.name === 'overTime') {
                          let overTime =
                            opts.data.reduce((accu, item) => {
                              return accu + Number(item.data[index]);
                            }, 0);

                            overTime = Math.round(overTime);
                          return (
                            <TableCell key={index} className={footerClasses}>
                              {dataWithSum.overTime}
                            </TableCell>
                          );
                        }
                        else if (col.name === 'arrearsLeaveSalaryPayable') {
                          let arrearsLeaveSalaryPayable =
                            opts.data.reduce((accu, item) => {
                              return accu + Number(item.data[index]);
                            }, 0);

                          arrearsLeaveSalaryPayable = Math.round(arrearsLeaveSalaryPayable);
                          return (
                            <TableCell key={index} className={footerClasses}>
                              {dataWithSum.arrearsLeaveSalaryPayable}
                            </TableCell>
                          );
                        }
                        else if (col.name === 'airCharges') {
                          let airCharges =
                            opts.data.reduce((accu, item) => {
                              return accu + Number(item.data[index]);
                            }, 0);

                          airCharges = Math.round(airCharges);
                          return (
                            <TableCell key={index} className={footerClasses}>
                              {dataWithSum.airCharges}
                            </TableCell>
                          );
                        }
                        else if (col.name === 'incentivesOtherPayable') {
                          let incentivesOtherPayable =
                            opts.data.reduce((accu, item) => {
                              return accu + Number(item.data[index]);
                            }, 0);

                          incentivesOtherPayable = Math.round(incentivesOtherPayable);
                          return (
                            <TableCell key={index} className={footerClasses}>
                              {dataWithSum.incentivesOtherPayable}
                            </TableCell>
                          );
                        }
                        else if (col.name === 'overTime') {
                          let otHrs =
                            opts.data.reduce((accu, item) => {
                              return accu + Number(item.data[index]);
                            }, 0);

                          otHrs = Math.round(otHrs);
                          return (
                            <TableCell key={index} className={footerClasses}>
                              {dataWithSum.otHrs}
                            </TableCell>
                          );
                        }
                        else if (col.name === 'otPayable') {
                          let otPayable =
                            opts.data.reduce((accu, item) => {
                              return accu + Number(item.data[index]);
                            }, 0);

                          otPayable = (otPayable).toFixed(2);
                          return (
                            <TableCell key={index} className={footerClasses}>
                              {dataWithSum.otPayable}
                            </TableCell>
                          );
                        }
                        else if (col.name === 'fuelExpenses') {
                          let fuelPayable =
                            opts.data.reduce((accu, item) => {
                              return accu + Number(item.data[index]);
                            }, 0);

                          fuelPayable = Math.round(fuelPayable);
                          return (
                            <TableCell key={index} className={footerClasses}>
                              {dataWithSum.fuelExpenses}
                            </TableCell>
                          );
                        }
                        else if (col.name === 'expensesPayable') {
                          let totalExpense =
                            opts.data.reduce((accu, item) => {

                              return accu + Number(item.data[index]);
                            }, 0);

                          totalExpense = Math.round(totalExpense);
                          return (
                            <TableCell key={index} className={footerClasses}>
                               {dataWithSum.expensesPayable}
                            </TableCell>
                          );
                        }
                        else if (col.name === 'totalGrossPayable') {
                          let totalGrossPayable =
                            opts.data.reduce((accu, item) => {
                              return accu + Number(item.data[index]);
                            }, 0);

                          totalGrossPayable = totalGrossPayable.toFixed(2);
                          return (
                            <TableCell key={index} className={footerClasses}>
                              {dataWithSum.totalGrossPayable}
                            </TableCell>
                          );
                        }
                        else if (col.name === 'rent') {
                          let rent =
                            opts.data.reduce((accu, item) => {
                              return accu + Number(item.data[index]);
                            }, 0);

                          rent = (rent).toFixed(2);
                          return (
                            <TableCell key={index} className={footerClasses}>
                               {dataWithSum.rent}
                            </TableCell>
                          );
                        }
                        else if (col.name === 'advance') {
                          let advance =
                            opts.data.reduce((accu, item) => {
                              return accu + Number(item.data[index]);
                            }, 0);

                          advance = (advance).toFixed(2);
                          return (
                            <TableCell key={index} className={footerClasses}>
                              {dataWithSum.advance}
                            </TableCell>
                          );
                        }
                        else if (col.name === 'otherDeductions') {
                          let otherDeduction =
                            opts.data.reduce((accu, item) => {
                              return accu + Number(item.data[index]);
                            }, 0);

                          otherDeduction = (otherDeduction).toFixed(2);
                          return (
                            <TableCell key={index} className={footerClasses}>
                              {dataWithSum.otherDeductions}
                            </TableCell>
                          );
                        }
                        else if (col.name === 'grantSalaryHold') {
                          let gratuityHoldPayable =
                            opts.data.reduce((accu, item) => {
                              return accu + Number(item.data[index]);
                            }, 0);

                          gratuityHoldPayable = (gratuityHoldPayable).toFixed(2);
                          return (
                            <TableCell key={index} className={footerClasses}>
                              {dataWithSum.grantSalaryHold}
                            </TableCell>
                          );
                        }
                        else if (col.name === 'totalDeductions') {
                          let totalDeduction =
                            opts.data.reduce((accu, item) => {
                              return accu + Number(item.data[index]);
                            }, 0);

                          totalDeduction = (totalDeduction).toFixed(2);
                          return (
                            <TableCell key={index} className={footerClasses}>
                              {dataWithSum.totalDeductions}
                            </TableCell>
                          );
                        }
                        else if (col.name === 'netPayable') {
                          let netPayable =
                            opts.data.reduce((accu, item) => {
                              return accu + Number(item.data[index]);
                            }, 0);

                          netPayable = (netPayable).toFixed(2);
                          return (
                            <TableCell key={index} className={footerClasses}>
                              {dataWithSum.netPayable}
                            </TableCell>
                          );
                        }
                        else if (col.name === 'netRoundOff') {
                          let netRoundOff =
                            opts.data.reduce((accu, item) => {
                              return accu + Number(item.data[index]);
                            }, 0);

                          netRoundOff = (netRoundOff).toFixed(2);
                          return (
                            <TableCell key={index} className={footerClasses}>
                              {dataWithSum.netRoundOff}
                            </TableCell>
                          );
                        }
                        else if (col.name === 'molValue') {
                          let molValue =
                            opts.data.reduce((accu, item) => {
                              return accu + Number(item.data[index]);
                            }, 0);

                          molValue = (molValue).toFixed(2);
                          return (
                            <TableCell key={index} className={footerClasses}>
                              {dataWithSum.molValue}
                            </TableCell>
                          );
                        }
                        else if (col.name === 'netRoundOffDiff') {
                          let netRoundOffDiff =
                            opts.data.reduce((accu, item) => {
                              return accu + Number(item.data[index]);
                            }, 0);

                          netRoundOffDiff = (netRoundOffDiff).toFixed(2);
                          return (
                            <TableCell key={index} className={footerClasses}>
                              {dataWithSum.netRoundOffDiff}
                            </TableCell>
                          );
                        }
                        else {
                          return <TableCell key={index} className={footerClasses}>  </TableCell>;
                        }
                      }
                      return null;
                    })}
                  </TableRow>
                  <TableRow>
                    {opts.selectableRows !== 'none' ? <TableCell className={footerClasses} /> : null}
                    {opts.columns.map((col, index) => {
                      return <TableCell key={index} className={footerClasses}>{col.label}  </TableCell>;

                    })
                    }

                  </TableRow>
                
                </TableFooter>
              );
            },
          }
          setOption(options);
          setPayrollData(response.payload.data);
          return response.payload;
        }
      }
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };

  const breadcrumb = {
    mainHeader: "View Payroll",
    subHeader: "Home",
    subHeaderLink: "/dashboard",
    subHeader1: "payroll",
    subHeader1Link: null,
    subHeader2: null,
    subHeader2Link: null,
  };

  return (
    <MainLayout breadcrumb={breadcrumb}>
      <div className="card">
        <div className="card-body">
          <div className="card-header ">
            <p
              className="card-title"
              style={{
                fontSize: "20px",
                fontWeight: "500",
                color: ThemeColor.EASTERNBLUE,
              }}
            >
              View Payroll
            </p>
          </div>
          <div className="form-group row d-flex justify-content-center mt-5">
            <div className="col-sm-2  col-md-2">
              <label>Select Month</label>
            </div>
            <div className="col-sm-4  col-md-4">
              <input type="month"
                name="month"
                id="month"
                placeholder="select payroll"
                className="form-control"
                value={selectedMonthYear || 'Select Payroll'}
                onChange={handleMonthYearChange}

              />
            </div>
            <div className="col-sm-4  col-md-4">
              {payrollData.length > 0 && (
                <button type="submit" className="btn btn-primary" onClick={() =>
                  navigate("/payroll/view-payroll/view-wps-report/" + selectedMonthYear, { state: { moduleId } })
                }>
                  View WPS Report
                </button>
              )}
            </div>
          </div>

          <Datatable columns={columns} data={payrollData} options={option} api={viewPayrollListApi}/>
        </div>
      </div>
    </MainLayout>
  );
};

export default Payroll;
