import React, { useEffect, useState } from "react";
import Datatable from "../../components/datatable";
import { useDispatch } from "../../store";
import { ThemeColor } from "../../constants/themeColorConstant";
import { useLocation, useNavigate } from "react-router-dom";
import MainLayout from "../layout/MainLayout";
import { projectCostingThunk } from "../../store/reducers/projectCosting/projectCosting.thunk";

const ProjectExpense = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [projectExpenseData, setprojectExpenseData] = useState([]);
  const [option, setOption] = useState({});

  const location = useLocation();
  const pathnameSegments = location.pathname.split("/");
  const moduleName = pathnameSegments[1];

  const allowedModules = JSON.parse(localStorage.getItem("allowedModules"));
  const selectedModule = allowedModules.find(
    (module) => module.slug === moduleName
  );


  const moduleId = selectedModule.uid;



  const columns = [
    {
      name: "projectName",
      label: "Project Name",
      options: {
        customBodyRender: (value, tableMeta) => {
          const projectId = projectExpenseData[tableMeta.rowIndex].projectId;
          return (
            <div>
              {value} <i style={{ marginLeft: "10px" }} class="fa fa-plus-square"
                onClick={() =>
                  navigate(`/project-costing/project-expense/${projectId}`, { state: { moduleId } })
                } />
            </div>
          );
        },
      },
    },
    {
      name: "totalProjectedExpense",
      label: "total Projected Expense (Subcontract Value)",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "totalSupplierExpense",
      label: "total Supplier Expense",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "totalSalaryExpense",
      label: "total Salary Expense",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "totalActualExpense",
      label: "Total Actual Expense",
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  useEffect(() => {
    projectExpenseListApi()
  }, []);

  const projectExpenseListApi = async (page = 1, rowsPerPage = 10, searchText = '', name = '', order = '') => {
    try {
      const response = await dispatch(projectCostingThunk.totalExpenseList({ moduleId, page, rowsPerPage, searchText, name, order }));
      if (projectCostingThunk.totalExpenseList.fulfilled.match(response)) {
        if (response.payload) {
          const options = {
            count: response.payload.totalRecords,
            page: response.payload.currentPage - 1, // Zero-based index
            rowsPerPage: response.payload.recordPerPage,
          }
          setOption(options)
          setprojectExpenseData(response.payload.data);
          return response.payload
        }
      }
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };



  const breadcrumb = {
    mainHeader: "Project Costing Manage",
    subHeader: "Home",
    subHeaderLink: "/dashboard",
    subHeader1: "Project Costing Manage",
    subHeader1Link: null,
    subHeader2: null,
    subHeader2Link: null,
  };

  return (
    <MainLayout breadcrumb={breadcrumb}>
      <div className="card">
        <div className="card-body">
          <div className="card-header ">
            <p
              className="card-title"
              style={{
                fontSize: "20px",
                fontWeight: "500",
                color: ThemeColor.EASTERNBLUE,
              }}
            >
              Project Costing Manage
            </p>
          </div>
          <Datatable
            columns={columns}
            data={projectExpenseData}
            options={option}
            api={projectExpenseListApi}
          />
        </div>
      </div>
    </MainLayout>
  );
};

export default ProjectExpense;
