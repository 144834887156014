import React, { useEffect, useState } from "react";
import Datatable from "../../components/datatable";
import { useDispatch } from "../../store";
import { ThemeColor } from "../../constants/themeColorConstant";
import { useLocation, useNavigate } from "react-router-dom";
import MainLayout from "../layout/MainLayout";
import { employeeLoginThunk } from "../../store/reducers/employeeLogin/employeeLogin.thunk";
import ConfirmationBox from "../../components/ConfirmationBox";

const EmployeeLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [employeeData, setEmployeeData] = useState([]);
  const [option, setOption] = useState({});
  const [employeeIdToUpdate, setEmployeeIdToUpdate] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [statusToUpdate, setStatusToUpdate] = useState(false); // Add statusToUpdate state
  const [addConfirmation, setAddConfirmation] = useState(false);
  const [resetConfirmation, setResetConfirmation] = useState(false);

  const location = useLocation();
  const pathnameSegments = location.pathname.split('/');
  const moduleName = pathnameSegments[1];

  const allowedModules = JSON.parse(localStorage.getItem("allowedModules"));
  const selectedModule = allowedModules.find(
    (module) => module.slug === moduleName
  );

  const moduleId = selectedModule.uid

  const renderActions = (rowData) => {
    if (rowData && rowData.uid) {
      const employeeId = rowData.uid;
      const editIcon = (
        <i
          className="fa fa-edit"
          style={{ cursor: "pointer" }}
          onClick={() => navigate(`/employee/edit/${employeeId}`, { state: { moduleId } })}
        />
      );

      return (
        <div>
          {editIcon}
        </div>
      );
    } else {
      return null;
    }
  };

  const columns = [
    {
      name: "action",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const rowData = employeeData[dataIndex];
          return renderActions(rowData);
        },
      },
    },
    {
      name: "employeeCode",
      label: "Employee Code",
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "type",
      label: "Type",
    },
    {
      name: "mobileNumber",
      label: "Mobile Number",
    },
    {
      name: "officeEmail",
      label: "Office Email",
    },
    {
      name: "status",
      label: "Status",
    },
    {
      name: "isLoginAllow",
      label: "Add",
      options: {
        customBodyRender: (value, tableMeta) => {
          const rowData = employeeData[tableMeta.rowIndex];
          if (rowData && rowData.uid && value === false) {
            return (
              <i className="fa fa-user-plus" style={{ cursor: "pointer" }} onClick={() => handleAllowLogin(rowData.uid)} />
            );
          } else {
            return null;
          }
        },
      },
    },
    {
      name: "upd",
      label: "Update",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const rowData = employeeData[tableMeta.rowIndex];
          if (rowData && rowData.uid && rowData.status === 'active') {
            return (
              <i className="fa fa-pencil" aria-hidden="true" style={{ cursor: "pointer" }} 
              onClick={() => handleResetPassowrd(rowData.uid)}
               />
            );
          } else {
            return null;
          }
        },
      },
    },
    {
      name: "employeeLogin",
      label: "Employee Login",
    },
    {
      name: "isBlock",
      label: "Block",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const rowData = employeeData[tableMeta.rowIndex];
          if (rowData && rowData.uid) {
            const employeeId = rowData.uid;
            const statusIcon = value ? (
              <i
                className="fa fa-unlock"
                style={{ cursor: "pointer" }}
                onClick={() => handleConfirmUpdateStatus(employeeId, value)}
              />
            ) : (
              <i
                className="fa fa-lock"
                style={{ cursor: "pointer" }}
                onClick={() => handleConfirmUpdateStatus(employeeId, value)}
              />
            );
            return statusIcon;

          } else {
            return null;
          }
        },
      },
    },
  ];

  useEffect(() => {
    employeeLoginListApi();
  }, []);

    const employeeLoginListApi = async (page=1, rowsPerPage=10, searchText = '', name = '', order = '') => {
      try {
        const response = await dispatch(employeeLoginThunk.employeeLoginList({ moduleId, page, rowsPerPage, searchText, name, order }));
        if (employeeLoginThunk.employeeLoginList.fulfilled.match(response)) {
          if (response.payload) {
            const options = {
              count: response.payload.totalRecords,
              page: response.payload.currentPage - 1, // Zero-based index
              rowsPerPage: response.payload.recordsPerPage,
            }
            setOption(options)
            setEmployeeData(response.payload.data);
            return response.payload
          }
        }
      } catch (error) {
        console.error("Error fetching employee data:", error);
      }
    };

    const handleConfirmUpdateStatus = async (employeeId, currentStatus) => {
      setEmployeeIdToUpdate(employeeId);
      setStatusToUpdate(!currentStatus); // Toggle the statusToUpdate state
      setShowConfirmation(true);
    };

    const handleStatusUpdate = async () => {
      try {
        const response = await dispatch(
          employeeLoginThunk.updateEmployeeStatus({
            employeeId: employeeIdToUpdate,
            moduleId,
            data: { isBlock: statusToUpdate }, // Use the statusToUpdate state here
          })
        );
        if (employeeLoginThunk.updateEmployeeStatus.fulfilled.match(response)) {
          const updatedEmployeeData = employeeData.map((data) =>
            data.uid === employeeIdToUpdate
              ? { ...data, isBlock: statusToUpdate }
              : data
          );
          setEmployeeData(updatedEmployeeData);
          setEmployeeIdToUpdate(null);
          setShowConfirmation(false);
          return response;
        }
      } catch (error) {
        console.error("Error fetching employee data:", error);
      }
    };

    const handleAllowLogin = async (employeeId) => {
      setEmployeeIdToUpdate(employeeId);
      setAddConfirmation(true);
    };

    const updateAllowLogin = async () => {
      try {
        const response = await dispatch(
          employeeLoginThunk.allowEmployeeLogin({
            employeeId: employeeIdToUpdate,
            moduleId,
            data: { isLoginAllow: true }, // Set isLoginAllow to true
          })
        );
        if (employeeLoginThunk.allowEmployeeLogin.fulfilled.match(response)) {
          const updatedEmployeeData = employeeData.map((data) =>
            data.uid === employeeIdToUpdate
              ? { ...data, isLoginAllow: true }
              : data
          );
          setEmployeeData(updatedEmployeeData);
          setEmployeeIdToUpdate(null);
          setAddConfirmation(false);
          return response;
        }
      } catch (error) {
        console.error("Error fetching employee data:", error);
      }
    };

    const handleResetPassowrd = async (employeeId) => {
      setEmployeeIdToUpdate(employeeId);
      setResetConfirmation(true);
    };

    const updateResetPassowrd = async () => {
      try {
        const response = await dispatch(
          employeeLoginThunk.updateResetPassowrd({
            employeeId: employeeIdToUpdate,
            moduleId
          })
        );
        if (employeeLoginThunk.updateResetPassowrd.fulfilled.match(response)) {
          setEmployeeIdToUpdate(null);
          setResetConfirmation(false);
          return response;
        }
      } catch (error) {
        console.error("Error fetching employee data:", error);
      }
    };
  

  const breadcrumb = {
    mainHeader: "Manage Employee Portal Login",
    subHeader: "Home",
    subHeaderLink: "/dashboard",
    subHeader1: "List",
    subHeader1Link: null,
    subHeader2: null,
    subHeader2Link: null,
  };

  return (
    <MainLayout breadcrumb={breadcrumb}>
      <div className="card">
        <div className="card-body">
          <div className="card-header d-flex flex-column">
            <p
              className="card-title"
              style={{
                fontSize: "20px",
                fontWeight: "500",
                marginBottom: "10px",
                color: ThemeColor.EASTERNBLUE,
              }}
            >
              User Instruction
            </p>
            <b>Add Button:</b>
            <p>
              Add Button is visible after creating a New Employee. Click on Add
              Button to add employee in Employee Portal.
            </p>
            <b>Update Button:</b>
            <p>
              Update button will update Official Email of Employee in Employee
              Portal.
            </p>
            <b>Block Button:</b>
            <p>
              Click on Blk toggle Button to Enable / Disable Employee Portal
              access.
            </p>
          </div>

          <Datatable
            columns={columns}
            data={employeeData}
            options={option}
            customBodyRender={renderActions}
          />
           <ConfirmationBox
        show={showConfirmation}
        onHide={() => setShowConfirmation(false)}
        onConfirm={handleStatusUpdate}
        message="Are you sure you want to update status?"
      />
       <ConfirmationBox
            show={addConfirmation}
            onHide={() => setAddConfirmation(false)}
            onConfirm={updateAllowLogin}
            message="Are you sure you want to allow login for this employee?"
          />
        <ConfirmationBox
            show={resetConfirmation}
            onHide={() => setResetConfirmation(false)}
            onConfirm={updateResetPassowrd}
            message="Are you sure you want to reset password?"
          />

        </div>
      </div>
    </MainLayout>
  );
};

export default EmployeeLogin;
