import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const Menu = () => {
  const allowedModules = JSON.parse(localStorage.getItem("allowedModules"));
  const location = useLocation();

  const [activeMenus, setActiveMenus] = useState([]);

  useEffect(() => {
    const pathSegments = location.pathname.split("/").filter(Boolean);
    setActiveMenus(pathSegments);

  }, [location.pathname]);

  const isMenuItemActive = (menuItem) => {
    return activeMenus.includes(menuItem.slug);
  };

  const userData = JSON.parse(localStorage.getItem('userData'));
  const isEmployee = userData.isEmployee;
  const renderDashboard = () => {
    if (!isEmployee) {
      return (
        <li className="nav-item">
          <Link to="/dashboard" className={`nav-link ${activeMenus.includes('dashboard') ? 'active' : ''}`}>
            <i className="nav-icon fa fa-dashboard" />
            <p>Home</p>
          </Link>
        </li>
      )
    }
    return '';
  }

  const renderSelfEdit = () => {
    if (isEmployee) {
      return (
        <li className="nav-item">
          <Link to="/employee/self-edit" className={`nav-link ${activeMenus.includes('self-edit') ? 'active' : ''}`}>
            <i className="nav-icon fa fa-edit" />
            <p>Self Edit</p>
          </Link>
        </li>
      )
    }
    return '';
  }
  const renderMenuItem = (menuItem) => {
    const isActive = isMenuItemActive(menuItem);
    const hasSubmodules = menuItem.subModules && menuItem.subModules.length > 0;

    return (
      <li
        className={`nav-item ${isActive ? "menu-open" : ""}`}
        key={menuItem.id}
      >
        <Link
          to={`/${menuItem.slug}`}
          className={`nav-link ${isActive ? 'active' : ''}`}
        >
          <i className={`nav-icon ${menuItem.icon}`} />{" "}
          <p>
            {menuItem.name}
            {hasSubmodules && <i className="right fa fa-angle-left" />}
          </p>
        </Link>
        {hasSubmodules && (
          <ul className="nav nav-treeview">
            {menuItem.subModules.map((submenu) => (
              <li className="nav-item" key={submenu.id}>
                <Link
                  to={`/${menuItem.slug}/${submenu.slug}`}
                  className={`nav-link ${isMenuItemActive(submenu) ? 'active' : ''}`}
                >
                  <i
                    className={`fa fa-circle-thin nav-icon ${submenu.icon}`}
                  />
                  <p>{submenu.name}</p>
                </Link>
              </li>
            ))}
          </ul>
        )}
      </li>
    );
  };

  return (
    // <div >
    <aside className="main-sidebar sidebar-dark-primary elevation-4" style={{ height: "100vh", overflowY: "auto" }}>
      <div className="sidebar">
        <div className="user-panel mt-3 pb-3 mb-3 d-flex justify-content-center">
          <div className="image">
            <img
              src="/dist/img/logo.png"
              className="brand-image img-circle "
              alt="User"
            />
          </div>
        </div>
        <nav className="mt-2">
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            {renderDashboard()}


            {allowedModules.sort((a, b) => a.sortKey - b.sortKey).map((menuItem) => (
              renderMenuItem(menuItem)
            ))}

            {renderSelfEdit()}

          </ul>
        </nav>
      </div>
    </aside>
    // </div>
  );
};

export default Menu;
