import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "../../store";
import { ThemeColor } from "../../constants/themeColorConstant";
import MainLayout from "../layout/MainLayout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Datepicker from "../../components/datepicker";
import { parseCustomDate } from "../employee/Personal";
import { gratuityThunks } from "../../store/reducers/gratuity/gratuity.thunk";
import { employeeThunks } from "../../store/reducers/employee/employee.thunk";
import Select from "react-select";

const validationSchema = Yup.object().shape({
  fromDate: Yup.string().required("Please enter from date"),
  toDate: Yup.string().required("Please enter to date"),
});

const EditGratuity = () => {
  const [activeTab, setActiveTab] = useState("editGratuity");
  const [gratuityData,setGratuityData] = useState([])  
  const [employeeList, setEmployeeList] = useState([]);

  const navigate = useNavigate()
  const location = useLocation();
  const { moduleId } = location.state || {};
  const {id} = useParams()
  const dispatch = useDispatch();

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
    setValues,
  } = useFormik({
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      delete values.employeeIdObj;
        const formattedValues = {
            ...values,
            fromDate: values.fromDate && parseCustomDate(values.fromDate),
            toDate: values.toDate && parseCustomDate(values.toDate),
          };
      await updateGratuityApi(formattedValues);
      resetForm();
    },
    initialValues: {
       employeeId : gratuityData && gratuityData.employee ? gratuityData.employee.value : null,
       employeeIdObj: gratuityData.employee ? gratuityData.employee : null,
       fromDate : gratuityData ? gratuityData.fromDate : "",
       toDate : gratuityData ? gratuityData.toDate : "",
       roundOff : gratuityData ? gratuityData.roundOff : null,
       reason : gratuityData ? gratuityData.reason : null,
       remark : gratuityData ? gratuityData.remark : null,
       description : gratuityData ? gratuityData.description : null,
       attachedForm : gratuityData ? gratuityData.attachedForm : null
    },
  });


  useEffect(() => {
    getGratuityDataApi()
    employeeListApi()
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loginClickHandle = (e) => {
    e.preventDefault();
    e.persist();
    handleSubmit(e);
  };

  const getGratuityDataApi = async () => {
    try {
      const response = await dispatch(gratuityThunks.gratuityDetails({id,moduleId}));
      if (gratuityThunks.gratuityDetails.fulfilled.match(response)) {
        if (response.payload) {
          let employee = {};
          let res = response.payload;
          if(res && res.employee){
          employee.value =  res.employee.uid;
          employee.label = res.employee.name;
          res.employee = employee;}
          setGratuityData(res);
        }
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };


  const employeeListApi = async () => {
    try {
      const response = await dispatch(
        employeeThunks.employeeList({ moduleId })
      );
      if (employeeThunks.employeeList.fulfilled.match(response)) {
        if (response.payload) {
          setEmployeeList(response.payload);
          return response.payload;
        }
      }
    } catch (error) {
      console.error("Error fetching company data:", error);
    }
  };

    const updateGratuityApi = async (data) => {
      try {
        const response = await dispatch(
          gratuityThunks.updateGratuity({id, data, moduleId })
        );
        if (gratuityThunks.updateGratuity.fulfilled.match(response)) {
          navigate("/gratuity")
        }
      } catch (error) {
        console.error("Error fetching employee data:", error);
      }
    };


  const breadcrumb = {
    mainHeader: "Manage Gratuity",
    subHeader: "Home",
    subHeaderLink: "/dashboard",
    subHeader1: "Gratuity",
    subHeader1Link: "/gratuity",
    subHeader2: "edit",
    subHeader2Link: null,
  };

  return (
    <>
      <MainLayout breadcrumb={breadcrumb}>
        <div className="card card-info">
          <div className="card-body">
            <ul
              className="nav nav-tabs"
              style={{
                borderBottom: `2px solid ${ThemeColor.GREY}`,
                backgroundColor: "transparent",
              }}
            >
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    activeTab === "editGratuity" ? "active" : ""
                  }`}
                  onClick={() => handleTabChange("editGratuity")}
                  style={{
                    borderBottom: `3px solid ${
                      activeTab === "editGratuity" ? ThemeColor.BLUE : "transparent"
                    }`,
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    backgroundColor: "transparent",
                  }}
                >
                  Edit Gratuity
                </button>
              </li>
            </ul>
            <form
              className="form-horizontal"
              id="gratuity_info"
              onSubmit={handleSubmit}
            >
              <div className="card-body">
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">Employee</label>
                  <div className="col-sm-5  col-md-5">
                  <Select
                placeholder="Select Employee"
                isSearchable
                id="employeeId"
                name="employeeId"
                onChange={(selectedOption) => {
                  setValues({
                    ...values,
                    employeeIdObj: selectedOption,
                    employeeId: selectedOption.value,
                  })
                }}
                value={
                  values.employeeIdObj
                }
                options={employeeList.map((employee) => ({
                  value: employee.uid,
                  label:  employee.employee_code + " - " + employee.name,
                }))}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">From Date <span style={{ color: ThemeColor.RED }}>*</span></label>
                  <div className="col-sm-5  col-md-5">
                    <Datepicker
                      name="fromDate"
                      id="fromDate"
                      value={values.fromDate}
                      // readOnly={true}
                      dateFormat="yyyy-mm-dd"
                      onChange={(date) =>
                        setValues({
                          ...values,
                          fromDate: parseCustomDate(date.target.value),
                        })
                      }
                    />
                      {errors.fromDate && touched.fromDate && (
                    <div className="error" style={{ color: ThemeColor.RED }}>
                      {errors.fromDate}
                    </div>
                  )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">To Date <span style={{ color: ThemeColor.RED }}>*</span></label>
                  <div className="col-sm-5  col-md-5">
                    <Datepicker
                      name="toDate"
                      id="toDate"
                      value={values.toDate}
                      dateFormat="yyyy-mm-dd"
                      onChange={(date) =>
                        setValues({
                          ...values,
                          toDate: parseCustomDate(date.target.value),
                        })
                      }
                    />
                      {errors.toDate && touched.toDate && (
                    <div className="error" style={{ color: ThemeColor.RED }}>
                      {errors.toDate}
                    </div>
                  )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Round Off</label>
                  <div className="col-sm-5  col-md-5">
                    <input
                      type="text"
                      className="form-control"
                      name="roundOff"
                      id="roundOff"
                      placeholder="0.00"
                      value={values.roundOff}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Reason</label>
                  <div className="col-sm-5  col-md-5">
                    <textarea
                      id="reason"
                      name="reason"
                      cols={2}
                      rows={2}
                      value={values.reason}
                      className="form-control"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    ></textarea>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Remark</label>
                  <div className="col-sm-5  col-md-5">
                    <textarea
                      id="remark"
                      name="remark"
                      cols={2}
                      rows={2}
                      value={values.remark}
                      className="form-control"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    ></textarea>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Description</label>
                  <div className="col-sm-5  col-md-5">
                    <textarea
                      id="description"
                      name="description"
                      cols={2}
                      rows={2}
                      value={values.description}
                      className="form-control"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    ></textarea>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Attached Forms</label>
                  <div className="col-sm-5  col-md-5">
                    <div className="d-flex" style={{ gap: "5px" }}>
                      <input
                        style={{ width: "20px" }}
                        type="radio"
                        value="true"
                        name="attachedForm"
                        id="attachedForm"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={values.attachedForm}
                      />
                      Yes
                      <input
                        style={{ width: "20px", marginLeft: "10px" }}
                        type="radio"
                        value="false"
                        name="attachedForm"
                        id="attachedForm"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={values.attachedForm}
                      />
                      No
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-center">
              <button
                type="submit"
                className="btn btn-primary"
                style={{ padding: "8px 20px" }}
                onClick={(e) => loginClickHandle(e)}
              >
                <i className="fa fa-edit" style={{ marginRight: "8px" }} />
                Update
              </button>
              <button
                type="button"
                className="btn btn-default"
                style={{ padding: "8px 20px", marginLeft: "10px" }}
                onClick={()=>{
                  navigate("/gratuity")
                }}
              >
                Cancel
              </button>
            </div>
            </form>
          </div>
          {/* <ToastMessage toastData={toastData} /> */}
        </div>
      </MainLayout>
    </>
  );
};

export default EditGratuity;
