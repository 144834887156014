import { combineReducers } from 'redux';
import authReducer from "./reducers/authentication/authSlice";
import toastReducer from "./reducers/toast/toast.slice"


const rootReducer = combineReducers({
  auth: authReducer , 
  toast: toastReducer,
});

export default rootReducer;
