import React, { useEffect, useState } from "react";
import Datatable from "../../components/datatable";


const PackageHistory = ({employeeData,api}) => {

  const [packageData,setPackageData] = useState([])
  const [option, setOption] = useState({});


 
useEffect(()=>{
  if(employeeData){
    if(employeeData.data){
    setPackageData(employeeData.data.history)
    const options = {
      count: employeeData.totalRecords,
      page:employeeData.currentPage - 1, // Zero-based index
      rowsPerPage:employeeData.recordsPerPage,
    }
    setOption(options)
  }
  }
},[employeeData])

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "basic",
      label: "Basic",
    },
    {
      name: "hra",
      label: "HRA",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "otherAllowance",
      label: "Other Allowance",
    },
    {
      name: "transport",
      label: "Transport",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "telephone",
      label: "Telephone",
      options: {
        filter: false,
        sort: true,
      },
    },
    // {
    //   name: "furniture",
    //   label: "Furniture",
    //   options: {
    //     filter: false,
    //     sort: true,
    //   },
    // },
    {
      name: "vehicleAllowance",
      label: "Vehicle",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "petrol",
      label: "Petrol",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "incentives",
      label: "Incentives",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "airfare",
      label: "Air Fare",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "molValue",
      label: "MOL Value",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "modeOfTransfer",
      label: "Transaction Mode",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "fromDate",
      label: "From",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "toDate",
      label: "To",
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

 

   

  return <Datatable columns={columns} data={packageData} options={option} api={api} />;
};

export default PackageHistory;
