import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiCall } from "../../../utils/api";
import { ToastType, setToastContent } from "../toast/toast.slice";
import { HTTP_METHODS } from "../../../constants/methodsConstant";
import { ROUTES } from "../../../constants/routesConstant";

export const companyData = createAsyncThunk(
  "company/list",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.GET,
        ROUTES.GET_COMPANY,
        {},
        inputData.moduleId
      );
      if (response?.isSuccess) {
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);

export const updateCompanyData = createAsyncThunk(
  "company/update",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.PATCH,
        ROUTES.UPDATE_COMPANY,
        inputData.data,
        inputData.moduleId
      );
      if (response?.isSuccess) {
        dispatch(
          setToastContent({
            type: ToastType.success,
            message: response.message,
          })
        );
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);

export const getDashboardDetails = createAsyncThunk(
  "dashboard",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.POST,
        ROUTES.DASHBOARD_DETAILS,
        inputData,
        ""
      );
      if (response?.isSuccess) {
        dispatch(
          setToastContent({
            type: ToastType.success,
            message: response.message,
          })
        );
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);

export const companyThunks = {
  companyData,
  updateCompanyData,
  getDashboardDetails,
};
