import React from "react";
import Header from "../../components/header/Header";
import Menu from "../../components/sidebar/Menu";
import Footer from "../../components/footer/Footer";
import { useSelector } from "../../store";
import ToastMessage from "../../components/Toast";
import Breadcrumb from "../../components/header/Breadcrumb";

const MainLayout = ({ children,breadcrumb }) => {
  const toastData = useSelector((state) => state.toast);
  console.log("here main layour");
  return (
    <div>
      <Header />
      <Menu />
      <div className="content-wrapper">
        <Breadcrumb breadcrumb={breadcrumb}/>
        {children}
      </div>
      <ToastMessage toastData={toastData} />
      <Footer />
    </div>
  );
};

export default MainLayout;
