import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch } from "../../store";
import { useParams } from "react-router";
import { insuranceThunks } from "../../store/reducers/insurance/insurance.thunk";
import { parseCustomDate } from "../employee/Personal";
import Datepicker from "../../components/datepicker";

const WorksmanCompensation = ({ activeTab, moduleId, employeeData }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [worksmanType,setWorksmanType] = useState([])


  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
  } = useFormik({
    enableReinitialize: true,
    onSubmit: async (values) => {
      const formattedValues = {
        ...values,
        worksmanPolicyExpiry: values.worksmanPolicyExpiry && parseCustomDate(values.worksmanPolicyExpiry),
      };
      await updateWorkmanComponsationApi(formattedValues);
    },
    initialValues: {
      worksmanMedicalInsuranceProvider: employeeData.insurance
        ? employeeData.insurance.worksmanMedicalInsuranceProvider
        : null,
      worksmanPolicyNumber: employeeData.insurance
        ? employeeData.insurance.worksmanPolicyNumber
        : null,
      worksmanPolicyExpiry: employeeData.insurance
        ? employeeData.insurance.worksmanPolicyExpiry
        : null,
      worksmanPolicyType: employeeData.insurance
        ? employeeData.insurance.worksmanPolicyType
        : null,
      worksmanPolicyCoverage: employeeData.insurance
        ? employeeData.insurance.worksmanPolicyCoverage
        : null,
    },
  });


  useEffect(()=>{
    getWorksmanTypeApi()
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const updateWorkmanComponsationApi = async (data) => {
    try {
      const response = await dispatch(
        insuranceThunks.updateInsuranceDetails({
          id,
          data,
          moduleId,
          activeTab,
        })
      );
      if (insuranceThunks.updateInsuranceDetails.fulfilled.match(response)) {
       return response
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const getWorksmanTypeApi = async () => {
    try {
      const response = await dispatch(insuranceThunks.worksmanTypeList(moduleId));
      if (insuranceThunks.worksmanTypeList.fulfilled.match(response)) {
        if (response.payload) {
          setWorksmanType(response.payload);
        }
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  return (
    <form
      className="form-horizontal"
      id="education_info"
      onSubmit={handleSubmit}
    >
      <div className="card-body">
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">
            Worksman Insurance Provider Name
          </label>
          <div className="col-sm-5  col-md-5">
            <input
              type="text"
              className="form-control"
              name="worksmanMedicalInsuranceProvider"
              id="worksmanMedicalInsuranceProvider"
              placeholder="Worksman Insurance Provider Name"
              value={values.worksmanMedicalInsuranceProvider}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Worksman Policy Number</label>
          <div className="col-sm-5  col-md-5">
            <input
              type="text"
              className="form-control"
              name="worksmanPolicyNumber"
              id="worksmanPolicyNumber"
              placeholder="Worksman Policy Number"
              value={values.worksmanPolicyNumber || null}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Expiry</label>
          <div className="col-sm-5  col-md-5">
            <Datepicker
              name="worksmanPolicyExpiry"
              id="worksmanPolicyExpiry"
              value={values.worksmanPolicyExpiry}
              dateFormat="yyyy-mm-dd"
              onChange={(date) =>
                setValues({
                  ...values,
                  worksmanPolicyExpiry: parseCustomDate(date.target.value),
                })
              }
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Type</label>
          <div className="col-sm-5  col-md-5">
            <select
              className="form-control"
              name="worksmanPolicyType"
              id="worksmanPolicyType"
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option>Select</option>
              {worksmanType.map((data) => {
                return (
                  <option
                    key={data.slug}
                    selected={data.slug === values.worksmanPolicyType}
                    value={data.slug}
                  >
                    {data.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Coverage</label>
          <div className="col-sm-5  col-md-5">
            <input
              type="text"
              className="form-control"
              name="worksmanPolicyCoverage"
              id="worksmanPolicyCoverage"
              placeholder="Link to download"
              value={values.worksmanPolicyCoverage}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <button
          type="submit"
          className="btn btn-primary"
          style={{ padding: "8px 20px" }}
        >
          <i className="fa fa-floppy-o" style={{ marginRight: "8px" }} />
          Update
        </button>
      </div>
    </form>
  );
};

export default WorksmanCompensation;
