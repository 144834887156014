import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "../../store";
import { ThemeColor } from "../../constants/themeColorConstant";
import { packageThunks } from "../../store/reducers/package/package.thunk";
import Datepicker from "../../components/datepicker";
import { parseCustomDate } from "../employee/Personal";
import { useNavigate, useParams } from "react-router-dom";


const validationSchema = Yup.object().shape({
  basic: Yup.string().required("Please select status"),
  hra: Yup.string().required("please enter hra"),
  otherAllowance: Yup.string().required("please enter other allowance"),
  salaryRevisedOn: Yup.string().required("please enter salary revisied on"),
});

const Package = ({ moduleId, employeeData }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [modeOfTransferList, setModeOfTransferList] = useState([]);

  const navigate = useNavigate();


  const {
    errors,
    touched,
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
  } = useFormik({
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const formattedValues = {
        ...values,
        salaryRevisedOn: values.salaryRevisedOn && parseCustomDate(values.salaryRevisedOn),
      };

      await updatePackageDetailsApi(formattedValues);
    },
    initialValues: {
      basic: employeeData && employeeData.package ? employeeData.package.basic : "",
      hra: employeeData && employeeData.package ? employeeData.package.hra : "",
      otherAllowance: employeeData && employeeData.package
        ? employeeData.package.otherAllowance
        : "",
      transport: employeeData && employeeData.package ? employeeData.package.transport : null,
      telephone: employeeData && employeeData.package ? employeeData.package.telephone : null,
      vehicleAllowance: employeeData && employeeData.package ? employeeData.package.vehicleAllowance : null,
      petrol: employeeData && employeeData.package ? employeeData.package.petrol : null,
      incentives: employeeData && employeeData.package ? employeeData.package.incentives : null,
      airfare: employeeData && employeeData.package ? employeeData.package.airfare : null,
      modeOfTransfer: employeeData && employeeData.package
        ? employeeData.package.modeOfTransfer
        : null,
      molValue: employeeData && employeeData.package ? employeeData.package.molValue : null,
      salaryRevisedOn: employeeData && employeeData.package
        ? employeeData.package.salaryRevisedOn
        : "",
    },

  });


  useEffect(() => {
    modeOfTransferListApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updatePackageDetailsApi = async (data) => {
    try {
      const response = await dispatch(
        packageThunks.updatePackageDetails({ id, data, moduleId })
      );
      if (packageThunks.updatePackageDetails.fulfilled.match(response)) {
        navigate("/package");
        return response;
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };


  const modeOfTransferListApi = async () => {
    try {
      const response = await dispatch(
        packageThunks.modeOfTransferList({ id, moduleId })
      );
      if (packageThunks.modeOfTransferList.fulfilled.match(response)) {
        if (response.payload) {
          setModeOfTransferList(response.payload);
          return response.payload;
        }
      }
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };

  return (
    <form
      className="form-horizontal"
      id="education_info"
      onSubmit={handleSubmit}
    >
      <div className="card-body">
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">
            Basic <span style={{ color: ThemeColor.RED }}>*</span>
          </label>
          <div className="col-sm-5  col-md-5">
            <input
              type="text"
              className="form-control"
              name="basic"
              id="basic"
              placeholder="0.00"
              value={values.basic}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.basic && touched.basic && (
              <div className="error" style={{ color: ThemeColor.RED }}>
                {errors.basic}
              </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">
            HRA <span style={{ color: ThemeColor.RED }}>*</span>
          </label>
          <div className="col-sm-5  col-md-5">
            <input
              type="text"
              className="form-control"
              name="hra"
              id="hra"
              placeholder="0.00"
              value={values.hra}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.hra && touched.hra && (
              <div className="error" style={{ color: ThemeColor.RED }}>
                {errors.hra}
              </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">
            Other Allowance <span style={{ color: ThemeColor.RED }}>*</span>
          </label>
          <div className="col-sm-5  col-md-5">
            <input
              type="text"
              className="form-control"
              name="otherAllowance"
              id="otherAllowance"
              placeholder="0.00"
              value={values.otherAllowance}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.otherAllowance && touched.otherAllowance && (
              <div className="error" style={{ color: ThemeColor.RED }}>
                {errors.otherAllowance}
              </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Transport</label>
          <div className="col-sm-5  col-md-5">
            <input
              type="text"
              className="form-control"
              name="transport"
              id="transport"
              placeholder="0.00"
              value={values.transport}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Telephone</label>
          <div className="col-sm-5  col-md-5">
            <input
              type="text"
              className="form-control"
              name="telephone"
              id="telephone"
              placeholder="0.00"
              value={values.telephone}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Vehicle Allowance</label>
          <div className="col-sm-5  col-md-5">
            <input
              type="text"
              className="form-control"
              name="vehicleAllowance"
              id="vehicleAllowance"
              placeholder="0.00"
              value={values.vehicleAllowance}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Petrol</label>
          <div className="col-sm-5  col-md-5">
            <input
              type="text"
              className="form-control"
              name="petrol"
              id="petrol"
              placeholder="0.00"
              value={values.petrol}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Incentives</label>
          <div className="col-sm-5  col-md-5">
            <input
              type="text"
              className="form-control"
              name="incentives"
              id="incentives"
              placeholder="0.00"
              value={values.incentives}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Air Fare</label>
          <div className="col-sm-5  col-md-5">
            <input
              type="text"
              className="form-control"
              name="airfare"
              id="airfare"
              placeholder="0.00"
              value={values.airfare}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">
            Mode Of Transfer
          </label>
          <div className="col-sm-5  col-md-5">
            <select
              className="form-control"
              name="modeOfTransfer"
              id="modeOfTransfer"
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option>Select</option>
              {modeOfTransferList.map((data, index) => {
                return (
                  <option key={index} value={data.slug} selected={data.slug === values.modeOfTransfer}>
                    {data.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">MOL Value</label>
          <div className="col-sm-5  col-md-5">
            <input
              type="text"
              className="form-control"
              name="molValue"
              id="molValue"
              placeholder="0.00"
              value={values.molValue}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">
            Salary Revised On <span style={{ color: ThemeColor.RED }}>*</span>
          </label>
          <div className="col-sm-5  col-md-5">
            <Datepicker
              name="salaryRevisedOn"
              id="salaryRevisedOn"
              value={values.salaryRevisedOn}
              dateFormat="dd-mm-yyyy"
              onChange={(date) =>
                setValues({
                  ...values,
                  salaryRevisedOn: parseCustomDate(date.target.value),
                })
              }
            />

            {errors.salaryRevisedOn && touched.salaryRevisedOn && (
              <div className="error" style={{ color: ThemeColor.RED }}>
                {errors.salaryRevisedOn}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <button
          type="submit"
          className="btn btn-primary"
          style={{ padding: "8px 20px" }}
        >
          <i className="fa fa-floppy-o" style={{ marginRight: "8px" }} />
          Update
        </button>
      </div>
    </form>
  );
};

export default Package;
