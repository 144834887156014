import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { employeeThunks } from "../../store/reducers/employee/employee.thunk";
import { useDispatch } from "../../store";
import { ThemeColor } from "../../constants/themeColorConstant";
import MainLayout from "../layout/MainLayout";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { planningThunk } from "../../store/reducers/planning/planning.thunk";
import { clientThunks } from "../../store/reducers/client/client.thunk";
import { projectThunks } from "../../store/reducers/projects/project.thunk";

const validationSchema = Yup.object().shape({
  employeeId: Yup.string().required("Please select employee"),
  currentProjectId: Yup.string().required("Please select current project"),
  startDate : Yup.string().required("Please select start date"),  
  lastWorkingDay : Yup.string().required("Please select last working date") ,
  leavePlannedDate :Yup.string().required("Please select leave planned date"),
  returnDate :Yup.string().required("Please select return date"),
  plannedReplacementId1 : Yup.string().required("Please select planned replacement 1"),
  clientId : Yup.string().required("Please select client"),
  isOpen : Yup.string().required("Please select status")

});

const AddPlanning = () => {
  const [activeTab, setActiveTab] = useState("add");
  const [employeeList,setEmployeeList] = useState([])
  const [clientList,setClientList] = useState([])
  const [projectList,setProjectList] = useState([])
 
  const location = useLocation();
  const { moduleId } = location.state || {};
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    resetForm,
    setFieldValue,
  } = useFormik({
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
        console.log({values})
      await addPlanningApi(values);
      resetForm();
    },
    initialValues: {
        employeeId :"",
        currentProjectId:"",
        startDate:"",
        lastWorkingDay:"",
        leavePlannedDate:"",
        returnDate:"",
        plannedReplacementId1:"",
        clientId:"",
        isOpen:"",
    },
  });


  const loginClickHandle = (e) => {
    e.preventDefault();
    e.persist();
    handleSubmit(e);
  };

  useEffect(()=>{
    getEmployeeListApi()
    getClientListApi()
    getProjectApi()
  },[])

  const addPlanningApi = async (data) => {
    console.log({data})
    try {
      const response = await dispatch(
        planningThunk.addPlanning({ data, moduleId })
      );
      if (planningThunk.addPlanning.fulfilled.match(response)) {
        navigate("/planning");
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const getEmployeeListApi = async () => {
    try {
      const response = await dispatch(
        employeeThunks.employeeList(moduleId)
      );
      if (employeeThunks.employeeList.fulfilled.match(response)) {
        if (response.payload) {
          setEmployeeList(response.payload);
        }
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };
  const getClientListApi = async () => {
    try {
      const response = await dispatch(
        clientThunks.clientList(moduleId)
      );
      if (clientThunks.clientList.fulfilled.match(response)) {
        if (response.payload) {
            setClientList(response.payload);
        }
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };
  const getProjectApi = async () => {
    try {
      const response = await dispatch(
        projectThunks.projectList(moduleId)
      );
      if (projectThunks.projectList.fulfilled.match(response)) {
        if (response.payload) {
            setProjectList(response.payload);
        }
      }
    } catch (error) {
      console.error("Error fetching project list:", error);
    }
  };

  const breadcrumb = {
    mainHeader: "Planning",
    subHeader: "Home",
    subHeaderLink: "/dashboard",
    subHeader1: "Planning",
    subHeader1Link: "/planning",
    subHeader2: "add",
    subHeader2Link: null,
  };

  return (
    <>
      <MainLayout breadcrumb={breadcrumb}>
        <div className="card card-info">
          <div className="card-body">
            <ul
              className="nav nav-tabs"
              style={{
                borderBottom: `2px solid ${ThemeColor.GREY}`,
                backgroundColor: "transparent",
              }}
            >
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    activeTab === "add" ? "active" : ""
                  }`}
                  onClick={() => handleTabChange("add")}
                  style={{
                    borderBottom: `3px solid ${
                      activeTab === "add" ? ThemeColor.BLUE : "transparent"
                    }`,
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    backgroundColor: "transparent",
                  }}
                >
                  Add
                </button>
              </li>
            </ul>
            <form
              className="form-horizontal"
              id="personal_info"
              onSubmit={handleSubmit}
            >
              <div className="card-body">
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">
                    Employee  <span style={{ color: ThemeColor.RED }}>*</span>
                  </label>
                  <div className="col-sm-5  col-md-5">
                     <Select
                      placeholder="Select Employee"
                      isSearchable
                      id="employeeId"
                      name="employeeId"
                      onChange={(selectedOption) => {
                        setFieldValue(
                          "employeeId",
                          selectedOption ? selectedOption.value : ""
                        );
                      }}
                      options={employeeList.map((data) => ({
                        value: data.uid,
                        label:  data.employee_code + " - " + data.name ,
                      }))}
                    />
                    {errors.employeeId && touched.employeeId && (
                      <div className="error" style={{ color: ThemeColor.RED }}>
                        {errors.employeeId}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">
                    Current Project  <span style={{ color: ThemeColor.RED }}>*</span>
                  </label>
                  <div className="col-sm-5  col-md-5">
                     <Select
                      placeholder="Select Project"
                      isSearchable
                      id="currentProjectId"
                      name="currentProjectId"
                      onChange={(selectedOption) => {
                        setFieldValue(
                          "currentProjectId",
                          selectedOption ? selectedOption.value : ""
                        );
                      }}
                      options={projectList.map((data) => ({
                        value: data.uid,
                        label: data.project_name,
                      }))}
                    />
                    {errors.currentProjectId && touched.currentProjectId && (
                      <div className="error" style={{ color: ThemeColor.RED }}>
                        {errors.currentProjectId}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Start Date <span style={{ color: ThemeColor.RED }}>*</span>
                  </label>
                  <div className="col-sm-5  col-md-5">
                    <input
                      type="date"
                      className="form-control"
                      name="startDate"
                      id="startDate"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                     {errors.startDate && touched.startDate && (
                      <div className="error" style={{ color: ThemeColor.RED }}>
                        {errors.startDate}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Last Working Day <span style={{ color: ThemeColor.RED }}>*</span>
                  </label>
                  <div className="col-sm-5  col-md-5">
                    <input
                      type="date"
                      className="form-control"
                      name="lastWorkingDay"
                      id="lastWorkingDay"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                     {errors.lastWorkingDay && touched.lastWorkingDay && (
                      <div className="error" style={{ color: ThemeColor.RED }}>
                        {errors.lastWorkingDay}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Leave Planned Date <span style={{ color: ThemeColor.RED }}>*</span>
                  </label>
                  <div className="col-sm-5  col-md-5">
                    <input
                      type="date"
                      className="form-control"
                      name="leavePlannedDate"
                      id="leavePlannedDate"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                     {errors.leavePlannedDate && touched.leavePlannedDate && (
                      <div className="error" style={{ color: ThemeColor.RED }}>
                        {errors.leavePlannedDate}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Return Date <span style={{ color: ThemeColor.RED }}>*</span>
                  </label>
                  <div className="col-sm-5  col-md-5">
                    <input
                      type="date"
                      className="form-control"
                      name="returnDate"
                      id="returnDate"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                     {errors.returnDate && touched.returnDate && (
                      <div className="error" style={{ color: ThemeColor.RED }}>
                        {errors.returnDate}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">
                    Planned Replacement 1  <span style={{ color: ThemeColor.RED }}>*</span>
                  </label>
                  <div className="col-sm-5  col-md-5">
                     <Select
                      placeholder="Select Employee"
                      isSearchable
                      id="plannedReplacementId1"
                      name="plannedReplacementId1"
                      onChange={(selectedOption) => {
                        setFieldValue(
                          "plannedReplacementId1",
                          selectedOption ? selectedOption.value : ""
                        );
                      }}
                      options={employeeList.map((data) => ({
                        value: data.uid,
                        label:  data.employee_code + " - " + data.name,
                      }))}
                    />
                    {errors.plannedReplacementId1 && touched.plannedReplacementId1 && (
                      <div className="error" style={{ color: ThemeColor.RED }}>
                        {errors.plannedReplacementId1}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">
                    Planned Replacement 2 
                  </label>
                  <div className="col-sm-5  col-md-5">
                     <Select
                      placeholder="Select Employee"
                      isSearchable
                      id="plannedReplacementId2"
                      name="plannedReplacementId2"
                      onChange={(selectedOption) => {
                        setFieldValue(
                          "plannedReplacementId2",
                          selectedOption ? selectedOption.value : ""
                        );
                      }}
                      options={employeeList.map((data) => ({
                        value: data.uid,
                        label:  data.employee_code + " - " + data.name,
                      }))}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Client <span style={{ color: ThemeColor.RED }}>*</span>
                   </label>
                  <div className="col-sm-5  col-md-5">
                    <Select
                      placeholder="Select Client"
                      isSearchable
                      id="clientId"
                      name="clientId"
                      onChange={(selectedOption) => {
                        setFieldValue(
                          "clientId",
                          selectedOption ? selectedOption.value : ""
                        );
                      }}
                      options={clientList.map((data) => ({
                        value: data.uid,
                        label: data.name,
                      }))}
                    />
                    {errors.clientId && touched.clientId && (
                      <div className="error" style={{ color: ThemeColor.RED }}>
                        {errors.clientId}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Planned Project</label>
                  <div className="col-sm-5  col-md-5">
                     <Select
                      placeholder="Select Project"
                      isSearchable
                      id="plannedProjectId"
                      name="plannedProjectId"
                      onChange={(selectedOption) => {
                        setFieldValue(
                          "plannedProjectId",
                          selectedOption ? selectedOption.value : ""
                        );
                      }}
                      options={projectList.map((data) => ({
                        value: data.uid,
                        label: data.project_name,
                      }))}
                    />
                  </div>
                </div>
                <div className="form-group row">
          <label className="col-sm-4 col-md-3">Status</label>
          <div className="col-sm-5  col-md-5">
            <div className="d-flex" style={{ gap: "5px" }}>
              <input
                style={{ width: "20px" }}
                name="isOpen"
                id="isOpen"
                type="radio"
                value="true"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              Open
              <input
                style={{ width: "20px", marginLeft: "10px" }}
                type="radio"
                name="isOpen"
                id="isOpen"
                value="false"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              Close
            </div>
            {errors.isOpen && touched.isOpen && (
              <div className="error" style={{ color: ThemeColor.RED }}>
                {errors.isOpen}
              </div>
            )}
          </div>
        </div>
              </div>
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ gap: "10px", marginTop: "30px" }}
              >
                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{
                    padding: "8px 20px",
                    width: "120px",
                  }}
                  onClick={(e) => loginClickHandle(e)}
                >
                  <i
                    className="fa fa-floppy-o"
                    style={{ marginRight: "8px" }}
                  />
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-default"
                  style={{
                    padding: "8px 20px",
                    width: "120px",
                  }}
                  onClick={()=>{navigate("/planning")}}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default AddPlanning;
