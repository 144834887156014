import React, { useEffect, useState } from "react";
import Datatable from "../../components/datatable";
import { useDispatch } from "../../store";
import { ThemeColor } from "../../constants/themeColorConstant";
import { useLocation, useNavigate } from "react-router-dom";
import MainLayout from "../layout/MainLayout";
import { planningThunk } from "../../store/reducers/planning/planning.thunk";

const Planning = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [planningData, setPlanningData] = useState([]);
  const [option, setOption] = useState({});

  const location = useLocation();
  const pathnameSegments = location.pathname.split("/");
  const moduleName = pathnameSegments[1];

  const allowedModules = JSON.parse(localStorage.getItem("allowedModules"));
  const selectedModule = allowedModules.find(
    (module) => module.slug === moduleName
  );

  const moduleId = selectedModule.uid;

  const renderActions = (rowData) => {
    if (rowData && rowData.uid) {
    const planningId = rowData.uid;
    if (rowData.isOpen) {
    const editIcon = (
      <i
        className="fa fa-edit fa-lg"
        style={{ cursor: "pointer" }}
        onClick={() => navigate(`/planning/edit/${planningId}`, { state: { moduleId } })}
      />
    );
    
    return (
      <div>
        {editIcon}
      </div>
    );
    }}
    else{
      return null
    }
  };

  const columns = [
    {
      name: "action",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const rowData = planningData[dataIndex];
          return renderActions(rowData);
        },
      },
    },
    {
      name: "employeeCode",
      label: "Employee Code",
    },
    {
      name: "employeeName",
      label: "Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "designation",
      label: "Designation",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "projectName",
      label: "Project",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "planStartDate",
      label: "Plan start date",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "lastWorkingDay",
      label: "Last working day",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "vacationPlanned",
      label: "vacation planned",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "returnFromLeave",
      label: "Return from leave",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "plannedReplacement1",
      label: "Planned Replacement1",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "plannedReplacement2",
      label: "Planned Replacement2",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "vehicle",
      label: "Vehicle",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "remark",
      label: "Remark",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "visaExpiry",
      label: "Visa expiry",
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  useEffect(() => {
     getPlanningListApi()
  }, []);

    const getPlanningListApi = async (page=1,rowsPerPage=10,searchText='',name='',order='') => {
      try {
        const response = await dispatch(planningThunk.planningList({moduleId,page,rowsPerPage,searchText,name,order}));
        if (planningThunk.planningList.fulfilled.match(response)) {
          if (response.payload) {
            const options = {
              count: response.payload.totalRecords,
              page: response.payload.currentPage-1, // Zero-based index
              rowsPerPage:response.payload.recordPerPage,
            }
            setOption(options)
            setPlanningData(response.payload.data);
            return response.payload
          }
        }
      } catch (error) {
        console.error("Error fetching client data:", error);
      }
    };

  const breadcrumb = {
    mainHeader: "Planning Manage",
    subHeader: "Home",
    subHeaderLink: "/dashboard",
    subHeader1: "listing",
    subHeader1Link: null,
    subHeader2: null,
    subHeader2Link: null,
  };

  return (
    <MainLayout breadcrumb={breadcrumb}>
      <div className="card">
        <div className="card-body">
          <div className="card-header ">
            <p
              className="card-title"
              style={{
                fontSize: "20px",
                fontWeight: "500",
                color: ThemeColor.EASTERNBLUE,
              }}
            >
              Planning Manage
            </p>
            <div className="d-flex justify-content-end">
              <button
                onClick={() =>
                  navigate("/planning/add", { state: { moduleId } })
                }
                className="btn btn-primary"
              >
                Add
              </button>

            </div>
          </div>
          <Datatable
            columns={columns}
            data={planningData}
            options={option}
            customBodyRender={renderActions}
            api={getPlanningListApi}
          />
        </div>
      </div>
    </MainLayout>
  );
};

export default Planning;
