import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "../../store";
import { ThemeColor } from "../../constants/themeColorConstant";
import MainLayout from "../layout/MainLayout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Datepicker from "../../components/datepicker";
import { parseCustomDate } from "../employee/Personal";
import { vehicleThunks } from "../../store/reducers/vehicleMatrix/vehicle.thunk";
import { projectThunks } from "../../store/reducers/projects/project.thunk";
import Select from "react-select";

const validationSchema = Yup.object().shape({
  vehicleId: Yup.string().required("Please select vehicle"),
});

const EditVehicle = () => {
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState("editVehicle");
  const [vehicleData,setVehicleData] = useState([])  
  const [projectListData,setProjectListData] = useState([])
  const [vehicleListData,setVehicleListData] = useState([])

  const location = useLocation();
  const { moduleId } = location.state || {};
  const { id } = useParams();

  const dispatch = useDispatch();

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
  } = useFormik({
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      delete values.projectIdObj;
        const formattedValues = {
            ...values,
            fromDate: values.fromDate && parseCustomDate(values.fromDate),
            toDate: values.toDate && parseCustomDate(values.toDate),
          };
          await updateVehicleApi(formattedValues);
      
    },
    initialValues: {
        vehicleId : vehicleData && vehicleData.vehicle  ? vehicleData.vehicle.uid : "",
        description : vehicleData ? vehicleData.description : null,
        projectId :  vehicleData.project ? vehicleData.project.uid : "",
        projectIdObj: vehicleData.project ? vehicleData.project : null,
        fromDate : vehicleData ? vehicleData.fromDate : null,
        toDate : vehicleData ? vehicleData.toDate : null,
    },
  });

  useEffect(() => {
    getProjectApi()
    getVehicleDataApi()
    getVehicleListApi()
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loginClickHandle = (e) => {
    e.preventDefault();
    e.persist();
    handleSubmit(e);
  };

  const getVehicleDataApi = async () => {
    try {
      const response = await dispatch(vehicleThunks.vehicleDetails({id,moduleId}));
      if (vehicleThunks.vehicleDetails.fulfilled.match(response)) {
        if (response.payload) {
          let project = {};
          let res = response.payload;
          if(res && res.project){
          project.value =  res.project.uid;
          project.label = res.project.project_name;
          res.project = project
        }
          setVehicleData(res);
        }
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

    const updateVehicleApi = async (data) => {
      try {
        const response = await dispatch(
          vehicleThunks.updateVehicle({ id,data, moduleId })
        );
        if (vehicleThunks.updateVehicle.fulfilled.match(response)) {
          navigate("/vehicle-matrix")
        }
      } catch (error) {
        console.error("Error fetching employee data:", error);
      }
    };

  const getProjectApi = async () => {
    try {
      const response = await dispatch(
        projectThunks.projectList(moduleId)
      );
      if (projectThunks.projectList.fulfilled.match(response)) {
        if (response.payload) {
          setProjectListData(response.payload);
        }
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const getVehicleListApi = async () => {
    try {
      const response = await dispatch(
        vehicleThunks.vehicleList(moduleId)
      );
      if (vehicleThunks.vehicleList.fulfilled.match(response)) {
        if (response.payload) {
          setVehicleListData(response.payload);
        }
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const breadcrumb = {
    mainHeader: "Vehicle Matrix",
    subHeader: "Home",
    subHeaderLink: "/dashboard",
    subHeader1: "Vehicle-Matrix",
    subHeader1Link: "/vehicle-matrix",
    subHeader2: "edit",
    subHeader2Link: null,
  };


  return (
    <>
      <MainLayout breadcrumb={breadcrumb}>
        <div className="card card-info">
          <div className="card-body">
            <ul
              className="nav nav-tabs"
              style={{
                borderBottom: `2px solid ${ThemeColor.GREY}`,
                backgroundColor: "transparent",
              }}
            >
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    activeTab === "editVehicle" ? "active" : ""
                  }`}
                  onClick={() => handleTabChange("editVehicle")}
                  style={{
                    borderBottom: `3px solid ${
                      activeTab === "editVehicle" ? ThemeColor.BLUE : "transparent"
                    }`,
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    backgroundColor: "transparent",
                  }}
                >
                  Edit Vehicle
                </button>
              </li>
            </ul>
            <form
              className="form-horizontal"
              id="personal_info"
              onSubmit={handleSubmit}
            >
              <div className="card-body">
              <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Vehicle <span style={{ color: ThemeColor.RED }}>*</span></label>
                  <div className="col-sm-5  col-md-5">
                    <select
                      className="form-control"
                      name="vehicleId"
                      id="vehicleId"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option>Select</option>
                      {vehicleListData.map((data, index) => {
                        return (
                          <option key={index} value={data.uid}  selected={data.uid === values.vehicleId}>
                            {data.vehicle_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Description</label>
                  <div className="col-sm-5  col-md-5">
                    <textarea
                      id="description"
                      name="description"
                      cols={2}
                      rows={2}
                      value={values.description}
                      className="form-control"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    ></textarea>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Project</label>
                  <div className="col-sm-5  col-md-5">
                <Select
                placeholder="Select Project"
                isSearchable
                id="project"
                name="project"
                onChange={(selectedOption) => {
                  setValues({
                    ...values,
                    projectIdObj: selectedOption,
                    projectId: selectedOption.value,
                  })
                }}
                value={
                  values.projectIdObj
                }
                options={projectListData.map((project) => ({
                  value: project.uid,
                  label: project.project_name,
                }))}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">From Date</label>
                  <div className="col-sm-5  col-md-5">
                    <Datepicker
                      name="fromDate"
                      id="fromDate"
                      value={values.fromDate}
                      dateFormat="yyyy-mm-dd"
                      onChange={(date) =>
                        setValues({
                          ...values,
                          fromDate: parseCustomDate(date.target.value),
                        })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">To Date</label>
                  <div className="col-sm-5  col-md-5">
                    <Datepicker
                      name="toDate"
                      id="toDate"
                      value={values.toDate}
                      dateFormat="yyyy-mm-dd"
                      onChange={(date) =>
                        setValues({
                          ...values,
                          toDate: parseCustomDate(date.target.value),
                        })
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-center">
              <button
                type="submit"
                className="btn btn-primary"
                style={{ padding: "8px 20px" }}
                onClick={(e) => loginClickHandle(e)}
              >
                <i className="fa fa-edit" style={{ marginRight: "8px" }} />
                Update
              </button>
              <button
                type="button"
                className="btn btn-default"
                style={{ padding: "8px 20px", marginLeft: "10px" }}
                onClick={()=>{
                  navigate("/vehicle-matrix")
                }}
              >
                Cancel
              </button>
            </div>
            </form>
          </div>
          {/* <ToastMessage toastData={toastData} /> */}
        </div>
      </MainLayout>
    </>
  );
};

export default EditVehicle;
