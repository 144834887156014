import React, { useEffect, useState } from "react";
import Datatable from "../../components/datatable";
import { useDispatch } from "../../store";
import { ThemeColor } from "../../constants/themeColorConstant";
import { useLocation, useNavigate } from "react-router-dom";
import MainLayout from "../layout/MainLayout";
import { gratuityThunks } from "../../store/reducers/gratuity/gratuity.thunk";
import { employeeThunks } from "../../store/reducers/employee/employee.thunk";
import Select from "react-select";
import { payrollThunks } from "../../store/reducers/payroll/payroll.thunk";

const ViewPayslip = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [payslipData, setPayslipData] = useState([]);
  const [selectedMonthYear, setSelectedMonthYear] = useState("");
  const [option, setOption] = useState({});
 

  const location = useLocation();
  const pathnameSegments = location.pathname.split("/");
  const moduleName = pathnameSegments[1];

  const allowedModules = JSON.parse(localStorage.getItem("allowedModules"));
  const selectedModule = allowedModules.find(
    (module) => module.slug === moduleName
  );

  const moduleId = selectedModule.uid;

 

  const columns = [
    {
      name: "viewPaySlip",
      label: "View Payslip",
    },
    {
      name: "empCode",
      label: "emp code",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
        name: "name",
        label: "name",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "designation",
        label: "designation",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "officeEmail",
        label: "office email",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "emailStatus",
        label: "email status",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "sentBy",
        label: "sent by",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "sendOn",
        label: "sent on",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "month",
        label: "Month",
        options: {
          filter: false,
          sort: true,
        },
      },
    
  ];

  const handleMonthYearChange = async (event) => {
    const selectedDate = event.target.value;
    setSelectedMonthYear(selectedDate);
    viewPaySlipListApi()
  };
  const viewPaySlipListApi = async (page=1,rowsPerPage=10,searchText='',name='',order='') => {
    try {
        const response = await dispatch(payrollThunks.viewPaySlipList({ selectedMonthYear, moduleId, page, rowsPerPage, searchText, name, order }));
        if (payrollThunks.viewPaySlipList.fulfilled.match(response)) {
          if (response.payload) {
            const options = {
              count: response.payload.totalRecords,
              page: response.payload.currentPage - 1, // Zero-based index
              rowsPerPage: response.payload.recordPerPage,
            }
            setOption(options)
            setPayslipData(response.payload.data);
            return response.payload;
          }
        }
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };

  const breadcrumb = {
    mainHeader: "Manage Payslip",
    subHeader: "Home",
    subHeaderLink: "/dashboard",
    subHeader1: "Payslip View",
    subHeader1Link: null,
    subHeader2: null,
    subHeader2Link: null,
  };

  return (
    <MainLayout breadcrumb={breadcrumb}>
      <div className="card">
        <div className="card-body">
          <div className="form-group row d-flex justify-content-center mt-3">
              <div className="col-sm-4  col-md-4">
              <input type="month"
              name="month"
              id="month"
              className="form-control"
              value={selectedMonthYear}
              onChange={handleMonthYearChange}
            />
            </div>
          </div>
          
          <Datatable columns={columns} data={payslipData} options={option} />
        </div>
      </div>
    </MainLayout>
  );
};

export default ViewPayslip;
