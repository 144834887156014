import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ThemeColor } from '../../constants/themeColorConstant';
import Datatable from '../../components/datatable';
import { projectCostingThunk } from '../../store/reducers/projectCosting/projectCosting.thunk';
import { useDispatch } from '../../store';
import MainLayout from '../layout/MainLayout';
import SubcontractorTotalExpense from './projectExpenseDetails/SubcontractorTotalExpense';


const ProjectDetails = ({ moduleId }) => {

  const navigate = useNavigate()
  const [projectDetailsData, setProjectDetailsData] = useState()
  const [options, setOption] = useState()
  const dispatch = useDispatch()
  const { id } = useParams();


  const renderActions = (rowData) => {

    if (rowData && rowData.projectId) {
      const expenseId = rowData.projectId;

      const editIcon = (
        <i
          className="fa fa-edit"
          style={{ cursor: "pointer" }}
          onClick={() =>
            navigate(`/project-costing/project-expense/${expenseId}`, { state: { moduleId } })
          }
        />
      );

      return (
        <div>
          {editIcon}
        </div>
      );
    }
    else {
      return null
    }
  };

  const columns = [
    {
      name: "viewDetails",
      label: "View Details",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const rowData = projectDetailsData[dataIndex];
          return renderActions(rowData);
        },
      },
    },
    {
      name: "projectName",
      label: "project name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "totalExpense",
      label: "total amount",
    },
    {
      name: "totalPaidExpense",
      label: "paid amount",
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  useEffect(() => {
    projectWiseSubcontractorApi()
  }, [])

  const projectWiseSubcontractorApi = async (page = 1, rowsPerPage = 10, searchText = '', name = '', order = '') => {
    try {
      const response = await dispatch(projectCostingThunk.projectWiseSubContractor({ id: id, moduleId, page, rowsPerPage, searchText, name, order }));
      if (projectCostingThunk.projectWiseSubContractor.fulfilled.match(response)) {
        if (response.payload) {
          const options = {
            count: response.payload.totalRecords,
            page: response.payload.currentPage - 1, // Zero-based index
            rowsPerPage: response.payload.recordPerPage,
          }
          setOption(options)
          setProjectDetailsData(response.payload.data);
          return response.payload
        }
      }
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };

  const options2 = {
    print: true,
    filter: true,
    download: true,
    viewColumns: true
  }

  const breadcrumb = {
    mainHeader: "Subcontractorwise Expense Details",
    subHeader: "Home",
    subHeaderLink: "/dashboard",
    subHeader1: "subcontractor",
    subHeader1Link: "project-costing/sub-contractor",
    subHeader2: "Subcontractorwise Expense Details",
    subHeader2Link: null,
  };

  return (
    <MainLayout breadcrumb={breadcrumb}>
      <div className="card">
        <SubcontractorTotalExpense />
      </div>
      <div className='card'>
        <div className="card-body">
          <div className="card-header ">
            <p
              className="card-title"
              style={{
                fontSize: "20px",
                fontWeight: "500",
                color: ThemeColor.EASTERNBLUE,
              }}
            >
              Project Details
            </p>
          </div>
          <Datatable
            columns={columns}
            data={projectDetailsData}
            options={options2}
            api={projectWiseSubcontractorApi}
            customBodyRender={renderActions}
          />
        </div>
      </div>
    </MainLayout>
  );
}

export default ProjectDetails;
