import React, { useEffect, useState } from "react";
import { useDispatch } from "../../store";
import MainLayout from "../layout/MainLayout";
import { useLocation, useParams } from "react-router-dom";
import Datatable from "../../components/datatable";
import { leaveThunks } from "../../store/reducers/leave/leave.thunk";
import { ThemeColor } from "../../constants/themeColorConstant";
import { employeeThunks } from "../../store/reducers/employee/employee.thunk";

const LeaveDetails = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("Personal");
  const [leaveData, setLeaveData] = useState([]);
  const [employeeList,setEmployeeList] = useState([])
  const [employeeId, setEmployeeId] = useState(null);
  const location = useLocation();
  const { moduleId } = location.state || {};
  const {id} = useParams()

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  const columns = [
    {
      name: "employeeName",
      label: "NAME",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "leaveType",
      label: "TYPE",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "fromDate",
      label: "FROM DATE",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "toDate",
      label: "TO DATE",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "totalDays",
      label: "Total Days",
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  useEffect(() => {
    getLeaveDetails();
    employeeListApi()
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeId]);

  useEffect(()=>{
    setEmployeeId(id)
  },[])

  
  const getLeaveDetails = async () => {
    try {
      if (employeeId) {
      const response = await dispatch(
        leaveThunks.leaveDetails({ employeeId, moduleId })
      );
      if (leaveThunks.leaveDetails.fulfilled.match(response)) {
        if (response.payload) {
          setLeaveData(response.payload);
        }
      }
    }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };


  const employeeListApi = async () => {
    try {
      const response = await dispatch(
        employeeThunks.employeeList({moduleId })
      );
      if (employeeThunks.employeeList.fulfilled.match(response)) {
        if (response.payload) {
          setEmployeeList(response.payload);
          return response.payload;
        }
      }
    } catch (error) {
      console.error("Error fetching company data:", error);
    }
  };


  const options = {
    count: 10,
  };

  const breadcrumb = {
    mainHeader: "Leave History",
    subHeader: "Home",
    subHeaderLink: "/dashboard",
    subHeader1: "Leave",
    subHeader1Link: "/leaves",
    subHeader2: "Leave History",
    subHeader2Link: null,
  };

  return (
    <MainLayout breadcrumb={breadcrumb}>
        <div className="card">
          <div className="card-body">
          <ul
              className="nav nav-tabs"
              style={{
                borderBottom: `2px solid ${ThemeColor.GREY}`,
                backgroundColor: "transparent",
              }}
            >
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    activeTab === "Personal" ? "active" : ""
                  }`}
                  onClick={() => handleTabChange("Personal")}
                  style={{
                    borderBottom: `3px solid ${
                      activeTab === "Personal" ? ThemeColor.BLUE : "transparent"
                    }`,
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    backgroundColor: "transparent",
                  }}
                >
                  Leave History
                </button>
              </li>
            </ul>
           
           <div className="card-body">
          <div className="form-group row ">
            <label className="col-sm-4 col-md-3">Employee <span style={{ color: ThemeColor.RED }}>*</span></label>
            <div className="col-sm-5  col-md-5">
              <select 
              className="form-control"
               name="employee"
               id="employee" 
               onChange={(event) => setEmployeeId(event.target.value)}>
                <option>Select</option>
                    {employeeList.map((data, index) => {
                        return (
                          <option key={index} value={data.uid} selected={data.uid == id}>
                            {data.name}
                          </option>
                        );
                      })}
              </select>
            </div>
          </div>
          <Datatable columns={columns} data={leaveData} options={options} />
        </div>
         
          </div>
      </div>
      
    </MainLayout>
  );
};

export default LeaveDetails;
