import { createAsyncThunk } from "@reduxjs/toolkit";
import { HTTP_METHODS } from "../../../constants/methodsConstant";
import { ROUTES } from "../../../constants/routesConstant";
import { ToastType, setToastContent } from "../toast/toast.slice";
import { ApiCall } from "../../../utils/api";



export const employeeLoginList = createAsyncThunk(
  "employeeSelfAccess/list",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.GET,
        `${ROUTES.GET_EMPLOYEELOGIN}?page=${inputData.page}&limit=${inputData.rowsPerPage}&search=${inputData.searchText}&sort=${inputData.name}&order=${inputData.order}`,
        {},
        inputData.moduleId
      );
      if (response?.isSuccess) {
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);

export const updateEmployeeStatus = createAsyncThunk(
  "employeeLogin/updateEmployeeStatus",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.PATCH,
        `${ROUTES.GET_EMPLOYEELOGIN}/${inputData.employeeId}/block`,
        inputData.data,
        inputData.moduleId
      );
      if (response?.isSuccess) {
        dispatch(
          setToastContent({
            type: ToastType.success,
            message: response.message,
          })
        );
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);

export const allowEmployeeLogin = createAsyncThunk(
  "employeeLogin/allowEmployeeLogin",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.PATCH,
        `${ROUTES.GET_EMPLOYEELOGIN}/${inputData.employeeId}/login`,
        inputData.data,
        inputData.moduleId
      );
      if (response?.isSuccess) {
        dispatch(
          setToastContent({
            type: ToastType.success,
            message: response.message,
          })
        );
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);

export const updateResetPassowrd = createAsyncThunk(
  "employeeLogin/resetPassword",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.PATCH,
        `${ROUTES.GET_EMPLOYEELOGIN}/${inputData.employeeId}/reset-password`,
        {},
        inputData.moduleId
      );
      if (response?.isSuccess) {
        dispatch(
          setToastContent({
            type: ToastType.success,
            message: response.message,
          })
        );
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);


export const employeeLoginThunk = {
employeeLoginList,
updateEmployeeStatus,
allowEmployeeLogin,
updateResetPassowrd
}