import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "../../store";
import { ThemeColor } from "../../constants/themeColorConstant";
import MainLayout from "../layout/MainLayout";
import { useLocation, useNavigate } from "react-router-dom";
import { vehicleThunks } from "../../store/reducers/vehicleMatrix/vehicle.thunk";
import { projectThunks } from "../../store/reducers/projects/project.thunk";
import Select from "react-select";

const validationSchema = Yup.object().shape({
  vehicleId: Yup.string().required("Please select vehicles"),
  projectId: Yup.string().required("Please select vehicles"),

});

const AddVehicle = () => {

  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState("addNew");
  const [projectListData,setProjectListData] = useState([])
  const [vehicleListData,setVehicleListData] = useState([])


  const location = useLocation();
  const { moduleId } = location.state || {};

  const dispatch = useDispatch();

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue
  } = useFormik({
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      await addVehicleApi(values);
      
    },
    initialValues: {
      vehicleId:"",
      projectId:""
    },
  });

  useEffect(() => {
    getProjectApi()
    getVehicleListApi()
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loginClickHandle = (e) => {
    e.preventDefault();
    e.persist();
    handleSubmit(e);
  };

    const addVehicleApi = async (data) => {
      try {
        const response = await dispatch(
          vehicleThunks.addVehicle({ data, moduleId })
        );
        if (vehicleThunks.addVehicle.fulfilled.match(response)) {
          navigate("/vehicle-matrix")
        }
      } catch (error) {
        console.error("Error fetching employee data:", error);
      }
    };

    const getProjectApi = async () => {
      try {
        const response = await dispatch(
          projectThunks.projectList(moduleId)
        );
        if (projectThunks.projectList.fulfilled.match(response)) {
          if (response.payload) {
            setProjectListData(response.payload);
          }
        }
      } catch (error) {
        console.error("Error fetching employee data:", error);
      }
    };

    const getVehicleListApi = async () => {
      try {
        const response = await dispatch(
          vehicleThunks.vehicleList(moduleId)
        );
        if (vehicleThunks.vehicleList.fulfilled.match(response)) {
          if (response.payload) {
            setVehicleListData(response.payload);
          }
        }
      } catch (error) {
        console.error("Error fetching employee data:", error);
      }
    };

  const breadcrumb = {
    mainHeader: "Vehicle Matrix",
    subHeader: "Home",
    subHeaderLink: "/dashboard",
    subHeader1: "Vehicle-Matrix",
    subHeader1Link: "/vehicle-matrix",
    subHeader2: "add",
    subHeader2Link: null,
  };

  return (
    <>
      <MainLayout breadcrumb={breadcrumb}>
        <div className="card card-info">
          <div className="card-body">
            <ul
              className="nav nav-tabs"
              style={{
                borderBottom: `2px solid ${ThemeColor.GREY}`,
                backgroundColor: "transparent",
              }}
            >
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    activeTab === "addNew" ? "active" : ""
                  }`}
                  onClick={() => handleTabChange("addNew")}
                  style={{
                    borderBottom: `3px solid ${
                      activeTab === "addNew" ? ThemeColor.BLUE : "transparent"
                    }`,
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    backgroundColor: "transparent",
                  }}
                >
                  Add New
                </button>
              </li>
            </ul>
            <form
              className="form-horizontal"
              id="personal_info"
              onSubmit={handleSubmit}
            >
              <div className="card-body">
              <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Vehicle <span style={{ color: ThemeColor.RED }}>*</span></label>
                  <div className="col-sm-5  col-md-5">
                     <Select
                      placeholder="Select Vehicle"
                      isSearchable
                      id="vehicleId"
                      name="vehicleId"
                      onChange={(selectedOption) => {
                        setFieldValue(
                          "vehicleId",
                          selectedOption ? selectedOption.value : ""
                        );
                      }}
                      options={vehicleListData.map((data) => ({
                        value: data.uid,
                        label: data.vehicle_name,
                      }))}
                    />
                    {errors.vehicleId && touched.vehicleId && (
                      <div className="error" style={{ color: ThemeColor.RED }}>
                        {errors.vehicleId}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Description</label>
                  <div className="col-sm-5  col-md-5">
                    <textarea
                      id="description"
                      name="description"
                      cols={2}
                      rows={2}
                      value={values.description}
                      className="form-control"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    ></textarea>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Project <span style={{ color: ThemeColor.RED }}>*</span></label>
                  <div className="col-sm-5  col-md-5">
                     <Select
                      placeholder="Select Project"
                      isSearchable
                      id="projectId"
                      name="projectId"
                      onChange={(selectedOption) => {
                        setFieldValue(
                          "projectId",
                          selectedOption ? selectedOption.value : ""
                        );
                      }}
                      options={projectListData.map((data) => ({
                        value: data.uid,
                        label: data.project_name,
                      }))}
                    />
                    {errors.projectId && touched.projectId && (
                      <div className="error" style={{ color: ThemeColor.RED }}>
                        {errors.projectId}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">From Date</label>
                  <div className="col-sm-5  col-md-5">
                    <input
                    type="date"
                    className="form-control"
                    name="fromDate"
                    id="fromDate"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">To Date</label>
                  <div className="col-sm-5  col-md-5">
                  <input
                    type="date"
                    name="toDate"
                    id="toDate"
                    className="form-control"
                    onChange={handleChange}
                    onBlur={handleBlur}
                   />
                  </div>
                </div>
              </div>

              <div
                className="d-flex align-items-center justify-content-center"
                style={{ gap: "10px", marginTop: "30px" }}
              >
                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{
                    padding: "8px 20px",
                    width: "120px",
                  }}
                  onClick={(e) => loginClickHandle(e)}
                >
                  <i
                    className="fa fa-floppy-o"
                    style={{ marginRight: "8px" }}
                  />
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-default"
                  style={{
                    padding: "8px 20px",
                    width: "120px",
                  }}
                  onClick={()=>{navigate("/vehicle-matrix")}}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
          {/* <ToastMessage toastData={toastData} /> */}
        </div>
      </MainLayout>
    </>
  );
};

export default AddVehicle;
