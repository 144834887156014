import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ThemeColor } from "../../constants/themeColorConstant";
import { useParams } from "react-router-dom";
import { employeeThunks } from "../../store/reducers/employee/employee.thunk";
import { useDispatch } from "../../store";
import { emailRegex } from "../../constants/methodsConstant";



const validationSchema = Yup.object().shape({
  permanentAddress: Yup.string().required("Please enter permanent address"),
  officeEmail : Yup.string()
  .email()
  .required('Please enter valid email id')
  .matches(emailRegex, 'Please enter valid email')
  .trim(),
  personalEmail : Yup.string()
  .email()
  .required('Please enter valid email id')
  .matches(emailRegex, 'Please enter valid email')
  .trim(),
});

const Contact = ({activeTab,moduleId,employeeData}) => {

  const { id } = useParams();
  const dispatch = useDispatch()
  const {
    values,
    errors,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
  } = useFormik({
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async(values) => {
     await updateContactDataApi(values)
    },
    initialValues: {
      permanentAddress: employeeData ? employeeData.permanentAddress : "",
      officeEmail:employeeData ? employeeData.officeEmail : "",
      personalEmail: employeeData ? employeeData.personalEmail :"",
      presentAddress : employeeData ? employeeData.presentAddress : null,
      mobileNumber: employeeData ? employeeData.mobileNumber : null,
      secondaryNumber : employeeData ? employeeData.secondaryNumber :null,
      homeTownNumber: employeeData ? employeeData.homeTownNumber :null,
      emergencyContact: employeeData ? employeeData.emergencyContact :null,
      otherNumber:employeeData ? employeeData.otherNumber :null,
      phoneNumber:employeeData ? employeeData.phoneNumber :null,
      alternateNumber:employeeData ? employeeData.alternateNumber :null,
      whatsAppNumber:employeeData ? employeeData.whatsAppNumber :null,
      skypeId:employeeData ? employeeData.skypeId :null,
      facebookId:employeeData ? employeeData.facebookId :null,
      linkedinId:employeeData ? employeeData.linkedinId :null,
      twitterId : employeeData ? employeeData.twitterId :null
    },
  });

  
  const updateContactDataApi = async (data) => {
    try {
      const response = await dispatch(employeeThunks.updateEmployee({id,data,moduleId,activeTab}));
      if (employeeThunks.updateEmployee.fulfilled.match(response)) {
            return response.payload
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };


  return (
    <form className="form-horizontal" id="contact_info" onSubmit={handleSubmit}>
      <div className="card-body">
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">
            Permanent Address <span style={{ color: ThemeColor.RED }}>*</span>
          </label>
          <div className="col-sm-5  col-md-5">
            <textarea
              id="permanentAddress"
              name="permanentAddress"
              cols={2}
              rows={2}
              className="form-control"
              value={values.permanentAddress}
              onChange={handleChange}
              onBlur={handleBlur}
            ></textarea>
             {errors.permanentAddress && touched.permanentAddress && (
              <div className="error" style={{ color: ThemeColor.RED }}>
                {errors.permanentAddress}
              </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Present Address</label>
          <div className="col-sm-5  col-md-5">
            <textarea
              id="presentAddress"
              name="presentAddress"
              cols={2}
              rows={2}
              value={values.presentAddress}
              className="form-control"
              onChange={handleChange}
              onBlur={handleBlur}
            ></textarea>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Mobile Number</label>
          <div className="col-sm-5  col-md-5">
            <input
              type="text"
              className="form-control"
              name="mobileNumber"
              id="mobileNumber"
              placeholder="Mobile number"
              value={values.mobileNumber}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Secondary Number</label>
          <div className="col-sm-5  col-md-5">
            <input
              type="text"
              className="form-control"
              name="secondaryNumber"
              id="secondaryNumber"
              placeholder="Secondary number"
              value={values.secondaryNumber}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Home Town</label>
          <div className="col-sm-5  col-md-5">
            <input
              type="text"
              className="form-control"
              name="homeTownNumber"
              id="homeTownNumber"
              placeholder="Home Town"
              value={values.homeTownNumber}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Emergency Contact</label>
          <div className="col-sm-5  col-md-5">
            <input
              type="text"
              className="form-control"
              name="emergencyContact"
              id="emergencyContact"
              placeholder="Emergency contact"
             value={values.emergencyContact}
             onChange={handleChange}
             onBlur={handleBlur}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Other Number</label>
          <div className="col-sm-5  col-md-5">
            <input
              type="text"
              className="form-control"
              name="otherNumber"
              id="otherNumber"
              placeholder="Other number"
              value={values.otherNumber}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Phone Number</label>
          <div className="col-sm-5  col-md-5">
            <input
              type="text"
              className="form-control"
              name="phoneNumber"
              id="phoneNumber"
              placeholder="Phone number"
             value={values.phoneNumber}
             onChange={handleChange}
             onBlur={handleBlur}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Alternative Number</label>
          <div className="col-sm-5  col-md-5">
            <input
              type="text"
              className="form-control"
              name="alternateNumber"
              id="alternateNumber"
              placeholder="Alternative number"
              value={values.alternateNumber}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">
            Office Email <span style={{ color: ThemeColor.RED }}>*</span>
          </label>
          <div className="col-sm-5  col-md-5">
            <input
              type="text"
              className="form-control"
              name="officeEmail"
              id="officeEmail"
              placeholder=" Office Email"
              value={values.officeEmail}
              onChange={handleChange}
              onBlur={handleBlur}

            />
            {errors.officeEmail && touched.officeEmail && (
              <div className="error" style={{ color: ThemeColor.RED }}>
                {errors.officeEmail}
              </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">
            Personal Email <span style={{ color: ThemeColor.RED }}>*</span>
          </label>
          <div className="col-sm-5  col-md-5">
            <input
              type="text"
              className="form-control"
              name="personalEmail"
              id="personalEmail"
              placeholder="Personal Email"
              value={values.personalEmail}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.personalEmail && touched.personalEmail && (
              <div className="error" style={{ color: ThemeColor.RED }}>
                {errors.personalEmail}
              </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Skype Id</label>
          <div className="col-sm-5  col-md-5">
            <input
              type="text"
              className="form-control"
              name="skypeId"
              id="skypeId"
              placeholder="Skype Id"
              value={values.skypeId}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Whatsapp</label>
          <div className="col-sm-5  col-md-5">
            <input
              type="text"
              className="form-control"
              name="whatsAppNumber"
              id="whatsAppNumber"
              placeholder="Whatsapp"
              value={values.whatsAppNumber}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Facebook Id</label>
          <div className="col-sm-5  col-md-5">
            <input
              type="text"
              className="form-control"
              name="facebookId"
              id="facebookId"
              placeholder="Facebook Id"
              value={values.facebookId}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">LinkedIn Id</label>
          <div className="col-sm-5  col-md-5">
            <input
              type="text"
              className="form-control"
              name="linkedinId"
              id="linkedinId"
              placeholder="LinkedIn Id"
              value={values.linkedinId}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Twitter Id</label>
          <div className="col-sm-5  col-md-5">
            <input
              type="text"
              className="form-control"
              name="twitterId"
              id="twitterId"
              placeholder="Twitter Id"
              value={values.twitterId}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <button
          type="submit"
          className="btn btn-primary"
          style={{ padding: "8px 20px" }}
        >
          <i className="fa fa-floppy-o" style={{ marginRight: "8px" }} />
          Update
        </button>
      </div>
    </form>
  );
};

export default Contact;
