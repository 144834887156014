import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dob:null,
  dateOfJoining:null,
  gratuityDate:null,
  startExperienceDate:null,
  leftDate:null,
  uid:null,
  employeeCode:null,
  name:null,
  gender:null,
  designationId:null,
  departmentId:null,
  experience:null,
  currentProjectId:null,
  status:null,
  sendPayslip:null,
  photo:null
};

const employeeSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {
    setEmployee: (state,action) => {
      const payload = action.payload;
      state.dob = payload.dob
      state.dateOfJoining = payload.dateOfJoining
      state.departmentId = payload.departmentId
      state.designationId = payload.designationId
      state.employeeCode = payload.employeeCode
      state.experience = payload.experience
      state.gender = payload.gender
      state.gratuityDate = payload.gratuityDate
      state.name = payload.name
      state.leftDate = payload.leftDate
      state.startExperienceDate = payload.startExperienceDate
      state.currentProjectId = payload.currentProjectId
      state.sendPayslip = payload.sendPayslip
      state.status = payload.status
      state.photo = payload.photo
      state.uid = payload.uid

    },
    updateEmployee :(state,action)=>{
      const payload = action.payload;
      state.dob = payload.dob
      state.dateOfJoining = payload.dateOfJoining
      state.departmentId = payload.departmentId
      state.designationId = payload.designationId
      state.employeeCode = payload.employeeCode
      state.experience = payload.experience
      state.gender = payload.gender
      state.gratuityDate = payload.gratuityDate
      state.name = payload.name
      state.leftDate = payload.leftDate
      state.startExperienceDate = payload.startExperienceDate
      state.currentProjectId = payload.currentProjectId
      state.sendPayslip = payload.sendPayslip
      state.status = payload.status
      state.photo = payload.photo
      state.uid = payload.uid
    }
  },
});

export const {setEmployee,updateEmployee} = employeeSlice.actions;

export default employeeSlice.reducer;