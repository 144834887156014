import { createAsyncThunk } from "@reduxjs/toolkit";
import { HTTP_METHODS } from "../../../constants/methodsConstant";
import { ROUTES } from "../../../constants/routesConstant";
import { ApiCall } from "../../../utils/api";
import { ToastType, setToastContent } from "../toast/toast.slice";


export const addClient = createAsyncThunk(
  "client/addClient",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.POST,
        ROUTES.CREATE_CLIENT,
        inputData.data,
        inputData.moduleId
      );
      if (response?.isSuccess) {
        dispatch(
          setToastContent({
            type: ToastType.success,
            message: response.message,
          })
        );

        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);

export const getClient = createAsyncThunk(
  "client/getClient",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.GET,
        ROUTES.GET_CLIENT+`/${inputData.id}`,
        {},
        inputData.moduleId
      );
      if (response?.isSuccess) {
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);

export const updateClient = createAsyncThunk(
  "client/updateClient",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.PATCH,
        ROUTES.UPDATE_CLIENT+`/${inputData.id}`,
        inputData.data,
        inputData.moduleId
      );
      if (response?.isSuccess) {
        dispatch(
          setToastContent({
            type: ToastType.success,
            message: response.message,
          })
        );
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);


export const clientData = createAsyncThunk(
  "client/list",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.GET,
        `${ROUTES.GET_CLIENT}?page=${inputData.page}&limit=${inputData.rowsPerPage}&search=${inputData.searchText}&sort=${inputData.name}&order=${inputData.order}`,
        {},
        inputData.moduleId
      );
      if (response?.isSuccess) {
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);

export const getDesciplineList = createAsyncThunk(
  "employee/desciplineList",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.GET,
        ROUTES.GET_DESCIPLINES,
        {},
        inputData
      );
      if (response?.isSuccess) {
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);
export const getBusinessType = createAsyncThunk(
  "employee/businessTypeList",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.GET,
        ROUTES.GET_BUSINESSTYPES,
        {},
        inputData
      );
      if (response?.isSuccess) {
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);
export const getLegalStatus = createAsyncThunk(
  "employee/legalStatusList",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.GET,
        ROUTES.GET_LEGALSTATUS,
        {},
        inputData
      );
      if (response?.isSuccess) {
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);
export const getMarketingStatus = createAsyncThunk(
  "employee/marketStatusList",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.GET,
        ROUTES.GET_MARKETINGSTATUS,
        {},
        inputData
      );
      if (response?.isSuccess) {
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);
export const getCurrentStatus = createAsyncThunk(
  "employee/marketStatusList",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.GET,
        ROUTES.GET_CURRENTSTATUS,
        {},
        inputData
      );
      if (response?.isSuccess) {
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);
export const getRating = createAsyncThunk(
  "employee/ratingList",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.GET,
        ROUTES.GET_RATING,
        {},
        inputData
      );
      if (response?.isSuccess) {
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);

export const clientList = createAsyncThunk(
  "client/list",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.GET,
        ROUTES.GET_CLIENT+'/list',
        {},
        inputData.moduleId
      );
      if (response?.isSuccess) {
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);

export const deleteClient = createAsyncThunk(
  "employee/deleteClient",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.DELETE,
        ROUTES.GET_CLIENT + `/${inputData.id}`,
        {},
        inputData.moduleId
      );
      if (response?.isSuccess) {
        dispatch(
          setToastContent({
            type: ToastType.success,
            message: response.message,
          })
        );
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);

export const clientContactList = createAsyncThunk(
  "client/contact list",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.GET,
        `${ROUTES.CLIENT_CONTACT_LIST}/${inputData.selectedClient}`,
        {},
        inputData.moduleId
      );
      if (response?.isSuccess) {
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);

export const clientDesignationList = createAsyncThunk(
  "employee/designationList",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.GET,
        ROUTES.CLIENT_DESIGNATION,
        {},
        inputData
      );
      if (response?.isSuccess) {
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);

export const clientContactData = createAsyncThunk(
  "client/getClient",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.GET,
        ROUTES.CLIENT_CONTACT+`/${inputData.id}`,
        {},
        inputData.moduleId
      );
      if (response?.isSuccess) {
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);

export const updateClientContact = createAsyncThunk(
  "client/updateClientContact",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.PATCH,
        `${ROUTES.CLIENT_CONTACT}/${inputData.id}`,
        inputData.data,
        inputData.moduleId
      );
      if (response?.isSuccess) {
        dispatch(
          setToastContent({
            type: ToastType.success,
            message: response.message,
          })
        );
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);

export const addClientContact = createAsyncThunk(
  "client/updateClientContact",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.POST,
        ROUTES.CLIENT_CONTACT,
        inputData.data,
        inputData.moduleId
      );
      if (response?.isSuccess) {
        dispatch(
          setToastContent({
            type: ToastType.success,
            message: response.message,
          })
        );
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);


export const clientThunks = {
  clientData,
  addClient,
  getClient,
  updateClient,
  getDesciplineList,
  getBusinessType,
  getLegalStatus,
  getMarketingStatus,
  getCurrentStatus,
  getRating,
  clientList,
  deleteClient,
  clientContactList,
  clientDesignationList,
  clientContactData,
  updateClientContact,
  addClientContact
};
