import React, { useEffect, useState } from "react";
import { authorityTab } from "../../data/data";
import { ThemeColor } from "../../constants/themeColorConstant";
import { useDispatch } from "../../store";
import MainLayout from "../layout/MainLayout";
import { useLocation, useParams } from "react-router-dom";
import PassportDetails from "./PassportDetails";
import ImmigrationDetails from "./ImmigrationDetails";
import LaborDepartment from "./LaborDepartment";
import { authorityThunks } from "../../store/reducers/authority/authority.thunk";
import { images } from "../../assets/asset";

const EditAuthority = () => {
  const [activeTab, setActiveTab] = useState("passport-details");
  const dispatch = useDispatch();

  const { id } = useParams();
  const [employeeData, setEmployeeData] = useState([]);
  const location = useLocation();
  const { moduleId } = location.state || {};

  const handleTabChange = (tab) => {
    setActiveTab(tab.slug);
  };

  useEffect(() => {
    getAuthorityDetailsApi();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const getAuthorityDetailsApi = async () => {
    const data = {
      id,
      activeTab,
      moduleId,
    };
    try {
      const response = await dispatch(authorityThunks.authoritiesDetails(data));
      if (authorityThunks.authoritiesDetails.fulfilled.match(response)) {
        if (response.payload) {
          setEmployeeData(response.payload);
        }
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };


  const breadcrumb = {
    mainHeader: "Authority Master",
    subHeader: "Home",
    subHeaderLink: "/dashboard",
    subHeader1: "Authority Master",
    subHeader1Link: "/authority",
    subHeader2: "edit",
    subHeader2Link: null,
  };

  return (
    <MainLayout breadcrumb={breadcrumb}>
      <div className="card">
        <div className="card-body" style={{ padding: "20px" }}>
          <div className="form-group row col-lg-12">
            <div className="col-sm-3 col-md-3">
              <img
               src={employeeData && employeeData.photo!=null ? employeeData.photo : images.profileImage}
                alt="profile"
                //width="165px"
                height="165px"
              />
            </div>
            <div className="col-sm-9 col-md-9">
              <div className="form-group row">
                <div className="col-sm-3 col-md-3 ">
                  <label>
                    <i className="fa fa-user" /> Name :
                  </label>
                </div>
                <div className="col-sm-3 col-md-3">
                  <p>{employeeData.name}</p>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-3 col-md-3">
                  <label>
                    <i className="fa fa-bolt" /> Status :
                  </label>
                </div>
                <div className="col-sm-3 col-md-3">
                  <p>{employeeData.status}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card card-info">
        <div className="card-body">
          <ul
            className="nav nav-tabs"
            style={{
              borderBottom: `2px solid ${ThemeColor.GREY}`,
              backgroundColor: "transparent",
            }}
          >
            {authorityTab.map((tab) => (
              <li className="nav-item" key={tab.id}>
                <button
                  className={`nav-link ${
                    activeTab === tab.slug ? "active" : ""
                  }`}
                  onClick={() => handleTabChange(tab)}
                  style={{
                    borderBottom: `3px solid ${
                      activeTab === tab.slug ? ThemeColor.BLUE : "transparent"
                    }`,
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    backgroundColor: "transparent",
                  }}
                >
                  {tab.name}
                </button>
              </li>
            ))}
          </ul>
          {activeTab === "passport-details" && (
            <PassportDetails
              employeeData={employeeData}
              moduleId={moduleId}
              activeTab={activeTab}
            />
          )}
          {activeTab === "immigration-details" && (
            <ImmigrationDetails
              moduleId={moduleId}
              employeeData={employeeData}
              activeTab={activeTab}
            />
          )}
          {activeTab === "labor-department-details" && (
            <LaborDepartment
              moduleId={moduleId}
              employeeData={employeeData}
              activeTab={activeTab}
            />
          )}
        </div>
      </div>
    </MainLayout>
  );
};

export default EditAuthority;
