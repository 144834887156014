import { createAsyncThunk } from "@reduxjs/toolkit";
import { HTTP_METHODS } from "../../../constants/methodsConstant";
import { ROUTES } from "../../../constants/routesConstant";
import { ToastType, setToastContent } from "../toast/toast.slice";
import { ApiCall } from "../../../utils/api";

export const getPriorityData = createAsyncThunk(
  "companies/priority/get-data",
  async (bodyPayload, { dispatch }) => {
    console.log("Payload ###", bodyPayload);
    try {
      const response = await ApiCall(
        HTTP_METHODS.POST,
        `${ROUTES.GET_PRIORITY}?page=${bodyPayload.page}&limit=${bodyPayload.rowsPerPage}&search=${bodyPayload.searchText}&sort=${bodyPayload.name}&order=${bodyPayload.order}`,
        { columnsToSelect: bodyPayload.columnsToSelect },
        bodyPayload.moduleId
      );

      if (response?.isSuccess) {
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);

export const getPriorityColumns = createAsyncThunk(
  "companies/priority/get-column-list",
  async (bodyPayload, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.GET,
        `${ROUTES.GET_PRIORITY_COLUMNS_LIST}`,
        null,
        bodyPayload.moduleId
      );

      if (response?.isSuccess) {
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);

export const priorityThunks = {
  getPriorityData,
  getPriorityColumns,
};
