import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "../../store";
import { ThemeColor } from "../../constants/themeColorConstant";
import MainLayout from "../layout/MainLayout";
import { useLocation, useNavigate} from "react-router-dom";
import { assetThunks } from "../../store/reducers/asset/asset.thunk";
import { projectThunks } from "../../store/reducers/projects/project.thunk";
import { employeeThunks } from "../../store/reducers/employee/employee.thunk";
import Select from "react-select";

const validationSchema = Yup.object().shape({
  assetCode: Yup.string().required("Please enter asset code"),
  manufacturer : Yup.string().required("please enter manufacturer")

    
});

const AddAssets = () => {
  const [activeTab, setActiveTab] = useState("Add Asset");
  const [employeeList, setEmployeeList] = useState([]);
  const [statusData,setStatusData] = useState([])
  const [typeData,setTypeData] = useState([])
  const [projectData,setProjectData] = useState([])
 

  const location = useLocation();
  const { moduleId } = location.state || {};
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const {
    errors,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    setFieldValue
  } = useFormik({
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      await addAssetApi(values);
    },
    initialValues: {
      assetCode :"",
      manufacturer:""
    },
  });


 useEffect(()=>{
  employeeListApi()
  getStatusApi()
  getTypesApi()
  getProjectApi()
   // eslint-disable-next-line react-hooks/exhaustive-deps
 },[])

  const addAssetApi = async (data) => {
    try {
      const response = await dispatch(
        assetThunks.addAsset({ data, moduleId })
      );
      if (assetThunks.addAsset.fulfilled.match(response)) {
          navigate("/assets")
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };


  const employeeListApi = async () => {
    try {
      const response = await dispatch(
        employeeThunks.employeeList({moduleId })
      );
      if (employeeThunks.employeeList.fulfilled.match(response)) {
        if (response.payload) {
          setEmployeeList(response.payload);
          return response.payload;
        }
      }
    } catch (error) {
      console.error("Error fetching company data:", error);
    }
  };

  const getStatusApi = async () => {
    try {
      const response = await dispatch(
        assetThunks.statusList(moduleId)
      );
      if (assetThunks.statusList.fulfilled.match(response)) {
        if (response.payload) {
          setStatusData(response.payload);
        }
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const getTypesApi = async () => {
    try {
      const response = await dispatch(
        assetThunks.typeList(moduleId)
      );
      if (assetThunks.typeList.fulfilled.match(response)) {
        if (response.payload) {
          setTypeData(response.payload);
        }
      }
    } catch (error) {
      console.error("Error fetching type data:", error);
    }
  };

  const getProjectApi = async () => {
    try {
      const response = await dispatch(
        projectThunks.projectList(moduleId)
      );
      if (projectThunks.projectList.fulfilled.match(response)) {
        if (response.payload) {
          setProjectData(response.payload);
        }
      }
    } catch (error) {
      console.error("Error fetching project list:", error);
    }
  };

  
  const breadcrumb = {
    mainHeader: "Asset Edit",
    subHeader: "Home",
    subHeaderLink: "/dashboard",
    subHeader1: "Asset",
    subHeader1Link: "/assets",
    subHeader2: "edit",
    subHeader2Link: null,
  };

  return (
    <MainLayout breadcrumb={breadcrumb}>
      <div className="card card-info">
        <div className="card-body">
          <ul
            className="nav nav-tabs"
            style={{
              borderBottom: `2px solid ${ThemeColor.GREY}`,
              backgroundColor: "transparent",
            }}
          >
            <li className="nav-item">
              <button
                className={`nav-link ${
                  activeTab === "Add Asset" ? "active" : ""
                }`}
                onClick={() => handleTabChange("Add Asset")}
                style={{
                  borderBottom: `3px solid ${
                    activeTab === "Add Asset"
                      ? ThemeColor.BLUE
                      : "transparent"
                  }`,
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  backgroundColor: "transparent",
                }}
              >
               Add Asset
              </button>
            </li>
          </ul>
          <form
            className="form-horizontal"
            id="asset_info"
            onSubmit={handleSubmit}
          >
            <div className="card-body">
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">
                  Asset Code <span style={{ color: ThemeColor.RED }}>*</span>
                </label>
                <div className="col-sm-5  col-md-5">
                  <input
                    type="text"
                    className="form-control"
                    name="assetCode"
                    id="assetCode"
                    placeholder="Asset Code"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.assetCode && touched.assetCode && (
                    <div className="error" style={{ color: ThemeColor.RED }}>
                      {errors.assetCode}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">Manufacturer  <span style={{ color: ThemeColor.RED }}>*</span></label>
                <div className="col-sm-5  col-md-5">
                  <input
                    type="text"
                    className="form-control"
                    name="manufacturer"
                    id="manufacturer"
                    placeholder="Manufacturer"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                   {errors.manufacturer && touched.manufacturer && (
                    <div className="error" style={{ color: ThemeColor.RED }}>
                      {errors.manufacturer}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">Serial Number</label>
                <div className="col-sm-5  col-md-5">
                  <input
                    type="text"
                    className="form-control"
                    name="serialNumber"
                    id="serialNumber"
                    placeholder="Serial Number"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">Purchase Date</label>
                <div className="col-sm-5  col-md-5">
                   <input
                      type="date"
                      className="form-control"
                      name="purchaseDate"
                      id="purchaseDate"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">Type</label>
                <div className="col-sm-5  col-md-5">
                   <Select
                      placeholder="Select Type"
                      isSearchable
                      id="type"
                      name="type"
                      onChange={(selectedOption) => {
                        setFieldValue(
                          "type",
                          selectedOption ? selectedOption.value : ""
                        );
                      }}
                      options={typeData.map((data) => ({
                        value: data.slug,
                        label: data.name,
                      }))}
                    />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">Status</label>
                <div className="col-sm-5  col-md-5">
                   <Select
                      placeholder="Select Status"
                      isSearchable
                      id="status"
                      name="status"
                      onChange={(selectedOption) => {
                        setFieldValue(
                          "status",
                          selectedOption ? selectedOption.value : ""
                        );
                      }}
                      options={statusData.map((data) => ({
                        value: data.slug,
                        label: data.name,
                      }))}
                    />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">Description</label>
                <div className="col-sm-5  col-md-5">
                  <textarea
                    id="description"
                    name="description"
                    cols={2}
                    rows={2}
                    className="form-control"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  ></textarea>
                </div>
              </div>
             
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">Software Name</label>
                <div className="col-sm-5  col-md-5">
                  <input
                    type="text"
                    className="form-control"
                    name="softwareName"
                    id="softwareName"
                    placeholder="Software Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </div>
            
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">Installed Date</label>
                <div className="col-sm-5  col-md-5">
                    <input
                      type="date"
                      className="form-control"
                      name="installedDate"
                      id="installedDate"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">Expiry Date</label>
                <div className="col-sm-5  col-md-5">
                   <input
                      type="date"
                      className="form-control"
                      name="expiryDate"
                      id="expiryDate"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">Project</label>
                <div className="col-sm-5  col-md-5">
                   <Select
                      placeholder="Select Project"
                      isSearchable
                      id="projectId"
                      name="projectId"
                      onChange={(selectedOption) => {
                        setFieldValue(
                          "projectId",
                          selectedOption ? selectedOption.value : ""
                        );
                      }}
                      options={projectData.map((data) => ({
                        value: data.uid,
                        label: data.project_name,
                      }))}
                    />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">Office / Site</label>
                <div className="col-sm-5  col-md-5">
                  <input
                    type="text"
                    className="form-control"
                    name="office"
                    id="office"
                    placeholder="Office / Site"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">Assigned To</label>
                <div className="col-sm-5  col-md-5">
                   <Select
                      placeholder="Select Employee"
                      isSearchable
                      id="assignTo"
                      name="assignTo"
                      onChange={(selectedOption) => {
                        setFieldValue(
                          "assignTo",
                          selectedOption ? selectedOption.value : ""
                        );
                      }}
                      options={employeeList.map((data) => ({
                        value: data.uid,
                        label:  data.employee_code + " - " + data.name,
                      }))}
                    />
                </div>
              </div>
             
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">From Date</label>
                <div className="col-sm-5  col-md-5">
                   <input
                      type="date"
                      className="form-control"
                      name="fromDate"
                      id="fromDate"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                </div>
              </div>
            </div>
            <div
                className="d-flex align-items-center justify-content-center"
                style={{ gap: "10px", marginTop: "30px" }}
              >
                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{
                    padding: "8px 20px",
                    width: "120px",
                   
                  }}
                >
                  <i
                    className="fa fa-floppy-o"
                    style={{ marginRight: "8px" }}
                  />
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-default"
                  style={{
                    padding: "8px 20px",
                    width: "120px",
                  }}
                  onClick={()=>{navigate("/assets")}}
                >
                  Cancel
                </button>
              </div>
          </form>
        </div>
      </div>
    </MainLayout>
  );
};

export default AddAssets;
