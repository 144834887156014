import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "../../store";
import { ThemeColor } from "../../constants/themeColorConstant";
import MainLayout from "../layout/MainLayout";
import { useLocation, useNavigate } from "react-router-dom";
import { gratuityThunks } from "../../store/reducers/gratuity/gratuity.thunk";
import { employeeThunks } from "../../store/reducers/employee/employee.thunk";
import Select from "react-select";

const validationSchema = Yup.object().shape({
  fromDate: Yup.string().required("Please enter from date"),
  toDate: Yup.string().required("Please enter to date"),
});

const AddGratuity = () => {
  const [activeTab, setActiveTab] = useState("addNew");
  const [employeeList, setEmployeeList] = useState([]);

  const navigate = useNavigate()
  const location = useLocation();
  const { moduleId } = location.state || {};

  const dispatch = useDispatch();

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const { handleBlur,
     handleChange, 
     handleSubmit,
     errors,
     values,
     touched,
     setFieldValue,
     } =
    useFormik({
      validationSchema: validationSchema,
      enableReinitialize: true,
      onSubmit: async (values) => {
        await addGratuityApi(values);
      },
      initialValues: {
        fromDate:"",
        toDate:"",
      },
    });

  useEffect(()=>{
    employeeListApi()
  },[])

  const loginClickHandle = (e) => {
    e.preventDefault();
    e.persist();
    handleSubmit(e);
  };
  
  const addGratuityApi = async (data) => {
    try {
      const response = await dispatch(
        gratuityThunks.addGratuity({ data, moduleId })
      );
      if (gratuityThunks.addGratuity.fulfilled.match(response)) {
        navigate("/gratuity")
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const employeeListApi = async () => {
    try {
      const response = await dispatch(
        employeeThunks.gratuityEmployeeList({ gratuity:false,moduleId })
      );
      if (employeeThunks.gratuityEmployeeList.fulfilled.match(response)) {
        if (response.payload) {
          setEmployeeList(response.payload);
          return response.payload;
        }
      }
    } catch (error) {
      console.error("Error fetching company data:", error);
    }
  };

  const breadcrumb = {
    mainHeader: "Manage Gratuity",
    subHeader: "Home",
    subHeaderLink: "/dashboard",
    subHeader1: "Gratuity",
    subHeader1Link: "/gratuity",
    subHeader2: "add",
    subHeader2Link: null,
  };

  return (
    <>
      <MainLayout breadcrumb={breadcrumb}>
        <div className="card card-info">
          <div className="card-body">
            <ul
              className="nav nav-tabs"
              style={{
                borderBottom: `2px solid ${ThemeColor.GREY}`,
                backgroundColor: "transparent",
              }}
            >
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    activeTab === "addNew" ? "active" : ""
                  }`}
                  onClick={() => handleTabChange("addNew")}
                  style={{
                    borderBottom: `3px solid ${
                      activeTab === "addNew" ? ThemeColor.BLUE : "transparent"
                    }`,
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    backgroundColor: "transparent",
                  }}
                >
                  Add Gratuity
                </button>
              </li>
            </ul>
            <form
              className="form-horizontal"
              id="gratuity_info"
              onSubmit={handleSubmit}
            >
              <div className="card-body">
                <div className="form-group row">
                <label className="col-sm-4 col-md-3">Employee</label>
                  <div className="col-sm-5  col-md-5">
                    {/* <select
                      className="form-control"
                      name="employeeId"
                      id="employeeId"
                      onChange={handleChange}

                    >
                      <option>Select Employee</option>
                      {employeeList.map((data, index) => {
                        return (
                          <option key={index} value={data.uid}>
                            {data.name}
                          </option>
                        );
                      })}
                    </select> */}
                     <Select
                      placeholder="Select Employee"
                      isSearchable
                      id="employeeId"
                      name="employeeId"
                      onChange={(selectedOption) => {
                        setFieldValue(
                          "employeeId",
                          selectedOption ? selectedOption.value : ""
                        );
                      }}
                      options={employeeList.map((data) => ({
                        value: data.uid,
                        label:  data.employee_code + " - " + data.name,
                      }))}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">From Date  <span style={{ color: ThemeColor.RED }}>*</span></label>
                  <div className="col-sm-5  col-md-5">
                    <input
                      type="date"
                      name="fromDate"
                      id="fromDate"
                      className="form-control"
                      onChange={handleChange}
                      
                    />
                      {errors.fromDate && touched.fromDate && (
                    <div className="error" style={{ color: ThemeColor.RED }}>
                      {errors.fromDate}
                    </div>
                  )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">To Date  <span style={{ color: ThemeColor.RED }}>*</span></label>
                  <div className="col-sm-5  col-md-5">
                    <input
                      type="date"
                      name="toDate"
                      id="toDate"
                      onChange={handleChange}
                      className="form-control"
                    />
                    {errors.toDate && touched.toDate && (
                    <div className="error" style={{ color: ThemeColor.RED }}>
                      {errors.toDate}
                    </div>
                  )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Round Off</label>
                  <div className="col-sm-5  col-md-5">
                    <input
                      type="text"
                      className="form-control"
                      name="roundOff"
                      id="roundOff"
                      placeholder="0.00"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Reason</label>
                  <div className="col-sm-5  col-md-5">
                    <textarea
                      id="reason"
                      name="reason"
                      cols={2}
                      rows={2}
                      className="form-control"
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Remark</label>
                  <div className="col-sm-5  col-md-5">
                    <textarea
                      id="remark"
                      name="remark"
                      cols={2}
                      rows={2}
                      className="form-control"
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Description</label>
                  <div className="col-sm-5  col-md-5">
                    <textarea
                      id="description"
                      name="description"
                      cols={2}
                      rows={2}
                      className="form-control"
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Attached Forms</label>
                  <div className="col-sm-5  col-md-5">
                    <div className="d-flex" style={{ gap: "5px" }}>
                      <input
                        style={{ width: "20px" }}
                        type="radio"
                        value="true"
                        name="attachedForm"
                        id="attachedForm"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      Yes
                      <input
                        style={{ width: "20px", marginLeft: "10px" }}
                        type="radio"
                        value="false"
                        name="attachedForm"
                        id="attachedForm"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      No
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="d-flex align-items-center justify-content-center"
                style={{ gap: "10px", marginTop: "30px" }}
              >
                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{
                    padding: "8px 20px",
                    width: "120px",
                  }}
                  onClick={(e) => loginClickHandle(e)}
                >
                  <i
                    className="fa fa-floppy-o"
                    style={{ marginRight: "8px" }}
                  />
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-default"
                  style={{
                    padding: "8px 20px",
                    width: "120px",
                  }}
                  onClick={()=>{navigate("/grauity")}}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default AddGratuity;
