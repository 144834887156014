import React from 'react';
import { useFormik } from "formik";
import { useSelector } from '../../store';
import { images } from "../../assets/asset";


const UserProfile = () => {

  const userData = JSON.parse(localStorage.getItem("userData"));


  return (
    <form className="form-horizontal">
    <div className="card-body">
      <div className="form-group row">
        <label className="col-sm-4 col-md-3">Profile</label>
        <div className="col-sm-5  col-md-5">
          <img
              src={ userData && userData.profile!=null ? userData.profile : images.profileImage}
               alt="Employee profile"
                //width="165px"
                height="165px"
            />
        </div>
      </div>
      <div className="form-group row">
          <label className="col-sm-4 col-md-3">
            Name 
          </label>
          <div className="col-sm-5  col-md-5">
            <input
              readOnly
              type="text"
              className="form-control"
              name="name"
              id="name"
              value={userData.name}
              placeholder="Name"
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">
           Email 
          </label>
          <div className="col-sm-5  col-md-5">
            <input
            readOnly
              type="email"
              className="form-control"
              name="email"
              id="email"
              value={userData.email}
              placeholder="email"
            />
          </div>
        </div>
    </div>
  </form>
  );
}

export default UserProfile;
