import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiCall } from "../../../utils/api";
import { ToastType, setToastContent } from "../toast/toast.slice";
import { ROUTES } from "../../../constants/routesConstant";
import { HTTP_METHODS } from "../../../constants/methodsConstant";
import { setEmployee } from "./employeeSlice";

export const addEmployee = createAsyncThunk(
  "employee/addEmployee",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.POST,
        ROUTES.GET_EMPLOYEE,
        inputData.data,
        inputData.moduleId
      );
      if (response?.isSuccess) {
        setEmployee(response.payload);
        dispatch(
          setToastContent({
            type: ToastType.success,
            message: response.message,
          })
        );

        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);

export const addEmployeeProject = createAsyncThunk(
  "employee/addEmployeeProject",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.PATCH,
        `${ROUTES.GET_EMPLOYEE}/${inputData.id}/projects`,
        inputData.data,
        inputData.moduleId
      );
      if (response?.isSuccess) {
        dispatch(
          setToastContent({
            type: ToastType.success,
            message: response.message,
          })
        );
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);

export const employeeDetails = createAsyncThunk(
  "employee/editEmployee",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.GET,
        `${ROUTES.GET_EMPLOYEE}/${inputData.id}/${inputData.activeTab}`,
        {},
        inputData.moduleId
      );
      if (response?.isSuccess) {
        setEmployee(response.payload);
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);

export const employeeListData = createAsyncThunk(
  "employee/employeelist",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.GET,
        `${ROUTES.GET_EMPLOYEE}?page=${inputData.page}&limit=${inputData.rowsPerPage}&search=${inputData.searchText}&sort=${inputData.name}&order=${inputData.order}`,
        {},
        inputData.moduleId
      );
      if (response?.isSuccess) {
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);

export const deleteEmployee = createAsyncThunk(
  "employee/deleteEmployee",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.DELETE,
        ROUTES.GET_EMPLOYEE + `/${inputData.employeeId}`,
        {},
        inputData.moduleId
      );
      if (response?.isSuccess) {
        dispatch(
          setToastContent({
            type: ToastType.success,
            message: response.message,
          })
        );
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);

export const updateEmployee = createAsyncThunk(
  "employee/updateEmployeeForm",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.PATCH,
        `${ROUTES.GET_EMPLOYEE}/${inputData.id}/${inputData.activeTab}`,
        inputData.data,
        inputData.moduleId
      );
      if (response?.isSuccess) {
        setEmployee(response.payload);
        dispatch(
          setToastContent({
            type: ToastType.success,
            message: response.message,
          })
        );
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);

export const getDesignationList = createAsyncThunk(
  "employee/designationList",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.GET,
        ROUTES.GET_DESIGNATION,
        {},
        inputData
      );
      if (response?.isSuccess) {
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);
export const getDepartmentList = createAsyncThunk(
  "employee/departmentList",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.GET,
        ROUTES.GET_DEPARTMENT,
        {},
        inputData
      );
      if (response?.isSuccess) {
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);
export const getBloodGroups = createAsyncThunk(
  "employee/bloodGroupList",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.GET,
        ROUTES.GET_BLOODGROUP,
        {},
        inputData
      );
      if (response?.isSuccess) {
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);
export const getEmployeeStatus = createAsyncThunk(
  "employee/employeeStatus",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.GET,
        ROUTES.EMPLOYEE_STATUS,
        {},
        inputData
      );
      if (response?.isSuccess) {
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);

export const getEmployeeType = createAsyncThunk(
  "employee/employeeType",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.GET,
        ROUTES.EMPLOYEE_TYPES,
        {},
        inputData
      );
      if (response?.isSuccess) {
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);

export const getGender = createAsyncThunk(
  "employee/employeeGender",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.GET,
        ROUTES.GET_GENDER,
        {},
        inputData
      );
      if (response?.isSuccess) {
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);

export const attendanceFilterData = createAsyncThunk(
  "employee/attendanceFilterData",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.GET,
        `${ROUTES.GET_EMPLOYEE}/${inputData.id}/attendance-project-filter-data`,
        {},
        inputData
      );
      if (response?.isSuccess) {
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);

export const employeeList = createAsyncThunk(
  "employee/employeeList",
  async (inputData, { dispatch }) => {
    try {
      let status = inputData.status ? inputData.status : "";
      const response = await ApiCall(
        HTTP_METHODS.GET,
        `${ROUTES.GET_EMPLOYEELIST}?status=${status}`,
        {},
        inputData.moduleId
      );
      if (response?.isSuccess) {
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);

export const gratuityEmployeeList = createAsyncThunk(
  "employee/employeeList",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.GET,
        `${ROUTES.GRATUITY_EMPLOYEE}/?hasGratuity=${inputData.gratuity}`,
        {},
        inputData.moduleId
      );
      if (response?.isSuccess) {
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);

export const employeeVehicleList = createAsyncThunk(
  "employee/employeeVehicleList",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.GET,
        `${ROUTES.EMPLOYEE_VEHICLE}/${inputData.id}`,
        {},
        inputData.moduleId
      );
      if (response?.isSuccess) {
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);

export const updateEmployeeType = createAsyncThunk(
  "employee/updateEmployeeType",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.PATCH,
        `${ROUTES.GET_EMPLOYEE}/${inputData.id}/type`,
        inputData.data,
        inputData.moduleId
      );
      if (response?.isSuccess) {
        dispatch(
          setToastContent({
            type: ToastType.success,
            message: response.message,
          })
        );
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);

export const employeeThunks = {
  addEmployee,
  employeeListData,
  employeeDetails,
  deleteEmployee,
  updateEmployee,
  employeeList,
  getDesignationList,
  getDepartmentList,
  getBloodGroups,
  getEmployeeStatus,
  getGender,
  attendanceFilterData,
  addEmployeeProject,
  employeeVehicleList,
  gratuityEmployeeList,
  updateEmployeeType,
  getEmployeeType,
};
