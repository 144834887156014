import React from 'react'
import { useFormik } from "formik";
import { useDispatch } from '../../store';
import { useParams } from 'react-router';
import { insuranceThunks } from '../../store/reducers/insurance/insurance.thunk';


const OthersDetails = ({activeTab,moduleId,employeeData})=> {
  const dispatch = useDispatch()
  const {id} = useParams()
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    enableReinitialize: true,
    onSubmit: async(values) => {
      await updateOtherDetailsApi(values)
    },
    initialValues: {
      allergies : employeeData.insurance ? employeeData.insurance.allergies : null,
      existingMedicalConditions : employeeData.insurance ? employeeData.insurance.existingMedicalConditions : null,
      existingMedications : employeeData.insurance ? employeeData.insurance.existingMedications : null,
    },
  });

  const updateOtherDetailsApi = async (data) => {
    try {
      const response = await dispatch(insuranceThunks.updateInsuranceDetails({id,data,moduleId,activeTab}));
      if (insuranceThunks.updateInsuranceDetails.fulfilled.match(response)) {
        return response
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  }

  
  return (
    <form className="form-horizontal" id="education_info" onSubmit={handleSubmit}>
    <div className="card-body">
      <div className="form-group row">
        <label className="col-sm-4 col-md-3">Allergies</label>
        <div className="col-sm-5  col-md-5">
          <input
            type="text"
            className="form-control"
            name="allergies"
            id="allergies"
            placeholder="Allergies"
           value={values.allergies}
           onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-sm-4 col-md-3">Existing Medical Conditions</label>
        <div className="col-sm-5  col-md-5">
          <input
            type="text"
            className="form-control"
            name="existingMedicalConditions"
            id="existingMedicalConditions"
            placeholder="Existing Medical Conditions"
           value={values.existingMedicalConditions}
           onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      </div>  
     <div className="form-group row">
        <label className="col-sm-4 col-md-3">Existing Medications</label>
        <div className="col-sm-5  col-md-5">
          <input
            type="text"
            className="form-control"
            name="existingMedications"
            id="existingMedications"
            placeholder="Existing Medications"
           value={values.existingMedications}
           onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      </div>
    </div>
    <div className="d-flex align-items-center justify-content-center">
      <button
        type="submit"
        className="btn btn-primary"
        style={{ padding: "8px 20px" }}
      >
        <i className="fa fa-floppy-o" style={{ marginRight: "8px" }} />
        Update
      </button>
    </div>
  </form>
  )
}

export default OthersDetails