import React, { useEffect, useState } from "react";
import Datatable from "../../components/datatable";
import MainLayout from "../layout/MainLayout";
import { useDispatch } from "../../store";
import { roleThunks } from "../../store/reducers/role/role.thunk";
import { useLocation, useNavigate } from "react-router-dom";
import { ThemeColor } from "../../constants/themeColorConstant";
import ConfirmationBox from "../../components/ConfirmationBox";

const Role = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [roleData, setRoleData] = useState([]);
  const [option, setOption] = useState({})
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [roleIdToDelete, setRoleIdToDelete] = useState(null);
  const [roleIdToUpdateStatus, setRoleIdToUpdateStatus] = useState(null);

  const location = useLocation();
  const pathnameSegments = location.pathname.split('/');
  const moduleName = pathnameSegments[1];
  const allowedModules = JSON.parse(localStorage.getItem("allowedModules"));
  const selectedModule = allowedModules.find((module) => module.slug === moduleName);
  const moduleId = selectedModule.uid

  const renderActions = (rowData) => {
    if (rowData && rowData.uid) {
      const roleId = rowData.uid;
      const editIcon = (
        <i
          className="fa fa-edit"
          style={{ cursor: "pointer" }}
          onClick={() => navigate(`/role/edit/${roleId}`, { state: { moduleId } })}
        />
      );
      const deleteIcon = (
        <i style={{ marginLeft: "10px", cursor: "pointer" }}
          className="fa fa-trash-o"
         onClick={() => {
          setRoleIdToDelete(roleId);
          setShowConfirmation(true);
        }}
        />
      );
  
      return (
        <div>
          {editIcon}
          {deleteIcon}
        </div>
      );
    } else {
      return null; 
    }
  };

  const columns = [
    {
      name: "action",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const rowData = roleData[dataIndex];
          return renderActions(rowData);
        },
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "access",
      label: "Access",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return <div style={{ whiteSpace: "nowrap" }}>{value}</div>;
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const rowData = roleData[tableMeta.rowIndex];
          if (rowData && rowData.uid) {
            const roleId = rowData.uid;
            const statusIcon = value ? (
              <i
                className="fa fa-unlock"
                style={{ cursor: "pointer" }}
                onClick={() => handleConfirmUpdateStatus(roleId, false)}
              />
            ) : (
              <i
                className="fa fa-lock"
                style={{ cursor: "pointer" }}
                onClick={() => handleConfirmUpdateStatus(roleId, true)}
              />
            );
            return statusIcon;
          } else {
            return null;
          }
        },
      },
    },    
  ];

  useEffect(()=>{
    getRoleListApi()
  },[])

  const getRoleListApi = async (page=1,rowsPerPage=10,searchText='',name='',order='') => {
    try {
      const response = await dispatch(roleThunks.roleData({moduleId,page,rowsPerPage,searchText,name,order}));
      if (roleThunks.roleData.fulfilled.match(response)) {
        if (response.payload) {
          const options = {
            count: response.payload.totalRecords,
            page: response.payload.currentPage-1,
            rowsPerPage:response.payload.recordsPerPage,
          }
          setOption(options)
          setRoleData(response.payload.data);
          return response.payload
        }
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await dispatch(roleThunks.deleteRole({roleId:roleIdToDelete,moduleId}));
      if (roleThunks.deleteRole.fulfilled.match(response)) {
        const updatedRoleData = roleData.filter((role) => role && role.uid !== roleIdToDelete);
        setRoleData(updatedRoleData);
        setRoleIdToDelete(null);
        setShowConfirmation(false);
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const handleConfirmUpdateStatus = async (roleId) => {
    setRoleIdToUpdateStatus(roleId);
    setShowConfirmation(true);
  };

  const confirmStatusUpdate = async (status) => {
    try {
      const response = await dispatch(roleThunks.updateStatus({roleId: roleIdToUpdateStatus, moduleId, data: {status: !status } }));
      if (roleThunks.updateStatus.fulfilled.match(response)) {
        const updatedRoleData = roleData.map((role) =>
          role.uid === roleIdToUpdateStatus ? { ...role, status: !status } : role
        );
        setRoleData(updatedRoleData);
        setRoleIdToUpdateStatus(null);
        setShowConfirmation(false);
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const breadcrumb = {
    mainHeader: "Manage Role",
    subHeader: "Home",
    subHeaderLink: "/dashboard",
    subHeader1: "Manage Role",
    subHeader1Link: null,
    subHeader2: null,
    subHeader2Link: null,
  };

  return (
    <MainLayout breadcrumb={breadcrumb}>
      <div className="card">
        <div className="card-body">
          <div className="card-header ">
            <p
              className="card-title"
              style={{
                fontSize: "20px",
                fontWeight: "500",
                color: ThemeColor.EASTERNBLUE,
              }}
            >
              Role Master
            </p>
            <div className="d-flex justify-content-end">
              <button
                onClick={() =>
                  navigate("/role/add", { state: { moduleId } })
                }
                className="btn btn-primary"
              >
                Add
              </button>
            </div>
          </div>
          <Datatable
            data={roleData}
            columns={columns}
            options={option}
            customBodyRender={renderActions}
            api={getRoleListApi}
          />
        </div>
      </div>
      <ConfirmationBox
        show={showConfirmation}
        onHide={() => {
          setRoleIdToDelete(null);
          setRoleIdToUpdateStatus(null);
          setShowConfirmation(false);
        }}
        onConfirm={() => {
          if (roleIdToDelete !== null) {
            handleConfirmDelete();
          } else if (roleIdToUpdateStatus !== null) {
            confirmStatusUpdate(roleData.find((role) => role.uid === roleIdToUpdateStatus).status);
          }
        }}
        message={
          roleIdToDelete !== null
            ? "Are you sure you want to delete role?"
            : "Are you sure you want to update status?"
        }
      />
    </MainLayout>
  );
};

export default Role;
