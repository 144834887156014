import React, { useEffect, useState } from "react";
import Datatable from "../../components/datatable";
import { useDispatch } from "../../store";
import { ThemeColor } from "../../constants/themeColorConstant";
import {useLocation} from "react-router-dom";
import MainLayout from "../layout/MainLayout";
import { attendanceThunks } from "../../store/reducers/attendance/attendance.thunk";


const AttendanceDetails = () => {
  

  const dispatch = useDispatch();
  const [attendanceDetails, setAttendanceDetails] = useState([]);
  const [option,setOption] = useState({})

  const location = useLocation();
  const pathnameSegments = location.pathname.split('/');
  const moduleName = pathnameSegments[1];
  
  const allowedModules = JSON.parse(localStorage.getItem("allowedModules"));
  const selectedModule = allowedModules.find(
    (module) => module.slug === moduleName
  );

  const moduleId = selectedModule.uid

  

  const columns = [
    {
      name: "employeeCode",
      label: "Employee Code",
    },
    {
      name: "employeeName",
      label: "Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "designation",
      label: "Designation",
    },
    {
      name: "employeeStatus",
      label: "Employee Status",
    },
    {
      name: "attendance",
      label: "Attendance",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "month",
      label: "Month",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "year",
      label: "Year",
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  useEffect(() => {
    getAttendanceDetailsApi()
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const getAttendanceDetailsApi = async (page=1,rowsPerPage=10,searchText='',name='',order='') => {
    try {
      const response = await dispatch(attendanceThunks.attendanceViewData({moduleId,page,rowsPerPage,searchText,name,order}));
      if (attendanceThunks.attendanceViewData.fulfilled.match(response)) {
        if (response.payload) {
          const options = {
            count: response.payload.totalRecords,
            page: response.payload.currentPage-1, // Zero-based index
            rowsPerPage:response.payload.recordsPerPage,
          }
          setOption(options)
          setAttendanceDetails(response.payload.data);
          return response.payload
        }
      }
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };

  const breadcrumb = {
    mainHeader: "Attendance View Manage",
    subHeader : "Home",
    subHeaderLink : "/dashboard",
    subHeader1 : "Attendance-view",
    subHeader1Link : null,
    subHeader2 : null,
    subHeader2Link : null,
  };


  return (
    <MainLayout breadcrumb={breadcrumb}>
      <div className="card">
        <div className="card-body">
          <div className="card-header ">
            <p
              className="card-title"
              style={{
                fontSize: "20px",
                fontWeight: "500",
                color: ThemeColor.EASTERNBLUE,
              }}
            >
             Attendance View Manage
            </p>
          </div>

          <Datatable columns={columns} data={attendanceDetails} options={option} api={getAttendanceDetailsApi}  />
        </div>
      </div>
    </MainLayout>
  );
};

export default AttendanceDetails;
