import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "../../store";
import { ThemeColor } from "../../constants/themeColorConstant";
import MainLayout from "../layout/MainLayout";
import { useLocation, useNavigate,useParams } from "react-router-dom";
import Datepicker from "../../components/datepicker";
import { parseCustomDate } from "../employee/Personal";
import { projectThunks } from "../../store/reducers/projects/project.thunk";
import { clientThunks } from "../../store/reducers/client/client.thunk";

const validationSchema = Yup.object().shape({
  clientId: Yup.string().required("Please select client"),
  type: Yup.string().required("Please select type"),
});

const EditProject = () => {
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState("projectEdit");
  const [projectData,setProjectData] = useState([])
  const [statusList,setStatusList] = useState([])
  const [typeList,setTypeList] = useState([])
  const [scopeList,setScopeList] = useState([])
  const [clientList,setClientList] = useState([])
 
  const { id } = useParams();
  const location = useLocation();
  const { moduleId } = location.state || {};

  const dispatch = useDispatch();

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    setValues
  } = useFormik({
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {

      const formattedValues = {
        ...values,
         lpoDate: values.lpoDate && parseCustomDate(values.lpoDate),
        startDate: values.startDate && parseCustomDate(values.startDate),
        expectedFinishDate: values.expectedFinishDate && parseCustomDate(values.expectedFinishDate),
        actualFinishDate: values.actualFinishDate &&  parseCustomDate(values.actualFinishDate),
      };

      await updateProjectApi(formattedValues);
    },
    initialValues: {
      projectNumber : projectData ? projectData.projectNumber : null,
      projectName : projectData ? projectData.projectName :null,
      scope : projectData ? projectData.scope : null,
      location : projectData ? projectData.location : null,
      clientId : projectData && projectData.client ? projectData.client.uid :"",
      quoteRef : projectData ? projectData.quoteRef :null,
      lpoNumber : projectData ? projectData.lpoNumber :null,
      lpoValue : projectData ? projectData.lpoValue :null,
      lpoDate : projectData ? projectData.lpoDate :null,
      startDate : projectData ? projectData.startDate :null,
      expectedFinishDate : projectData ? projectData.expectedFinishDate :null,
      actualFinishDate : projectData ? projectData.actualFinishDate :null,
      status : projectData ? projectData.status :null,
      projectRemark : projectData ? projectData.projectRemark :null,
      reservedPercentage : projectData ? projectData.reservedPercentage :null,
      type : projectData ? projectData.type : ""
    },
  });

  useEffect(() => {
    getProjectDataApi()
    getScopeApi()
    getStatusApi()
    getTypesApi()
    getClientListApi()
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loginClickHandle = (e) => {
    e.preventDefault();
    e.persist();
    handleSubmit(e);
  };

  
  const getProjectDataApi = async () => {
    try {
      const response = await dispatch(projectThunks.projectDetails({id,moduleId}));
      if (projectThunks.projectDetails.fulfilled.match(response)) {
        if (response.payload) {
          setProjectData(response.payload);
        }
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };


  const updateProjectApi = async (data) => {
    try {
      const response = await dispatch(
        projectThunks.updateProject({ id,data, moduleId })
      );
      if (projectThunks.updateProject.fulfilled.match(response)) {
        navigate("/projects")
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const getScopeApi = async () => {
    try {
      const response = await dispatch(projectThunks.projectScopesList(moduleId));
      if (projectThunks.projectScopesList.fulfilled.match(response)) {
        if (response.payload) {
          setScopeList(response.payload);
        }
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };
  const getStatusApi = async () => {
    try {
      const response = await dispatch(projectThunks.projectStatusList(moduleId));
      if (projectThunks.projectStatusList.fulfilled.match(response)) {
        if (response.payload) {
          setStatusList(response.payload);
        }
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const getTypesApi = async () => {
    try {
      const response = await dispatch(projectThunks.projectTypesList(moduleId));
      if (projectThunks.projectTypesList.fulfilled.match(response)) {
        if (response.payload) {
          setTypeList(response.payload);
        }
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const getClientListApi = async () => {
    try {
      const response = await dispatch(clientThunks.clientList({moduleId}));
      if (clientThunks.clientList.fulfilled.match(response)) {
        if (response.payload) {  
          setClientList(response.payload);
          return response.payload
        }
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };
  

  const breadcrumb = {
    mainHeader: "Project Edit",
    subHeader: "Home",
    subHeaderLink: "/dashboard",
    subHeader1: "Project",
    subHeader1Link: "/projects",
    subHeader2: "edit",
    subHeader2Link: null,
  };

  return (
    <>
      <MainLayout breadcrumb={breadcrumb}>
        <div className="card card-info">
          <div className="card-body">
            <ul
              className="nav nav-tabs"
              style={{
                borderBottom: `2px solid ${ThemeColor.GREY}`,
                backgroundColor: "transparent",
              }}
            >
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    activeTab === "projectEdit" ? "active" : ""
                  }`}
                  onClick={() => handleTabChange("Project Edit")}
                  style={{
                    borderBottom: `3px solid ${
                      activeTab === "projectEdit" ? ThemeColor.BLUE : "transparent"
                    }`,
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    backgroundColor: "transparent",
                  }}
                >
                  Project Edit
                </button>
              </li>
            </ul>
            <form
              className="form-horizontal"
              id="personal_info"
              onSubmit={handleSubmit}
            >
              <div className="card-body">
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">
                    Project Number
                  </label>
                  <div className="col-sm-5  col-md-5">
                    <input
                      type="text"
                      className="form-control"
                      name="projectNumber"
                      id="projectNumber"
                      placeholder="Project Number"
                      value={values.projectNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">
                    Project Name
                  </label>
                  <div className="col-sm-5  col-md-5">
                    <input
                      type="text"
                      className="form-control"
                      name="projectName"
                      id="projectName"
                      placeholder="Project Name"
                      value={values.projectName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">
                    Scope 
                  </label>
                  <div className="col-sm-5  col-md-5">
                    <select
                      className="form-control"
                      name="scope"
                      id="scope"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option>Select</option>
                      {scopeList.map((data, index) => {
                        return (
                          <option key={index} value={data.slug} selected={values.scope === data.slug}>
                            {data.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-4 col-md-3">Location</label>
                    <div className="col-sm-5  col-md-5">
                        <textarea
                        id="location"
                        name="location"
                        cols={2}
                        rows={2}
                        value={values.location}
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        ></textarea>
                    </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Client <span style={{ color: ThemeColor.RED }}>*</span></label>
                  <div className="col-sm-5  col-md-5">
                    <select
                      className="form-control"
                      name="clientId"
                      id="clientId"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option>Select</option>
                      {clientList.map((data, index) => {
                        return (
                          <option key={index} value={data.uid} selected={data.uid === values.clientId}>
                            {data.name}
                          </option>
                        );
                      })}
                    </select>
                    {errors.clientId && touched.clientId && (
                      <div className="error" style={{ color: ThemeColor.RED }}>
                        {errors.clientId}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Type <span style={{ color: ThemeColor.RED }}>*</span></label>
                  <div className="col-sm-5  col-md-5">
                    <select
                      className="form-control"
                      name="type"
                      id="type"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option>Select</option>
                      {typeList.map((data, index) => {
                        return (
                          <option key={index} value={data.slug} selected={data.slug === values.type}>
                            {data.name}
                          </option>
                        );
                      })}
                    </select>
                    {errors.type && touched.type && (
                      <div className="error" style={{ color: ThemeColor.RED }}>
                        {errors.type}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">
                  Quote Ref
                  </label>
                  <div className="col-sm-5  col-md-5">
                    <input
                      type="text"
                      className="form-control"
                      name="quoteRef"
                      id="quoteRef"
                      placeholder="Quote Ref"
                      value={values.quoteRef}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">
                  LPO Number
                  </label>
                  <div className="col-sm-5  col-md-5">
                    <input
                      type="text"
                      className="form-control"
                      name="lpoNumber"
                      id="lpoNumber"
                      placeholder="LPO Number"
                      value={values.lpoNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">
                  LPO Value
                  </label>
                  <div className="col-sm-5  col-md-5">
                    <input
                      type="text"
                      className="form-control"
                      name="lpoValue"
                      id="lpoValue"
                      placeholder="LPO Value"
                      value={values.lpoValue}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">LPO Date</label>
                  <div className="col-sm-5  col-md-5">
                   
                    <Datepicker
                        name="lpoDate"
                        id="lpoDate"
                        value={values.lpoDate}
                        dateFormat="yyyy-mm-dd"
                        onChange={(date) => 
                        setValues({ ...values, lpoDate: parseCustomDate(date.target.value) })
                        }
                        />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Start Date</label>
                  <div className="col-sm-5  col-md-5">
                     <Datepicker
                        name="startDate"
                        id="startDate"
                        value={values.startDate}
                        dateFormat="yyyy-mm-dd"
                        onChange={(date) => 
                        setValues({ ...values, startDate: parseCustomDate(date.target.value) })
                        }
                        />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Expected Finish Date</label>
                  <div className="col-sm-5  col-md-5">
                    <Datepicker
                        name="expectedFinishDate"
                        id="expectedFinishDate"
                        value={values.expectedFinishDate}
                        dateFormat="yyyy-mm-dd"
                        onChange={(date) => 
                        setValues({ ...values, expectedFinishDate: parseCustomDate(date.target.value) })
                        }
                        />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Actual Finish Date</label>
                  <div className="col-sm-5  col-md-5">
                    <Datepicker
                        name="actualFinishDate"
                        id="actualFinishDate"
                        value={values.actualFinishDate}
                        dateFormat="yyyy-mm-dd"
                        onChange={(date) => 
                        setValues({ ...values, actualFinishDate: parseCustomDate(date.target.value) })
                        }
                        />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">
                    Status 
                  </label>
                  <div className="col-sm-5  col-md-5">
                    <select
                      className="form-control"
                      name="status"
                      id="status"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option>Select</option>
                      {statusList.map((data, index) => {
                        return (
                          <option key={index} value={data.slug} selected={values.status === data.slug}>
                            {data.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-4 col-md-3">Project Remark</label>
                    <div className="col-sm-5  col-md-5">
                        <textarea
                        id="projectRemark"
                        name="projectRemark"
                        cols={2}
                        rows={2}
                        value={values.projectRemark}
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        ></textarea>
                    </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">
                  Emergency Reserved Percentage (%) 
                  </label>
                  <div className="col-sm-5  col-md-5">
                    <select
                      className="form-control"
                      name="reservedPercentage"
                      id="reservedPercentage"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option>Select</option>
                      {[...Array(100).keys()].map((number) => (
                        <option key={number + 1} value={number + 1} selected={values.reservedPercentage === number+1}>
                          {number + 1}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-center">
              <button
                type="submit"
                className="btn btn-primary"
                style={{ padding: "8px 20px" }}
                onClick={(e) => loginClickHandle(e)}
              >
                <i className="fa fa-edit" style={{ marginRight: "8px" }} />
                Update
              </button>
              <button
                type="button"
                className="btn btn-default"
                style={{ padding: "8px 20px", marginLeft: "10px" }}
                onClick={()=>{
                  navigate("/projects")
                }}
              >
                Cancel
              </button>
            </div>
            </form>
          </div>
          {/* <ToastMessage toastData={toastData} /> */}
        </div>
      </MainLayout>
    </>
  );
};

export default EditProject;
