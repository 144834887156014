import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { customStyles, getMuiTheme } from "../assets/js/style";

const Datatable = ({
  title,
  data,
  columns,
  options = {},
  api,
  components = {},
  hideSrNo,
}) => {
  const [tableData, setTableData] = useState([]);
  const [currentRowsPerPage, setCurrentRowsPerPage] = useState(
    options.rowsPerPage
  );

  useEffect(() => {
    setTableData(data);
  }, [data]);

  const columnsWithSrNo = [
    !hideSrNo && {
      name: "srno",
      label: "Sr. No",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const { rowIndex } = tableMeta;
          return rowIndex + 1;
        },
      },
    },
    ...columns.map((col) => ({
      ...col,
      label: col.label.toUpperCase(), // Capitalize the label
    })),
  ].filter((c) => c);

  const changePage = (page, rowsPerPage) => {
    page = page + 1;
    // rowsPerPage = rowsPerPage == 'all' ? 1 : rowsPerPage;
    setCurrentRowsPerPage(rowsPerPage);
    api(page, rowsPerPage)
      .then((response) => {
        setTableData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  const changeRowsPerPage = (page, rowsPerPage) => {
    page = page == 0 ? page + 1 : page;
    // rowsPerPage = rowsPerPage == 'all' ? 1 : rowsPerPage;
    setCurrentRowsPerPage(rowsPerPage);
    api(page, rowsPerPage)
      .then((response) => {
        setTableData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const search = (page, rowsPerPage, searchText) => {
    searchText = searchText == null ? "" : searchText;
    api(page + 1, rowsPerPage, searchText)
      .then((response) => {
        setTableData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const sort = (page, rowsPerPage, searchText, name, order) => {
    const apiSearchText = searchText || "";
    api(page + 1, rowsPerPage, apiSearchText, name, order)
      .then((response) => {
        setTableData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  const options1 = {
    customTableBodyFooterRender: options.customTableBodyFooterRender,
    filter: options.filter == false ? false : true,
    download: options.download == false ? false : true,
    sort: true,
    downloadOptions: {
      filename: `${title}.csv`,

      // 'custom-file-name.csv', // Specify your desired file name
    },

    search: options.search == false ? false : true,
    print: options.print == false ? false : true,
    pagination: options.pagination == false ? false : true,
    viewColumns: options.viewColumns ? false : true,
    filterType: "dropdown",
    responsive: "vertical",
    serverSide: options.serverSide == false ? false : true,
    count: options.count || 0,
    page: options.page || 0,
    selectableRows: "none",
    rowsPerPage:
      currentRowsPerPage == "all" ? options.count : currentRowsPerPage,
    rowsPerPageOptions: [10, 15, 20, { label: "All", value: "all" }],
    sortOrder: "asc",
    onTableChange: (action, tableState) => {
      if (options1.serverSide) {
        switch (action) {
          case "changePage":
            changePage(tableState.page, tableState.rowsPerPage);
            break;
          case "changeRowsPerPage":
            changeRowsPerPage(tableState.page, tableState.rowsPerPage);
            break;
          case "search":
            search(
              tableState.page,
              tableState.rowsPerPage,
              tableState.searchText
            );
            break;
          case "sort":
            // console.log("here1",tableState.sortOrder.name,);
            sort(
              tableState.page,
              tableState.rowsPerPage,
              tableState.searchText,
              tableState.sortOrder.name,
              tableState.sortOrder.direction
            );
            break;
          default:
          // console.log("Unrecognized action:", action);
        }
      }
    },
  };

  return (
    <div className="card-body">
      <div className="tab-content p-0">
        <div className="table-responsive" style={{ overflowX: "auto" }}>
          <style>{customStyles}</style>
          <ThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              fixedHeader
              title={title}
              data={tableData}
              columns={columnsWithSrNo}
              options={options1}
              components={{
                ...components,
              }}
            />
          </ThemeProvider>
        </div>
      </div>
    </div>
  );
};

export default Datatable;
