import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MainLayout from "../../layout/MainLayout";
import Datatable from "../../../components/datatable";
import { ThemeColor } from "../../../constants/themeColorConstant";
import { useDispatch } from "../../../store";
import ProjectExpense from "./ProjectExpense";
import ExpenseDetails from "./ExpenseDetails";
import ActualExpense from "./ActualExpense";


const ProjectExpenseDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const location = useLocation();
  const pathnameSegments = location.pathname.split("/");
  const moduleName = pathnameSegments[1];

  const allowedModules = JSON.parse(localStorage.getItem("allowedModules"));
  const selectedModule = allowedModules.find(
    (module) => module.slug === moduleName
  );

  const moduleId = selectedModule.uid;


  const breadcrumb = {
    mainHeader: "Project Costing Report",
    subHeader: "Home",
    subHeaderLink: "/dashboard",
    subHeader1: "Project Costing Report",
    subHeader1Link: "/project-costing/project-expense",
    subHeader2: null,
    subHeader2Link: null,
  };

  return (
    <MainLayout breadcrumb={breadcrumb}>
      <div className="card">
        <ProjectExpense moduleId={moduleId} />

        <ExpenseDetails moduleId={moduleId} />

        <ActualExpense moduleId={moduleId} />

      </div>
    </MainLayout>
  );
};

export default ProjectExpenseDetails;
