import { Link} from "react-router-dom";

const Breadcrumb = ({ breadcrumb }) => {
    let sub2 = '';
    if (breadcrumb && breadcrumb.subHeader2) {
        if (breadcrumb.subHeader2Link) {
            sub2 = <li className="breadcrumb-item">
                <Link to={breadcrumb.subHeader2Link}>{breadcrumb.subHeader2}</Link>
            </li>;
        } else {
            sub2 = <li
                className="breadcrumb-item"
                style={{ textTransform: "capitalize" }}
            >{breadcrumb.subHeader2}
            </li>;
        }
    }
    return (
        <>
            {breadcrumb ?
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">{breadcrumb.mainHeader}</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to={breadcrumb.subHeaderLink}>{breadcrumb.subHeader}</Link>
                                    </li>

                                    {breadcrumb.subHeader1Link ?
                                        <li className="breadcrumb-item">
                                            <Link to={breadcrumb.subHeader1Link}>{breadcrumb.subHeader1}</Link>
                                        </li>
                                        :
                                        <li
                                            className="breadcrumb-item active"
                                            style={{ textTransform: "capitalize" }}
                                        >{breadcrumb.subHeader1}</li>
                                    }


                                    {sub2 ?
                                        sub2
                                        :
                                        null
                                    }


                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                :
                null
            }
        </>
    )
};
export default Breadcrumb;
