import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { useDispatch } from "../../../store";
import { ThemeColor } from "../../../constants/themeColorConstant";
import MainLayout from "../../layout/MainLayout";
import { projectCostingThunk } from "../../../store/reducers/projectCosting/projectCosting.thunk";
import Datepicker from "../../../components/datepicker";
import { parseCustomDate } from "../../employee/Personal";

const validationSchema = Yup.object().shape({

});

const EditProjectExpenseDetails = () => {

  const navigate = useNavigate()

  /* eslint-disable no-unused-vars */
  const [activeTab, setActiveTab] = useState("AddUser");
  /* eslint-disable no-unused-vars */

  const [expenseData, setExpenseData] = useState([])
  const [projectedList, setProjectedList] = useState([])
  const [subContractorList, setSubContractorList] = useState([])


  const dispatch = useDispatch();
  const location = useLocation();
  const { projectId } = location.state || {};

  const pathnameSegments = location.pathname.split("/");
  const moduleName = pathnameSegments[1];

  const allowedModules = JSON.parse(localStorage.getItem("allowedModules"));
  const selectedModule = allowedModules.find(
    (module) => module.slug === moduleName
  );

  const { id } = useParams()
  const moduleId = selectedModule.uid;

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    setFieldValue,
    setValues
  } = useFormik({
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      console.log({ values });
      delete values.subContractorIdObj;
      delete values.isProjected;
      delete values.subContractorId;
      delete values.date;
      const formattedValues = {
        ...values,
        // date: values.date && parseCustomDate(values.date),
      };
      await updateExpenseDetailsApi(formattedValues);
    },
    initialValues: {

      subContractorId: expenseData && expenseData.subcontractor ? expenseData.subcontractor.value : null,
      subContractorIdObj: expenseData ? expenseData.subcontractor : null,
      isProjected: expenseData ? expenseData.isProjected : null,
      expense: expenseData ? expenseData.expense : null,
      date: expenseData ? expenseData.date : null,
      type: expenseData ? expenseData.type : null,
      invoiceNo: expenseData ? expenseData.invoiceNo : null,
      remark: expenseData ? expenseData.remark : null
    },
  });

  useEffect(() => {
    getSubContractorListApi()
    getIsProjectedListApi()
    getExpenseDataApi()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateExpenseDetailsApi = async (data) => {
    try {
      const response = await dispatch(projectCostingThunk.editProjectExpenseDetails({ data,id, moduleId }));
      if (projectCostingThunk.editProjectExpenseDetails.fulfilled.match(response)) {
        navigate(`/project-costing/project-expense/${projectId}`)
        return response

      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const getSubContractorListApi = async () => {
    try {
      const response = await dispatch(
        projectCostingThunk.subContractorsList(moduleId)
      );
      if (projectCostingThunk.subContractorsList.fulfilled.match(response)) {
        if (response.payload) {
          setSubContractorList(response.payload);
        }
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const getIsProjectedListApi = async () => {
    try {
      const response = await dispatch(
        projectCostingThunk.isProjectedList(moduleId)
      );
      if (projectCostingThunk.isProjectedList.fulfilled.match(response)) {
        if (response.payload) {
          setProjectedList(response.payload);
        }
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const getExpenseDataApi = async () => {
    const data = {
      id,
      moduleId,
    };
    try {
      const response = await dispatch(projectCostingThunk.getProjectExpenseDetails(data));
      if (projectCostingThunk.getProjectExpenseDetails.fulfilled.match(response)) {
        if (response.payload) {
          let subcontractor = {};
          let res = response.payload;
          if (res && res.subcontractor) {
            subcontractor.value = res.subcontractor.uid;
            subcontractor.label = res.subcontractor.name;
            res.subcontractor = subcontractor;
          }
          setExpenseData(res);
        }
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };


  const breadcrumb = {
    mainHeader: "Project Expense Details Edit",
    subHeader: "Home",
    subHeaderLink: "/dashboard",
    subHeader1: "Project Expense Manage",
    subHeader1Link: "/project-costing/project-expense",
    subHeader2: "Project Expense Details ",
    subHeader2Link: "/project-costing/project-expense",
  };

  return (
    <MainLayout breadcrumb={breadcrumb}>
      <div className="card card-info">
        <div className="card-body">
          <ul
            className="nav nav-tabs"
            style={{
              borderBottom: `2px solid ${ThemeColor.GREY}`,
              backgroundColor: "transparent",
            }}
          >
            <li className="nav-item">
              <button
                className={`nav-link ${activeTab === "AddUser" ? "active" : ""
                  }`}
                style={{
                  borderBottom: `3px solid ${activeTab === "AddUser" ? ThemeColor.BLUE : "transparent"
                    }`,
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  backgroundColor: "transparent",
                }}
              >
                Edit
              </button>
            </li>
          </ul>
          <form className="form-horizontal" onSubmit={handleSubmit}>
            <div className="card-body">
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">
                  Sub-Contractor <span style={{ color: ThemeColor.RED }}>*</span>
                </label>
                <div className="col-sm-5  col-md-5">
                  <Select
                    isDisabled
                    isSearchable
                    id="subContractorId"
                    name="subContractorId"
                    onChange={(selectedOption) => {
                      setValues({
                        ...values,
                        subContractorIdObj: selectedOption,
                        subContractorId: selectedOption.value,
                      });
                    }}
                    value={values.subContractorIdObj}
                    options={subContractorList.map((data) => ({
                      value: data.uid,
                      label: data.name,
                    }))}
                  />
                  {errors.subContractorId && touched.subContractorId && (
                    <div className="error" style={{ color: ThemeColor.RED }}>
                      {errors.subContractorId}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">
                  Is Projected/Actual Expense
                </label>
                <div className="col-sm-5  col-md-5">
                  <select
                    disabled
                    className="form-control"
                    name="isProjected"
                    id="isProjected"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option>Select</option>
                    {projectedList.map((data, index) => {
                      return (
                        <option
                          key={index}
                          selected={data.slug === values.isProjected}
                          value={data.slug}
                        >
                          {data.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">
                  Expense
                </label>
                <div className="col-sm-5  col-md-5">
                  <input
                    type="text"
                    className="form-control"
                    name="expense"
                    id="expense"
                    value={values.expense}
                    placeholder="Expense"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.expense && touched.expense && (
                    <div className="error" style={{ color: ThemeColor.RED }}>
                      {errors.expense}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">
                  Type
                </label>
                <div className="col-sm-5  col-md-5">
                  <input
                    type="text"
                    className="form-control"
                    name="type"
                    id="type"
                    value={values.type}
                    placeholder="Type"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.type && touched.type && (
                    <div className="error" style={{ color: ThemeColor.RED }}>
                      {errors.type}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">
                  Invoice No/Ref No
                </label>
                <div className="col-sm-5  col-md-5">
                  <input
                    type="text"
                    className="form-control"
                    name="invoiceNo"
                    id="invoiceNo"
                    value={values.invoiceNo}
                    placeholder="Invoice No/Ref No"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">Date</label>
                <div className="col-sm-5  col-md-5">
                  <Datepicker
                    readOnly={true}
                    name="date"
                    id="date"
                    value={values.date}
                    dateFormat="dd-mm-yyyy"
                    onChange={(date) =>
                      setValues({
                        ...values,
                        date: parseCustomDate(date.target.value),
                      })
                    }
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">Remark</label>
                <div className="col-sm-5  col-md-5">
                  <textarea
                    id="remark"
                    name="remark"
                    cols={2}
                    rows={2}
                    value={values.remark}
                    className="form-control"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  ></textarea>
                </div>
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-center">
              <button
                type="submit"
                className="btn btn-primary"
                style={{ padding: "8px 20px" }}
              >
                <i className="fa fa-edit" style={{ marginRight: "8px" }} />
                Update
              </button>
              <button
                type="button"
                className="btn btn-default"
                style={{ padding: "8px 20px", marginLeft: "10px" }}
                onClick={() => {
                  navigate("")
                }}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </MainLayout>
  );
};

export default EditProjectExpenseDetails;
