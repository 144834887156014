import React from 'react'
import { useParams } from 'react-router';
import { useDispatch } from '../../store';
import { employeeThunks } from '../../store/reducers/employee/employee.thunk';
import { useFormik } from 'formik';

const Notes = ({activeTab,moduleId,employeeData}) => {
  const {id} = useParams()
  const dispatch = useDispatch()

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    enableReinitialize: true,
    onSubmit: async(values) => {
      await updateNotesDataApi(values)
    },
    initialValues: {
      remark: employeeData ? employeeData.remark : null,
      notes: employeeData ? employeeData.notes :null,
    },
  });

  const updateNotesDataApi = async (data) => {
    try {
      const response = await dispatch(employeeThunks.updateEmployee({id,data,moduleId,activeTab}));
      if (employeeThunks.updateEmployee.fulfilled.match(response)) {
            return response.payload
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };


  return (
    <form className="form-horizontal" id="notes_info" onSubmit={handleSubmit}>
    <div className="card-body">
      <div className="form-group row">
        <label className="col-sm-4 col-md-3">Remark</label>
        <div className="col-sm-5  col-md-5">
          <textarea
          id='remark'
          name='remark'
            cols={2}
            rows={2}
            className="form-control"
            value={values.remark}
            onChange={handleChange}
            onBlur={handleBlur}
          ></textarea>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-sm-4 col-md-3">Notes</label>
        <div className="col-sm-5  col-md-5">
          <textarea
          id='notes'
          name='notes'
            cols={2}
            rows={2}
            className="form-control"
            value={values.notes}
            onChange={handleChange}
            onBlur={handleBlur}
          ></textarea>
        </div>
      </div>
    </div>
    <div className="d-flex align-items-center justify-content-center">
      <button
        type="submit"
        className="btn btn-primary"
        style={{ padding: "8px 20px" }}
      >
        <i className="fa fa-floppy-o" style={{ marginRight: "8px" }} />
        Update
      </button>
    </div>
  </form>
  )
}

export default Notes