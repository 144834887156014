import React, { useEffect, useState } from "react";
import { employeeThunks } from "../../store/reducers/employee/employee.thunk";
import { useParams } from "react-router-dom";
import { useDispatch } from "../../store";
import { useFormik } from "formik";
import Datepicker from "../../components/datepicker";
import { parseCustomDate } from "./Personal";

const Transport = ({ moduleId,activeTab,employeeData}) => {

  const dispatch = useDispatch()
  const [employeeVehicle,setEmployeeVehicle] = useState([])
  const {id} = useParams()

  const {
    values,
    handleChange,
    setValues,
    handleSubmit,
  } = useFormik({
    enableReinitialize: true,
    onSubmit: async(values) => {
      const formattedValues = {
        ...values,
        contractExpiry: values.contractExpiry && parseCustomDate(values.contractExpiry),
      };
      await updateTransportDataApi(formattedValues)
    },
    initialValues: {
      morningVehicleId: employeeData && employeeData.morningVehicle ?  employeeData.morningVehicle.uid : null,
      eveningVehicleId: employeeData  && employeeData.eveningVehicle ?  employeeData.eveningVehicle.uid : null,
      contractExpiry : employeeData && employeeData.contractExpiry ? employeeData.contractExpiry : null
    },
  });


  useEffect(()=>{
    employeeVehicleApi()
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  const updateTransportDataApi = async (data) => {
    try {
      const response = await dispatch(
        employeeThunks.updateEmployee({ id, data, moduleId, activeTab })
      );
      if (employeeThunks.updateEmployee.fulfilled.match(response)) {
        return response
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const employeeVehicleApi = async (data) => {
    try {
      const response = await dispatch(
        employeeThunks.employeeVehicleList({ id, moduleId })
      );
      if (employeeThunks.employeeVehicleList.fulfilled.match(response)) {
        if (response.payload) {
          setEmployeeVehicle(response.payload);
        }
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };


  return (
    <form className="form-horizontal" id="personal_info" onSubmit={handleSubmit}>
      <div className="card-body">
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Morning Vehicle</label>
          <div className="col-sm-5  col-md-5">
            <select
              className="form-control"
              name="morningVehicleId"
              id="morningVehicleId"
              onChange={handleChange}
            >
              <option>Select</option>
              {employeeVehicle.map((data) => {
                return (
                  <option
                    key={data.uid}
                    selected={data.uid === values.morningVehicleId}
                    value={data.uid}
                  >
                    {data.vehicleName}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Evening Vehicle</label>
          <div className="col-sm-5  col-md-5">
            <select
              className="form-control"
              name="eveningVehicleId"
              id="eveningVehicleId"
              onChange={handleChange}
            >
              <option>Select</option>
              {employeeVehicle.map((data) => {
                return (
                  <option
                    key={data.uid}
                    selected={data.uid === values.eveningVehicleId}
                    value={data.uid}
                  >
                    {data.vehicleName}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Contract Expiry</label>
          <div className="col-sm-5  col-md-5">
          <Datepicker
              name="contractExpiry"
              id="contractExpiry"
              value={values.contractExpiry}
              dateFormat="dd-mm-yyyy"
              onChange={(date) =>
                setValues({
                  ...values,
                  contractExpiry: parseCustomDate(date.target.value),
                })
              }
            />
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <button
          type="submit"
          className="btn btn-primary"
          style={{ padding: "8px 20px" }}
        >
          <i className="fa fa-floppy-o" style={{ marginRight: "8px" }} />
          Update
        </button>
      </div>
    </form>
  );
};

export default Transport;
