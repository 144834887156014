import React, { useEffect, useState } from "react";
import Datatable from "../../components/datatable";
import { useDispatch } from "../../store";
import { ThemeColor } from "../../constants/themeColorConstant";
import { useLocation, useParams } from "react-router-dom";
import MainLayout from "../layout/MainLayout";
import { vehicleThunks } from "../../store/reducers/vehicleMatrix/vehicle.thunk";

const VehicleHistory = () => {
  const dispatch = useDispatch();
  const [vehicleHistoryData, setVehicleHistoryData] = useState([]);
  const [option, setOption] = useState({});


  const {id} = useParams()
  const location = useLocation();
  const pathnameSegments = location.pathname.split("/");
  const moduleName = pathnameSegments[1];

  const allowedModules = JSON.parse(localStorage.getItem("allowedModules"));
  const selectedModule = allowedModules.find(
    (module) => module.slug === moduleName
  );

  const moduleId = selectedModule.uid;


  const columns = [
    {
      name: "empCode",
      label: "Emp Code",
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "expiryDate",
      label: "Expiry Date",
    },
    {
      name: "project",
      label: "Project",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return value ? value.name : "";
        },
      },
    },
    {
        name: "projectLocation",
        label: "Project Location",
        options: {
          filter: false,
          sort: true,
          
        },
      },
      {
        name: "fromDate",
        label: "from Date",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "toDate",
        label: "To Date",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "vehicle",
        label: "vehicle",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return value ? value.vehicle_name : "";
          },
        },
      },
  ];

  useEffect(() => {
     getVehicleHistoryDetailsApi()
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

    const getVehicleHistoryDetailsApi = async (page=1,rowsPerPage=10,searchText='',name='',order='') => {
      try {
        const response = await dispatch(vehicleThunks.vehicleHistoryList({id,moduleId,page,rowsPerPage,searchText,name,order}));
        if (vehicleThunks.vehicleHistoryList.fulfilled.match(response)) {
          if (response.payload) {
            const options = {
              count: response.payload.totalRecords,
              page: response.payload.currentPage-1, // Zero-based index
              rowsPerPage:response.payload.recordsPerPage,
            }
            setOption(options)
            setVehicleHistoryData(response.payload.data);
            return response.payload
          }
        }
      } catch (error) {
        console.error("Error fetching client data:", error);
      }
    };

  const breadcrumb = {
    mainHeader: "Vehicle-Matrix History",
    subHeader: "Home",
    subHeaderLink: "/dashboard",
    subHeader1: "Vehicle-Matrix",
    subHeader1Link: "/vehicle-matrix",
    subHeader2: "History",
    subHeader2Link: null,
  };

  return (
    <MainLayout breadcrumb={breadcrumb}>
      <div className="card">
        <div className="card-body">
          <div className="card-header ">
            <p
              className="card-title"
              style={{
                fontSize: "20px",
                fontWeight: "500",
                color: ThemeColor.EASTERNBLUE,
              }}
            >
           Vehicle Matrix History
            </p>
          </div>
          <Datatable
            columns={columns}
            data={vehicleHistoryData}
            options={option}
          />
        </div>
      </div>
    </MainLayout>
  );
};

export default VehicleHistory;
