import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useDispatch } from 'react-redux';
import { setToastContent } from '../store/reducers/toast/toast.slice';


const ToastMessage = ({ toastData }) => {
  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(setToastContent({ type: null, message: null, duration: null }));
  };

  const severity = (toastType) => {
    switch (toastType) {
      case 'success':
        return 'success';
      case 'error':
        return 'error';
      case 'warning':
        return 'warning';
      default:
        return 'info';
    }
  };

  if (!toastData.message) return null;

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={toastData.message !== null}
      onClose={handleClose}
      autoHideDuration={toastData.duration || 2000}
    >
      <Alert
        variant="filled"
        severity={severity(toastData.type)}
        sx={{ width: '100%' }}
      >
        {toastData.message}
      </Alert>
    </Snackbar>
  );
};

export default ToastMessage;
