import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiCall } from "../../../utils/api";
import { HTTP_METHODS } from "../../../constants/methodsConstant";
import { ROUTES } from "../../../constants/routesConstant";
import { ToastType, setToastContent } from "../toast/toast.slice";

export const vehicleListData = createAsyncThunk(
    "vehicle/list",
    async (inputData, { dispatch }) => {
      try {
        const response = await ApiCall(
          HTTP_METHODS.GET,
          `${ROUTES.GET_VEHICLE}?page=${inputData.page}&limit=${inputData.rowsPerPage}&search=${inputData.searchText}&sort=${inputData.name}&order=${inputData.order}`,
          {},
          inputData.moduleId
        );
        if (response?.isSuccess) {
          return response.payload;
        } else {
          dispatch(
            setToastContent({
              type: ToastType.error,
              message: response.message,
            })
          );
        }
      } catch (error) {
        throw new Error("An error occurred");
      }
    }
  );

  export const vehicleDetails = createAsyncThunk(
    "vehicleDetails/details",
    async (inputData, { dispatch }) => {
      try {
        const response = await ApiCall(
          HTTP_METHODS.GET,
          `${ROUTES.GET_VEHICLE}/${inputData.id}`,
          {},
          inputData.moduleId
        );
        if (response?.isSuccess) {
          return response.payload;
        } else {
          dispatch(
            setToastContent({
              type: ToastType.error,
              message: response.message,
            })
          );
        }
      } catch (error) {
        throw new Error("An error occurred");
      }
    }
  );


  export const addVehicle = createAsyncThunk(
    "vehicle/add",
    async (inputData, { dispatch }) => {
      try {
        const response = await ApiCall(
          HTTP_METHODS.POST,
          ROUTES.GET_VEHICLE,
          inputData.data,
          inputData.moduleId
        );
        if (response?.isSuccess) {
          dispatch(
            setToastContent({
              type: ToastType.success,
              message: response.message,
            })
          );
          return response.payload;
        } else {
          dispatch(
            setToastContent({
              type: ToastType.error,
              message: response.message,
            })
          );
        }
      } catch (error) {
        throw new Error("An error occurred");
      }
    }
  );
  export const updateVehicle = createAsyncThunk(
    "vehicle/update",
    async (inputData, { dispatch }) => {
      try {
        const response = await ApiCall(
          HTTP_METHODS.PATCH,
          `${ROUTES.GET_VEHICLE}/${inputData.id}`,
          inputData.data,
          inputData.moduleId
        );
        if (response?.isSuccess) {
          dispatch(
            setToastContent({
              type: ToastType.success,
              message: response.message,
            })
          );

          return response.payload;
        } else {
          dispatch(
            setToastContent({
              type: ToastType.error,
              message: response.message,
            })
          );
        }
      } catch (error) {
        throw new Error("An error occurred");
      }
    }
  );

  export const deleteVehicle = createAsyncThunk(
    "vehicle/delete",
    async (inputData, { dispatch }) => {  
      try {
        const response = await ApiCall(
          HTTP_METHODS.DELETE,
          ROUTES.GET_VEHICLE+`/${inputData.id}`,
          {},
          inputData.moduleId
        );
        if (response?.isSuccess) {
          dispatch(
            setToastContent({
              type: ToastType.success,
              message: response.message,
            })
          );
          return response.payload;
        } else {
          dispatch(
            setToastContent({
              type: ToastType.error,
              message: response.message,
            })
          );
        }
      } catch (error) {
        throw new Error("An error occurred");
      }
    }
  );

  export const vehicleHistoryList = createAsyncThunk(
    "vehicle/list",
    async (inputData, { dispatch }) => {
      try {
        const response = await ApiCall(
          HTTP_METHODS.GET,
          `${ROUTES.GET_VEHICLE_HISTORY}/${inputData.id}?page=${inputData.page}&limit=${inputData.rowsPerPage}&search=${inputData.searchText}&sort=${inputData.name}&order=${inputData.order}`,
          {},
          inputData.moduleId
        );
        if (response?.isSuccess) {
          dispatch(
            setToastContent({
              type: ToastType.success,
              message: response.message,
            })
          );
          return response.payload;
        } else {
          dispatch(
            setToastContent({
              type: ToastType.error,
              message: response.message,
            })
          );
        }
      } catch (error) {
        throw new Error("An error occurred");
      }
    }
  );

  export const vehicleList = createAsyncThunk(
    "vehicle/VehicleList",
    async (inputData, { dispatch }) => {
      try {
        const response = await ApiCall(
          HTTP_METHODS.GET,
          ROUTES.GET_VEHICLELIST,
          {},
          inputData.moduleId
        );
        if (response?.isSuccess) {
          return response.payload;
        } else {
          dispatch(
            setToastContent({
              type: ToastType.error,
              message: response.message,
            })
          );
        }
      } catch (error) {
        throw new Error("An error occurred");
      }
    }
  );


  export const vehicleThunks = {
    vehicleListData,
    vehicleList,
    deleteVehicle,
    updateVehicle,
    addVehicle,
    vehicleHistoryList,
    vehicleDetails
  }