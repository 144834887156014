import { createAsyncThunk } from "@reduxjs/toolkit";
import { setAllowedModules, setUser } from "./authSlice";
import { ApiCall } from "../../../utils/api";
import { ToastType, setToastContent } from "../toast/toast.slice";
import { ROUTES } from "../../../constants/routesConstant";
import { HTTP_METHODS } from "../../../constants/methodsConstant";


export const login = createAsyncThunk(
  "auth/login",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.POST,
        ROUTES.LOGIN_ROUTE,
        inputData
      );
      if (response?.isSuccess) {
       
        dispatch(
          setToastContent({
            type: ToastType.success,
            message: "You have logged IN Successfully!",
          })
        );
        console.log(response.payload);
        let userData = {
          userName : response.payload.userName,
          name : response.payload.userName,
          email : response.payload.email,
          profile : response.payload.photo,
          uid : response.payload.uid,
          isEmployee : response.payload.isEmployee
        }
        const userDataJSON = JSON.stringify(userData);
        localStorage.setItem("accessToken", response.payload.accessToken);
        localStorage.setItem("allowedModules",JSON.stringify(response.payload.allowedModules));
        localStorage.setItem("userData", userDataJSON);
        dispatch(setUser(userDataJSON))
        dispatch(setAllowedModules(response.payload.allowedModules));
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: "Invalid credentials!",
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);

export const updatePassword = createAsyncThunk(
  "employee/updatePassword",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.PATCH,
        `${ROUTES.GET_USER}/profile/changePassword`,
        inputData.data,
        inputData.moduleId
      );
      if (response?.isSuccess) {
        dispatch(
          setToastContent({
            type: ToastType.success,
            message: response.message,
          })
        );
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);

export const updatePasswordforEmployee = createAsyncThunk(
  "employee/updatePassword",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.PATCH,
        `${ROUTES.GET_EMPLOYEE}/update-password`,
        inputData.data,
        inputData.moduleId
      );
      if (response?.isSuccess) {
        dispatch(
          setToastContent({
            type: ToastType.success,
            message: response.message,
          })
        );
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);

export const employeeLogin = createAsyncThunk(
  "auth/emp-login",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.POST,
        ROUTES.EMPLOYEE_LOGIN_ROUTE,
        inputData
      );
      if (response?.isSuccess) {
       
        dispatch(
          setToastContent({
            type: ToastType.success,
            message: "You have logged IN Successfully!",
          })
        );
        let userData = {
          userName : response.payload.employeeCode,
          name : response.payload.name,
          email : response.payload.officeEmail,
          profile : response.payload.photo,
          isEmployee : response.payload.isEmployee,
          uid : response.payload.uid,
        }
        const userDataJSON = JSON.stringify(userData);
        localStorage.setItem("accessToken", response.payload.accessToken);
        localStorage.setItem("allowedModules",JSON.stringify(response.payload.allowedModules));
        localStorage.setItem("userData", userDataJSON);
        dispatch(setUser(userDataJSON))
        dispatch(setAllowedModules(response.payload.allowedModules));
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: "Invalid credentials!",
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);


export const authThunks = {
  login,
  updatePassword,
  employeeLogin,
  updatePasswordforEmployee
};
