import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { employeeThunks } from "../../store/reducers/employee/employee.thunk";
import { useDispatch } from "../../store";
import { ThemeColor } from "../../constants/themeColorConstant";
import MainLayout from "../layout/MainLayout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { planningThunk } from "../../store/reducers/planning/planning.thunk";
import { clientThunks } from "../../store/reducers/client/client.thunk";
import { projectThunks } from "../../store/reducers/projects/project.thunk";
import { parseCustomDate } from "../employee/Personal";
import Datepicker from "../../components/datepicker";

const validationSchema = Yup.object().shape({
  employeeId: Yup.string().required("Please select employee"),
  currentProjectId: Yup.string().required("Please select current project"),
  startDate : Yup.string().required("Please select start date"),  
  lastWorkingDay : Yup.string().required("Please select last working date") ,
  leavePlannedDate :Yup.string().required("Please select leave planned date"),
  returnDate :Yup.string().required("Please select return date"),
  plannedReplacementId1 : Yup.string().required("Please select planned replacement 1"),
  clientId : Yup.string().required("Please select client")
});

const EditPlanning = () => {
  const [activeTab, setActiveTab] = useState("add");
  const [employeeList,setEmployeeList] = useState([])
  const [clientList,setClientList] = useState([])
  const [projectList,setProjectList] = useState([])
  const [planningData,setPlanningData] = useState([])
 
  const location = useLocation();
  const { moduleId } = location.state || {};
  const {id} = useParams()
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    resetForm,
    setValues,
    setFieldValue,
  } = useFormik({
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
        delete values.employeeIdObj;
        delete values.clientObj;
        delete values.currentProjectObj;
        delete values.plannedProjectObj;
        delete values.plannedReplacement1Obj;
        delete values.plannedReplacement2Obj;
  
        const formattedValues = {
            ...values,
            startDate: values.startDate && parseCustomDate(values.startDate),
            lastWorkingDay: values.lastWorkingDay && parseCustomDate(values.lastWorkingDay),
            leavePlannedDate:
              values.leavePlannedDate &&
              parseCustomDate(values.leavePlannedDate),
            returnDate:
              values.returnDate && parseCustomDate(values.returnDate),
          };

      await updatePlanningApi(formattedValues);
      resetForm();
    },
    initialValues: {
        employeeId : planningData && planningData.employee ? planningData.employee.value : null,
        employeeIdObj : planningData ? planningData.employee : null,
        currentProjectId: planningData && planningData.currentProject ? planningData.currentProject.value : null,
        currentProjectObj: planningData ? planningData.currentProject : null,
        startDate: planningData && planningData.startDate ? planningData.startDate : null,
        lastWorkingDay:planningData && planningData.lastWorkingDay ? planningData.lastWorkingDay : null,
        leavePlannedDate: planningData && planningData.leavePlannedDate ? planningData.leavePlannedDate : null,
        returnDate: planningData && planningData.returnDate ? planningData.returnDate : null,
        plannedReplacementId1: planningData && planningData.plannedReplacement1 ? planningData.plannedReplacement1.value : null,
        plannedReplacement1Obj :planningData ? planningData.plannedReplacement1 : null,
        plannedReplacementId2 : planningData && planningData.plannedReplacement2 ? planningData.plannedReplacement2.value : null,
        plannedReplacement2Obj :planningData ? planningData.plannedReplacement2 : null,
        clientId: planningData && planningData.client ? planningData.client.value : null,
        clientObj: planningData ? planningData.client : null,
        isOpen : planningData && planningData.isOpen ? planningData.isOpen.toString() : false,
        plannedProjectId : planningData.plannedProject ? planningData.plannedProject.value : null,
        plannedProjectObj : planningData ? planningData.plannedProject: null,

    },
  });


  const loginClickHandle = (e) => {
    e.preventDefault();
    e.persist();
    handleSubmit(e);
  };

  useEffect(()=>{
    getEmployeeListApi()
    getClientListApi()
    getProjectApi()
    getPlanningDetailsApi()
  },[])

  const updatePlanningApi = async (data) => {
    try {
      const response = await dispatch(
        planningThunk.updatePlanning({id, data, moduleId })
      );
      if (planningThunk.updatePlanning.fulfilled.match(response)) {
        navigate("/planning");
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const getPlanningDetailsApi = async () => {
    try {
      const response = await dispatch(
        planningThunk.planningDetails({ id, moduleId })
      );
      if (planningThunk.planningDetails.fulfilled.match(response)) {
        if (response.payload) {
            let employee = {};
            let currentProject = {}
            let plannedReplacement1 = {}
            let plannedReplacement2 = {}
            let plannedProject = {}
            let client = {}
            let res = response.payload;
            if(res && res.employee){
            employee.value =  res.employee.uid;
            employee.label = res.employee.name;
            res.employee = employee;
            } 
            if(res && res.currentProject){
                currentProject.value =  res.currentProject.uid;
                currentProject.label = res.currentProject.project_name;
                res.currentProject = currentProject;
            }
            if(res && res.plannedReplacement1){
                plannedReplacement1.value =  res.plannedReplacement1.uid;
                plannedReplacement1.label = res.plannedReplacement1.name;
                res.plannedReplacement1 = plannedReplacement1;
            }    
            if(res && res.plannedReplacement2){
                plannedReplacement2.value =  res.plannedReplacement2.uid;
                plannedReplacement2.label = res.plannedReplacement2.name;
                res.plannedReplacement2 = plannedReplacement2;
            }     
            if(res && res.plannedProject){
                plannedProject.value =  res.plannedProject.uid;
                plannedProject.label = res.plannedProject.project_name;
                res.plannedProject = plannedProject;
            }    
            if(res && res.client){
                client.value =  res.client.uid;
                client.label = res.client.name;
                res.client = client;
            }  
            setPlanningData(res);        
         }  
       
        }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const getEmployeeListApi = async () => {
    try {
      const response = await dispatch(
        employeeThunks.employeeList(moduleId)
      );
      if (employeeThunks.employeeList.fulfilled.match(response)) {
        if (response.payload) {
          setEmployeeList(response.payload);
        }
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };
  const getClientListApi = async () => {
    try {
      const response = await dispatch(
        clientThunks.clientList(moduleId)
      );
      if (clientThunks.clientList.fulfilled.match(response)) {
        if (response.payload) {
            setClientList(response.payload);
        }
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };
  const getProjectApi = async () => {
    try {
      const response = await dispatch(
        projectThunks.projectList(moduleId)
      );
      if (projectThunks.projectList.fulfilled.match(response)) {
        if (response.payload) {
            setProjectList(response.payload);
        }
      }
    } catch (error) {
      console.error("Error fetching project list:", error);
    }
  };
  

  const breadcrumb = {
    mainHeader: "Planning",
    subHeader: "Home",
    subHeaderLink: "/dashboard",
    subHeader1: "Planning",
    subHeader1Link: "/planning",
    subHeader2: "add",
    subHeader2Link: null,
  };

  return (
    <>
      <MainLayout breadcrumb={breadcrumb}>
        <div className="card card-info">
          <div className="card-body">
            <ul
              className="nav nav-tabs"
              style={{
                borderBottom: `2px solid ${ThemeColor.GREY}`,
                backgroundColor: "transparent",
              }}
            >
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    activeTab === "add" ? "active" : ""
                  }`}
                  onClick={() => handleTabChange("add")}
                  style={{
                    borderBottom: `3px solid ${
                      activeTab === "add" ? ThemeColor.BLUE : "transparent"
                    }`,
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    backgroundColor: "transparent",
                  }}
                >
                  Edit
                </button>
              </li>
            </ul>
            <form
              className="form-horizontal"
              id="personal_info"
              onSubmit={handleSubmit}
            >
              <div className="card-body">
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">
                    Employee  <span style={{ color: ThemeColor.RED }}>*</span>
                  </label>
                  <div className="col-sm-5  col-md-5">
                     <Select
                      placeholder="Select Employee"
                      isSearchable
                      id="employeeId"
                      name="employeeId"
                      onChange={(selectedOption) => {
                        setValues({
                          ...values,
                          employeeIdObj: selectedOption,
                          employeeId: selectedOption.value,
                        })
                      }}
                      value={values.employeeIdObj}
                      options={employeeList.map((data) => ({
                        value: data.uid,
                        label:  data.name ,
                      }))}
                    />
                    {errors.employeeId && touched.employeeId && (
                      <div className="error" style={{ color: ThemeColor.RED }}>
                        {errors.employeeId}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">
                    Current Project  <span style={{ color: ThemeColor.RED }}>*</span>
                  </label>
                  <div className="col-sm-5  col-md-5">
                     <Select
                      placeholder="Select Project"
                      isSearchable
                      id="currentProjectId"
                      name="currentProjectId"
                      onChange={(selectedOption) => {
                        setValues({
                            ...values,
                            currentProjectObj: selectedOption,
                            currentProjectId: selectedOption.value,
                          })
                      }}
                      value={values.currentProjectObj}
                      options={projectList.map((data) => ({
                        value: data.uid,
                        label: data.project_name,
                      }))}
                    />
                    {errors.currentProjectId && touched.currentProjectId && (
                      <div className="error" style={{ color: ThemeColor.RED }}>
                        {errors.currentProjectId}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Start Date <span style={{ color: ThemeColor.RED }}>*</span>
                  </label>
                  <div className="col-sm-5  col-md-5">
                     <Datepicker
                            name="startDate"
                            id="startDate"
                            value={values.startDate}
                            dateFormat="dd-mm-yyyy"
                            onChange={(date) =>
                            setValues({
                                ...values,
                                startDate: parseCustomDate(date.target.value),
                            })
                            }
                        />
                     {errors.startDate && touched.startDate && (
                      <div className="error" style={{ color: ThemeColor.RED }}>
                        {errors.startDate}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Last Working Day <span style={{ color: ThemeColor.RED }}>*</span>
                  </label>
                  <div className="col-sm-5  col-md-5">
                     <Datepicker
                            name="lastWorkingDay"
                            id="lastWorkingDay"
                            value={values.lastWorkingDay}
                            dateFormat="dd-mm-yyyy"
                            onChange={(date) =>
                            setValues({
                                ...values,
                                lastWorkingDay: parseCustomDate(date.target.value),
                            })
                            }
                        />
                     {errors.lastWorkingDay && touched.lastWorkingDay && (
                      <div className="error" style={{ color: ThemeColor.RED }}>
                        {errors.lastWorkingDay}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Leave Planned Date <span style={{ color: ThemeColor.RED }}>*</span>
                  </label>
                  <div className="col-sm-5  col-md-5">
                     <Datepicker
                            name="leavePlannedDate"
                            id="leavePlannedDate"
                            value={values.leavePlannedDate}
                            dateFormat="dd-mm-yyyy"
                            onChange={(date) =>
                            setValues({
                                ...values,
                                leavePlannedDate: parseCustomDate(date.target.value),
                            })
                            }
                        />
                     {errors.leavePlannedDate && touched.leavePlannedDate && (
                      <div className="error" style={{ color: ThemeColor.RED }}>
                        {errors.leavePlannedDate}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Return Date <span style={{ color: ThemeColor.RED }}>*</span>
                  </label>
                  <div className="col-sm-5  col-md-5">
                     <Datepicker
                            name="returnDate"
                            id="returnDate"
                            value={values.returnDate}
                            dateFormat="dd-mm-yyyy"
                            onChange={(date) =>
                            setValues({
                                ...values,
                                returnDate: parseCustomDate(date.target.value),
                            })
                            }
                        />
                     {errors.returnDate && touched.returnDate && (
                      <div className="error" style={{ color: ThemeColor.RED }}>
                        {errors.returnDate}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">
                    Planned Replacement 1  <span style={{ color: ThemeColor.RED }}>*</span>
                  </label>
                  <div className="col-sm-5  col-md-5">
                     <Select
                      placeholder="Select Employee"
                      isSearchable
                      id="plannedReplacementId1"
                      name="plannedReplacementId1"
                      onChange={(selectedOption) => {
                        setValues({
                            ...values,
                            plannedReplacement1Obj: selectedOption,
                            plannedReplacementId1: selectedOption.value,
                          })
                      }}
                      value={values.plannedReplacement1Obj}
                      options={employeeList.map((data) => ({
                        value: data.uid,
                        label:  data.employee_code + " - " + data.name,
                      }))}
                    />
                    {errors.plannedReplacementId1 && touched.plannedReplacementId1 && (
                      <div className="error" style={{ color: ThemeColor.RED }}>
                        {errors.plannedReplacementId1}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">
                    Planned Replacement 2 
                  </label>
                  <div className="col-sm-5  col-md-5">
                     <Select
                      placeholder="Select Employee"
                      isSearchable
                      id="plannedReplacementId2"
                      name="plannedReplacementId2"
                      onChange={(selectedOption) => {
                        setValues({
                            ...values,
                            plannedReplacement2Obj: selectedOption,
                            plannedReplacementId2: selectedOption.value,
                          })
                      }}
                      value={values.pla}
                      options={employeeList.map((data) => ({
                        value: data.uid,
                        label:  data.employee_code + " - " + data.name,
                      }))}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Client <span style={{ color: ThemeColor.RED }}>*</span>
                   </label>
                  <div className="col-sm-5  col-md-5">
                    <Select
                      placeholder="Select Client"
                      isSearchable
                      id="clientId"
                      name="clientId"
                      onChange={(selectedOption) => {
                        setValues({
                            ...values,
                            clientObj: selectedOption,
                            clientId: selectedOption.value,
                          })
                      }}
                      value={values.clientObj}
                      options={clientList.map((data) => ({
                        value: data.uid,
                        label: data.name,
                      }))}
                    />
                    {errors.clientId && touched.clientId && (
                      <div className="error" style={{ color: ThemeColor.RED }}>
                        {errors.clientId}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Planned Project</label>
                  <div className="col-sm-5  col-md-5">
                     <Select
                      placeholder="Select Project"
                      isSearchable
                      id="plannedProjectId"
                      name="plannedProjectId"
                      onChange={(selectedOption) => {
                        setValues({
                            ...values,
                            plannedProjectObj: selectedOption,
                            plannedProjectId: selectedOption.value,
                          })
                      }}
                      value={values.plannedProjectObj}
                      options={projectList.map((data) => ({
                        value: data.uid,
                        label: data.project_name,
                      }))}
                    />
                  </div>
                </div>
                <div className="form-group row">
          <label className="col-sm-4 col-md-3">Status</label>
          <div className="col-sm-5  col-md-5">
            <div className="d-flex" style={{ gap: "5px" }}>
              <input
                style={{ width: "20px" }}
                name="isOpen"
                id="isOpen"
                type="radio"
                value="true"
                onChange={handleChange}
                checked={values.isOpen === "true"}
              />
              Open
              <input
                style={{ width: "20px", marginLeft: "10px" }}
                type="radio"
                name="isOpen"
                id="isOpen"
                value="false"
                onChange={handleChange}
                checked={values.isOpen === "false"}
              />
              Close
            </div>
          </div>
        </div>
              </div>
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ gap: "10px", marginTop: "30px" }}
              >
                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{
                    padding: "8px 20px",
                    width: "120px",
                  }}
                  onClick={(e) => loginClickHandle(e)}
                >
                  <i
                    className="fa fa-floppy-o"
                    style={{ marginRight: "8px" }}
                  />
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-default"
                  style={{
                    padding: "8px 20px",
                    width: "120px",
                  }}
                  onClick={()=>{navigate("/planning")}}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default EditPlanning;
