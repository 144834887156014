import React, { useEffect, useState } from "react";
import Datatable from "../../components/datatable";
import { useDispatch } from "../../store";
import { ThemeColor } from "../../constants/themeColorConstant";
import { useLocation, useNavigate } from "react-router-dom";
import MainLayout from "../layout/MainLayout";
import { assetThunks } from "../../store/reducers/asset/asset.thunk";
import ConfirmationBox from "../../components/ConfirmationBox";

const Assets = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [assetsData, setAssetsData] = useState([]);
  const [option, setOption] = useState({});
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [assetIdToDelete, setAssetIdToDelete] = useState(null);

  const location = useLocation();
  const pathnameSegments = location.pathname.split("/");
  const moduleName = pathnameSegments[1];

  const allowedModules = JSON.parse(localStorage.getItem("allowedModules"));
  const selectedModule = allowedModules.find(
    (module) => module.slug === moduleName
  );

  const moduleId = selectedModule.uid;

  const renderActions = (rowData) => {
    if (rowData && rowData.uid) {
    const assetId = rowData.uid;
    const editIcon = (
      <i
        className="fa fa-edit"
        style={{ cursor: "pointer" }}
       onClick={() => navigate(`/assets/edit/${assetId}`, { state: { moduleId } })}
      />
    );
    const deleteIcon = (
      <i style={{ marginLeft: "10px", cursor: "pointer" }}
        className="fa fa-trash-o"
       onClick={() => {
        setAssetIdToDelete(assetId);
        setShowConfirmation(true);
      }}
      />
    );

    return (
      <div>
        {editIcon}
        {deleteIcon}
      </div>
    );
    }
    else{
      return null
    }
  };

  const columns = [
    {
      name: "action",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const rowData = assetsData[dataIndex];
          return renderActions(rowData);
        },
      },
    },
    {
      name: "assetCode",
      label: "Asset Code",
    },
    {
      name: "manufacturer",
      label: "Manufacturer",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "type",
      label: "type",
    },
    {
      name: "serialNumber",
      label: "Serial Number",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "purchaseDate",
      label: "Purchase date",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "softwareName",
      label: "Software Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "installedDate",
      label: "Installed Date",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "expiryDate",
      label: "Expiry Date",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "description",
      label: "Description",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "employeeCode",
      label: "Emp Code",
      // options: {
      //   filter: false,
      //   sort: true,
      //   customBodyRender: (value) => {
      //     return value ? value.employee_code : "";
      //   }, 
      // },
    },
    {
        name: "employeeName",
        label: "Assigned To",
        // options: {
        //   filter: false,
        //   sort: true,
        //   customBodyRender: (value) => {
        //     return value ? value.name : "";
        //   }, 
        // },
      },
      {
        name: "fromDate",
        label: "Assigned Date",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "projectName",
        label: "Project",
        // options: {
        //   filter: false,
        //   sort: true,
        //   customBodyRender: (value) => {
        //     return value ? value.project_name : "";
        //   },
        // },
      },
      {
        name: "office",
        label: "Office/Website",
        options: {
          filter: false,
          sort: true,
        },
      },
  ];


  useEffect(() => {
    getAssetDetailsApi()
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

    const getAssetDetailsApi = async (page=1,rowsPerPage=10,searchText='',name='',order='') => {
      try {
        const response = await dispatch(assetThunks.assetList({moduleId,page,rowsPerPage,searchText,name,order}));
        if (assetThunks.assetList.fulfilled.match(response)) {
          if (response.payload) {
            const options = {
              count: response.payload.totalRecords,
              page: response.payload.currentPage-1, // Zero-based index
              rowsPerPage:response.payload.recordsPerPage,
            }
            setOption(options)
            setAssetsData(response.payload.data);
            return response.payload
          }
        }
      } catch (error) {
        console.error("Error fetching client data:", error);
      }
    };

    const handleConfirmDelete = async (id) => {
      try {
        const response = await dispatch(assetThunks.deleteAsset({id:assetIdToDelete,moduleId}));
        if (assetThunks.deleteAsset.fulfilled.match(response)) {
          const updatedAssetData = assetsData.filter((asset) => asset && asset.uid !== assetIdToDelete); // Check if 'role' exists
          setAssetsData(updatedAssetData);
        }
      } catch (error) {
        console.error("Error fetching asset data:", error);
      }
      setAssetIdToDelete(null);
      setShowConfirmation(false);
    };

  const breadcrumb = {
    mainHeader: "Assets Master",
    subHeader: "Home",
    subHeaderLink: "/dashboard",
    subHeader1: "Assets Master",
    subHeader1Link: null,
    subHeader2: null,
    subHeader2Link: null,
  };

  return (
    <MainLayout breadcrumb={breadcrumb}>
      <div className="card">
        <div className="card-body">
          <div className="card-header ">
            <p
              className="card-title"
              style={{
                fontSize: "20px",
                fontWeight: "500",
                color: ThemeColor.EASTERNBLUE,
              }}
            >
              Assets Master
            </p>
            <div className="d-flex justify-content-end">
              <button
                onClick={() =>
                  navigate("/assets/add", { state: { moduleId } })
                }
                className="btn btn-primary"
              >
                Add
              </button>

            </div>
          </div>
          <Datatable
            columns={columns}
            data={assetsData}
            options={option}
            customBodyRender={renderActions}
            api={getAssetDetailsApi}
          />
        </div>
      </div>
      <ConfirmationBox
        show={showConfirmation}
        onHide={() => setShowConfirmation(false)}
        onConfirm={handleConfirmDelete}
        message="Are you sure you want to delete asset?"
      />
    </MainLayout>
  );
};

export default Assets;
