import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { employeeThunks } from "../../store/reducers/employee/employee.thunk";
import { useDispatch } from "../../store";
import { ThemeColor } from "../../constants/themeColorConstant";
import MainLayout from "../layout/MainLayout";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";

const validationSchema = Yup.object().shape({
  type: Yup.string().required("Please select type"),
  name: Yup.string()
    .required("Please enter employee name")
    .matches(/^[A-Za-z\s]+$/, "Employee name must contain only letters")
    .trim(),
  dateOfJoining : Yup.string().required("Please select joining date")  
});

const AddEmployee = () => {
  const [activeTab, setActiveTab] = useState("Personal");
  const [typeEmployee, setEmployeeType] = useState([]);
  const [employeeDesignation, setEmployeeDesignation] = useState([]);
  const [employeeDepartment, setEmployeeDepartment] = useState([]);
  const [gender, setGender] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  const location = useLocation();
  const { moduleId } = location.state || {};
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    resetForm,
    setFieldValue,
  } = useFormik({
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      await addEmployeeApi(values);
      resetForm();
    },
    initialValues: {
      type: "",
      name: "",
      dateOfJoining:"",
    },
  });

  useEffect(() => {
    getEmployeeStatusApi();
    getEmployeeDepartmentApi();
    getEmployeeDesignationApi();
    getEmployeeGenderApi();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loginClickHandle = (e) => {
    e.preventDefault();
    e.persist();
    handleSubmit(e);
  };

  const addEmployeeApi = async (data) => {
    try {
      const response = await dispatch(
        employeeThunks.addEmployee({ data, moduleId })
      );
      if (employeeThunks.addEmployee.fulfilled.match(response)) {
        navigate("/employee");
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const getEmployeeStatusApi = async () => {
    try {
      const response = await dispatch(
        employeeThunks.getEmployeeType(moduleId)
      );
      if (employeeThunks.getEmployeeType.fulfilled.match(response)) {
        if (response.payload) {
          setEmployeeType(response.payload);
        }
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };
  const getEmployeeDepartmentApi = async () => {
    try {
      const response = await dispatch(
        employeeThunks.getDesignationList(moduleId)
      );
      if (employeeThunks.getDesignationList.fulfilled.match(response)) {
        if (response.payload) {
          setEmployeeDesignation(response.payload);
        }
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };
  const getEmployeeDesignationApi = async () => {
    try {
      const response = await dispatch(
        employeeThunks.getDepartmentList(moduleId)
      );
      if (employeeThunks.getDepartmentList.fulfilled.match(response)) {
        if (response.payload) {
          setEmployeeDepartment(response.payload);
        }
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };
  const getEmployeeGenderApi = async () => {
    try {
      const response = await dispatch(employeeThunks.getGender(moduleId));
      if (employeeThunks.getGender.fulfilled.match(response)) {
        if (response.payload) {
          setGender(response.payload);
        }
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const breadcrumb = {
    mainHeader: "Employees",
    subHeader: "Home",
    subHeaderLink: "/dashboard",
    subHeader1: "Employees",
    subHeader1Link: "/employee",
    subHeader2: "add",
    subHeader2Link: null,
  };

  return (
    <>
      <MainLayout breadcrumb={breadcrumb}>
        <div className="card card-info">
          <div className="card-body">
            <ul
              className="nav nav-tabs"
              style={{
                borderBottom: `2px solid ${ThemeColor.GREY}`,
                backgroundColor: "transparent",
              }}
            >
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    activeTab === "Personal" ? "active" : ""
                  }`}
                  onClick={() => handleTabChange("Personal")}
                  style={{
                    borderBottom: `3px solid ${
                      activeTab === "Personal" ? ThemeColor.BLUE : "transparent"
                    }`,
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    backgroundColor: "transparent",
                  }}
                >
                  Personal
                </button>
              </li>
            </ul>
            <form
              className="form-horizontal"
              id="personal_info"
              onSubmit={handleSubmit}
            >
              <div className="card-body">
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">
                    Employee Type <span style={{ color: ThemeColor.RED }}>*</span>
                  </label>
                  <div className="col-sm-5  col-md-5">
                     <Select
                      placeholder="Select Type"
                      isSearchable
                      id="type"
                      name="type"
                      onChange={(selectedOption) => {
                        setFieldValue(
                          "type",
                          selectedOption ? selectedOption.value : ""
                        );
                      }}
                      options={typeEmployee.map((data) => ({
                        value: data.slug,
                        label: data.name,
                      }))}
                    />
                    {errors.type && touched.type && (
                      <div className="error" style={{ color: ThemeColor.RED }}>
                        {errors.type}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">
                    Name <span style={{ color: ThemeColor.RED }}>*</span>
                  </label>
                  <div className="col-sm-5  col-md-5">
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      id="name"
                      placeholder="Employee Name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.name && touched.name && (
                      <div className="error" style={{ color: ThemeColor.RED }}>
                        {errors.name}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Gender</label>
                  <div className="col-sm-5  col-md-5">
                    <div className="d-flex" style={{ gap: "5px" }}>
                      {gender.map((data) => (
                        <label style={{ fontWeight: "normal" }} key={data.slug}>
                          <input
                            style={{ width: "20px" }}
                            type="radio"
                            name="gender"
                            id={data.slug}
                            value={data.slug}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {data.name}
                        </label>
                      ))}
                    </div>

                    <div className="error" id="err_name" />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Date Of Birth</label>
                  <div className="col-sm-5  col-md-5">
                    <input
                      type="date"
                      className="form-control"
                      name="dob"
                      id="dob"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Designation</label>
                  <div className="col-sm-5  col-md-5">
                    <Select
                      placeholder="Select Designation"
                      isSearchable
                      id="designationId"
                      name="designationId"
                      onChange={(selectedOption) => {
                        setFieldValue(
                          "designationId",
                          selectedOption ? selectedOption.value : ""
                        );
                      }}
                      options={employeeDesignation.map((employee) => ({
                        value: employee.uid,
                        label:  employee.name,
                      }))}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Department</label>
                  <div className="col-sm-5  col-md-5">
                     <Select
                      placeholder="Select Department"
                      isSearchable
                      id="departmentId"
                      name="departmentId"
                      onChange={(selectedOption) => {
                        setFieldValue(
                          "departmentId",
                          selectedOption ? selectedOption.value : ""
                        );
                      }}
                      options={employeeDepartment.map((employee) => ({
                        value: employee.uid,
                        label:  employee.name,
                      }))}
                    />
                  </div>
                </div>
                {/* <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Experience</label>
                  <div className="col-sm-5  col-md-5">
                    <input
                      type="date"
                      className="form-control"
                      name="experience"
                      id="experience"
                      placeholder="Experience"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div> */}
                <div className="form-group row">
              <label className="col-sm-4 col-md-3">Date Of Joining <span style={{ color: ThemeColor.RED }}>*</span> </label>
              <div className="col-sm-5  col-md-5">
              <input
                      type="date"
                      className="form-control"
                      name="dateOfJoining"
                      id="dateOfJoining"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                      {errors.dateOfJoining && touched.dateOfJoining && (
                      <div className="error" style={{ color: ThemeColor.RED }}>
                        {errors.dateOfJoining}
                      </div>
                    )}
              </div>
            
              </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Gratuity Date</label>
                  <div className="col-sm-5  col-md-5">
                    <input
                      type="date"
                      className="form-control"
                      name="gratuityDate"
                      id="gratuityDate"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">
                    Start Experience Date
                  </label>
                  <div className="col-sm-5  col-md-5">
                    <input
                      type="date"
                      className="form-control"
                      name="startExperienceDate"
                      id="startExperienceDate"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Photo</label>
                  <div className="col-sm-5  col-md-5">
                    {selectedImage && (
                      <img
                        src={URL.createObjectURL(selectedImage)}
                        alt="Employee profile"
                        style={{
                          width: "125px",
                          height: "125px",
                          marginLeft: "10px",
                          marginRight: "10px",
                        }}
                      />
                    )}

                    <input
                      type="file"
                      name="photo"
                      id="photo"
                      onChange={(event) => {
                        const file = event.target.files[0];
                        setSelectedImage(file);
                        const fileExtension = file.name.split(".").pop();
                        const reader = new FileReader();
                        reader.onload = (e) => {
                          const base64Data = e.target.result;
                          const photoObject = {};
                          photoObject[`emp.${fileExtension}`] = base64Data;

                          setFieldValue("photo", photoObject);
                        };
                        reader.readAsDataURL(file);
                      }}
                    />
                    <div className="error" id="err_name" />
                  </div>
                </div>
              </div>
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ gap: "10px", marginTop: "30px" }}
              >
                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{
                    padding: "8px 20px",
                    width: "120px",
                  }}
                  onClick={(e) => loginClickHandle(e)}
                >
                  <i
                    className="fa fa-floppy-o"
                    style={{ marginRight: "8px" }}
                  />
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-default"
                  style={{
                    padding: "8px 20px",
                    width: "120px",
                  }}
                  onClick={()=>{navigate("/employee")}}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default AddEmployee;
