import React, { useState } from 'react';
import MainLayout from '../layout/MainLayout';
import { userTab } from '../../data/data';
import UserProfile from './UserProfile';
import ForgotPassword from './ForgotPassword';
import { ThemeColor } from '../../constants/themeColorConstant';

const EditProfile = () => {

    const [activeTab, setActiveTab] = useState("profile-details");


    const handleTabChange = (tab) => {
        setActiveTab(tab.slug);
      };

      const breadcrumb = {
        mainHeader: "Profile",
        subHeader : "Home",
        subHeaderLink : "/dashboard",
        subHeader1 : "Profile",
        subHeader1Link : null,
        subHeader2 : null,
        subHeader2Link : null,
      };

  return (
    <MainLayout  breadcrumb={breadcrumb}>
       <div className="card card-info">
        <div className="card-body">
          <ul
            className="nav nav-tabs"
            style={{
              borderBottom: `2px solid ${ThemeColor.GREY}`,
              backgroundColor: "transparent",
            }}
          >
            {userTab.map((tab) => (
              <li className="nav-item" key={tab.id}>
                <button
                  className={`nav-link ${
                    activeTab === tab.slug ? "active" : ""
                  }`}
                  onClick={() => handleTabChange(tab)}
                  style={{
                    borderBottom: `3px solid ${
                      activeTab === tab.slug ? ThemeColor.BLUE : "transparent"
                    }`,
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    backgroundColor: "transparent",
                  }}
                >
                  {tab.name}
                </button>
              </li>
            ))}
          </ul>
          {activeTab === "profile-details" && (
            <UserProfile
              // employeeData={employeeData}
              // moduleId={moduleId}
              activeTab={activeTab}
            />
          )}
          {activeTab === "change-password" && (
            <ForgotPassword
              // moduleId={moduleId}
              // employeeData={employeeData}
              activeTab={activeTab}
            />
          )}
        </div>
      </div>
    </MainLayout>
  );
}

export default EditProfile;
