import React from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import { processMonth } from "../../utils/processMonth";

const barColors = [
  "#8884d8",
  "#8884d8",
  "#8884d8",
  "#8884d8",
  "#8884d8",
  "#8884d8",
  "#8884d8",
  "#8884d8",
];
function CustomLegend({ selectedYear }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          width: "40px",
          height: "15px",
          backgroundColor: "#8884d8",
          marginRight: "1em",
        }}
      ></div>
      <p style={{ margin: 0 }}>Payments for {selectedYear}</p>
    </div>
  );
}
export default function SalaryChart({ data, selectedYear }) {
  const processedPoints = data
    ? Object.entries(data).map((e) => {
        return {
          Month: processMonth(e[0].toString()),
          Amount: e[1],
        };
      })
    : [];

  return (
    <ResponsiveContainer>
      <BarChart
        data={processedPoints}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="Month" />
        <YAxis dataKey="Amount" />
        <Tooltip />
        <Bar dataKey="Amount">
          {processedPoints.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={barColors[index % 20]} />
          ))}
        </Bar>
        <Legend content={<CustomLegend selectedYear={selectedYear} />} />
      </BarChart>
    </ResponsiveContainer>
  );
}
