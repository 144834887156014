import React, { useEffect, useState } from "react";
import Datatable from "../../components/datatable";

import { useDispatch } from "../../store";
import { ThemeColor } from "../../constants/themeColorConstant";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MainLayout from "../layout/MainLayout";
import { packageThunks } from "../../store/reducers/package/package.thunk";
import { payrollThunks } from "../../store/reducers/payroll/payroll.thunk";

const WpsReport = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const [wpsReportData, setWpsReportData] = useState([]);
  const [option, setOption] = useState({});

  const location = useLocation();
  const pathnameSegments = location.pathname.split("/");
  const moduleName = pathnameSegments[1];

  const allowedModules = JSON.parse(localStorage.getItem("allowedModules"));
  const selectedModule = allowedModules.find(
    (module) => module.slug === moduleName
  );

  const moduleId = selectedModule.uid;


  const columns = [
    {
      name: "empCode",
      label: "Employee ID",
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "designationName",
      label: "Designation",
    },
    {
      name: "wpsRouteCode",
      label: "Route Code",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "wpsPersonalNo",
      label: "Personal Number",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "wpsIban",
      label: "WPS No",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "netPayable",
      label: "Net Salary Payable",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "month",
      label: "Month",
      options: {
        filter: false,
        sort: true,
      },
    },
  ];


  useEffect(() => {
    getWpsReportApi()
  }, []);

  const getWpsReportApi = async (page = 1, rowsPerPage = 10, searchText = '', name = '', order = '') => {
    try {
      const selectedMonthYear = params.month;
      const formattedMonthYear = selectedMonthYear.split('-').reverse().join('/');
      const response = await dispatch(payrollThunks.viewWps({ selectedMonthYear: formattedMonthYear, page, rowsPerPage, searchText, name, order }));
      if (payrollThunks.viewWps.fulfilled.match(response)) {
        if (response.payload) {
          const options = {
            count: response.payload.totalRecords,
            page: response.payload.currentPage - 1, // Zero-based index
            rowsPerPage: response.payload.recordsPerPage,
          }
          setOption(options)
          setWpsReportData(response.payload.data);
          return response.payload
        }
      }
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };


  const breadcrumb = {
    mainHeader: "View Payroll WPS Report",
    subHeader: "Home",
    subHeaderLink: "/dashboard",
    subHeader1: "View Payroll",
    subHeader1Link: "/payroll/view-payroll",
    subHeader2: "View Payroll-WPS",
    subHeader2Link: null,
  };

  return (
    <MainLayout breadcrumb={breadcrumb}>
      <div className="card">
        <Datatable
          columns={columns}
          data={wpsReportData}
          options={option}
          api={getWpsReportApi}
        />
      </div>

    </MainLayout>
  );
};

export default WpsReport;
