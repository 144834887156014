import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "../../store";
import { ThemeColor } from "../../constants/themeColorConstant";
import MainLayout from "../layout/MainLayout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { employeeThunks } from "../../store/reducers/employee/employee.thunk";
import {  expenseDeductionThunks } from "../../store/reducers/expenseDeduction/expenseDeduction.thunk";
import Datepicker from "../../components/datepicker";
import { parseCustomDate } from "../employee/Personal";
import Select from "react-select";

const validationSchema = Yup.object().shape({
  employeeId: Yup.string().required("Please select employee"),
  type: Yup.string().required("Please select type"),
});

const EditExpenseDeduction = () => {

  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState("addNew");
  const [employeeList,setEmployeeList] = useState([])
  const [expenseTypeList,setExpenseTypeList] = useState([])
  const [expenseData,setExpenseData] = useState([])

  const location = useLocation();
  const { moduleId } = location.state || {};
  const {id} = useParams()

  const dispatch = useDispatch();

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    touched,
    setValues,
    handleSubmit,
  } = useFormik({
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      delete values.employeeIdObj;
        const formattedValues = {
            ...values,
            date: values.date && parseCustomDate(values.date),
          };
      await updateExpenseDeductionApi(formattedValues);
    },
    initialValues: {
      employeeId : expenseData && expenseData.employee ? expenseData.employee.value :"" ,
      employeeIdObj: expenseData.employee ? expenseData.employee : null,
      type: expenseData ?  parseInt(expenseData.type) : "",
      amount : expenseData ? expenseData.amount : null,
      date : expenseData ? expenseData.date : null,
      remark : expenseData ? expenseData.remark : null
    },
  });


  useEffect(() => {
    employeeListApi()
    expenseTypeListApi()
    getExpenseDataApi()
   // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const loginClickHandle = (e) => {
    e.preventDefault();
    e.persist();
    handleSubmit(e);
  };

    const updateExpenseDeductionApi = async (data) => {
      try {
        const response = await dispatch(
          expenseDeductionThunks.updateExpense({ id,data, moduleId })
        );
        if (expenseDeductionThunks.updateExpense.fulfilled.match(response)) {
          
            navigate("/expense-deduction")
            return response.payload;
          
        }
      } catch (error) {
        console.error("Error fetching employee data:", error);
      }
    };

    const getExpenseDataApi = async () => {
        try {
          const response = await dispatch(expenseDeductionThunks.getExpenseDetails({id,moduleId}));
          if (expenseDeductionThunks.getExpenseDetails.fulfilled.match(response)) {
            if (response.payload) {
              let employee = {};
              let res = response.payload;
              if(res && res.employee){
              employee.value =  res.employee.uid;
              employee.label = res.employee.name;
              res.employee = employee;}
              setExpenseData(res);
            }
          }
        } catch (error) {
          console.error("Error fetching employee data:", error);
        }
      };

  

    const employeeListApi = async () => {
        try {
          const response = await dispatch(
            employeeThunks.employeeList({moduleId })
          );
          if (employeeThunks.employeeList.fulfilled.match(response)) {
            if (response.payload) {
              setEmployeeList(response.payload);
              return response.payload;
            }
          }
        } catch (error) {
          console.error("Error fetching company data:", error);
        }
      };

      const expenseTypeListApi = async () => {
        try {
          const response = await dispatch(
            expenseDeductionThunks.expenseTypeList({moduleId })
          );
          if (expenseDeductionThunks.expenseTypeList.fulfilled.match(response)) {
            if (response.payload) {
              setExpenseTypeList(response.payload);
              return response.payload;
            }
          }
        } catch (error) {
          console.error("Error fetching company data:", error);
        }
      };

  const breadcrumb = {
    mainHeader: "Expense Edit",
    subHeader: "Home",
    subHeaderLink: "/dashboard",
    subHeader1: "Expense Edit",
    subHeader1Link: "/expense-deduction",
    subHeader2: "edit",
    subHeader2Link: null,
  };

  return (
    <>
      <MainLayout breadcrumb={breadcrumb}>
        <div className="card card-info">
          <div className="card-body">
            <ul
              className="nav nav-tabs"
              style={{
                borderBottom: `2px solid ${ThemeColor.GREY}`,
                backgroundColor: "transparent",
              }}
            >
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    activeTab === "addNew" ? "active" : ""
                  }`}
                  onClick={() => handleTabChange("addNew")}
                  style={{
                    borderBottom: `3px solid ${
                      activeTab === "addNew" ? ThemeColor.BLUE : "transparent"
                    }`,
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    backgroundColor: "transparent",
                  }}
                >
                  Edit Expense
                </button>
              </li>
            </ul>
            <form
              className="form-horizontal"
              id="personal_info"
              onSubmit={handleSubmit}
            >
              <div className="card-body">
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">
                    Employee <span style={{ color: ThemeColor.RED }}>*</span>
                  </label>
                  <div className="col-sm-5  col-md-5">
                    <Select
                placeholder="Select Employee"
                isSearchable
                id="employeeId"
                name="employeeId"
                onChange={(selectedOption) => {
                  setValues({
                    ...values,
                    employeeIdObj: selectedOption,
                    employeeId: selectedOption.value,
                  })
                }}
                value={
                  values.employeeIdObj
                }
                options={employeeList.map((employee) => ({
                  value: employee.uid,
                  label:  employee.employee_code + " - " + employee.name,
                }))}
                    />
                    {errors.employeeId && touched.employeeId && (
                      <div className="error" style={{ color: ThemeColor.RED }}>
                        {errors.employeeId}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">
                    Type <span style={{ color: ThemeColor.RED }}>*</span>
                  </label>
                  <div className="col-sm-5  col-md-5">
                  <select
                      className="form-control"
                      name="type"
                      id="type"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {expenseTypeList.map((data, index) => {
                        return (
                          <option key={index} value={data.id} selected={data.id == values.type}>
                            {data.name}
                          </option>
                        );
                      })}
                    </select>
                    {errors.type && touched.type && (
                      <div className="error" style={{ color: ThemeColor.RED }}>
                        {errors.type}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Amount</label>
                  <div className="col-sm-5  col-md-5">
                    <input
                      type="text"
                      className="form-control"
                      name="amount"
                      id="amount"
                      value={values.amount}
                      placeholder="Amount"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Date</label>
                  <div className="col-sm-5  col-md-5">
                  <Datepicker
                    name="date"
                    id="date"
                    value={values.date}
                    dateFormat="yyyy-mm-dd"
                    onChange={(date) =>
                      setValues({
                        ...values,
                        date: parseCustomDate(date.target.value),
                      })
                    }
                  />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-md-3">Remark</label>
                  <div className="col-sm-5  col-md-5">
                    <textarea
                      id="remark"
                      name="remark"
                      cols={2}
                      rows={2}
                      value={values.remark}
                      className="form-control"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    ></textarea>
                  </div>
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-center" style={{ gap: "10px", marginTop: "30px" }}>
                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{ padding: "8px 20px" }}
                  onClick={(e) => loginClickHandle(e)}
                >
                  <i
                    className="fa fa-floppy-o"
                    style={{ marginRight: "8px" }}
                  />
                  Update
                </button>
                <button
                  type="button"
                  className="btn btn-default"
                  style={{
                    padding: "8px 20px",
                    width: "120px",
                  }}
                  onClick={()=>{navigate("/expense-deduction")}}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default EditExpenseDeduction;
