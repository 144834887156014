import { BrowserRouter, Route, Routes } from "react-router-dom";
import ProtectedRoute from "../components/auth/ProtectedRoutes";
import UnProtectedRoute from "../components/auth/UnProtectedRoutes";
import Dashboard from "../pages/dashboard/Dashboard";
import ForgotPassword from "../pages/ForgotPassword";
import Signin from "../pages/Signin";
import AddEmployee from "../pages/employee/AddEmployee";
import EmployeeDetails from "../pages/employee/EmployeeDetails";
import EditEmployee from "../pages/employee/EditEmployee";
import Company from "../pages/company/Company";
import Role from "../pages/Role/Role";
import Client from "../pages/client/Client";
import AddClient from "../pages/client/AddClient";
import EditClient from "../pages/client/EditClient";
import BankDetails from "../pages/bank/BankDetails";
import EditDetails from "../pages/bank/EditDetails";
import Project from "../pages/project/Project";
import Insurance from "../pages/insurance/Insurance";
import EditInsurance from "../pages/insurance/EditInsurance";
import AuthorityDetails from "../pages/authority/AuthorityDetails";
import EditAuthority from "../pages/authority/EditAuthority";
import Leave from "../pages/leave/Leave";
import LeaveDetails from "../pages/leave/LeaveDetails";
import AttendanceDetails from "../pages/attendanceView/AttendanceDetails";
import EditRole from "../pages/Role/EditRole"
import Users from "../pages/users/Users";
import EditUser from "../pages/users/EditUser";
import Attendance from "../pages/attendance/Attendance";
import EditPackage from "../pages/package/EditPackage";
import PackageDetails from "../pages/package/PackageDetails";
import EditProject from "../pages/project/EditProject";
import ExpenseDeduction from "../pages/expenseDeduction/ExpenseDeduction";
import AddRole from "../pages/Role/AddRole";
import AddUser from "../pages/users/AddUser";
import Planning from "../pages/planning/Planning";
import Assets from "../pages/assets/Assets";
import EditAssets from "../pages/assets/EditAssets";
import Form from "../pages/form/Form";
import AddForm from "../pages/form/AddForm";
import EditForm from "../pages/form/EditForm";
import Vehicle from "../pages/vehicle/Vehicle";
import AddVehicle from "../pages/vehicle/AddVehicle";
import EditVehicle from "../pages/vehicle/EditVehicle";
import VehicleHistory from "../pages/vehicle/VehicleHistory";
import Gratuity from "../pages/gratuity/Gratuity";
import AddGratuity from "../pages/gratuity/AddGratuity";
import EditGratuity from "../pages/gratuity/EditGratuity";
import Priority from "../pages/priority/Priority";
import AddAssets from "../pages/assets/AddAssets";
import AddProject from "../pages/project/AddProject";
import EmployeeLogin from "../pages/employeeLogin/EmployeeLogin";
import AddExpenseDeduction from "../pages/expenseDeduction/AddExpenseDeduction";
import ContactManage from "../pages/client/ContactManage";
import EditContact from "../pages/client/EditContact";
import Payroll from "../pages/payroll/Payroll";
import AddContact from "../pages/client/AddContact";
import EditExpenseDeduction from "../pages/expenseDeduction/EditExpenseDeduction";
import PayrollSummary from "../pages/payroll/PayrollSummary";
import ViewPayslip from "../pages/payroll/ViewPayslip";
import EmailPayslip from "../pages/payroll/EmailPayslip";
import EmployeeWiseReport from "../pages/report/EmployeeWiseReport";
import EmployeeWiseReport2 from "../pages/report/EmployeeWiseReport2";
import ProjectWiseReport from "../pages/report/ProjectWiseReport";
import LeaveReport from "../pages/report/LeaveReport";
import VehicleReport from "../pages/report/VehicleReport";
import ProjectSalaryExpense from "../pages/report/ProjectSalaryExpense";
import EditProfile from "../pages/userProfile/EditProfile";
import GeneratePayroll from "../pages/payroll/GeneratePayroll";
import ProjectExpenseDetails from "../pages/projectCosting/projectExpenseDetails/ProjectExpenseDetails";
import AddProjectExpenseDetails from "../pages/projectCosting/projectExpenseDetails/AddProjectExpenseDetail";
import EditProjectExpenseDetails from "../pages/projectCosting/projectExpenseDetails/EditProjectExpenseDetails";
import Chart from "../pages/chart/Chart";
import SubContractor from "../pages/projectCosting/SubContractor";
import AddSubContractor from "../pages/projectCosting/AddSubcontractor";
import EditSubContractor from "../pages/projectCosting/EditSubcontractor";
import ProjectCosting from "../pages/projectCosting/ProjectCosting";
import ProjectExpense from "../pages/projectCosting/ProjectExpense";
import WpsReport from "../pages/payroll/WpsReport";
import ProjectDetails from "../pages/projectCosting/ProjectDetails";
import AddPlanning from "../pages/planning/AddPlanning";
import EditPlanning from "../pages/planning/EditPlanning";
import EmployeeSignin from "../pages/EmployeeSignin";
import SelfEdit from "../pages/employee/SelfEdit";




export default function MainRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<UnProtectedRoute />}>
          <Route path="login" element={<Signin />} />
          <Route path="forgotPassword" element={<ForgotPassword />} />
          <Route index element={<Signin />} />
        </Route>
        {/* <Route path="/employee" element={<EmployeeSignin />} /> */}

        <Route path="/employee/login" element={<EmployeeSignin />} />
        
        <Route path="/" element={<ProtectedRoute />}>
          <Route path="/dashboard" element={<Dashboard />} />

          
          {/* EMPLOYEE ROUTES */}
          <Route path="/employee" element={<EmployeeDetails />} />
          <Route path="/employee/add" element={<AddEmployee />} />
          <Route path="/employee/edit/:id" element={<EditEmployee />} />
          <Route path="/employee/self-edit" element={<SelfEdit />} />
          {/* EMPLOYEE ROUTES */}
          
          {/* COMPANY ROUTES */}
          <Route path="/company-info" element={<Company />} />
          {/* COMPANY ROUTES */}

          {/* ROLE ROUTES */}
          <Route path="/role" element={<Role/>} />
          <Route path="/role/edit/:id" element={<EditRole/>} />
          <Route path="/role/add" element={<AddRole/>} />
          {/* ROLE ROUTES */}

           {/* BANK ROUTES */}
           <Route path="/bank" element={<BankDetails/>} />
           <Route path="/bank/edit/:id" element={<EditDetails/>} />
          {/* BANK ROUTES */}

          {/* PROJECT ROUTES */}
          <Route path="/projects" element={<Project/>} />
          <Route path="/projects/add" element={<AddProject/>} />
          <Route path="/projects/edit/:id" element={<EditProject/>} />
          {/* PROJECT ROUTES */}

          {/* CLIENT ROUTES */}
          <Route path="/clients" element={<Client/>} />
          <Route path="/clients/add" element={<AddClient/>} />
          <Route path="/clients/edit/:id" element={<EditClient/>} />
          <Route path="/clients/contact-manage/add/:id" element={<AddContact/>} />
          <Route path="/clients/contact-manage/:id" element={<ContactManage/>} />
          <Route path="/clients/contact-manage/edit/:id" element={<EditContact/>} />
          {/* CLIENT ROUTES */}

          {/* INSURANCE ROUTE  */}
          <Route path="/insurance" element={<Insurance/>} />
          <Route path="/insurance/edit/:id" element={<EditInsurance/>} />
          {/* INSURANCE ROUTE  */}

          {/* AUTHORITY ROUTE */}
          <Route path="/authority" element={<AuthorityDetails/>} />
          <Route path="/authority/edit/:id" element={<EditAuthority/>} />
          {/* AUTHORITY ROUTE */}

           {/* LEAVE ROUTES */}
           <Route path="/leaves" element={<Leave/>} />
           <Route path="/leaves/edit/:id" element={<LeaveDetails/>} />
          {/* LEAVE ROUTES */}

          {/* ATTENDANCE VIEW ROUTE */}
           <Route path="/attendance-view" element={<AttendanceDetails/>} />
          {/* ATTENDANCE VIEW ROUTE */}

          {/* ATTENDANCE ROUTE */}
            <Route path="/attendance" element={<Attendance/>} />
          {/* ATTENDANCE ROUTE */}

          {/* HRMS USER ROUTE */}
          <Route path="/hrms-user" element={<Users/>} />
          <Route path="/hrms-user/edit/:id" element={<EditUser/>} />
          <Route path="/hrms-user/add" element={<AddUser/>} />
          {/* HRMS USER ROUTE */}


          {/* HRMS USER ROUTE */}
          <Route path="/hrms-user/edit" element={<EditUser/>} />
          {/* HRMS USER ROUTE */}

          {/* PACKAGE ROUTE */}
            <Route path="/package" element={<PackageDetails/>} />
            <Route path="/package/edit/:id" element={<EditPackage/>} />
          {/* PACKAGE ROUTE */}

          {/* EXPENSE-DEDUCTION ROUTE */}
          <Route path="/expense-deduction" element={<ExpenseDeduction/>} />
          <Route path="/expense-deduction/add" element={<AddExpenseDeduction/>} />
          <Route path="/expense-deduction/edit/:id" element={<EditExpenseDeduction/>} />
          {/* EXPENSE-DEDUCTION ROUTE */}

          {/* PLANNING ROUTE */}
            <Route path="/planning" element={<Planning/>} />
            <Route path="/planning/add" element={<AddPlanning/>} />
            <Route path="/planning/edit/:id" element={<EditPlanning/>} />
          {/* PLANNING ROUTE */}

          {/* ASSET ROUTE */}
          <Route path="/assets" element={<Assets/>} />
          <Route path="/assets/edit/:id" element={<EditAssets/>} />
          <Route path="/assets/add" element={<AddAssets/>} />
          {/* ASSET ROUTE */}

          {/* FORM ROUTE */}
          <Route path="/form-template" element={<Form/>} />
          <Route path="/form-template/add" element={<AddForm/>} />
          <Route path="/form-template/edit/:id" element={<EditForm/>} />
          {/* FORM ROUTE */}

           {/* VEHICLE ROUTE */}
           <Route path="/vehicle-matrix" element={<Vehicle/>} />
          <Route path="/vehicle-matrix/add" element={<AddVehicle/>} />
          <Route path="/vehicle-matrix/edit/:id" element={<EditVehicle/>} />
          <Route path="/vehicle-matrix/history/:id" element={<VehicleHistory/>} />
          {/* VEHICLE ROUTE */}

          {/* GRATUITY ROUTE */}
            <Route path="/gratuity" element={<Gratuity/>} />
            <Route path="/gratuity/add" element={<AddGratuity/>} />
            <Route path="/gratuity/edit/:id" element={<EditGratuity/>} />
          {/* GRATUITY ROUTE */}

          {/* PRRIORITY ROUTE */}
           <Route path="/priority" element={<Priority/>} />
          {/* PRRIORITY ROUTE */}

            {/* PAYROL ROUTE */}
            <Route path="/payroll/view-payroll" element={<Payroll/>} />
            <Route path="/payroll/view-payroll/view-wps-report/:month" element={<WpsReport/>} />
            <Route path="/payroll/payroll-summury" element={<PayrollSummary/>} /> 
            <Route path="/payroll/view-payslip" element={<ViewPayslip/>} />
             <Route path="/payroll/email-payslip" element={<EmailPayslip/>} />
             <Route path="/payroll/generate-payroll" element={<GeneratePayroll/>} />
          {/* PAYROLL ROUTE */}

           {/* PROJECT COSTING ROUTE */}
           <Route path="/project-costing/project-costing-report" element={<ProjectCosting/>} />
           <Route path="/project-costing/project-expense" element={<ProjectExpense/>} />
           <Route path="/project-costing/project-expense/add/:id" element={<AddProjectExpenseDetails/>} />
           <Route path="/project-costing/project-expense/edit/:id" element={<EditProjectExpenseDetails/>} />
           <Route path="/project-costing/project-expense/:id" element={<ProjectExpenseDetails/>} />
           <Route path="/project-costing/sub-contract" element={<SubContractor/>} />
           <Route path="/project-costing/sub-contract/projectDetails/:id" element={<ProjectDetails/>} />
           <Route path="/project-costing/sub-contract/add" element={<AddSubContractor/>} />
           <Route path="/project-costing/sub-contract/edit/:id" element={<EditSubContractor/>} />
          {/* PROJECT COSTING ROUTE */}

          {/* REPORT */}
          <Route path="/report/employee-wise-report" element={<EmployeeWiseReport/>} />
          <Route path="/report/employee-wise-report-2" element={<EmployeeWiseReport2/>} />
          <Route path="/report/project-wise-report" element={<ProjectWiseReport/>} />
          <Route path="/report/leave-report" element={<LeaveReport/>} />
          <Route path="/report/vehicle-report" element={<VehicleReport/>} />
          <Route path="/report/project-salary-expense" element={<ProjectSalaryExpense/>} />

          {/* REPORT */}

          {/* EMPLOYEE LOGIN ROUTE */}
          <Route path="/employeeLogin" element={<EmployeeLogin/>} />
          {/* EMPLOYEE LOGIN ROUTE */}

          {/* PROFILE */}
          <Route path="/profile" element={<EditProfile/>} />
          {/* PROFILE */}

          {/* EMPLOYEE LOGIN */}
          <Route path="/employee-self-access" element={<EmployeeLogin/>} />
          {/* EMPLOYEE LOGIN */}


          <Route path="*" element={<Dashboard />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
