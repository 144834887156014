import React, { useEffect, useState } from "react";
import { SelfEditTab } from "../../data/data";
import CurrentProject from "./CurrentProject";
import Transport from "./Transport";
import { ThemeColor } from "../../constants/themeColorConstant";
import { useDispatch } from "../../store";
import { employeeThunks } from "../../store/reducers/employee/employee.thunk";
import MainLayout from "../layout/MainLayout";
import { useLocation, useParams } from "react-router-dom";
import { images } from "../../assets/asset";

const SelfEdit = () => {
  const [activeTab, setActiveTab] = useState("current-projects");
  const dispatch = useDispatch();


  const [statusEmployee, setEmployeeStatus] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const location = useLocation();
  const { moduleId } = location.state || {};

  const handleTabChange = (tab) => {
    setActiveTab(tab.slug);
  };
  const userData = JSON.parse(localStorage.getItem('userData'));
  const id  = userData.uid;


  useEffect(() => {
    getEmployeeStatusApi();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getEmployeeDetailsApi();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const getEmployeeStatusApi = async () => {
    try {
      const response = await dispatch(
        employeeThunks.getEmployeeStatus(moduleId)
      );
      if (employeeThunks.getEmployeeStatus.fulfilled.match(response)) {
        if (response.payload) {
          setEmployeeStatus(response.payload);
        }
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };
 

  const getEmployeeDetailsApi = async () => {
    const data = {
      id,
      activeTab,
      moduleId,
    };
    try {
      // const response = await dispatch(employeeThunks.employeeDetails(data));
      // if (employeeThunks.employeeDetails.fulfilled.match(response)) {
      //   if (response.payload) {
      //     let department = {};
      //     let res = activeTab == 'attendance-project-filter-data' ? response.payload.employee : response.payload;
      //     if(res && res.department){
      //     department.value =  res.department.uid;
      //     department.label = res.department.name;
      //     res.department = department;}
      //     setEmployeeData(res);
      //   }
      // }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };


  const breadcrumb = {
    mainHeader: "Employees",
    subHeader : "Home",
    subHeaderLink : "/dashboard",
    subHeader1 : "Employees",
    subHeader1Link : "/employee",
    subHeader2 : "edit",
    subHeader2Link : null,
  };

  return (
    <>
      <MainLayout breadcrumb={breadcrumb} >
        <div className="card">
          <div className="card-body" style={{ padding: "20px" }}>
            <div className="form-group row col-lg-12">
              <div className="col-sm-3 col-md-3">
                <img
                  src={ employeeData && employeeData.photo!=null ? employeeData.photo : images.profileImage}
                  alt="profile"
                  style={{width:"125px",height:"125px"}}
                />
              </div>
              <div className="col-sm-9 col-md-9">
                <div className="form-group row">
                  <div className="col-sm-3 col-md-3 ">
                    <label>
                      <i className="fa fa-user" /> Name :
                    </label>
                  </div>
                  <div className="col-sm-3 col-md-3">
                    <p style={{ textTransform: 'capitalize' }}>{employeeData.name}</p>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-3 col-md-3">
                    <label>
                      <i className="fa fa-bolt" /> Status :
                    </label>
                  </div>
                  <div className="col-sm-3 col-md-3">
                    <p style={{ textTransform: 'capitalize' }}>{employeeData.status}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card card-info">
          <div className="card-body">
            <ul
              className="nav nav-tabs"
              style={{
                borderBottom: `2px solid ${ThemeColor.GREY}`,
                backgroundColor: "transparent",
              }}
            >
              {SelfEditTab.map((tab) => (
                <li className="nav-item" key={tab.id}>
                  <button
                    className={`nav-link ${
                      activeTab === tab.slug ? "active" : ""
                    }`}
                    onClick={() => handleTabChange(tab)}
                    style={{
                      borderBottom: `3px solid ${
                        activeTab === tab.slug ? ThemeColor.BLUE : "transparent"
                      }`,
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      backgroundColor: "transparent",
                    }}
                  >
                    {tab.name}
                  </button>
                </li>
              ))}
            </ul>
          
            {activeTab === "transports" && <Transport moduleId={moduleId} employeeData={employeeData.employeeTransport} activeTab={activeTab}  />}
            {activeTab === "current-projects" && <CurrentProject moduleId={moduleId} employeeData={employeeData} activeTab={activeTab}  />}
            {activeTab === "assets" && <CurrentProject moduleId={moduleId} employeeData={employeeData} activeTab={activeTab}  />}
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default SelfEdit;
