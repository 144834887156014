import React, { useEffect, useState } from "react";
import { ThemeColor } from "../../constants/themeColorConstant";
import MainLayout from "../layout/MainLayout";
import { useDispatch } from "../../store";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { roleThunks } from "../../store/reducers/role/role.thunk";
import { userThunks } from "../../store/reducers/users/users.thunk";
import { images } from "../../assets/asset";

const validationSchema = Yup.object().shape({
  userName: Yup.string()
    .required("Please enter name")
    .matches(
      /^[A-Za-z\s]+$/,
      "Company name must contain only letters and spaces"
    )
    .trim(),
  roleId: Yup.string().required("Please select role"),
  permission: Yup.string().required("Please select permission"),
  userId: Yup.string().required("Please enter userId"),
  mobileNumber: Yup.string()
    .required("Please enter mobile number")
    .matches(/^\d+$/, "Mobile number should only contain numbers"),
});

const EditUser = () => {

  const navigate = useNavigate()
  /* eslint-disable no-unused-vars */
  const [activeTab, setActiveTab] = useState("EditUser");
  /* eslint-disable no-unused-vars */
  const [userData, setUserData] = useState([]);
  const [permissionList,setPermissionList] = useState([])
  const [selectedImage, setSelectedImage] = useState(null);
  const [roleData,setRoleData] = useState([]) 
    
  const dispatch = useDispatch();

  const {id} = useParams()

  const location = useLocation();
  const pathnameSegments = location.pathname.split("/");
  const moduleName = pathnameSegments[1];

  const allowedModules = JSON.parse(localStorage.getItem("allowedModules"));
  const selectedModule = allowedModules.find(
    (module) => module.slug === moduleName
  );


  const moduleId = selectedModule.uid;

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    setFieldValue
  } = useFormik({
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      await updateUserApi(values);
    },
    initialValues: {
      
      userName :  userData ?  userData.userName : "",
      status : userData ? userData.status : true,
      permission : userData ? userData.permission : "",
      mobileNumber : userData ? userData.mobileNumber : "",
      roleId : userData && userData.role ? userData.role.uid : "",
      userId : userData ? userData.userId :""
    },
  });



  useEffect(() => {
    getRoleListApi()
    getPermissionApi()
    getUserDataApi()
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const breadcrumb = {
    mainHeader: "Manage User",
    subHeader : "Home",
    subHeaderLink : "/dashboard",
    subHeader1 : "Users",
    subHeader1Link : "/hrms-user",
    subHeader2 : "Edit",
    subHeader2Link : null,
  };

  const getRoleListApi = async () => {
    try {
      const response = await dispatch(roleThunks.roleData({moduleId}));
      if (roleThunks.roleData.fulfilled.match(response)) {
        if (response.payload) {
          
          setRoleData(response.payload.data);
          return response.payload
        }
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const getPermissionApi = async () => {
    try {
      const response = await dispatch(userThunks.userPermission({moduleId}));
      if (userThunks.userPermission.fulfilled.match(response)) {
        if (response.payload) {  
          setPermissionList(response.payload);
          return response.payload
        }
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const getUserDataApi = async () => {
    try {
      const response = await dispatch(userThunks.userDetails({id,moduleId}));
      if (userThunks.userDetails.fulfilled.match(response)) {
        if (response.payload) {
          
          setUserData(response.payload);
        }
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };


    const updateUserApi = async (data) => {
      try {
        const response = await dispatch(
          userThunks.updateUser({id, data, moduleId })
        );
        if (userThunks.updateUser.fulfilled.match(response)) {
            navigate("/hrms-user")
        }
      } catch (error) {
        console.error("Error fetching employee data:", error);
      }
    };

  return (
    <MainLayout breadcrumb={breadcrumb}>
      <div className="card card-info">
        <div className="card-body">
          <ul
            className="nav nav-tabs"
            style={{
              borderBottom: `2px solid ${ThemeColor.GREY}`,
              backgroundColor: "transparent",
            }}
          >
            <li className="nav-item">
              <button
                className={`nav-link ${
                  activeTab === "EditUser" ? "active" : ""
                }`}
                style={{
                  borderBottom: `3px solid ${
                    activeTab === "EditUser" ? ThemeColor.BLUE : "transparent"
                  }`,
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  backgroundColor: "transparent",
                }}
              >
                Edit User
              </button>
            </li>
          </ul>
          <form className="form-horizontal" onSubmit={handleSubmit}>
            <div className="card-body">
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">Photo</label>
                <div className="col-sm-5  col-md-5">
                { selectedImage ? ( 
                <img
                      src={URL.createObjectURL(selectedImage)}
                      alt="Employee profile"
                      //width="165px"
                      height="165px"
                    ></img>) : 
                    <img
                      src={userData && userData.profilePic!=null ? userData.profilePic : images.profileImage}
                      alt=" Employee profile"
                      //width="165px"
                      height="165px"
                    ></img>
                    }

                  <input
                    type="file"
                    name="profilePic"
                    id="profilePic"
                    onChange={(event) => {
                      const file = event.target.files[0];
                      if (file) {
                        setSelectedImage(file);
                        const fileExtension = file.name.split('.').pop();
                        const reader = new FileReader();
                        reader.onload = (e) => {
                          const base64Data = e.target.result;
                          const profilePicObject = {};
                          profilePicObject[`emp.${fileExtension}`] = base64Data;
                          setFieldValue("profilePic", profilePicObject);
                        };
                        reader.readAsDataURL(file);
                      } else {
                        setFieldValue("profilePic", {});
                      }
                    }}
                  />
                  <div className="error" id="err_name" />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">
                  Role <span style={{ color: ThemeColor.RED }}>*</span>
                </label>
                <div className="col-sm-5  col-md-5">
                  <select
                    className="form-control"
                    name="role"
                    id="role"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option>Select</option>
                    {roleData.map((data, index) => {
                        return (
                          <option key={index} value={data.uid} selected={data.uid === values.roleId}>
                            {data.name}
                          </option>
                        );
                      })}
                  </select>
                  {errors.role && touched.role && (
                    <div className="error" style={{ color: ThemeColor.RED }}>
                      {errors.role}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">
                  Permision <span style={{ color: ThemeColor.RED }}>*</span>
                </label>
                <div className="col-sm-5  col-md-5">
                  <select
                    className="form-control"
                    name="permission"
                    id="permission"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option>Select</option>
                    {permissionList.map((data, index) => {
                        return (
                          <option key={index} value={data.slug} selected={data.slug === values.permission}>
                            {data.name}
                          </option>
                        );
                      })}
                  </select>
                  {errors.permission && touched.permission && (
                    <div className="error" style={{ color: ThemeColor.RED }}>
                      {errors.permission}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">
                  User Name <span style={{ color: ThemeColor.RED }}>*</span>
                </label>
                <div className="col-sm-5  col-md-5">
                  <input
                    type="text"
                    className="form-control"
                    name="userName"
                    id="userName"
                    placeholder="User Name"
                    value={values.userName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.userName && touched.userName && (
                    <div className="error" style={{ color: ThemeColor.RED }}>
                      {errors.userName}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">
                  User ID <span style={{ color: ThemeColor.RED }}>*</span>
                </label>
                <div className="col-sm-5  col-md-5">
                  <input
                    type="text"
                    className="form-control"
                    name="userId"
                    id="userId"
                    placeholder="User Id"
                    value={values.userId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.userId && touched.userId && (
                    <div className="error" style={{ color: ThemeColor.RED }}>
                      {errors.userId}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">
                  Mobile <span style={{ color: ThemeColor.RED }}>*</span>
                </label>
                <div className="col-sm-5  col-md-5">
                  <input
                    type="text"
                    className="form-control"
                    name="mobileNumber"
                    id="mobileNumber"
                    placeholder="Mobile Number"
                    value={values.mobileNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.mobileNumber && touched.mobileNumber && (
                    <div className="error" style={{ color: ThemeColor.RED }}>
                      {errors.mobileNumber}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-center">
              <button
                type="submit"
                className="btn btn-primary"
                style={{ padding: "8px 20px" }}
              >
                <i className="fa fa-edit" style={{ marginRight: "8px" }} />
                Update
              </button>
              <button
                type="button"
                className="btn btn-default"
                style={{ padding: "8px 20px", marginLeft: "10px" }}
                onClick={()=>{
                  navigate("/hrms-user")
                }}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </MainLayout>
  );
};

export default EditUser;
