


export const EmployeeTab =[{
  id:1,
  name:"Personal",
  slug:"personal"
},
{
  id:2,
  name:"Contact",
  slug:"contacts"
},
{
  id:3,
  name:"Education",
  slug:"educations"
},
{
  id:4,
  name:"Other",
  slug:"others"
},
{
  id:5,
  name:"Notes",
  slug:"notes"
},
{
  id:6,
  name:"Transport",
  slug:"transports"
},
{
  id:7,
  name:"Attendance Project Filter",
  slug:"attendance-project-filter-data"
}
] 

export const SelfEditTab =[{
  id:1,
  name:"Current Project",
  slug:"current-projects"
},
{
  id:2,
  name:"Assets",
  slug:"assets"
},
{
  id:3,
  name:"Transport",
  slug:"transports"
},

] 


export const insuranceTab = [
  {
    id:1,
    name:"Health Insurance Details ",
    slug:"health-insurance-details"
  },
  {
    id:2,
    name:"Workmen's Compensation",
    slug:"worksman-compensation"
  },
  {
    id:3,
    name:"Others",
    slug:"others"
  },
]
export const authorityTab = [
  {
    id:1,
    name:"Passport Details",
    slug:"passport-details"
  },
  {
    id:2,
    name:"Immigration Details",
    slug:"immigration-details"
  },
  {
    id:3,
    name:"Labor Department Details",
    slug:"labor-department-details"
  },
]

export const EmployeeData = [
  {
    id: 1,
    action: <i className="fa fa-times" />,
    client: "Red Mechanical & Electrical Eng Services",
    projectno:15004,
      project: "ADP-Block P + ETS",
  },
  {
    id: 2,
    action: <i className="fa fa-times" />,
    client: "Red Mechanical & Electrical Eng Services",
    projectno:15005,
      project: "ADP-Block P 2D",
  },
  {
    id: 3,
    action: <i className="fa fa-times" />,
    client: "Spacewell Interiors",
    projectno:15009,
      project: "	Arqaam Capital",
  },
  {
    id: 4,
    action: <i className="fa fa-times" />,
    client: "Clarke Samadhin Associates",
    projectno:15025,
      project: "CSA_Revit CAD Support",
  },
  {
    id: 5,
    action: <i className="fa fa-times" />,
    client: "Ae Arma Elektropanc",
    projectno:15026,
      project: "River Park Drawings",
  },
  {
    id: 6,
    action: <i className="fa fa-times" />,
    client: "WME Engineering Consultants",
    projectno:15031,
      project: "Head office (3D CAD Support)",
  },
  {
    id: 7,
    action: <i className="fa fa-times" />,
    client: "Trinity Holdings",
    projectno:15032,
      project: "Rove Hotel_3D drgs_Zabeel",
  },
 
];

export const bankTab = [
  {
  id:1,
  name:"Account Details",
  slug:"account-details"
},
{
  id:2,
  name:"WPS Details",
  slug:"wps-details"
},
]

export const packageTab = [
  {
  id:1,
  name:"Package",
  slug:"package"
},
{
  id:2,
  name:"Package History",
  slug:"history"
},
]


export const leaveArray = [
  {
    id: 1,
    name: "Sick Leave",
    slug: "sick-leave",
    extraSlug: "totalSickLeaves",
  },
  {
    id: 2,
    name: "Annual Leave",
    slug: "annual-leave",
    extraSlug: "totalAnnualLeaves",
  },
  {
    id: 3,
    name: "Loss of pay",
    slug: "loss-of-pay",
    extraSlug: "totalLossOfPays",
  },
];

export const hoursArray = [
  {
    id: 1,
    name: "Normal hr",
    slug: "normalHours",
    extraSlug: "totalNormalHours",
  },
  { id: 2, name: "Normal OT", slug: "normalOt", extraSlug: "totalNormalOt" },
  { id: 3, name: "Extra OT", slug: "extraOt", extraSlug: "totalExtraOt" },
];

export const userTab = [
  {
    id:1,
    name:"Profile Details",
    slug:"profile-details"
  },
  {
    id:2,
    name:"Change Password",
    slug:"change-password"
  },
]