import React, { useEffect, useState } from "react";
import { packageTab } from "../../data/data";
import { useDispatch } from "../../store";
import MainLayout from "../layout/MainLayout";
import { useLocation, useParams } from "react-router-dom";
import Package from "./Package";
import PackageHistory from "./PackageHistory";
import CurrentProject from "./CurrentProject";
import { ThemeColor } from "../../constants/themeColorConstant";
import { packageThunks } from "../../store/reducers/package/package.thunk";
import { images } from "../../assets/asset";



const EditPackage = () => {
  const [activeTab, setActiveTab] = useState("package");
  const dispatch = useDispatch();

  const { id } = useParams();
  const [employeeData, setEmployeeData] = useState([]);
  const location = useLocation();
  const { moduleId } = location.state || {};

  const handleTabChange = (tab) => {
    setActiveTab(tab.slug);
  };

  useEffect(() => {}, []);

  useEffect(() => {
    getPackageDetailsApi()
  }, [activeTab]);

  const getPackageDetailsApi = async (page=1, rowsPerPage=10, searchText = '', name = '', order = '') => {
    const data = {
      id,
      activeTab,
      moduleId,
      page,
      rowsPerPage,
      searchText,
      name,
      order,
    };
    console.log({data})
    try {
      const response = await dispatch(packageThunks.packageDetails(data));
      if (packageThunks.packageDetails.fulfilled.match(response)) {
        if (response.payload) {
          setEmployeeData(response.payload);
        }
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const breadcrumb = {
    mainHeader: "Package Edit",
    subHeader: "Home",
    subHeaderLink: "/dashboard",
    subHeader1: "Package",
    subHeader1Link: "/package",
    subHeader2: "edit",
    subHeader2Link: null,
  };

  return (
    <MainLayout breadcrumb={breadcrumb}>
      <div className="card">
        <div className="card-body" style={{ padding: "20px" }}>
          <div className="form-group row col-lg-12">
            <div className="col-sm-3 col-md-3">
              {activeTab === "package" && <img
                 src={employeeData && employeeData.photo!=null ? employeeData.photo : images.profileImage}
                alt="profile"
                //width="165px"
                height="165px"
              />}
              { activeTab === "history" && employeeData.data && <img
                src={employeeData && employeeData.data && employeeData.data.photo!=null ? employeeData.photo : images.profileImage}
                alt="profile"
                //width="165px"
                height="165px"
              />}
            </div>
            <div className="col-sm-9 col-md-9">
              <div className="form-group row">
                <div className="col-sm-3 col-md-3 ">
                  <label>
                    <i className="fa fa-user" /> Name :
                  </label>
                </div>
                <div className="col-sm-3 col-md-3">
                {activeTab === "package" &&  <p>{employeeData.name}</p>}
                {activeTab === "history" &&  employeeData.data && <p>{employeeData.data.employeeName}</p>}
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-3 col-md-3">
                  <label>
                    <i className="fa fa-bolt" /> Status :
                  </label>
                </div>
                <div className="col-sm-3 col-md-3">
                {activeTab === "package" &&  <p>{ employeeData.status}</p>}
                {activeTab === "history" && employeeData.data && <p>{ employeeData.data.status}</p>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card card-info">
        <div className="card-body">
          <ul
            className="nav nav-tabs"
            style={{
              borderBottom: `2px solid ${ThemeColor.GREY}`,
              backgroundColor: "transparent",
            }}
          >
            {packageTab.map((tab) => (
              <li className="nav-item" key={tab.id}>
                <button
                  className={`nav-link ${
                    activeTab === tab.slug ? "active" : ""
                  }`}
                  onClick={() => handleTabChange(tab)}
                  style={{
                    borderBottom: `3px solid ${
                      activeTab === tab.slug ? ThemeColor.BLUE : "transparent"
                    }`,
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    backgroundColor: "transparent",
                  }}
                >
                  {tab.name}
                </button>
              </li>
            ))}
          </ul>
          {activeTab === "package" && (
            <Package
              employeeData={employeeData}
              moduleId={moduleId}
              activeTab={activeTab}
            />
          )}
          {activeTab === "history" && (
            <PackageHistory
              moduleId={moduleId}
              employeeData={employeeData}
              activeTab={activeTab}
              api={getPackageDetailsApi}
            />
          )}
        </div>
      </div>
    </MainLayout>
  );
};

export default EditPackage;
