import React, { useEffect, useState } from "react";
import { ThemeColor } from "../../constants/themeColorConstant";
import MainLayout from "../layout/MainLayout";
import { useDispatch } from "../../store";
import * as Yup from "yup";
import { useFormik } from "formik";
import { companyThunks } from "../../store/reducers/company/company.thunk";
import { emailRegex } from "../Signin";
import { useLocation } from "react-router-dom";
import { parseCustomDate} from "../employee/Personal";
import Datepicker from "../../components/datepicker";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required("Please enter email")
    .matches(emailRegex, "Please enter valid email"),
  name: Yup.string()
    .required("Please enter company name")
    .matches(
      /^[A-Za-z\s]+$/,
      "Company name must contain only letters and spaces"
    )
    .trim(),
  address: Yup.string().required("Please enter address"),
  phoneNumber: Yup.string().required("Please enter phone number"),
  fax: Yup.string().matches(/^\d{8}$/, 'Fax must be an 8-digit number')
  .required('Please enter fax'),
});

const Company = () => {
  const [activeTab, setActiveTab] = useState("information");
  const [companyData, setCompanyData] = useState([]);
  const dispatch = useDispatch();
  const location = useLocation();
  const pathnameSegments = location.pathname.split("/");
  const moduleName = pathnameSegments[1];

  const allowedModules = JSON.parse(localStorage.getItem("allowedModules"));
  const selectedModule = allowedModules.find(
    (module) => module.slug === moduleName
  );

  const moduleId = selectedModule.uid;
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
  } = useFormik({
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async(values) => {
      const updatedCompanyDocuments = values.companyDocuments.map(
        (document) => ({
          ...document,
          issueDate: document.issueDate ? parseCustomDate(document.issueDate) : null,
          expiryDate: document.expiryDate
            ? parseCustomDate(document.expiryDate)
            : null,
        })
      );

      const updatedValues = {
        ...values,
        companyDocuments: updatedCompanyDocuments,
      };

      await updateCompanyApi(updatedValues)
    },
    initialValues: {
      email: companyData ? companyData.email : "",
      name: companyData ? companyData.name : "",
      address: companyData ? companyData.address : "",
      phoneNumber: companyData ? companyData.phoneNumber : "",
      fax: companyData ? companyData.fax : "",
      postBox: companyData ? companyData.postBox : null,
      ownership: companyData ? companyData.ownership : null,
      companyDocuments: companyData.companyDocuments
        ? companyData.companyDocuments
        : [],
    },
  });

  useEffect(() => {
    companyApi();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const companyApi = async () => {
    try {
      const response = await dispatch(
        companyThunks.companyData({moduleId })
      );
      if (companyThunks.companyData.fulfilled.match(response)) {
        if (response.payload) {
          setCompanyData(response.payload);
          return response.payload;
        }
      }
    } catch (error) {
      console.error("Error fetching company data:", error);
    }
  };

  const updateCompanyApi = async (data) => {
    try {
      const response = await dispatch(
        companyThunks.updateCompanyData({ data, moduleId })
      );
      if (companyThunks.updateCompanyData.fulfilled.match(response)) {
        return response
      }
    } catch (error) {
      console.error("Error fetching company data:", error);
    }
  };

  return (
    <MainLayout>
      <div className="card card-info">
        <div className="card-body">
          <ul
            className="nav nav-tabs"
            style={{
              borderBottom: `2px solid ${ThemeColor.GREY}`,
              backgroundColor: "transparent",
            }}
          >
            <li className="nav-item">
              <button
                className={`nav-link ${
                  activeTab === "information" ? "active" : ""
                }`}
                onClick={() => handleTabChange("information")}
                style={{
                  borderBottom: `3px solid ${
                    activeTab === "information"
                      ? ThemeColor.BLUE
                      : "transparent"
                  }`,
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  backgroundColor: "transparent",
                }}
              >
                Information
              </button>
            </li>
          </ul>
          <form className="form-horizontal" onSubmit={handleSubmit}>
            <div className="card-body">
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">
                  Company Name <span style={{ color: ThemeColor.RED }}>*</span>
                </label>
                <div className="col-sm-5  col-md-5">
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    id="name"
                    placeholder="Company Name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.name && touched.name && (
                    <div className="error" style={{ color: ThemeColor.RED }}>
                      {errors.name}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">
                  Address <span style={{ color: ThemeColor.RED }}>*</span>
                </label>
                <div className="col-sm-5  col-md-5">
                  <input
                    type="text"
                    className="form-control"
                    name="address"
                    id="address"
                    placeholder="Company Address"
                    value={values.address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.address && touched.address && (
                    <div className="error" style={{ color: ThemeColor.RED }}>
                      {errors.address}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">
                  Phone <span style={{ color: ThemeColor.RED }}>*</span>
                </label>
                <div className="col-sm-5  col-md-5">
                  <input
                    type="text"
                    className="form-control"
                    name="phoneNumber"
                    id="phoneNumber"
                    placeholder="Company Phone Number"
                    value={values.phoneNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.phoneNumber && touched.phoneNumber && (
                    <div className="error" style={{ color: ThemeColor.RED }}>
                      {errors.phoneNumber}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">
                  Fax <span style={{ color: ThemeColor.RED }}>*</span>
                </label>
                <div className="col-sm-5  col-md-5">
                  <input
                    type="text"
                    className="form-control"
                    name="fax"
                    id="fax"
                    placeholder="Company Fax"
                    value={values.fax}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.fax && touched.fax && (
                    <div className="error" style={{ color: ThemeColor.RED }}>
                      {errors.fax}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">
                  Email <span style={{ color: ThemeColor.RED }}>*</span>
                </label>
                <div className="col-sm-5  col-md-5">
                  <input
                    type="text"
                    className="form-control"
                    name="email"
                    id="email"
                    placeholder="Company Email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.email && touched.email && (
                    <div className="error" style={{ color: ThemeColor.RED }}>
                      {errors.email}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">Post Box</label>
                <div className="col-sm-5  col-md-5">
                  <input
                    type="text"
                    className="form-control"
                    name="postBox"
                    id="postBox"
                    placeholder="Company PostBox"
                    value={values.postBox}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.postBox && touched.postBox && (
                    <div className="error" style={{ color: ThemeColor.RED }}>
                      {errors.postBox}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">Ownership</label>
                <div className="col-sm-5  col-md-5">
                  <input
                    type="text"
                    className="form-control"
                    name="ownership"
                    id="ownership"
                    placeholder="Company Ownership"
                    value={values.ownership}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.ownership && touched.ownership && (
                    <div className="error" style={{ color: ThemeColor.RED }}>
                      {errors.ownership}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead
                    style={{
                      backgroundColor: ThemeColor.BLUE,
                      color: ThemeColor.WHITE,
                    }}
                  >
                    <tr>
                      <th>Document Name</th>
                      <th>File Number</th>
                      <th>Issue Date</th>
                      <th>Expiry Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {values.companyDocuments?.map((data, dataIndex) => (
                     
                      <tr key={dataIndex}>
                        <td>{data.documentName}</td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            name={`companyDocuments[${dataIndex}].fileNumber`}
                            value={data.fileNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </td>
                        <td>
                          <Datepicker
                            name={`companyDocuments[${dataIndex}].issueDate`}
                            onChange={(date) =>
                              handleChange({
                                target: {
                                  name: `companyDocuments[${dataIndex}].issueDate`,
                                  value: parseCustomDate(date.target.value),
                                },
                              })
                            }
                            value={data.issueDate}
                            dateFormat="yyyy-mm-dd"
                          />
                        </td>
                        <td>
                          <Datepicker
                            name={`companyDocuments[${dataIndex}].expiryDate`}
                            onChange={(date) =>
                              handleChange({
                                target: {
                                  name: `companyDocuments[${dataIndex}].expiryDate`,
                                  value: parseCustomDate(date.target.value),
                                },
                              })
                            }
                            value={data.expiryDate}
                            dateFormat="yyyy-mm-dd"
                          />
                        </td>
                        {/* Add other cells as needed */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-center">
              <button
                type="submit"
                className="btn btn-primary"
                style={{ padding: "8px 20px" }}
              >
                <i className="fa fa-edit" style={{ marginRight: "8px" }} />
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </MainLayout>
  );
};

export default Company;
