import { createSlice } from '@reduxjs/toolkit';

export const ToastType = {
  success: 'success',
  error: 'error',
  warning: 'warning',
  info: 'info',
};

const toastSlice = createSlice({
  name: 'toast',
  initialState: {
    type: null,
    message: null,
    duration: null,
  },
  reducers: {
    setToastContent: (state, action) => {
      const { type, message, duration } = action.payload;
      state.type = type;
      state.message = message;
      state.duration = duration;
    }
  },
});

export const { setToastContent } = toastSlice.actions;

export default toastSlice.reducer;
