import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiCall } from "../../../utils/api";
import { HTTP_METHODS } from "../../../constants/methodsConstant";
import { ROUTES } from "../../../constants/routesConstant";
import { ToastType, setToastContent } from "../toast/toast.slice";

export const gratuityList = createAsyncThunk(
    "gratuity/list",
    async (inputData, { dispatch }) => {
      try {
        const response = await ApiCall(
          HTTP_METHODS.GET,
          `${ROUTES.GET_GRATUITY}/${inputData.selectedValue}?page=${inputData.page}&limit=${inputData.rowsPerPage}&search=${inputData.searchText}&sort=${inputData.name}&order=${inputData.order}`,
          {},
          inputData.moduleId
        );
        if (response?.isSuccess) {
          return response.payload;
        } else {
          dispatch(
            setToastContent({
              type: ToastType.error,
              message: response.message,
            })
          );
        }
      } catch (error) {
        throw new Error("An error occurred");
      }
    }
);

export const gratuityDetails = createAsyncThunk(
  "gratuity/details",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.GET,
        `${ROUTES.GET_GRATUITY}/find/${inputData.id}`,
        inputData.data,
        inputData.moduleId
      );
      if (response?.isSuccess) {
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);

export const addGratuity = createAsyncThunk(
    "gratuity/add",
    async (inputData, { dispatch }) => {
      try {
        const response = await ApiCall(
          HTTP_METHODS.POST,
          ROUTES.GET_GRATUITY,
          inputData.data,
          inputData.moduleId
        );
        if (response?.isSuccess) {
          dispatch(
            setToastContent({
              type: ToastType.success,
              message: response.message,
            })
          );
          return response.payload;
        } else {
          dispatch(
            setToastContent({
              type: ToastType.error,
              message: response.message,
            })
          );
        }
      } catch (error) {
        throw new Error("An error occurred");
      }
    }
  );

  export const updateGratuity = createAsyncThunk(
    "gratuity/update",
    async (inputData, { dispatch }) => {
      try {
        const response = await ApiCall(
          HTTP_METHODS.PATCH,
          `${ROUTES.GET_GRATUITY}/${inputData.id}`,
          inputData.data,
          inputData.moduleId
        );
        if (response?.isSuccess) {
          dispatch(
            setToastContent({
              type: ToastType.success,
              message: response.message,
            })
          );
          return response.payload;
        } else {
          dispatch(
            setToastContent({
              type: ToastType.error,
              message: response.message,
            })
          );
        }
      } catch (error) {
        throw new Error("An error occurred");
      }
    }
  );

  
  export const deleteGratuity = createAsyncThunk(
    "gratuity/delete",
    async (inputData, { dispatch }) => {  
      try {
        const response = await ApiCall(
          HTTP_METHODS.DELETE,
          ROUTES.GET_GRATUITY+`/${inputData.id}`,
          {},
          inputData.moduleId
        );
        if (response?.isSuccess) {
          dispatch(
            setToastContent({
              type: ToastType.success,
              message: response.message,
            })
          );
          return response.payload;
        } else {
          dispatch(
            setToastContent({
              type: ToastType.error,
              message: response.message,
            })
          );
        }
      } catch (error) {
        throw new Error("An error occurred");
      }
    }
  );

  export const gratuityThunks = {
    addGratuity,
    updateGratuity,
    deleteGratuity,
    gratuityList,
    gratuityDetails
  };  