import React from 'react';
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from 'react-router-dom';
import { authThunks } from '../../store/reducers/authentication/auth.thunk';
import { useDispatch } from '../../store';
import { ThemeColor } from '../../constants/themeColorConstant';


const validationSchema = Yup.object().shape({
  oldPassword: Yup.string().required("Please enter password"),
  newPassword: Yup.string().required("Please enter new password"),
  confirmPassword: Yup.string()
    .required("Please enter confirm password")
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match with new password'),
});


const ForgotPassword = () => {

const dispatch = useDispatch()
const navigate = useNavigate()

const {
  values,
  handleBlur,
  handleChange,
  handleSubmit,
  setValues,
  errors,
  touched
} = useFormik({
  validationSchema: validationSchema,
  enableReinitialize: true,
  onSubmit: async (values) => {

    if (values.newPassword === values.confirmPassword) {
      var userdata=JSON.parse(localStorage.getItem('userData'));
      
      console.log(typeof userdata);
       if(userdata.isEmployee == true)
        {
          const data = {
            oldPassword: values.oldPassword,
            newPassword: values.newPassword,
            // userId: userdata.uid
          };
          changePasswordApiforEmployee(data);
        }
        else{
          const data = {
            oldPassword: values.oldPassword,
            password: values.newPassword,
            // userId: userdata.uid
          };
          changePasswordApi(data);
        }
    } else {
      alert('Passwords do not match');
    }
  },
  initialValues: {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  },
});


      const changePasswordApi = async (data) => {
        try {
          const response = await dispatch(
            authThunks.updatePassword({ data })
          );
          if (authThunks.updatePassword.fulfilled.match(response)) {
            navigate("/profile")
          }
        } catch (error) {
          console.error("Error fetching employee data:", error);
        }
      };

      const changePasswordApiforEmployee = async (data) => {
        try {
          const response = await dispatch(
            authThunks.updatePasswordforEmployee({ data })
          );
          if (authThunks.updatePasswordforEmployee.fulfilled.match(response)) {
            navigate("/profile")
          }
        } catch (error) {
          console.error("Error fetching employee data:", error);
        }
      };


  return (
    <form className="form-horizontal" onSubmit={handleSubmit}>
    <div className="card-body">
      <div className="form-group row">
          <label className="col-sm-4 col-md-3">
           Current Password
          </label>
          <div className="col-sm-5  col-md-5">
            <input
              type="password"
              className="form-control"
              name="oldPassword"
              id="oldPassword"
              placeholder="Current Password"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.oldPassword}
            />
             {errors.oldPassword && touched.oldPassword && (
                    <div className="error" style={{ color: ThemeColor.RED }}>
                      {errors.oldPassword}
                    </div>
                  )}
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">
           New Password 
          </label>
          <div className="col-sm-5  col-md-5">
            <input
              type="password"
              className="form-control"
              name="newPassword"
              id="newPassword"
              placeholder="New Password"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.newPassword}
            />
            {errors.newPassword && touched.newPassword && (
                    <div className="error" style={{ color: ThemeColor.RED }}>
                      {errors.newPassword}
                    </div>
                  )}
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">
           Confirm Password 
          </label>
          <div className="col-sm-5  col-md-5">
            <input
              type="password"
              className="form-control"
              name="confirmPassword"
              id="confirmPassword"
              placeholder="Confirm Password"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.confirmPassword}
            />
             {errors.confirmPassword && touched.confirmPassword && (
                    <div className="error" style={{ color: ThemeColor.RED }}>
                      {errors.confirmPassword}
                    </div>
                  )}
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-center">
              <button
                type="submit"
                className="btn btn-primary"
                style={{ padding: "8px 20px" }}
              >
                <i className="fa fa-edit" style={{ marginRight: "8px" }} />
                Save
              </button>
              <button
                type="button"
                className="btn btn-default"
                style={{ padding: "8px 20px", marginLeft: "10px" }}
                onClick={()=>{
                  navigate("/dashboard")
                }}
              >
                Cancel
              </button>
            </div>
    </div>
  </form>
  );
}

export default ForgotPassword;
