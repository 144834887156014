import React, { useEffect, useState } from "react";
import Datatable from "../../components/datatable";

import { useDispatch } from "../../store";
import { ThemeColor } from "../../constants/themeColorConstant";
import { useLocation, useNavigate } from "react-router-dom";
import MainLayout from "../layout/MainLayout";
import { expenseDeductionThunks } from "../../store/reducers/expenseDeduction/expenseDeduction.thunk";
import ConfirmationBox from "../../components/ConfirmationBox";


const ExpenseDeduction = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [expenseData, setExpenseData] = useState([]);
  const [option, setOption] = useState({});
  const [selectedMonthYear, setSelectedMonthYear] = useState();
  const [expenseIdToDelete, setExpenseIdToDelete] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const location = useLocation();
  const pathnameSegments = location.pathname.split("/");
  const moduleName = pathnameSegments[1];

  const allowedModules = JSON.parse(localStorage.getItem("allowedModules"));
  const selectedModule = allowedModules.find(
    (module) => module.slug === moduleName
  );

  const moduleId = selectedModule.uid;

  
  const renderActions = (rowData) => {
    if(rowData && rowData.uid){
    const expenseId = rowData.uid;
    const isPosted = rowData.isPosted;

    if (isPosted === true || isPosted === 'Y') {
      return null; 
    }
    const editIcon = (
      <i
        className="fa fa-edit"
        style={{ cursor: "pointer" }}
        onClick={() =>
          navigate(`/expense-deduction/edit/${expenseId}`, { state: { moduleId } })
        }
      />
    );
    const deleteIcon = (
      <i style={{ marginLeft: "10px", cursor: "pointer" }}
        className="fa fa-trash-o fa-lg"
       onClick={() => {
        setExpenseIdToDelete(expenseId);
        setShowConfirmation(true);
      }}
      />
    );

    return (
      <div>
        {editIcon}
        {deleteIcon}
      </div>
    );}
    else{
      return null
    }
  };

  const handleMonthYearChange = async (event) => {
    const selectedDate = event.target.value;
    setSelectedMonthYear(selectedDate);
  };

  const columns = [
    {
      name: "action",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const rowData = expenseData[dataIndex];
          return renderActions(rowData);
        },
      },
    },
    {
      name: "employeeCode",
      label: "Employee Code",
    },
    {
      name: "employeeName",
      label: "Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "designationName",
      label: "Designation",
    },
    // {
    //   name: "expenseType",
    //   label: "Expense Type",
    //   options: {
    //     filter: false,
    //     sort: true,
    //   },
    // },
    {
      name: "type",
      label: "Expense Type",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "date",
      label: "Date",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "remark",
      label: "Remark",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "isPosted",
      label: "Posted",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return value ? "Y" : "F"; 
        },
      },
    },
    {
      name: "postedDate",
      label: "Posted Date",
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  const geExpenseDeductionApi = async (
    page=1,
    rowsPerPage=10,
    searchText = "",
    name = "",
    order = ""
  ) => {
    try {
      if(selectedMonthYear){
        const [year, month] = selectedMonthYear.split('-');
        const formattedDate = `${month}/${year}`;
      const response = await dispatch(expenseDeductionThunks.expenseDeductionData({month:formattedDate,moduleId, page,rowsPerPage,searchText,name,order,
        })
      );
      if (expenseDeductionThunks.expenseDeductionData.fulfilled.match(response)) {
        if (response.payload) {
          const options = {
            count: response.payload.totalRecords,
            page: response.payload.currentPage - 1, // Zero-based index
            rowsPerPage: response.payload.recordsPerPage,
          };
          setOption(options);
          setExpenseData(response.payload.data);
          return response.payload;
        }
      }}
      else{
        setExpenseData()
        setOption()
      }
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };
  
  const handleConfirmDelete = async () => {
    try {
      const response = await dispatch(
        expenseDeductionThunks.deleteExpense({ id: expenseIdToDelete, moduleId })
      );
      if (expenseDeductionThunks.deleteExpense.fulfilled.match(response)) {
        const updatedExpenseData = expenseData.filter(
          (expense) => expense && expense.uid !== expenseIdToDelete
        );
        setExpenseData(updatedExpenseData);
      }
    } catch (error) {
      console.error("Error deleting employee:", error);
    }
    setExpenseIdToDelete(null);
    setShowConfirmation(false);
  };



  useEffect(() => {
    geExpenseDeductionApi();
  }, [selectedMonthYear]);

  const breadcrumb = {
    mainHeader: "Expense Manage",
    subHeader: "Home",
    subHeaderLink: "/dashboard",
    subHeader1: "Expense Manage",
    subHeader1Link: null,
    subHeader2: null,
    subHeader2Link: null,
  };

  return (
    <MainLayout breadcrumb={breadcrumb}>
      <div className="card">
        <div className="card-body">
          <div className="card-header ">
            <p
              className="card-title"
              style={{
                fontSize: "20px",
                fontWeight: "500",
                color: ThemeColor.EASTERNBLUE,
              }}
            >
              Expense Manage
            </p>
            <div className="d-flex justify-content-end">
              <button
                onClick={() =>
                  navigate("/expense-deduction/add", { state: { moduleId } })
                }
                className="btn btn-primary"
              >
                Add
              </button>
            </div>
            <div className="form-group row d-flex justify-content-center">
              <label className="col-sm-2 col-md-2">
                Select Month 
              </label>
              <div className="col-sm-4  col-md-4">
              <input type="month"
              name="month"
              id="month"
              
              className="form-control"
              value={selectedMonthYear}
              onChange={handleMonthYearChange}
            />
              </div>
            </div>
          </div>

          <Datatable
            columns={columns}
            data={expenseData}
            options={option}
            customBodyRender={renderActions}
            api={geExpenseDeductionApi}
          />
        </div>
        <ConfirmationBox
        show={showConfirmation}
        onHide={() => setShowConfirmation(false)}
        onConfirm={handleConfirmDelete}
        message="Are you sure you want to delete expense?"
      />
      </div>
    </MainLayout>
  );
};

export default ExpenseDeduction;
