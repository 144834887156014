
export const HTTP_METHODS = {
    GET: 'get',
    POST: 'post',
    PATCH: 'patch',
    DELETE: 'delete',
}

export const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

 export const handleLogout = () =>{
  const userData = JSON.parse(localStorage.getItem('userData'));
    localStorage.removeItem("accessToken")
    localStorage.removeItem("allowedModules")
    localStorage.removeItem("userData")
    if(userData.isEmployee == true)
      {
        window.location.href = "/employee/login"
      }
      else{
        window.location.href = "/";
      }
  }
