import React, { useEffect, useState } from "react";
import Datatable from "../../components/datatable";
import { useDispatch } from "../../store";
import { ThemeColor } from "../../constants/themeColorConstant";
import { useLocation, useNavigate } from "react-router-dom";
import MainLayout from "../layout/MainLayout";
import { gratuityThunks } from "../../store/reducers/gratuity/gratuity.thunk";
import { employeeThunks } from "../../store/reducers/employee/employee.thunk";
import { payrollThunks } from "../../store/reducers/payroll/payroll.thunk";
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import clsx from 'clsx';
import { makeStyles } from "tss-react/mui";

const GeneratePayroll = () => {
  const useStyles = makeStyles()(theme => ({
    footerCell: {
      backgroundColor: '#007bff !important',
      borderBottom: 'none',
      color: 'black'
    },
    stickyFooterCell: {
      position: 'sticky',
      bottom: 0,
      zIndex: 100,
    },
  }));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [payrollData, setPayrollData] = useState([]);
  const [selectedMonthYear, setSelectedMonthYear] = useState("");
  const [option, setOption] = useState({pagination : false});

  const location = useLocation();
  const pathnameSegments = location.pathname.split("/");
  const moduleName = pathnameSegments[1];
  const [stickyFooter, setStickyFooter] = useState(true);
  const { classes } = useStyles();

  const allowedModules = JSON.parse(localStorage.getItem("allowedModules"));
  const selectedModule = allowedModules.find(
    (module) => module.slug === moduleName
  );

  const moduleId = selectedModule.uid;
  const userData = JSON.parse(localStorage.getItem('userData'));

  const footerClasses = clsx({
    [classes.footerCell]: true,
    [classes.stickyFooterCell]: stickyFooter,
  });

  const columns = [
    {
      name: "employeeCode",
      label: "Emp Code",
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "designation",
      label: "Designation",
      // options: {
      //   customBodyRender: (value) => {
      //     return value.name;
      //   },
      // },
    },
    {
      name: "totalPackage",
      label: "total package",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "basicPayable",
      label: "Basic Payable",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "hraPayable",
      label: "HRA Payable",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "otherAllowancesPayable",
      label: "Other Allowance Payable",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "VehicleAllowancePayable",
      label: "Vehicle Allowance",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "presentDays",
      label: "Present Days",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "arrearsLeaveSalaryPayable",
      label: "Arrears Leave Salary Payable",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "airCharges",
      label: "Air Charges",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "incentivesOtherPayable",
      label: "Incentives Other Payable",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "otHrs",
      label: "Over Time (HRA)",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "otPayable",
      label: "OT Payable",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "fuelPayable",
      label: "Fuel Expense",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "expensesPayable",
      label: "Expense Payble",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "totalGrossPayable",
      label: "Total Gross Payable",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "rent",
      label: "Rent",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "advance",
      label: "Advance",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "otherDeduction",
      label: "Other Deduction",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "gratuityHoldPayable",
      label: "Grat-Salary Hold",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "totalDeduction",
      label: "Total Deduction",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "netPayable",
      label: "Net Payable",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "netRoundOff",
      label: "Net Round Off",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "molValue",
      label: "MOL Value",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "difference",
      label: "Difference %",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value + "%";
        },
      },
    },
    {
      name: "netRoundOffDiff",
      label: "Net Round Off Diff",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "month",
      label: "Month",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "transMode",
      label: "Transfer Mode",
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  const handleMonthYearChange = async (event) => {
    const selectedDate = event.target.value;
    setPayrollData([]);
    setSelectedMonthYear(selectedDate);
  };

  const generatePayroll = async (page = 1, rowsPerPage = 10, searchText = '', name = '', order = '') => {
    try {
      const formattedMonthYear = selectedMonthYear.split('-').reverse().join('/');
      const response = await dispatch(payrollThunks.generatePayrollList({ selectedMonthYear: formattedMonthYear, moduleId, page, rowsPerPage, searchText, name, order }));
      if (payrollThunks.generatePayrollList.fulfilled.match(response)) {
        if (response.payload) {
          const options = {
            count: response.payload.totalRecords,
            page: response.payload.currentPage - 1, // Zero-based index
            rowsPerPage: response.payload.recordPerPage,
            pagination : false,
            serverSide : false,
            print : false,
            filter : false,
            // download : false,
            customTableBodyFooterRender: function (opts) {

              return (
                <TableFooter className={footerClasses}>
                 <TableRow>
                    {opts.selectableRows !== 'none' ? <TableCell className={footerClasses} /> : null}
                    {opts.columns.map((col, index) => {

                      if (col.display === 'true') {
                        if (col.name === 'totalPackage') {
                          let totalPackage =
                            opts.data.reduce((accu, item) => {
                              return accu + Number(item.data[index]);
                            }, 0);

                            totalPackage = Math.round(totalPackage);
                          return (
                            <TableCell key={index} className={footerClasses}>
                               {totalPackage}
                            </TableCell>
                          );
                        }
                       else if (col.name === 'basicPayable') {
                          let basicPayable =
                            opts.data.reduce((accu, item) => {
                              return accu + Number(item.data[index]);
                            }, 0);

                          basicPayable = Math.round(basicPayable);
                          return (
                            <TableCell key={index} className={footerClasses}>
                               {basicPayable}
                            </TableCell>
                          );
                        }
                        else if (col.name === 'hraPayable') {
                          let hra =
                            opts.data.reduce((accu, item) => {
                              return accu + Number(item.data[index]);
                            }, 0);

                          hra = Math.round(hra);
                          return (
                            <TableCell key={index} className={footerClasses}>
                              {hra}
                            </TableCell>
                          );
                        }
                        else if (col.name === 'otherAllowancesPayable') {
                          let otherAllowancesPayable =
                            opts.data.reduce((accu, item) => {
                              return accu + Number(item.data[index]);
                            }, 0);

                          otherAllowancesPayable = Math.round(otherAllowancesPayable);
                          return (
                            <TableCell key={index} className={footerClasses}>
                              {otherAllowancesPayable}
                            </TableCell>
                          );
                        }
                        else if (col.name === 'VehicleAllowancePayable') {
                          let VehicleAllowancePayable =
                            opts.data.reduce((accu, item) => {
                              return accu + Number(item.data[index]);
                            }, 0);

                          VehicleAllowancePayable = Math.round(VehicleAllowancePayable);
                          return (
                            <TableCell key={index} className={footerClasses}>
                              {VehicleAllowancePayable}
                            </TableCell>
                          );
                        }
                        else if (col.name === 'arrearsLeaveSalaryPayable') {
                          let arrearsLeaveSalaryPayable =
                            opts.data.reduce((accu, item) => {
                              return accu + Number(item.data[index]);
                            }, 0);

                          arrearsLeaveSalaryPayable = Math.round(arrearsLeaveSalaryPayable);
                          return (
                            <TableCell key={index} className={footerClasses}>
                              {arrearsLeaveSalaryPayable}
                            </TableCell>
                          );
                        }
                        else if (col.name === 'airCharges') {
                          let airCharges =
                            opts.data.reduce((accu, item) => {
                              return accu + Number(item.data[index]);
                            }, 0);

                          airCharges = Math.round(airCharges);
                          return (
                            <TableCell key={index} className={footerClasses}>
                              {airCharges}
                            </TableCell>
                          );
                        }
                        else if (col.name === 'incentivesOtherPayable') {
                          let incentivesOtherPayable =
                            opts.data.reduce((accu, item) => {
                              return accu + Number(item.data[index]);
                            }, 0);

                          incentivesOtherPayable = Math.round(incentivesOtherPayable);
                          return (
                            <TableCell key={index} className={footerClasses}>
                              {incentivesOtherPayable}
                            </TableCell>
                          );
                        }
                        else if (col.name === 'otHrs') {
                          let otHrs =
                            opts.data.reduce((accu, item) => {
                              return accu + Number(item.data[index]);
                            }, 0);

                          otHrs = Math.round(otHrs);
                          return (
                            <TableCell key={index} className={footerClasses}>
                              {otHrs}
                            </TableCell>
                          );
                        }
                        else if (col.name === 'otPayable') {
                          let otPayable =
                            opts.data.reduce((accu, item) => {
                              return accu + Number(item.data[index]);
                            }, 0);

                          otPayable = (otPayable).toFixed(2);
                          return (
                            <TableCell key={index} className={footerClasses}>
                              {otPayable}
                            </TableCell>
                          );
                        }
                        else if (col.name === 'fuelPayable') {
                          let fuelPayable =
                            opts.data.reduce((accu, item) => {
                              return accu + Number(item.data[index]);
                            }, 0);

                          fuelPayable = Math.round(fuelPayable);
                          return (
                            <TableCell key={index} className={footerClasses}>
                              {fuelPayable}
                            </TableCell>
                          );
                        }
                        else if (col.name === 'expensesPayable') {
                          let totalExpense =
                            opts.data.reduce((accu, item) => {

                              return accu + Number(item.data[index]);
                            }, 0);

                          totalExpense = Math.round(totalExpense);
                          return (
                            <TableCell key={index} className={footerClasses}>
                               {totalExpense}
                            </TableCell>
                          );
                        }
                        else if (col.name === 'totalGrossPayable') {
                          let totalGrossPayable =
                            opts.data.reduce((accu, item) => {
                              return accu + Number(item.data[index]);
                            }, 0);

                          totalGrossPayable = totalGrossPayable.toFixed(2);
                          return (
                            <TableCell key={index} className={footerClasses}>
                              {totalGrossPayable}
                            </TableCell>
                          );
                        }
                        else if (col.name === 'rent') {
                          let rent =
                            opts.data.reduce((accu, item) => {
                              return accu + Number(item.data[index]);
                            }, 0);

                          rent = (rent).toFixed(2);
                          return (
                            <TableCell key={index} className={footerClasses}>
                               {rent}
                            </TableCell>
                          );
                        }
                        else if (col.name === 'advance') {
                          let advance =
                            opts.data.reduce((accu, item) => {
                              return accu + Number(item.data[index]);
                            }, 0);

                          advance = (advance).toFixed(2);
                          return (
                            <TableCell key={index} className={footerClasses}>
                              {advance}
                            </TableCell>
                          );
                        }
                        else if (col.name === 'otherDeduction') {
                          let otherDeduction =
                            opts.data.reduce((accu, item) => {
                              return accu + Number(item.data[index]);
                            }, 0);

                          otherDeduction = (otherDeduction).toFixed(2);
                          return (
                            <TableCell key={index} className={footerClasses}>
                              {otherDeduction}
                            </TableCell>
                          );
                        }
                        else if (col.name === 'gratuityHoldPayable') {
                          let gratuityHoldPayable =
                            opts.data.reduce((accu, item) => {
                              return accu + Number(item.data[index]);
                            }, 0);

                          gratuityHoldPayable = (gratuityHoldPayable).toFixed(2);
                          return (
                            <TableCell key={index} className={footerClasses}>
                              {gratuityHoldPayable}
                            </TableCell>
                          );
                        }
                        else if (col.name === 'totalDeduction') {
                          let totalDeduction =
                            opts.data.reduce((accu, item) => {
                              return accu + Number(item.data[index]);
                            }, 0);

                          totalDeduction = (totalDeduction).toFixed(2);
                          return (
                            <TableCell key={index} className={footerClasses}>
                              {totalDeduction}
                            </TableCell>
                          );
                        }
                        else if (col.name === 'netPayable') {
                          let netPayable =
                            opts.data.reduce((accu, item) => {
                              return accu + Number(item.data[index]);
                            }, 0);

                          netPayable = (netPayable).toFixed(2);
                          return (
                            <TableCell key={index} className={footerClasses}>
                              {netPayable}
                            </TableCell>
                          );
                        }
                        else if (col.name === 'netRoundOff') {
                          let netRoundOff =
                            opts.data.reduce((accu, item) => {
                              return accu + Number(item.data[index]);
                            }, 0);

                          netRoundOff = (netRoundOff).toFixed(2);
                          return (
                            <TableCell key={index} className={footerClasses}>
                              {netRoundOff}
                            </TableCell>
                          );
                        }
                        else if (col.name === 'molValue') {
                          let molValue =
                            opts.data.reduce((accu, item) => {
                              return accu + Number(item.data[index]);
                            }, 0);

                          molValue = (molValue).toFixed(2);
                          return (
                            <TableCell key={index} className={footerClasses}>
                              {molValue}
                            </TableCell>
                          );
                        }
                        else if (col.name === 'netRoundOffDiff') {
                          let netRoundOffDiff =
                            opts.data.reduce((accu, item) => {
                              return accu + Number(item.data[index]);
                            }, 0);

                          netRoundOffDiff = (netRoundOffDiff).toFixed(2);
                          return (
                            <TableCell key={index} className={footerClasses}>
                              {netRoundOffDiff}
                            </TableCell>
                          );
                        }
                        else {
                          return <TableCell key={index} className={footerClasses}>  </TableCell>;
                        }
                      }
                      return null;
                    })}
                  </TableRow>
                  <TableRow>
                    {opts.selectableRows !== 'none' ? <TableCell className={footerClasses} /> : null}
                    {opts.columns.map((col, index) => {
                      return <TableCell key={index} className={footerClasses}>{col.label}  </TableCell>;

                    })
                    }

                  </TableRow>
                
                </TableFooter>
              );
            },

          }
          setOption(options)
          setPayrollData(response.payload);
          return response.payload;
        }
      }
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };

  const approvePayroll = async (page = 1, rowsPerPage = 10, searchText = '', name = '', order = '') => {
    try {
      const formattedMonthYear = selectedMonthYear.split('-').reverse().join('/');
      const response = await dispatch(payrollThunks.approveGeneratedPayroll({ selectedMonthYear: formattedMonthYear, moduleId, page, rowsPerPage, searchText, name, order }));
      if (payrollThunks.approveGeneratedPayroll.fulfilled.match(response)) {
        return response
      }
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };


  const breadcrumb = {
    mainHeader: "Generate Payroll",
    subHeader: "Home",
    subHeaderLink: "/dashboard",
    subHeader1: "payroll",
    subHeader1Link: null,
    subHeader2: null,
    subHeader2Link: null,
  };

  return (
    <MainLayout breadcrumb={breadcrumb}>
      <div className="card">
        <div className="card-body">
          <div className="card-header ">
            <p
              className="card-title"
              style={{
                fontSize: "20px",
                fontWeight: "500",
                color: ThemeColor.EASTERNBLUE,
              }}
            >
              Generate Payroll
            </p>
          </div>
          <div className="form-group row d-flex justify-content-center mt-5">
            <div className="col-sm-2  col-md-2">
              <label>Select Month</label>
            </div>
            <div className="col-sm-4  col-md-4">
              <input type="month"
                name="month"
                id="month"
                placeholder="select payroll"
                className="form-control"
                value={selectedMonthYear || 'Select Payroll'}
                onChange={handleMonthYearChange}
              />
            </div>
            <div className="col-sm-4  col-md-4">
              {payrollData.length > 0 ? (
                <>
                  <button type="submit" className="btn btn-primary" style={{ marginRight: '5px' }} onClick={() =>
                    navigate("/payroll/view-payroll/view-wps-report/" + selectedMonthYear, { state: { moduleId } })
                  }>
                    View WPS Report
                  </button>
                  <button type="submit" className="btn btn-primary" onClick={() => approvePayroll()
                  }>
                    Approve
                  </button>
                </>
              ) : (
                <button type="submit" className="btn btn-primary" onClick={() => generatePayroll()}>
                  Generate Payroll
                </button>
              )}
            </div>
          </div>

          <Datatable columns={columns} data={payrollData} options={option} api={generatePayroll} />
        </div>
      </div>
    </MainLayout>
  );
};

export default GeneratePayroll;
