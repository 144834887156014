import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import MainLayout from "../layout/MainLayout";
import { attendanceThunks } from "../../store/reducers/attendance/attendance.thunk";
import { useLocation } from "react-router-dom";
import { useDispatch } from "../../store";
import Select from "react-select";
import "../attendance/style.css";
import { projectThunks } from "../../store/reducers/projects/project.thunk";
import { employeeThunks } from "../../store/reducers/employee/employee.thunk";
import { generateOptions } from "../../utils";
import { useFormik } from "formik";
import { parseCustomDate } from "../employee/Personal";
import { ThemeColor } from "../../constants/themeColorConstant";
import { hoursArray, leaveArray } from "../../data/data";
import { images } from "../../assets/asset";
import ConfirmationBox from "../../components/ConfirmationBox";


const validationSchema = Yup.object().shape({
  projectId: Yup.string().required("Please select project"),

});

const Attendance = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const pathnameSegments = location.pathname.split("/");
  const moduleName = pathnameSegments[1];

  const allowedModules = JSON.parse(localStorage.getItem("allowedModules"));
  const selectedModule = allowedModules.find(
    (module) => module.slug === moduleName
  );

  const moduleId = selectedModule.uid;

  const [selectedMonthYear, setSelectedMonthYear] = useState();
  const [selectedMonthYearObj, setSelectedMonthYearObj] = useState();
  const [employeeList, setEmployeeList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [employeeId, setEmployeeId] = useState();
  const [employeeImg, setEmployeeImg] = useState();
  const [monthlyAttendanceData, setMonthlyAttendanceData] = useState([]); // to load outside table
  const [monthlyAttendanceTotalData, setMonthlyAttendanceTotalData] = useState([]);
  const [projectWiseAttendanceData, setProjectWiseAttendanceData] = useState([]); // to fill model data
  const [projectWiseAttendanceTotalData, setProjectWiseAttendanceTotalData] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [addProject, setAddProject] = useState(0);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [formData, setFormData] = useState(monthlyAttendanceData);
  const [remarkValue, setRemarkValue] = useState()

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isDeleteEnable, setIsDeleteEnable] = useState(false);
  const [projectToDelete, setProjectIdToDelete] = useState(null);

  const {
    values,
    errors,
    isSubmitting,
    touched,
    handleBlur,
    handleSubmit } = useFormik({
      validationSchema: validationSchema,
      enableReinitialize: true,
      onSubmit: async (values) => {

        const formDataWithISODate = formData.map((data) => ({
          ...data,
          date: parseCustomDate(data.date),
        }));

        const formattedValues = {
          attendanceData: formDataWithISODate.map(
            ({ dayOfMonth, dayOfWeek, isWeekOff, ...rest }) => rest
          ),
          projectId: selectedProject.value,
        };
        setProjectIdToDelete(false);
        await updateAttendanceDataApi(formattedValues);
      },
      initialValues: {
        attendanceData: projectWiseAttendanceData ? projectWiseAttendanceData : "",
        projectId: selectedProject ? selectedProject.value : "",
      },
    });

  useEffect(() => {
    setFormData(projectWiseAttendanceData);
  }, [projectWiseAttendanceData, selectedProject]);

  const options = generateOptions();
  const selectCurrentMonthValue = () => {
    let dateObj = new Date();
    const currentDate = (dateObj.getMonth() + 1) + '-' + dateObj.getFullYear();
    var index = options.findIndex(o => o.value === currentDate);
    setSelectedMonthYear(options[index].value);
    setSelectedMonthYearObj(options[index]);
  };
  const userData = JSON.parse(localStorage.getItem("userData"));
  const employeeOptions = employeeList.map((employee) => ({
    value: employee.uid,
    label: employee.employee_code + " - " + employee.name,
    photo: employee.photo,
  }));


  const breadcrumb = {
    mainHeader: "Attendance",
    subHeader: "Home",
    subHeaderLink: "/dashboard",
    subHeader1: "Attendance",
    subHeader1Link: null,
    subHeader2: null,
    subHeader2Link: null,
  };

  useEffect(() => {
    employeeListApi();

  }, []);

  const handleAddRemarkClick = async () => {
    try {
      const [month, year] = selectedMonthYear.split('-');
      const formattedMonthYear = `${month.padStart(2, '0')}/${year}`;

      const newData = {
        month: formattedMonthYear,
        employeeId,
        remarks: remarkValue.remarks,
      };

      const response = await dispatch(
        attendanceThunks.remarkDataUpdate({ data: newData, moduleId })
      );

      if (attendanceThunks.remarkDataUpdate.fulfilled.match(response)) {
        // Update the remarkValue state here if the request is successful.
        setRemarkValue({ ...remarkValue, remarks: newData.remarks });
        return response;
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };



  const handleDropdownChange = (selectedOption) => {
    const selectedMonthYear = selectedOption.value;
    setSelectedMonthYearObj(selectedOption);
    setSelectedMonthYear(selectedMonthYear);
  };

  const handleProjectDDChange = (selectedOption, type = '') => {
    let selectedProjectOption = {};
    if (type == 'edit') {
      selectedOption.value = selectedOption.uid;
      selectedOption.label = selectedOption.projectName;
      setIsDeleteEnable(true)
    }
    selectedProjectOption = selectedOption;
    const selectedProjectId = selectedOption.value;

    setSelectedProject(selectedProjectOption);
    setAddProject(0);
    if (selectedProjectId) attendanceDetailsApi(selectedProjectId);
  };

  const handleAddProjectDDChange = () => {
    setAddProject(1);
    attendanceDetailsApi(0);
  };

  const handleEmployeeSelection = (event) => {
    console.log({event})
    const selectedUid = event.value; // Get the selected UID from the dropdown
    setEmployeeId(selectedUid);
    projectListApi(selectedUid);
    setEmployeeImg(event.photo);
    setSelectedEmployee(event);
    // Update the employeeId state
  };

  useEffect(() => {
    remarkDataListApi()
  }, [selectedMonthYear, employeeId, isSubmitting])


  const employeeListApi = async () => {
    try {
      const response = await dispatch(
        employeeThunks.employeeList({ moduleId, status: 'active' })
      );
      if (employeeThunks.employeeList.fulfilled.match(response)) {
        if (response.payload) {

          selectCurrentMonthValue();
          let selectEmployee = response.payload[0];
          if(userData.isEmployee){
            // alert(userData.uid);
            response.payload.map((employee) => {
              if(employee.uid == userData.uid){
                selectEmployee = employee;
              }
            });
          }
          // alert(JSON.stringify(selectEmployee))
          setEmployeeList(response.payload);
          setEmployeeId(selectEmployee.uid);
          setEmployeeImg(selectEmployee.photo);
          projectListApi(selectEmployee.uid);
          const employeeOptions = {
            value: selectEmployee.uid,
            label: selectEmployee.employee_code + " - " + selectEmployee.name,
            photo: selectEmployee.photo,
          };
          setSelectedEmployee(employeeOptions)
          return response.payload;
        }
      }
    } catch (error) {
      console.error("Error fetching company data:", error);
    }
  };

  const handleConfirmDelete = async () => {
    if (!projectToDelete) {
      return console.log({ projectToDelete })
    }
    try {
      const [month, year] = selectedMonthYear.split('-');
      const formattedMonthYear = `${month.padStart(2, '0')}/${year}`;
      let data = { projectId: projectToDelete.uid, employeeId: employeeId, month: formattedMonthYear };
      const response = await dispatch(projectThunks.employeeProjectDelete({ data, moduleId }));
      if (projectThunks.employeeProjectDelete.fulfilled.match(response)) {

        attendanceDetailsApi();
        remarkDataListApi()
        const closeButton = document.querySelector('[data-dismiss="modal"]');
        if (closeButton) {
          closeButton.click();
        }
        setProjectIdToDelete(null);
        setShowConfirmation(false);

      }
    } catch (error) {
      console.error("Error fetching asset data:", error);
    }

  };

  const remarkDataListApi = async () => {
    try {
      // console.log({ selectedMonthYear });

      const [month, year] = selectedMonthYear.split('-');
      const formattedMonthYear = `${month.padStart(2, '0')}/${year}`;
      const response = await dispatch(
        attendanceThunks.remarkData({ formattedMonthYear, employeeId, moduleId })
      );
      if (attendanceThunks.remarkData.fulfilled.match(response)) {

        setRemarkValue(response.payload)

        return response.payload;

      }
    } catch (error) {
      console.error("Error fetching company data:", error);
    }
  };

  const projectListApi = async (employeeId) => {
    try {
      console.log({ employeeId })
      const response = await dispatch(projectThunks.employeeWiseProjectList({ moduleId, employeeId }));
      if (projectThunks.employeeWiseProjectList.fulfilled.match(response)) {
        if (response.payload) {
          setProjectList(response.payload);
        }
      }
    } catch (error) {
      console.error("Error fetching project list:", error);
    }
  };



  useEffect(() => {

    if (employeeId && selectedMonthYear) {

      attendanceDetailsApi();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeId, selectedMonthYear]);


  const attendanceDetailsApi = async (projectId) => {
    try {
      const [month, year] = selectedMonthYear.split('-');
      const formattedMonthYear = `${month.padStart(2, '0')}/${year}`;
      const response = await dispatch(
        attendanceThunks.attendanceDetails({
          employeeId,
          moduleId,
          selectedMonthYear: formattedMonthYear,
          projectId,
        })
      );
      if (attendanceThunks.attendanceDetails.fulfilled.match(response)) {
        if (response.payload) {
          console.log({ response })
          if (projectId) {
            setProjectWiseAttendanceData(response.payload.attendances);
            setProjectWiseAttendanceTotalData(response.payload.totalFields);
          } else {
            setMonthlyAttendanceData(response.payload.attendances);
            setMonthlyAttendanceTotalData(response.payload.totalFields);
            return response.payload;
          }
        }
      }
    } catch (error) {
      console.error("Error fetching company data:", error);
    }
  };


  const updateAttendanceDataApi = async (data) => {
    try {
      const response = await dispatch(
        attendanceThunks.callUpdateAttendanceDataApi({ employeeId, data, moduleId, })
      );
      if (attendanceThunks.callUpdateAttendanceDataApi.fulfilled.match(response)) {
        const closeButton = document.querySelector('[data-dismiss="modal"]');
        if (closeButton) {
          closeButton.click();
        }
        attendanceDetailsApi()
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };


  const handleChange = (e, dataIndex) => {
    const { name, value, type, checked } = e.target;
    let value1;
    // Check if dataIndex is a valid index
    if (dataIndex >= 0 && dataIndex < formData.length) {
      const updatedData = [...formData];

      if (type === "text") {
        value1 = value == '' || isNaN(value) ? 0 : parseInt(value, 10);
        updatedData[dataIndex][name] = value1;
      } else if (type === "checkbox") {
        updatedData[dataIndex][name] = checked ? value : null;
      }

      // Update the state with the new formData
      setFormData(updatedData);
    }
  };

  const handleButtonClick = () => {
    setAddProject(0)
    setSelectedProject(null);
  };



  const attendanceTableView = (
    // this is a function to render dates
    attendanceData,
    totalData,
    isProjectWiseData = false
  ) => (
    <div className="table-container">
      <table class="table table-bordered responsive" id="atten_data">
        <tbody>
          <tr>
            <th>Day</th>
            {attendanceData.map((attendance, index) => (
              <th key={index}>{attendance.dayOfWeek}</th>
            ))}
            {/* if table is not generating from the model then we don't need total column in last so this code is added */}
            {addProject ? (
              ""
            ) : (
              <th class={ThemeColor.bgF} style={{ width: "100%" }}></th>
            )}
          </tr>
          <tr>
            <th>Date</th>
            {attendanceData.map((attendance, index) => (
              <th key={index} className={attendance.isWeekOff ? ThemeColor.bgF : ""}>
                {attendance.dayOfMonth}
              </th>
            ))}

            {/* if table is not generating from the model then we don't need total column in last so this code is added */}
            {addProject ? (
              ""
            ) : (
              <th class={ThemeColor.bgF} style={{ width: "100%" }}>
                Hours
              </th>
            )}
          </tr>
          {hoursArray.map((hourType, index) => (
            <tr key={index}>
              <th>{hourType.name}</th>
              {attendanceData.map((attendance, dayIndex) => (

                <td
                  key={dayIndex}
                  style={{
                    backgroundColor: attendance.isWeekOff ? ThemeColor.WHITE : ThemeColor.lightEASTERNBLUE,
                    // Add other style properties here conditionally if needed
                  }}
                >
                  <input
                    value={
                      attendance[hourType.slug] != 0 && !addProject
                        ? attendance[hourType.slug]
                        : ""
                    }
                    name={`${hourType.slug}`}
                    readOnly={!isProjectWiseData}
                    //{`${hourType.slug}`}
                    type="text"
                    className="form-control tableInp"
                    onChange={(e) => handleChange(e, dayIndex)}
                    maxLength={2}
                    style={{
                      padding: 0,
                      textAlign: "center",
                      width: "30px",
                      border: "none",
                      backgroundColor: "transparent",
                    }}
                  />
                </td>
              ))}

              {/* if table is not generating from the model then we don't need total column in last so this code is added */}
              {addProject ? (
                ""
              ) : (
                <td style={{ width: "100%", padding: 0 }}>
                  {totalData[hourType.extraSlug] || 0}
                </td>
              )}
            </tr>
          ))}

          {leaveArray.map((leaveType, leaveIndex) => (
            <tr key={leaveIndex}>
              <th>{leaveType.name}</th>
              {attendanceData.map((attendance, dayIndex) => {
                return (
                  <td
                    key={dayIndex}
                    style={{
                      textAlign: "center",
                      backgroundColor: attendance.isWeekOff
                        ? ThemeColor.WHITE
                        : ThemeColor.lightEASTERNBLUE,
                    }}
                  >
                    {isProjectWiseData ? (
                      <input
                        value={leaveType.slug}
                        name="leaveType"
                        type="checkbox"
                        className="form-control tableInp"
                        checked={attendance.leaveType === leaveType.slug}
                        onChange={(e) => handleChange(e, dayIndex)}
                      />
                    ) : (
                      <input
                        readOnly
                        value={
                          attendance.leaveType === leaveType.slug ? "1" : ""
                        }
                        name="leaveType"
                        style={{
                          padding: 0,
                          textAlign: "center",
                          width: "30px",
                          border: "none",
                          backgroundColor: "transparent",
                        }}
                        className="form-control tableInp"
                      />
                    )}
                  </td>
                );
              })}

              {/* if table is not generating from the model then we don't need total column in last so this code is added */}
              {addProject ? (
                ""
              ) : (
                <td style={{ width: "100%", padding: 0 }}>
                  {totalData[leaveType.extraSlug] || 0}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const upperBody = (
    <div className="card-body">
      <div className="form-group row lg-12">
        <div className="col-sm-6 col-md-3 col-lg-2">
          <img
            src={
              employeeImg && employeeImg != null
                ? employeeImg
                : images.profileImage
            }
            alt="profile"
            //width="165px"
            height="165px"
          />
        </div>
        <div className="col-sm-6 col-md-8">
          <div className="table-responsive">
            <table
              className="table table-bordered"
              style={{ borderColor: "red !important" }}
            >
              <tbody>
                <tr>
                  <th width="30%">Month</th>
                  <td colspan="3">
                    <Select
                      name="monthSelect"
                      id="monthSelect"
                      placeholder="Select Month"
                      isSearchable
                      options={options}
                      // defaultValue={selectCurrentMonthValue}
                      value={
                        selectedMonthYearObj != null
                          ? selectedMonthYearObj
                          : options[0]
                      }
                      onChange={handleDropdownChange}
                    />
                  </td>
                </tr>
                <tr>
                  <th width="30%">Name</th>
                  <td colSpan="3">
                    <Select
                      name="employeeSelect"
                      id="employeeSelect"
                      placeholder="Select Employee"
                      isSearchable
                      isDisabled={userData.isEmployee ? true : false}
                      options={employeeOptions}
                      value={
                        selectedEmployee != null
                          ? selectedEmployee
                          : employeeOptions[0]
                      }
                      onChange={handleEmployeeSelection}
                    />
                  </td>
                </tr>
                <tr>
                  <th>Project Name</th>
                  <td colSpan="3">
                    <button
                      className="btn btn-primary"
                      style={{ padding: "10px 20px" }}
                      data-toggle="modal"
                      data-target=".bd-example-modal-xl"
                      onClick={() => {
                        handleAddProjectDDChange()
                      }}
                    >
                      Add project
                    </button>
                  </td>
                </tr>
                {remarkValue && remarkValue.currentMonthProjects && remarkValue.currentMonthProjects.length == 0 ?
                  (<tr>
                    <td colSpan="4"> No project added</td>
                  </tr>) :
                  remarkValue && remarkValue.currentMonthProjects && remarkValue.currentMonthProjects.length > 0 &&
                  remarkValue.currentMonthProjects.map((data) => {
                    return (
                      <tr>
                        <th
                          style={{
                            backgroundColor: ThemeColor.WHITE,
                            color: ThemeColor.BLACK,
                          }}
                        >
                          {data.projectName}
                        </th>
                        <td colSpan="3">
                          <button
                            className="btn btn-primary"
                            style={{ padding: "10px 20px" }}
                            data-toggle="modal"
                            data-target=".bd-example-modal-xl"
                            onClick={() => {
                              handleProjectDDChange(data, 'edit')
                            }}
                          >
                            Edit Attendance
                          </button>
                        </td>
                      </tr>
                    )
                  })
                }
                <tr>
                  <th>Remark</th>
                  <td colSpan="3">
                    <div className="form-group row d-flex pr-2">
                      <div className="col-md-9">
                        <textarea
                          id="remark"
                          name="remark"
                          cols={2}
                          rows={2}
                          className="form-control"
                          value={remarkValue != null && remarkValue.remarks ? remarkValue.remarks : ""}
                          onChange={(e) => setRemarkValue({ ...remarkValue, remarks: e.target.value })}
                        ></textarea>
                      </div>
                      <div className="col-md-3 col-sm-2 mt-1">
                        <button
                          className="btn btn-primary"
                          style={{
                            marginLeft: "10px",
                            padding: "5px 20px",
                            height: "50px",
                            width: "100%",
                          }}
                          onClick={handleAddRemarkClick}
                        >
                          {remarkValue != null && remarkValue.remarks ? "Update" : "Add"}
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );

  const lowerBody = (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="card-body">
          {attendanceTableView(monthlyAttendanceData, monthlyAttendanceTotalData)}
          <div
            class="modal fade bd-example-modal-xl"
            tabindex="-1"
            role="dialog"
            aria-labelledby="myExtraLargeModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-xl">
              <div class="modal-content p-2">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    Attendance
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={handleButtonClick}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="form-group row d-flex justify-content-center mt-3">
                  <div className="col-sm-2  col-md-4 ">
                    <Select
                      name="projectId"
                      id="projectId"
                      placeholder="Select Project"
                      isSearchable
                      value={selectedProject}
                      options={projectList.map((project) => ({
                        value: project.uid,
                        label: project.projectNumber + " - " + project.projectName ,
                      }))}
                      onChange={handleProjectDDChange}
                      onBlur={handleBlur}
                    />
                    {errors.projectId && touched.projectId && (
                      <div className="error" style={{ color: ThemeColor.RED }}>
                        {errors.projectId}
                      </div>
                    )}
                  </div>
                </div>
                {selectedProject ? attendanceTableView(
                  projectWiseAttendanceData,
                  projectWiseAttendanceTotalData,
                  true
                ) : attendanceTableView(monthlyAttendanceData, monthlyAttendanceTotalData)}
                <div
                  className="d-flex align-items-center justify-content-center mb-3"
                  style={{ gap: "10px", marginTop: "30px" }}
                >
                  {isDeleteEnable ?
                    <button
                      type="button"
                      className="btn btn-danger"
                      style={{
                        padding: "8px 20px",
                        width: "120px"
                      }}
                      onClick={() => {
                        setProjectIdToDelete(selectedProject);
                        setShowConfirmation(true);
                      }}
                    >
                      <i
                        className="fa fa-trash-o"
                        style={{ marginRight: "8px" }}
                      />
                      Delete
                    </button>

                    : null}
                  <button
                    type="submit"
                    className="btn btn-primary"
                    style={{
                      padding: "8px 20px",
                      width: "120px"
                    }}

                  >
                    <i
                      className="fa fa-floppy-o"
                      style={{ marginRight: "8px" }}
                    />
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn btn-default"
                    data-dismiss="modal"
                    style={{
                      padding: "8px 20px",
                      width: "120px",
                    }}
                    onClick={handleButtonClick}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form >
      <ConfirmationBox
        show={showConfirmation}
        onHide={() => setShowConfirmation(false)}
        onConfirm={handleConfirmDelete}
        message="Are you sure you want to delete project?"
      />
    </div >
  );

  return (
    <MainLayout breadcrumb={breadcrumb}>
      <div className="card">{upperBody}</div>
      <div className="card">{lowerBody}</div>
    </MainLayout>
  );
};

export default Attendance;
