import React, { useEffect, useState } from "react";
import Datatable from "../../components/datatable";
import { useDispatch } from "../../store";
import { ThemeColor } from "../../constants/themeColorConstant";
import { useLocation, useNavigate } from "react-router-dom";
import MainLayout from "../layout/MainLayout";
import { vehicleThunks } from "../../store/reducers/vehicleMatrix/vehicle.thunk";
import ConfirmationBox from "../../components/ConfirmationBox";

const Vehicle = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [vehicleData, setVehicleData] = useState([]);
  const [option, setOption] = useState({});
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [vehicleIdToDelete, setVehicleIdToDelete] = useState(null);


  const location = useLocation();
  const pathnameSegments = location.pathname.split("/");
  const moduleName = pathnameSegments[1];

  const allowedModules = JSON.parse(localStorage.getItem("allowedModules"));
  const selectedModule = allowedModules.find(
    (module) => module.slug === moduleName
  );

  const moduleId = selectedModule.uid;

  const renderActions = (rowData) => {
    if (rowData && rowData.uid) {
    const vehicleId = rowData.uid;
    const editIcon = (
      <i
        className="fa fa-edit fa-lg"
        style={{ cursor: "pointer" }}
         onClick={() => navigate(`/vehicle-matrix/edit/${vehicleId}`, { state: { moduleId } })}
      />
    );
    const historyIcon = (
      <i
      style={{ marginLeft: "10px", cursor: "pointer" }}
      class="fa fa-clock-o fa-lg"   onClick={() => navigate(`/vehicle-matrix/history/${vehicleId}`, { state: { moduleId } })}></i>
    )

    const deleteIcon = (
      <i style={{ marginLeft: "10px", cursor: "pointer" }}
        className="fa fa-trash-o fa-lg"
       onClick={() => {
        setVehicleIdToDelete(vehicleId);
        setShowConfirmation(true);
      }}
      />
    );

    return (
      <div>
        {editIcon}
        {deleteIcon}
        {historyIcon}
      </div>
    );
    }
    else{
      return null
    }
  };

  const columns = [
    {
      name: "action",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const rowData = vehicleData[dataIndex];
          return renderActions(rowData);
        },
      },
    },
    {
      name: "projectName",
      label: "Project",
    },
    {
      name: "description",
      label: "Description",
      options: {
        filter: false,
        sort: true,
      },
    },
   
    {
      name: "vehicleName",
      label: "Vehicle Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
        name: "from_date",
        label: "From Date",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "to_date",
        label: "To Date",
        options: {
          filter: false,
          sort: true,
        },
      },
  ];

  useEffect(() => {
    getVehicleDetailsApi()
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


    const getVehicleDetailsApi = async (page=1,rowsPerPage=10,searchText='',name='',order='') => {
      try {
        const response = await dispatch(vehicleThunks.vehicleListData({moduleId,page,rowsPerPage,searchText,name,order}));
        if (vehicleThunks.vehicleListData.fulfilled.match(response)) {
          if (response.payload) {
            const options = {
              count: response.payload.totalRecords,
              page: response.payload.currentPage-1, // Zero-based index
              rowsPerPage:response.payload.recordsPerPage,
            }
            setOption(options)
            setVehicleData(response.payload.data);
            return response.payload
          }
        }
      } catch (error) {
        console.error("Error fetching client data:", error);
      }
    };

    const handleConfirmDelete = async () => {
      try {
        const response = await dispatch(vehicleThunks.deleteVehicle({id:vehicleIdToDelete,moduleId}));
        if (vehicleThunks.deleteVehicle.fulfilled.match(response)) {
          const updatedVehicleData = vehicleData.filter((vehicle) => vehicle && vehicle.uid !== vehicleIdToDelete); // Check if 'role' exists
          setVehicleData(updatedVehicleData);
        }
      } catch (error) {
        console.error("Error fetching asset data:", error);
      }
      setVehicleIdToDelete(null);
      setShowConfirmation(false);
    };


  const breadcrumb = {
    mainHeader: "Vehicle Matrix",
    subHeader: "Home",
    subHeaderLink: "/dashboard",
    subHeader1: "listing",
    subHeader1Link: null,
    subHeader2: null,
    subHeader2Link: null,
  };

  return (
    <MainLayout breadcrumb={breadcrumb}>
      <div className="card">
        <div className="card-body">
          <div className="card-header ">
            <p
              className="card-title"
              style={{
                fontSize: "20px",
                fontWeight: "500",
                color: ThemeColor.EASTERNBLUE,
              }}
            >
           Vehicle Matrix
            </p>
            <div className="d-flex justify-content-end">
              <button
                onClick={() =>
                  navigate("/vehicle-matrix/add", { state: { moduleId } })
                }
                className="btn btn-primary"
              >
                Add
              </button>

            </div>
          </div>
          <Datatable
            columns={columns}
            data={vehicleData}
            options={option}
            customBodyRender={renderActions}
          />
        </div>
      </div>
      <ConfirmationBox
        show={showConfirmation}
        onHide={() => setShowConfirmation(false)}
        onConfirm={handleConfirmDelete}
        message="Are you sure you want to delete vehicle?"
      />
    </MainLayout>
  );
};

export default Vehicle;
