import React, { useEffect, useState } from "react";
import Datatable from "../../components/datatable";
import { useDispatch } from "../../store";
import { ThemeColor } from "../../constants/themeColorConstant";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MainLayout from "../layout/MainLayout";
import { clientThunks } from "../../store/reducers/client/client.thunk";

const ContactManage = () => {
    const id = useParams()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [clientContactData, setClientContactData] = useState([]);
  const [option, setOption] = useState({});
  const [clientList,setClientList] = useState([])
  const [selectedClient, setSelectedClient] = useState(id.id);


  const location = useLocation();
  const pathnameSegments = location.pathname.split("/");
  const moduleName = pathnameSegments[1];

  const allowedModules = JSON.parse(localStorage.getItem("allowedModules"));
  const selectedModule = allowedModules.find(
    (module) => module.slug === moduleName
  );

  const moduleId = selectedModule.uid;

  const renderActions = (rowData) => {
    if (rowData && rowData.uid) {
   const clientId = rowData.uid;
    const editIcon = (
      <i
        className="fa fa-edit"
        style={{ cursor: "pointer" }}
        onClick={() => navigate(`/clients/contact-manage/edit/${clientId}`, { state: { moduleId } })}
      />
    );

    return (
      <div>
        {editIcon}
      </div>
    );}
    else{
        return null
    }
  };

  const columns = [
    {
      name: "action",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const rowData = clientContactData[dataIndex];
          return renderActions(rowData);
        },
      },
    },
    {
      name: "contactName",
      label: "Contact Name",
    },
    {
      name: "mobile",
      label: "Mobile",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "officeTel",
      label: "Office Tel",
    },
   
    {
        name: "email",
        label: "Email",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "designation",
        label: "Designation",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "short",
        label: "Short",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "clientName",
        label: "Client Name",
        options: {
          filter: false,
          sort: true,
        },
      },
  ];

  useEffect(() => {
    contactListApi();
    clientListApi()
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const clientListApi = async () => {
    try {
      const response = await dispatch(
        clientThunks.clientList({moduleId })
      );
      if (clientThunks.clientList.fulfilled.match(response)) {
        if (response.payload) {
          setClientList(response.payload);
          return response.payload;
        }
      }
    } catch (error) {
      console.error("Error fetching company data:", error);
    }
  };

  const handleClientChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedClient(selectedValue);
    contactListApi(selectedClient);
  };


    const contactListApi = async (page=1,rowsPerPage=10,searchText='',name='',order='') => {
      try {
          const response = await dispatch(clientThunks.clientContactList({selectedClient,moduleId, page, rowsPerPage, searchText, name, order }));
          if (clientThunks.clientContactList.fulfilled.match(response)) {
            if (response.payload) {
              const options = {
                count: response.payload.totalRecords,
                page: response.payload.currentPage - 1, // Zero-based index
                rowsPerPage: response.payload.recordPerPage,
              }
              setOption(options)
              setClientContactData(response.payload.data);
              return response.payload;
            }
          }
        } 
      catch (error) {
        console.error("Error fetching client data:", error);
      }
    };

  const breadcrumb = {
    mainHeader: "Contacts Manage",
    subHeader: "Home",
    subHeaderLink: "/dashboard",
    subHeader1: "Client",
    subHeader1Link: "/clients",
    subHeader2: "Contacts Manage",
    subHeader2Link: null,
  };


  return (
    <MainLayout breadcrumb={breadcrumb}>
      <div className="card">
        <div className="card-body">
          <div className="card-header ">
            <p
              className="card-title"
              style={{
                fontSize: "20px",
                fontWeight: "500",
                color: ThemeColor.EASTERNBLUE,
              }}
            >
            Contacts Manage
            </p>
            <div className="d-flex justify-content-end">
              <button
                onClick={() =>
                  navigate(`/clients/contact-manage/add/${selectedClient}`, { state: { moduleId } })
                }
                className="btn btn-primary"
              >
                Add
              </button>
            </div>
          </div>
          <div className="form-group row d-flex justify-content-center mt-5">
              <div className="col-sm-4  col-md-4">
                <select className="form-control" name="status" id="status"
                
                 onChange={handleClientChange} 
                 >
                  <option>Select Client</option>
                  {clientList.map((data, index) => {
                        return (
                          <option key={index} value={data.uid} selected={data.uid === id.id}>
                            {data.name}
                          </option>
                        );
                  })}
                </select>
              </div>
            </div>
          <Datatable
            columns={columns}
            data={clientContactData}
            options={option}
            customBodyRender={renderActions}
          />
        </div>
      </div>
    </MainLayout>
  );
};

export default ContactManage;
