import React from "react";

const Datepicker = ({ name, id, dateFormat, value, onChange,readOnly }) => {
  var newdate;
  if (value) newdate = value.split("-").reverse().join("-");
  return (
    <input
      type="date"
      // defaultValue="2017-05-20"
      name={name}
      id={id}
      value={newdate}
      dateFormat={dateFormat}
      onChange={onChange}
      className="form-control"
      readOnly={readOnly}
    />
  );
};

export default Datepicker;
