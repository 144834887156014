import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "../../store";
import { ThemeColor } from "../../constants/themeColorConstant";
import MainLayout from "../layout/MainLayout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {parseCustomDate } from "../employee/Personal";
import Datepicker from "../../components/datepicker";
import { assetThunks } from "../../store/reducers/asset/asset.thunk";
import { projectThunks } from "../../store/reducers/projects/project.thunk";
import { employeeThunks } from "../../store/reducers/employee/employee.thunk";
import Select from "react-select";

const validationSchema = Yup.object().shape({
  assetCode: Yup.string().required("Please enter asset code"),
    manufacturer : Yup.string().required("please enter manufacturer")

   
});

const EditAssets = () => {
  const [activeTab, setActiveTab] = useState("Edit Asset");
  const [assetsData, setAssetsData] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [statusData,setStatusData] = useState([])
  const [typeData,setTypeData] = useState([])
  const [projectData,setProjectData] = useState([])
 

  const location = useLocation();
  const { moduleId } = location.state || {};
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    resetForm,
    setValues,
  } = useFormik({
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      delete values.projectIdObj;
      delete values.assignToObj;
      const formattedValues = {
        ...values,
        fromDate: values.fromDate && parseCustomDate(values.fromDate),
        expiryDate: values.expiryDate && parseCustomDate(values.expiryDate),
        purchaseDate: values.purchaseDate && parseCustomDate(values.purchaseDate),
        installedDate: values.installedDate && parseCustomDate(values.installedDate),
      };
      await updateAssetDataApi(formattedValues);
      resetForm();
    },
    initialValues: {
      assetCode: assetsData ? assetsData.assetCode : "",
      manufacturer: assetsData ? assetsData.manufacturer : "",
      serialNumber: assetsData ? assetsData.serialNumber : null,
      purchaseDate: assetsData ? assetsData.purchaseDate : null,
      type: assetsData ? assetsData.type : null,
      status: assetsData ? assetsData.status : null,
      description: assetsData ? assetsData.description : null,
      expiryDate: assetsData ? assetsData.expiryDate : null,
      softwareName: assetsData ? assetsData.softwareName : null,
      installedDate: assetsData ? assetsData.installedDate : null,
      projectId: assetsData.project ? assetsData.project.uid : null,
      projectIdObj: assetsData.project ? assetsData.project : null,
      office: assetsData ? assetsData.office : null,
      assignTo: assetsData.employee ? assetsData.employee.uid : null,
      assignToObj: assetsData.employee ? assetsData.employee : null,
      fromDate:
        assetsData && assetsData.fromDate
          ? assetsData.fromDate
          : null,

    },
  });


  useEffect(() => {
    getAssetDataApi()
    employeeListApi()
    getStatusApi()
    getTypesApi()
    getProjectApi()
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
 
  const loginClickHandle = (e) => {
    e.preventDefault();
    e.persist();
    handleSubmit(e);
  }

  const updateAssetDataApi = async (data) => {
    try {
      const response = await dispatch(
        assetThunks.updateAsset({ id, data, moduleId })
      );
      if (assetThunks.updateAsset.fulfilled.match(response)) {
        navigate("/assets");
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const getAssetDataApi = async () => {
    try {
      const response = await dispatch(assetThunks.assetDetails({id,moduleId}));
      if (assetThunks.assetDetails.fulfilled.match(response)) {
        if (response.payload) {
          let project = {};
          let employee = {};
          let res = response.payload;
          if(res && res.project){
          project.value =  res.project.uid;
          project.label = res.project.project_name;
          res.project = project
        }
        if(res && res.employee){
          employee.value =  res.employee.uid;
          employee.label = res.employee.name;
          res.employee = employee
        }
          setAssetsData(res);
        }
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const getStatusApi = async () => {
    try {
      const response = await dispatch(
        assetThunks.statusList(moduleId)
      );
      if (assetThunks.statusList.fulfilled.match(response)) {
        if (response.payload) {
          setStatusData(response.payload);
        }
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const getTypesApi = async () => {
    try {
      const response = await dispatch(
        assetThunks.typeList(moduleId)
      );
      if (assetThunks.typeList.fulfilled.match(response)) {
        if (response.payload) {
          setTypeData(response.payload);
        }
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };
  const getProjectApi = async () => {
    try {
      const response = await dispatch(
        projectThunks.projectList(moduleId)
      );
      if (projectThunks.projectList.fulfilled.match(response)) {
        if (response.payload) {
          setProjectData(response.payload);
        }
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const employeeListApi = async () => {
    try {
      const response = await dispatch(
        employeeThunks.employeeList({moduleId })
      );
      if (employeeThunks.employeeList.fulfilled.match(response)) {
        if (response.payload) {
          setEmployeeList(response.payload);
          return response.payload;
        }
      }
    } catch (error) {
      console.error("Error fetching company data:", error);
    }
  };

  
  const breadcrumb = {
    mainHeader: "Asset Edit",
    subHeader: "Home",
    subHeaderLink: "/dashboard",
    subHeader1: "Asset",
    subHeader1Link: "/assets",
    subHeader2: "edit",
    subHeader2Link: null,
  };

  return (
    <MainLayout breadcrumb={breadcrumb}>
      <div className="card card-info">
        <div className="card-body">
          <ul
            className="nav nav-tabs"
            style={{
              borderBottom: `2px solid ${ThemeColor.GREY}`,
              backgroundColor: "transparent",
            }}
          >
            <li className="nav-item">
              <button
                className={`nav-link ${
                  activeTab === "Edit Asset" ? "active" : ""
                }`}
                onClick={() => handleTabChange("Edit Asset")}
                style={{
                  borderBottom: `3px solid ${
                    activeTab === "Edit Asset"
                      ? ThemeColor.BLUE
                      : "transparent"
                  }`,
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  backgroundColor: "transparent",
                }}
              >
               Edit Asset
              </button>
            </li>
          </ul>
          <form
            className="form-horizontal"
            id="asset_info"
            onSubmit={handleSubmit}
          >
            <div className="card-body">
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">
                  Asset Code <span style={{ color: ThemeColor.RED }}>*</span>
                </label>
                <div className="col-sm-5  col-md-5">
                  <input
                    type="text"
                    className="form-control"
                    name="assetCode"
                    id="assetCode"
                    placeholder="Asset Code"
                    value={values.assetCode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.assetCode && touched.assetCode && (
                    <div className="error" style={{ color: ThemeColor.RED }}>
                      {errors.assetCode}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">Manufacturer <span style={{ color: ThemeColor.RED }}>*</span>
                </label>
                <div className="col-sm-5  col-md-5">
                  <input
                    type="text"
                    className="form-control"
                    name="manufacturer"
                    id="manufacturer"
                    placeholder="Manufacturer"
                    value={values.manufacturer}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.manufacturer && touched.manufacturer && (
                    <div className="error" style={{ color: ThemeColor.RED }}>
                      {errors.manufacturer}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">Serial Number</label>
                <div className="col-sm-5  col-md-5">
                  <input
                    type="text"
                    className="form-control"
                    name="serialNumber"
                    id="serialNumber"
                    placeholder="Serial Number"
                    value={values.serialNumber}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">Purchase Date</label>
                <div className="col-sm-5  col-md-5">
                <Datepicker
                    name="purchaseDate"
                    id="purchaseDate"
                    value={values.purchaseDate}
                    dateFormat="yyyy-mm-dd"
                    onChange={(date) => setValues({ ...values, purchaseDate: parseCustomDate(date.target.value) })}
                  /> 
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">Type</label>
                <div className="col-sm-5  col-md-5">
                  <select
                    className="form-control"
                    name="type"
                    id="type"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option>Select</option>
                    {typeData.map((data, index) => {
                      return (
                        <option
                          key={index}
                          selected={data.slug === values.type}
                          value={data.slug}
                        >
                          {data.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">Status</label>
                <div className="col-sm-5  col-md-5">
                  <select
                    className="form-control"
                    name="status"
                    id="status"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option>Select</option>
                    {statusData.map((data, index) => {
                      return (
                        <option
                          key={index}
                          selected={data.slug === values.status}
                          value={data.slug}
                        >
                          {data.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">Description</label>
                <div className="col-sm-5  col-md-5">
                  <textarea
                    id="description"
                    name="description"
                    cols={2}
                    rows={2}
                    className="form-control"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  ></textarea>
                </div>
              </div>
         
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">Software Name</label>
                <div className="col-sm-5  col-md-5">
                  <input
                    type="text"
                    className="form-control"
                    name="softwareName"
                    id="softwareName"
                    placeholder="Software Name"
                    value={values.softwareName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">Installed Date</label>
                <div className="col-sm-5  col-md-5">
                <Datepicker
                    name="installedDate"
                    id="installedDate"
                    value={values.installedDate}
                    dateFormat="yyyy-mm-dd"
                    onChange={(date) => setValues({ ...values, installedDate: parseCustomDate(date.target.value) })}
                  /> 
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">Expiry Date</label>
                <div className="col-sm-5  col-md-5">
                <Datepicker
                    name="expiryDate"
                    id="expiryDate"
                    value={values.expiryDate}
                    dateFormat="yyyy-mm-dd"
                    onChange={(date) => setValues({ ...values, expiryDate: parseCustomDate(date.target.value) })}
                  /> 
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">Project</label>
                <div className="col-sm-5  col-md-5">
                <Select
                placeholder="Select Project"
                isSearchable
                id="project"
                name="project"
                onChange={(selectedOption) => {
                  setValues({
                    ...values,
                    projectIdObj: selectedOption,
                    projectId: selectedOption.value,
                  })
                }}
                value={
                  values.projectIdObj
                }
                options={projectData.map((project) => ({
                  value: project.uid,
                  label: project.project_name,
                }))}
                    />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">Office / Site</label>
                <div className="col-sm-5  col-md-5">
                  <input
                    type="text"
                    className="form-control"
                    name="office"
                    id="office"
                    placeholder="Office / Site"
                    value={values.office}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">Assigned To</label>
                <div className="col-sm-5  col-md-5">
                  {/* <select
                    className="form-control"
                    name="assignTo"
                    id="assignTo"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option>Select</option>
                    {employeeList.map((data, index) => {
                      return (
                        <option
                          key={index}
                          selected={data.uid === values.assignTo}
                          value={data.uid}
                        >
                          {data.name}
                        </option>
                      );
                    })}
                  </select> */}
                    <Select
                placeholder="Select Employee"
                isSearchable
                id="assignTo"
                name="assignTo"
                onChange={(selectedOption) => {
                  setValues({
                    ...values,
                    assignToObj: selectedOption,
                    assignTo: selectedOption.value,
                  })
                }}
                value={
                  values.assignToObj
                }
                options={employeeList.map((employee) => ({
                  value: employee.uid,
                  label:  employee.employee_code + " - " + employee.name,
                }))}
                    />
                </div>
              </div>
             
              <div className="form-group row">
                <label className="col-sm-4 col-md-3">From Date</label>
                <div className="col-sm-5  col-md-5">
                  <Datepicker
                    name="fromDate"
                    id="fromDate"
                    value={values.fromDate}
                    dateFormat="yyyy-mm-dd"
                    onChange={(date) => setValues({ ...values, fromDate: parseCustomDate(date.target.value) })}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <button
                type="submit"
                className="btn btn-primary"
                style={{ padding: "8px 20px" }}
                onClick={(e) => loginClickHandle(e)}
              >
                <i className="fa fa-edit" style={{ marginRight: "8px" }} />
                Update
              </button>
              <button
                type="button"
                className="btn btn-default"
                style={{ padding: "8px 20px", marginLeft: "10px" }}
                onClick={()=>{
                  navigate("/assets")
                }}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </MainLayout>
  );
};

export default EditAssets;
