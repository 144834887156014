import React, { useEffect, useState } from 'react'
import { useFormik } from "formik";
import { useDispatch } from '../../store';
import { useParams } from 'react-router';
import {parseCustomDate } from '../employee/Personal';
import { insuranceThunks } from '../../store/reducers/insurance/insurance.thunk';
import Datepicker from '../../components/datepicker';


const HealthInsuranceDetails = ({ activeTab, moduleId, employeeData }) => {

  const dispatch = useDispatch()
  const [healthType,setHealthType] = useState([])
  const { id } = useParams()

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
  } = useFormik({
    enableReinitialize: true,
    onSubmit: async (values) => {
      const formattedValues = {
        ...values,
        expiry: values.expiry && parseCustomDate(values.expiry),
      };

      await updateInsuranceDetailsApi(formattedValues)
    },
    initialValues: {
      medicalInsuranceProvider: employeeData.insurance ? employeeData.insurance.medicalInsuranceProvider : null,
      policyNumber: employeeData.insurance ? employeeData.insurance.policyNumber : null,
      expiry: employeeData.insurance ? employeeData.insurance.expiry : null,
      type: employeeData.insurance ? employeeData.insurance.type : null,
      coverage: employeeData.insurance ? employeeData.insurance.coverage : null
    },
  });

  useEffect(()=>{
    getHealthTypeApi()
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const updateInsuranceDetailsApi = async (data) => {
    try {
      const response = await dispatch(insuranceThunks.updateInsuranceDetails({ id, data, moduleId, activeTab }));
      if (insuranceThunks.updateInsuranceDetails.fulfilled.match(response)) {
        return response.payload
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const getHealthTypeApi = async () => {
    try {
      const response = await dispatch(insuranceThunks.healthTypeList(moduleId));
      if (insuranceThunks.healthTypeList.fulfilled.match(response)) {
        if (response.payload) {
          setHealthType(response.payload);
        }
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  return (
    <form className="form-horizontal" id="education_info" onSubmit={handleSubmit}>
      <div className="card-body">
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Medical Insurance Provider</label>
          <div className="col-sm-5  col-md-5">
            <input
              type="text"
              className="form-control"
              name="medicalInsuranceProvider"
              id="medicalInsuranceProvider"
              placeholder="Medical Insurance Provider"
              value={values.medicalInsuranceProvider}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Card/Policy Number</label>
          <div className="col-sm-5  col-md-5">
            <input
              type="text"
              className="form-control"
              name="policyNumber"
              id="policyNumber"
              placeholder="Policy Number"
              value={values.policyNumber}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Expiry</label>
          <div className="col-sm-5  col-md-5">
            <div className='form-group'>
              <Datepicker
                name="expiry"
                id="expiry"
                value={values.expiry}
                dateFormat="yyyy-mm-dd"
                onChange={(date) => 
                  setValues({ ...values, expiry: parseCustomDate(date.target.value) })
                }
              />
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Type</label>
          <div className="col-sm-5  col-md-5">
            <select
              className="form-control"
              name="type"
              id="type"
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option>Select</option>
              {healthType.map((data) => {
                return (
                  <option
                    key={data.slug}
                    selected={data.slug === values.type}
                    value={data.slug}
                  >
                    {data.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Coverage</label>
          <div className="col-sm-5  col-md-5">
            <input
              type="text"
              className="form-control"
              name="coverage"
              id="coverage"
              placeholder="Link to download"
              value={values.coverage}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <button
          type="submit"
          className="btn btn-primary"
          style={{ padding: "8px 20px" }}
        >
          <i className="fa fa-floppy-o" style={{ marginRight: "8px" }} />
          Update
        </button>
      </div>
    </form>
  )
}

export default HealthInsuranceDetails